import PouchDB from 'pouchdb-browser'
const db = new PouchDB('ecrimp-web')

export const useDB = () => {
    const get = async key => {
        try {
            const doc = await db.get(key)
            return doc
        } catch (error) {
            return null
        }
    }

    const put = async (key, data) => {
        const _id = key;
        const doc = await get(key)
        if (doc && doc.data === data) return doc
        return db.put({
            _id,
            _rev: doc?._rev,
            data,
        })
    }

    const remove = async key => {
        try {
            const doc = await get(key)
            if (doc) {
                return db.remove(doc)
            }
        } catch (error) {
        }
    }

    return {
        get, put, remove
    }
}