import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'
import "react-toastify/dist/ReactToastify.css";
import ApiServices from '../../utils/ApiServices';
import './DashboardStyles.css'
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Tabs, Tab } from "react-bootstrap";
import 'react-bootstrap/Accordion'
import Modal from 'react-bootstrap/Modal'
import { T } from 'react-translator-component'
import API from './../../utils/config.json'
import ToggleButton from 'react-toggle-button';
import { ToastContainer, toast } from 'react-toastify';
import { Ui } from './../../components/common/Toaster';
import { UnitConversion } from './UnitConversion'
import { font } from "./../FranklinGothic-normal";
import { fontBold } from "../FranklinGothic-Heavy";
import { fontDemi } from "../FranklinGothic-Demi";
import { fontItalic } from "../FranklinGothic-italic";
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import { clearNotesData, saveAccessoriesDataInIndexDB, saveAppliedNotesDataDataInIndexDB, saveFavoritesDataInIndexDB, getFavoritesDataFromIndexDB, saveresponseDataInIndexDB, savefinalNotesDashboardDataInIndexDB, getfinalNotesDashboardDataFromIndexDB, clearAppliedNotesData, getAppliedNotesDataDataFromIndexDB, getuserDataFromIndexDB, saveSelectedCrimper, getSelectedCrimper } from "../../IndexedDB/Utility";
import Util from "../../utils/Util";
import Observer from "./Observer";
import { PressureTypes, connectDataContext } from "../common/DataProvider";
import { AuthContext } from "../auth-hook/auth-context";
import { addPowers, isEmpty } from "../common/stringUtils";
var _ = require('lodash');

class MainDashboard extends Component {

    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.fetch = this.fetch.bind(this);
        this.state = {
            crimpersResponse: [],
            crimpers: [],
            filteredDatacrimpers: [],
            dies: [],
            hoseDescriptions: [],
            hoseDashes: [],
            stemTypes: [],
            pressures: [],
            selectDisabled: false,
            tableData: [],
            totalTableData: 0,
            activeId: '0',
            filterCrimper: "",
            filterDies: "",
            filterHoseDes: "",
            filterHoseSize: "",
            filterstem: "",
            filterPressure: "",
            selectedCrimper: "",
            filterArray: [],
            filterArray2: [],
            valuesDies: [],
            valuesDescription: [],
            valuesDashsize: [],
            valuesStemtype: [],
            valuesPressure: [],
            selectedCrimper: "",
            searchText: '',
            enableDownload: false,
            enableDownloadSelected: false,
            favdata: [],
            open: false,
            appliedFilters: false,
            openFilters: false,
            selectedDatatoPrint: [],
            openAddFilter: false,
            crimpsheets: [],
            crimperSheetName: "",
            crimpsheetsdetails: [],
            Toggle: this.props.pressureType === PressureTypes.mpa ? true : false,
            ApplyFilters: false,
            AtleastOneFilterSelected: false,
            invalidCrimpname: "",// Added in favor to retain the selected crimper throughtout the User session
            IsHydraulic: true,
            IsIndustrial: true,
            IsLifeGuard: true,
            IsNylon: true,
            selectedModesTypes: ["HYD", "IND"],
            selectedModesTypesAcc: ["LG", "HG"],
            OpenHoseSpec: false,
            dataForNotesLink: [],
            NotesMessage: [],
            NotesURL: [],
            openNotes: false,
            selectedHoseforLink: "",
            selectedSizeforLink: "",

            valuesDies2: [],
            valuesDescription2: [],
            valuesDashsize2: [],
            valuesStemtype2: [],
            valuesPressure2: [],
            selectedModesTypes2: [],
            favoritesData: [],
            openFav: false,
            removeSelectedFav: false,
            isLoading: false,
            accessoriesData: [],
            filteraccessoriesData: [],
            tableData2: [],



            valuesDescriptionAcc: [],
            valuesDashsizeAcc: [],
            valuesStemtypeAcc: [],
            valuesFerruleAcc: [],
            valuesDescriptionAcc2: [],
            valuesDashsizeAcc2: [],
            valuesStemtypeAcc2: [],
            valuesFerruleAcc2: [],
            activeEventKey: "Hoses",
            tableDataForAccessories: [],
            tableData2ForAccessories: [],
            openHoseSize: false,
            openOrientation: false,
            hoseSizeTbl: [],
            AppliedNotesData: [],
            originalFilterDta: [],

            HideSkiveLength: false,
            HideSkiveDiameter: false,
            Hidecrimplength: false,
            Hidecrimpinsertion: false,
            HIdecrimpTail: false,
            tabledataforPDF: [],
            hideSelectionbtns: false,
            FilteredNotesImage: [],

            selectedHeader: null,
            crimpersLoading: false,

        }
    }

    async componentDidMount() {
        // Added in favor to retain the selected crimper throughtout the User session
        const { crimper, revision } = this.props
        if (revision === null) return;
        this.setState({ open: !(!!crimper), hideSelectionbtns: !!crimper })
        if (!!crimper) {
            this.handleCrimperValueChanges("crimper", crimper, false)
        }
        // Added in favor to retain the selected crimper throughtout the User session

        await this.getFavorites();
        await this.getCrimpSheets();
        await this.getHoseSizeTbl();
        await this.getAccessories();
        await this.GetCrimpersDta();
        this.checkCondition();

        if(this.props.location.search) {
            await this.handleUrlParams()
        }

    }

    handleUrlParams = async () => {
        const search = this.props.location.search
        if (!search) return



        const params = new URLSearchParams(search)
        let hose, dashSizes = [], stemTypes = [], dieSets = []
        const hasHose = params.has('selectedHose'), hasDieSets = params.has('selectedDieSets'), hasDashSizes = params.has('selectedDashSizes'), hasStemTypes = params.has('selectedStemTypes')

        if (params.has('selectedCrimper')) {
            const crimper = params.get('selectedCrimper')
            if (crimper && crimper !== 'null' && crimper !== 'undefined') {
                await this.handleCrimperValueChanges("crimper", crimper, true, false)
            }
        }
        if (hasHose) {
            const selectedHose = params.get('selectedHose')
            if (selectedHose && selectedHose !== 'null' && selectedHose !== 'undefined') {
                hose = selectedHose
                this.setState({
                    DataHoseDescriptionsfiltered: [{
                        name: hose,
                        isChecked: true,
                    }]
                })
            }
        }
        if (hasDieSets) {
            const selectedDieSets = params.get('selectedDieSets')
            if (selectedDieSets && selectedDieSets !== 'null' && selectedDieSets !== 'undefined') {
                if (Array.isArray(selectedDieSets)) {
                    dieSets = selectedDieSets
                } else {
                    dieSets = [selectedDieSets]
                }
                this.setState({ DataDiesfiltered: dieSets.map(name => ({ name, isChecked: true, }),), })
            }
        }

        if (hasDashSizes) {
            const selectedDashSizes = params.get('selectedDashSizes')
            if (selectedDashSizes && selectedDashSizes !== 'null' && selectedDashSizes !== 'undefined') {
                if (Array.isArray(selectedDashSizes)) {
                    dashSizes = selectedDashSizes
                } else {
                    dashSizes = [selectedDashSizes]
                }

                this.setState({ DataHoseDashesfiltered: dashSizes.map(name => ({ name, isChecked: true, }),) })
            }
        }

        if (hasStemTypes) {
            const selectedStemTypes = params.get('selectedStemTypes')
            if (selectedStemTypes && selectedStemTypes !== 'null' && selectedStemTypes !== 'undefined') {
                if (Array.isArray(selectedStemTypes)) {
                    stemTypes = selectedStemTypes
                } else {
                    stemTypes = [selectedStemTypes]
                }
                this.setState({ DataStemTypesfiltered: stemTypes.map(name => ({ name, isChecked: true, }),), })
            }
        }


        if(hasHose || hasDieSets || hasDashSizes || hasStemTypes) {
            this.setState({ valuesDies: dieSets, valuesDashsize: dashSizes, valuesDescription: [hose], valuesStemtype: stemTypes })
            await this.handleSubmit('typehoseMode', dieSets, hose, dashSizes, stemTypes)
        }

    }

    handleCrimpChange = crimper => {
        if (crimper !== this.state.selectedCrimper) {
            if (crimper === null) {
                this.setState({ open: true })
            }

            this.setState({ selectedCrimper: crimper })
        }
    }

    GetCrimpersDta = async () => {

        // Get the Crimpers data
        this.setState({
            crimpersLoading: true,
            crimpersResponse: null,
        })
        const region = this.props.region;
        var url = API.URL + "crimpers?$format=json&$filter=REGION_ID eq '" + region + "'&$select=CRIMPER_ID,URL";


        await ApiServices.httpGET(url, (err, response) => {

            if (response?.data?.d?.results) {

                let responseCrimpers = response.data.d.results;

                let index = responseCrimpers.map(object => object.CRIMPER_ID).indexOf('Field Attachable');

                if (index != -1) {
                    responseCrimpers.splice(responseCrimpers.length - 1, 0, responseCrimpers.splice(index, 1)[0]);
                }

                let filteredDatacrimpers = responseCrimpers.map(item => item.CRIMPER_ID)
                    .filter((value, index, self) => self.indexOf(value) === index)
                this.setState({
                    crimpersResponse: responseCrimpers,
                    crimpers: filteredDatacrimpers,
                    FilteredCrimper: filteredDatacrimpers,
                    filteredDatacrimpers: filteredDatacrimpers,
                    selectDisabled: false,
                    crimpersLoading: false,
                });
            };
        });
    }

    getAccessories = async () => {
        const { selectedCrimper, selectedModesTypesAcc } = this.state
        var AccesoriesTypeModes = ""

        if (selectedModesTypesAcc.length != 0) {
            selectedModesTypesAcc.forEach((value, index) => {
                if (index == 0) {
                    AccesoriesTypeModes += ` and substringof('${value}', SLEEVE_ID)`;
                } else {
                    AccesoriesTypeModes += ` or substringof('${value}', SLEEVE_ID)`;
                }
            })

            var a = AccesoriesTypeModes;
            var b = " (";
            var c = ")";
            var position = 5;
            var position1 = -1;
            var output1 = [a.slice(0, position), b, a.slice(position)].join('');
            var output2 = [output1.slice(0, output1.length), c, output1.slice(output1.length)].join('');
            AccesoriesTypeModes = output2
            if (AccesoriesTypeModes.length < 6) {
                AccesoriesTypeModes = ""
            }
        }
        var crimper = selectedCrimper
        var url = API.URL + "accessories?$filter=CRIMPER_ID eq '" + crimper + "'" + AccesoriesTypeModes + "&$format=json"

        ApiServices.httpGET(url, (err, response) => {
            if (response?.data?.d?.results) {

                let responseCrimpers = response.data.d.results;
                for (var jk = 0; jk < responseCrimpers.length; jk++) {
                    if (responseCrimpers[jk].DASH_SIZE != null) {

                        if (responseCrimpers[jk].DASH_SIZE < 10) {

                            responseCrimpers[jk].DASH_SIZE = '0' + responseCrimpers[jk].DASH_SIZE

                        }
                    }
                }
                saveAccessoriesDataInIndexDB(responseCrimpers);
                // localStorage.setItem("Filteredaccessories", JSON.stringify(responseCrimpers))

                this.setState({
                    accessoriesData: responseCrimpers,
                    filteraccessoriesData: responseCrimpers,

                });
                this.setValueforAccesories(responseCrimpers);
            };
        });
    }
    getCrimpSheets = async () => {
        // Get the Crimp sheets data
        var email = Util.getCookie("email")
        var url = API.URL + "users/crimpsheets?$format=json&$filter=EMAIL eq '" + email + "'"
        await ApiServices.httpGET(url, (err, response) => {
            if (response?.data?.d?.results) {
                var crimpsheets = response.data.d.results;
                this.setState({ crimpsheets: response.data.d.results })
            };
        });
    }
    getHoseSizeTbl = async () => {
        // Get the HOse size icon table data
        var url = API.URL + `dash-size-conversions`

        await ApiServices.httpGET(url, (err, response) => {
            if (response?.data?.d?.results) {
                var hoseSizeTbl = response.data.d.results;
                this.setState({ hoseSizeTbl: hoseSizeTbl })
            };
        });
    }
    getCrimpSheetsTocreate = (name) => {
        // Get the Crimp sheets data
        var email = Util.getCookie("email")
        var url = API.URL + "users/crimpsheets?$format=json&$filter=EMAIL eq '" + email + "'"

        ApiServices.httpGET(url, (err, response) => {

            if (response) {
                var crimpsheets = response.data.d.results;
                for (var i = 0; i < crimpsheets.length; i++) {
                    if (crimpsheets[i].NAME == name) {
                        var Crimpsheetid = crimpsheets[i].CRIMPSHEET_ID
                    }
                }
                this.addToCrimperSheets(Crimpsheetid)
            };
        });
    }

    getFavorites = async (type = false) => {
        // Get the Favorites data
        this.setState({ isLoading: true })
        var email = Util.getCookie("email")

        var url = API.URL + "users/favorites?$format=json&$filter=EMAIL eq '" + email + "'"

        await ApiServices.httpGET(url, async (err, response) => {

            if (response?.data?.d?.results) {
                var favoritesData = response.data.d.results;
                saveFavoritesDataInIndexDB(favoritesData)
                this.setState({ favoritesData: favoritesData, isLoading: false })
                if (type) {
                    await this.getFavoritesloader();
                }
            };
        });

    }

    createCrimpsheets = () => {
        //  Create crimp sheets 
        const { crimpsheets, crimperSheetName, selectedDatatoPrint } = this.state;
        this.setState({ invalidCrimpname: "" })
        var email = Util.getCookie("email")
        if (crimperSheetName.trimStart().trimEnd() == "") {
            this.setState({ invalidCrimpname: "Name cannot be empty" })
            return false
        }


        var data = {
            "entries": [
                {
                    "EMAIL": email,
                    "NAME": crimperSheetName
                }
            ]
        }

        var url = API.URL + "users/crimpsheets"

        ApiServices.httpPOST(url, data, (err, response) => {
            if (response) {

                var datares = response;
                var name = datares.data[0].NAME


                if (response.status == "200" || response.status == "201") {
                    this.getCrimpSheets();
                    this.setState({ openAddFilter: false, crimperSheetName: "" })
                    if (selectedDatatoPrint.length != 0) {

                        this.getCrimpSheetsTocreate(name);

                    }
                }
            };
        });
    }



    setValue(responseCrimpers) {
        const { Immediatefilter } = this.props.auth;
        const { ApplyFilters, selectedModesTypes, selectedModesTypesAcc } = this.state;
        // setting and storing the values in array 
        if (responseCrimpers.length === 0) {
            this.setState({
                filteredDataCrimpers: [],
            })
            return;
        }
        var selecteddies = []
        var selectedHoseDescription = []
        var selectedHoseDashsize = []
        var selectedType = []
        var selectedHosePressure = []

        let allInputs = ["filteredDataDies", "filteredDataHoseDescriptions", "filteredDataHoseDashes", "filteredDataStemTypes", "filteredDataPressures"];

        let stateToSet = {};

        allInputs.map((value) => {

            let toFilter = '';

            if (value === "filteredDataDies") {
                toFilter = "DIE_ID";
            }

            if (value === "filteredDataHoseDescriptions") {
                toFilter = "DESCRIPTION";
            }

            if (value === "filteredDataHoseDashes") {
                toFilter = "DASH_SIZE";
            }

            if (value === "filteredDataStemTypes") {
                toFilter = "STEM_TYPE";
            }

            if (value === "filteredDataPressures") {
                toFilter = "WORKING_PRESSURE";
            }

            stateToSet[value] = responseCrimpers.map(item => item[toFilter])
                .filter((value, index, self) => self.indexOf(value) === index && value != null)
        })
        let allInputs_mod = ["DataDiesfiltered", "DataHoseDescriptionsfiltered", "DataHoseDashesfiltered", "DataStemTypesfiltered", "DataPressuresfiltered"];



        var ArrayOfFilters = {}
        for (var i = 0; i < allInputs_mod.length; i++) {
            ArrayOfFilters[allInputs_mod[i]] = stateToSet[allInputs[i]].map(function (elem) {

                return { "name": elem, "isChecked": false };
            });
        }

        this.setState(ArrayOfFilters)
        let filteredDataCrimpers = responseCrimpers.map(item => item.CRIMPER_ID)
            .filter((value, index, self) => self.indexOf(value) === index);

        stateToSet['filteredDataCrimpersResponse'] = responseCrimpers;
        stateToSet['filteredDataCrimpers'] = filteredDataCrimpers;

        this.setState(stateToSet)
        this.setState({ valuesDies: selecteddies, valuesDashsize: selectedHoseDashsize, valuesDescription: selectedHoseDescription, valuesStemtype: selectedType, valuesPressure: selectedHosePressure })
        if (selectedModesTypes.length > 0 || selecteddies.length > 0 || selectedHoseDescription.length > 0 || selectedHoseDashsize.length > 0 || selectedType.length > 0 || selectedHosePressure.length > 0) {
            this.setState({ appliedFilters: true, AtleastOneFilterSelected: true })
        }
        else {
            this.setState({ appliedFilters: false, AtleastOneFilterSelected: false })

        }
        if (Immediatefilter) {
            this.handleSubmit("typehoseMode", selecteddies, selectedHoseDescription, selectedHoseDashsize, selectedType, selectedHosePressure)
        }
    }

    setValueforAccesories(responseCrimpers) {
        const { Immediatefilter } = this.props.auth;
        const { ApplyFilters, selectedModesTypes, selectedModesTypesAcc } = this.state;
        // setting and storing the values in array 
        if (responseCrimpers.length === 0) {
            this.setState({
                filteredDataCrimpers: [],
            })
            return;
        }
        var selectedHoseDescriptionAcc = []
        var selectedHoseDashsizeAcc = []
        var selectedTypeAcc = []
        var selectedFerruleAcc = []

        let allInputs = ["filteredDataHoseDescriptionsAcc", "filteredDataHoseDashesAcc", "filteredDataStemTypesAcc", "filteredFerruleAcc"];

        let stateToSet = {};

        allInputs.map((value) => {

            let toFilter = '';



            if (value === "filteredDataHoseDescriptionsAcc") {
                toFilter = "DESCRIPTION";
            }

            if (value === "filteredDataHoseDashesAcc") {
                toFilter = "DASH_SIZE";
            }

            if (value === "filteredDataStemTypesAcc") {
                toFilter = "STEM_TYPE";
            }

            if (value === "filteredFerruleAcc") {
                toFilter = "FERRULE_DETAIL";
            }

            stateToSet[value] = responseCrimpers.map(item => item[toFilter])
                .filter((value, index, self) => self.indexOf(value) === index && value != null)
        })
        let allInputs_mod = ["DataHoseDescriptionsfilteredAcc", "DataHoseDashesfilteredAcc", "DataStemTypesfilteredAcc", "DataFerrulefilteredAcc"];



        var ArrayOfFiltersAcc = {}
        for (var i = 0; i < allInputs_mod.length; i++) {
            ArrayOfFiltersAcc[allInputs_mod[i]] = stateToSet[allInputs[i]].map(function (elem) {

                return { "name": elem, "isChecked": false };
            });
        }

        this.setState(ArrayOfFiltersAcc)
        let filteredDataCrimpers = responseCrimpers.map(item => item.CRIMPER_ID)
            .filter((value, index, self) => self.indexOf(value) === index);

        stateToSet['filteredDataCrimpersResponseAcc'] = responseCrimpers;

        stateToSet['filteredDataCrimpers'] = filteredDataCrimpers;

        this.setState(stateToSet)
        this.setState({ valuesDashsizeAcc: selectedHoseDashsizeAcc, valuesDescriptionAcc: selectedHoseDescriptionAcc, valuesStemtypeAcc: selectedTypeAcc, valuesFerruleAcc: selectedFerruleAcc })

        if (Immediatefilter) {
            this.handleSubmitAccessories("typeaccessoriesMode", "", selectedHoseDescriptionAcc, selectedHoseDashsizeAcc, selectedTypeAcc, selectedFerruleAcc)
        }
    }

    handleCrimperValueChanges = async (fieldName, crimper, shouldUpdateContext = true, shouldSetValue = true) => {
        if (shouldUpdateContext) {
            await this.props.setSelectedCrimper(crimper, crimper !== this.props.crimper)
        }
        //  filters the data based on the selcted crimper
        await clearAppliedNotesData()
        //  filters the data based on the selcted crimper
        const { selectedModesTypes } = this.state;

        this.setState({
            open: false,
            selectedCrimper: crimper,
            AppliedNotesData: [],
            FilteredNotesImage: [],
            tableData: [],
            tableDataForAccessories: [],
            filteredDataDies: [],
            filteredDataHoseDescriptions: [],
            filteredDataHoseDashes: [],
            filteredDataStemTypes: [],
            filteredDataPressures: [],
            valuesDies: [],
            valuesDashsize: [],
            valuesDescription: [],
            valuesPressure: [],
            valuesStemtype: [],
            enableDownload: false,
            enableDownloadSelected: false,
            tableData: [],
            appliedFilters: false

        })
        const response = crimper;
        var hoseTypeModes = ''
        if (selectedModesTypes.length != 0) {
            selectedModesTypes.forEach((value, index) => {
                if (index == 0) {
                    hoseTypeModes += ` and HOSE_TYPE_ID eq '${value}'`;
                } else {
                    hoseTypeModes += ` or HOSE_TYPE_ID eq '${value}'`;
                }
            })

            var a = hoseTypeModes;
            var b = " (";
            var c = ")";
            var position = 5;
            var output1 = [a.slice(0, position), b, a.slice(position)].join('');
            var output2 = [output1.slice(0, output1.length), c, output1.slice(output1.length)].join('');
            hoseTypeModes = output2
            if (hoseTypeModes.length < 6) {
                hoseTypeModes = ""
            }
        }




        this.setState({
            [fieldName]: response,
        }, () => {
            let url = API.URL + `crimpers/filters?&$orderby=DESCRIPTION&$filter=CRIMPER_ID eq '` + response + `'` + hoseTypeModes + `&$format=json`

            ApiServices.httpGET(url, (err, response) => {
                if (response?.data?.d?.results) {
                    let responseCrimpers = response.data.d.results;

                    for (var jk = 0; jk < responseCrimpers.length; jk++) {
                        if (responseCrimpers[jk].DASH_SIZE != null) {

                            if (responseCrimpers[jk].DASH_SIZE < 10) {

                                responseCrimpers[jk].DASH_SIZE = '0' + responseCrimpers[jk].DASH_SIZE

                            }
                        }
                    }

                    saveresponseDataInIndexDB(responseCrimpers)
                    this.setState({ filterArray: responseCrimpers, filterArray2: responseCrimpers, hideSelectionbtns: false })
                    if (shouldSetValue) {
                        this.setValue(responseCrimpers);
                    }
                    this.getAccessories();
                };
            });
        })
    }

    handleValueChanges = (type, e, data) => {
        // filters the data based on the applied filters
        const { Immediatefilter } = this.props.auth;

        const { accessoriesData, filterArray, filterArray2, ApplyFilters } = this.state;
        const { valuesDies, valuesDashsize, valuesDescription, valuesPressure, valuesStemtype, selectedModesTypes } = this.state;
        const { valuesDies2, valuesDashsize2, valuesDescription2, valuesPressure2, valuesStemtype2, selectedModesTypes2 } = this.state;

        const { DataDiesfiltered, DataHoseDescriptionsfiltered, DataHoseDashesfiltered, DataStemTypesfiltered, DataPressuresfiltered } = this.state

        var response = [e.target.value]

        var removeMe = e.target.value;


        var checkedonDies = false
        var checkedonDescription = false
        var checkedonDashsize = false
        var checkedonStemtype = false
        var checkedonPressure = false
        var checkedonHoseMode = false


        var selecteddies = valuesDies
        var selectedHoseDescription = valuesDescription
        var selectedHoseDashsize = valuesDashsize
        var selectedType = valuesStemtype
        var selectedHosePressure = valuesPressure
        var selectedMode = selectedModesTypes

        var diesSelected = valuesDies2
        var HoseDescriptionSelected = valuesDescription2
        var HoseDashsizeSelected = valuesDashsize2
        var stemTypeSelected = valuesStemtype2
        var HosePressureSelected = valuesPressure2
        var ModeSelected = selectedModesTypes2

        if (type == 'hoseMode') {


            selecteddies = []
            selectedHoseDescription = []
            selectedHoseDashsize = []
            selectedType = []
            selectedHosePressure = []
            selectedMode = []

            diesSelected = []
            HoseDescriptionSelected = []
            HoseDashsizeSelected = []
            stemTypeSelected = []
            HosePressureSelected = []

            this.setValue(filterArray)

            var result = data
            checkedonHoseMode = true
        }
        else {
            var result = filterArray2
        }

        if (type == 'hoseMode') {
            if (e.target.checked) {
                checkedonHoseMode = true
                selectedModesTypes.push(e.target.value)
                selectedMode = selectedModesTypes
            }
            else {
                checkedonHoseMode = false
                var valuesFilter = selectedModesTypes.filter(s => s !== removeMe);
                this.setState({ selectedModesTypes: valuesFilter })
                selectedMode = valuesFilter
            }
        }


        if (type == 'dies') {

            if (e.target.checked) {
                checkedonDies = true
                for (var i = 0; i < DataDiesfiltered.length; i++) {
                    if (DataDiesfiltered[i].name === e.target.value) {
                        DataDiesfiltered[i].isChecked = true
                    }
                }
                // getting checked values of dies
                valuesDies.push(e.target.value)
                selecteddies = valuesDies
            }
            else {
                checkedonDies = false
                for (var i = 0; i < DataDiesfiltered.length; i++) {
                    if (DataDiesfiltered[i].name === e.target.value) {
                        DataDiesfiltered[i].isChecked = false
                    }
                }
                // filtering unchecked values of dies

                var valuesFilter = valuesDies.filter(s => s !== removeMe);
                this.setState({ valuesDies: valuesFilter })

                selecteddies = valuesFilter
            }
            diesSelected = DataDiesfiltered.filter(s => s.isChecked);


        }
        if (type == 'hoseDescriptions') {

            if (e.target.checked) {
                checkedonDescription = true
                for (var i = 0; i < DataHoseDescriptionsfiltered.length; i++) {
                    if (DataHoseDescriptionsfiltered[i].name === e.target.value) {
                        DataHoseDescriptionsfiltered[i].isChecked = true
                        break
                    }
                }
                // getting checked values of hoseDescriptions

                valuesDescription.push(e.target.value)
                selectedHoseDescription = valuesDescription

            }
            else {
                checkedonDescription = false
                for (var i = 0; i < DataHoseDescriptionsfiltered.length; i++) {
                    if (DataHoseDescriptionsfiltered[i].name === e.target.value) {
                        DataHoseDescriptionsfiltered[i].isChecked = false
                        break
                    }
                }
                // filtering unchecked values of hoseDescriptions

                var valuesFilter = valuesDescription.filter(s => s !== removeMe);
                this.setState({ valuesDescription: valuesFilter })
                selectedHoseDescription = valuesFilter

            }
            HoseDescriptionSelected = DataHoseDescriptionsfiltered.filter(s => s.isChecked);

        }
        if (type == 'hoseDashes') {

            if (e.target.checked) {
                checkedonDashsize = true
                for (var i = 0; i < DataHoseDashesfiltered.length; i++) {
                    if (DataHoseDashesfiltered[i].name === e.target.value) {
                        DataHoseDashesfiltered[i].isChecked = true
                        break
                    }
                }
                // getting checked values of hosedash

                valuesDashsize.push(e.target.value)
                selectedHoseDashsize = valuesDashsize

            }
            else {
                checkedonDashsize = false
                for (var i = 0; i < DataHoseDashesfiltered.length; i++) {
                    if (DataHoseDashesfiltered[i].name === e.target.value) {
                        DataHoseDashesfiltered[i].isChecked = false
                        break
                    }
                }
                // filtering unchecked values of hosedash

                var valuesFilter = valuesDashsize.filter(s => s !== removeMe);
                this.setState({ valuesDashsize: valuesFilter })
                selectedHoseDashsize = valuesFilter

            }
            HoseDashsizeSelected = DataHoseDashesfiltered.filter(s => s.isChecked);

        }
        if (type == 'stemTypes') {

            if (e.target.checked) {
                checkedonStemtype = true
                for (var i = 0; i < DataStemTypesfiltered.length; i++) {
                    if (DataStemTypesfiltered[i].name === e.target.value) {
                        DataStemTypesfiltered[i].isChecked = true
                    }
                }
                // getting checked values of stremtype

                valuesStemtype.push(e.target.value)
                selectedType = valuesStemtype

            }
            else {
                checkedonStemtype = false
                for (var i = 0; i < DataStemTypesfiltered.length; i++) {
                    if (DataStemTypesfiltered[i].name === e.target.value) {
                        DataStemTypesfiltered[i].isChecked = false
                    }
                }
                // filtering unchecked values of hosedash

                var valuesFilter = valuesStemtype.filter(s => s !== removeMe);
                this.setState({ valuesStemtype: valuesFilter })
                selectedType = valuesFilter


            }

            stemTypeSelected = DataStemTypesfiltered.filter(s => s.isChecked);


        }
        if (type == 'pressures') {
            if (e.target.checked) {
                checkedonPressure = true
                for (var i = 0; i < DataPressuresfiltered.length; i++) {
                    if (DataPressuresfiltered[i].name >= Number(e.target.value)) {
                        DataPressuresfiltered[i].isChecked = true
                    }
                }
                // getting checked values of presure
                // checked all the pressures greater than the selected values
                for (var i = 0; i < DataPressuresfiltered.length; i++) {
                    if (DataPressuresfiltered[i].isChecked == true) {
                        valuesPressure.push(DataPressuresfiltered[i].name)
                    }
                }

                selectedHosePressure = valuesPressure
            }
            else {
                checkedonPressure = false
                for (var i = 0; i < DataPressuresfiltered.length; i++) {
                    if (DataPressuresfiltered[i].name === Number(e.target.value)) {
                        DataPressuresfiltered[i].isChecked = false
                    }
                }
                // filtering unchecked values of presuree
                var valuesFilter = valuesPressure.filter(s => s !== Number(removeMe));
                this.setState({ valuesPressure: valuesFilter })
                selectedHosePressure = valuesFilter

            }
            HosePressureSelected = DataPressuresfiltered.filter(s => s.isChecked);

        }

        // storing all selected values in 'filter1' array


        let filter1 = [{
            "DIE_ID": selecteddies,
            "DESCRIPTION": selectedHoseDescription,
            "DASH_SIZE": selectedHoseDashsize,
            "STEM_TYPE": selectedType,
            "WORKING_PRESSURE": selectedHosePressure,
        }];
        // storing all selected values in setstate for each

        this.setState({
            valuesDies2: diesSelected,
            valuesDescription2: HoseDescriptionSelected,
            valuesDashsize2: HoseDashsizeSelected,
            valuesStemtype2: stemTypeSelected,
            valuesPressure2: HosePressureSelected
        })
        // filtering the options(eg: DIE_ID, DESCRIPTION,..) from 'filter1' Array to apply the selected filters only

        for (var i = 0; i < filter1.length; i++) {

            if (filter1[i].DIE_ID.length == 0) {
                delete filter1[i].DIE_ID
            }
            if (filter1[i].DESCRIPTION.length == 0) {
                delete filter1[i].DESCRIPTION
            }
            if (filter1[i].DASH_SIZE.length == 0) {
                delete filter1[i].DASH_SIZE
            }
            if (filter1[i].STEM_TYPE.length == 0) {
                delete filter1[i].STEM_TYPE
            }
            if (filter1[i].WORKING_PRESSURE.length == 0) {
                delete filter1[i].WORKING_PRESSURE
            }
        }
        // getting number of objects in 'filter1' array

        var sizeofFilter1 = Object.keys(filter1[0]).length
        let FilteredResult = []
        if (sizeofFilter1 > 0) {
            // if only hose mode selected and some other options selclted
            FilteredResult = result.filter(obj => {
                return Object.entries(filter1[0]).every(([key, values]) => {
                    return values.some(value => {
                        return String(obj[key]).includes(String(value))
                    })
                })
            });
        }
        else {
            // if only hose mode selected and no other options selclted
            FilteredResult = result
        }



        let allInputs = ["filteredDataDies", "filteredDataHoseDescriptions", "filteredDataHoseDashes", "filteredDataStemTypes", "filteredDataPressures"];
        let allInputs_mod = ["DataDiesfiltered", "DataHoseDescriptionsfiltered", "DataHoseDashesfiltered", "DataStemTypesfiltered", "DataPressuresfiltered"];

        var valuesFilter = allInputs
        var valuesFilter2 = allInputs_mod

        let stateToSet = {};
        let stateToSet2 = {};

        valuesFilter.map((value) => {
            let toFilter = '';


            if (value === "filteredDataDies") {
                toFilter = "DIE_ID";
            }

            if (value === "filteredDataHoseDescriptions") {
                toFilter = "DESCRIPTION";
            }

            if (value === "filteredDataHoseDashes") {
                toFilter = "DASH_SIZE";
            }

            if (value === "filteredDataStemTypes") {
                toFilter = "STEM_TYPE";
            }

            if (value === "filteredDataPressures") {
                toFilter = "WORKING_PRESSURE";
            }

            stateToSet[value] = FilteredResult.map(item => item[toFilter])
                .filter((value, index, self) => self.indexOf(value) === index)
        })

        valuesFilter2.map((value) => {
            let toFilter2 = '';


            if (value === "DataDiesfiltered") {
                toFilter2 = "DIE_ID";
            }

            if (value === "DataHoseDescriptionsfiltered") {
                toFilter2 = "DESCRIPTION";
            }

            if (value === "DataHoseDashesfiltered") {
                toFilter2 = "DASH_SIZE";
            }

            if (value === "DataStemTypesfiltered") {
                toFilter2 = "STEM_TYPE";
            }

            if (value === "DataPressuresfiltered") {
                toFilter2 = "WORKING_PRESSURE";
            }
            if (value === "DataPressuresfiltered") {
                toFilter2 = "WORKING_PRESSURE";
            }

            stateToSet2[value] = FilteredResult.map(item => item[toFilter2])
                .filter((value, index, self) => self.indexOf(value) === index)

        })

        var ArrayOfFilters = {}
        for (var i = 0; i < valuesFilter2.length; i++) {
            ArrayOfFilters[valuesFilter2[i]] = stateToSet2[valuesFilter2[i]].map(function (elem) {

                return { "name": elem, "isChecked": false };
            });
        }


        if (!checkedonDescription && !(filter1[0]["DESCRIPTION"] != null || filter1[0]["DESCRIPTION"] != undefined)) {
            for (var i = 0; i < HoseDescriptionSelected.length; i++) {
                if (HoseDescriptionSelected.length == 0) {
                    ArrayOfFilters["DataHoseDescriptionsfiltered"].isChecked = false
                }
                else {
                    if (HoseDescriptionSelected[i].name == ArrayOfFilters["DataHoseDescriptionsfiltered"][i].name) {
                        ArrayOfFilters["DataHoseDescriptionsfiltered"][i].isChecked = true
                    }
                }
            }
        }
        else {
            if (checkedonDescription) {
                ArrayOfFilters["DataHoseDescriptionsfiltered"] = DataHoseDescriptionsfiltered

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataHoseDescriptionsfiltered"].length; i++) {
                    for (var j = 0; j < DataHoseDescriptionsfiltered.length; j++) {
                        if (ArrayOfFilters["DataHoseDescriptionsfiltered"][i].name == DataHoseDescriptionsfiltered[j].name) {
                            ArrayOfFilters["DataHoseDescriptionsfiltered"][i].isChecked = true
                        }
                    }

                }
            }


        }


        if (!checkedonDashsize && !(filter1[0]["DASH_SIZE"] != null || filter1[0]["DASH_SIZE"] != undefined)) {


            for (var i = 0; i < HoseDashsizeSelected.length; i++) {
                if (HoseDashsizeSelected.length == 0) {
                    ArrayOfFilters["DataHoseDashesfiltered"].isChecked = false
                }
                else {
                    if (HoseDashsizeSelected[i].name == ArrayOfFilters["DataHoseDashesfiltered"][i].name) {
                        ArrayOfFilters["DataHoseDashesfiltered"][i].isChecked = true
                    }
                }
            }
        } else {

            // ArrayOfFilters["DataHoseDashesfiltered"] = DataHoseDashesfiltered
            if (checkedonDashsize) {
                ArrayOfFilters["DataHoseDashesfiltered"] = DataHoseDashesfiltered

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataHoseDashesfiltered"].length; i++) {
                    for (var j = 0; j < DataHoseDashesfiltered.length; j++) {
                        if (ArrayOfFilters["DataHoseDashesfiltered"][i].name == DataHoseDashesfiltered[j].name) {
                            ArrayOfFilters["DataHoseDashesfiltered"][i].isChecked = true
                        }
                    }

                }
            }

        }
        if (!checkedonDies && !(filter1[0]["DIE_ID"] != null || filter1[0]["DIE_ID"] != undefined)) {
            for (var i = 0; i < diesSelected.length; i++) {
                if (diesSelected.length == 0) {
                    ArrayOfFilters["DataDiesfiltered"].isChecked = false
                }
                else {
                    if (diesSelected[i].name == ArrayOfFilters["DataDiesfiltered"][i].name) {
                        ArrayOfFilters["DataDiesfiltered"][i].isChecked = true
                    }
                }
            }
        } else {

            if (checkedonDies) {
                ArrayOfFilters["DataDiesfiltered"] = DataDiesfiltered

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataDiesfiltered"].length; i++) {
                    for (var j = 0; j < DataDiesfiltered.length; j++) {
                        if (ArrayOfFilters["DataDiesfiltered"][i].name == DataDiesfiltered[j].name) {
                            ArrayOfFilters["DataDiesfiltered"][i].isChecked = true
                        }
                    }

                }
            }

        }
        if (!checkedonStemtype && !(filter1[0]["STEM_TYPE"] != null || filter1[0]["STEM_TYPE"] != undefined)) {
            for (var i = 0; i < stemTypeSelected.length; i++) {
                if (stemTypeSelected.length == 0) {
                    ArrayOfFilters["DataStemTypesfiltered"].isChecked = false
                }
                else {
                    if (stemTypeSelected[i].name == ArrayOfFilters["DataStemTypesfiltered"][i].name) {
                        ArrayOfFilters["DataStemTypesfiltered"][i].isChecked = true
                    }
                }
            }
        } else {

            if (checkedonStemtype) {
                ArrayOfFilters["DataStemTypesfiltered"] = DataStemTypesfiltered

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataStemTypesfiltered"].length; i++) {
                    for (var j = 0; j < DataStemTypesfiltered.length; j++) {
                        if (ArrayOfFilters["DataStemTypesfiltered"][i].name == DataStemTypesfiltered[j].name) {
                            ArrayOfFilters["DataStemTypesfiltered"][i].isChecked = true
                        }
                    }

                }
            }

        }


        if (!checkedonPressure && !(filter1[0]["WORKING_PRESSURE"] != null || filter1[0]["WORKING_PRESSURE"] != undefined))
            for (var i = 0; i < HosePressureSelected.length; i++) {
                if (HosePressureSelected.length == 0) {
                    ArrayOfFilters["DataPressuresfiltered"].isChecked = false
                }
                else {
                    if (HosePressureSelected[i].name == ArrayOfFilters["DataPressuresfiltered"][i].name) {
                        ArrayOfFilters["DataPressuresfiltered"][i].isChecked = true
                    }
                }
            }
        else {

            if (checkedonPressure) {
                ArrayOfFilters["DataPressuresfiltered"] = DataPressuresfiltered

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataPressuresfiltered"].length; i++) {
                    for (var j = 0; j < DataPressuresfiltered.length; j++) {
                        if (ArrayOfFilters["DataPressuresfiltered"][i].name == DataPressuresfiltered[j].name) {
                            ArrayOfFilters["DataPressuresfiltered"][i].isChecked = true
                        }
                    }

                }
            }
        }


        let filteredDataCrimpers = FilteredResult.map(item => item.CRIMPER_ID)
            .filter((value, index, self) => self.indexOf(value) === index);

        stateToSet['filteredDataCrimpersResponse'] = FilteredResult;
        stateToSet['filteredDataCrimpers'] = filteredDataCrimpers;
        this.setState(ArrayOfFilters)
        this.setState(stateToSet)

        if (selectedModesTypes.length > 0 || selecteddies.length > 0 || selectedHoseDescription.length > 0 || selectedHoseDashsize.length > 0 || selectedType.length > 0 || selectedHosePressure.length > 0) {
            this.setState({ appliedFilters: true, AtleastOneFilterSelected: true })
        }
        else {
            this.setState({ appliedFilters: false, AtleastOneFilterSelected: false })

        } if (Immediatefilter) {
            this.handleSubmit("typehoseMode", selecteddies, selectedHoseDescription, selectedHoseDashsize, selectedType, selectedHosePressure)
        }
        this.setValueforAccesories(accessoriesData)

    }

    handleValueChangesInsideFilter = (type, value) => {
        // filters the data based on the applied filters
        const { Immediatefilter } = this.props.auth;

        const { filterArray, ApplyFilters } = this.state;
        const { valuesDies, valuesDashsize, valuesDescription, valuesPressure, valuesStemtype, selectedModesTypes } = this.state;
        const { valuesDies2, valuesDashsize2, valuesDescription2, valuesPressure2, valuesStemtype2 } = this.state;

        const { DataDiesfiltered, DataHoseDescriptionsfiltered, DataHoseDashesfiltered, DataStemTypesfiltered, DataPressuresfiltered } = this.state

        var result = filterArray

        var response = [value]

        var removeMe = value;


        var checkedonDies = false
        var checkedonDescription = false
        var checkedonDashsize = false
        var checkedonStemtype = false
        var checkedonPressure = false
        var checkedonHoseMode = false


        var selecteddies = valuesDies
        var selectedHoseDescription = valuesDescription
        var selectedHoseDashsize = valuesDashsize
        var selectedType = valuesStemtype
        var selectedHosePressure = valuesPressure
        var selectedMode = selectedModesTypes

        var diesSelected = valuesDies2
        var HoseDescriptionSelected = valuesDescription2
        var HoseDashsizeSelected = valuesDashsize2
        var stemTypeSelected = valuesStemtype2
        var HosePressureSelected = valuesPressure2
        var ModeSelected = []

        if (type == 'dies') {
            checkedonDies = true
            for (var i = 0; i < DataDiesfiltered.length; i++) {
                if (DataDiesfiltered[i].name === value) {
                    DataDiesfiltered[i].isChecked = false
                }
            }
            var valuesFilter = valuesDies.filter(s => s !== removeMe);
            this.setState({ valuesDies: valuesFilter })

            selecteddies = valuesFilter
            diesSelected = DataDiesfiltered.filter(s => s.isChecked);
        }
        if (type == 'hoseDescriptions') {
            checkedonDescription = true
            for (var i = 0; i < DataHoseDescriptionsfiltered.length; i++) {
                if (DataHoseDescriptionsfiltered[i].name === value) {
                    DataHoseDescriptionsfiltered[i].isChecked = false
                }
            }
            var valuesFilter = valuesDescription.filter(s => s !== removeMe);
            this.setState({ valuesDescription: valuesFilter })
            selectedHoseDescription = valuesFilter


            HoseDescriptionSelected = DataHoseDescriptionsfiltered.filter(s => s.isChecked);

        }
        if (type == 'hoseDashes') {


            checkedonDashsize = true
            for (var i = 0; i < DataHoseDashesfiltered.length; i++) {
                if (DataHoseDashesfiltered[i].name === value) {
                    DataHoseDashesfiltered[i].isChecked = false
                }
            }
            var valuesFilter = valuesDashsize.filter(s => s !== removeMe);
            this.setState({ valuesDashsize: valuesFilter })
            selectedHoseDashsize = valuesFilter


            HoseDashsizeSelected = DataHoseDashesfiltered.filter(s => s.isChecked);

        }
        if (type == 'stemTypes') {
            checkedonStemtype = true
            for (var i = 0; i < DataStemTypesfiltered.length; i++) {
                if (DataStemTypesfiltered[i].name === value) {
                    DataStemTypesfiltered[i].isChecked = false
                }
            }
            var valuesFilter = valuesStemtype.filter(s => s !== removeMe);
            this.setState({ valuesStemtype: valuesFilter })
            selectedType = valuesFilter
            stemTypeSelected = DataStemTypesfiltered.filter(s => s.isChecked);
        }
        if (type == 'pressures') {
            checkedonPressure = true
            for (var i = 0; i < DataPressuresfiltered.length; i++) {
                if (DataPressuresfiltered[i].name === Number(value)) {
                    DataPressuresfiltered[i].isChecked = false
                }
            }
            var valuesFilter = valuesPressure.filter(s => s !== Number(removeMe));
            this.setState({ valuesPressure: valuesFilter })
            selectedHosePressure = valuesFilter
            HosePressureSelected = DataPressuresfiltered.filter(s => s.isChecked);

        }


        let filter1 = [{
            "DIE_ID": selecteddies,
            "DESCRIPTION": selectedHoseDescription,
            "DASH_SIZE": selectedHoseDashsize,
            "STEM_TYPE": selectedType,
            "WORKING_PRESSURE": selectedHosePressure,
            "HOSE_TYPE_ID": selectedMode
        }];


        this.setState({
            valuesDies2: diesSelected,
            valuesDescription2: HoseDescriptionSelected,
            valuesDashsize2: HoseDashsizeSelected,
            valuesStemtype2: stemTypeSelected,
            valuesPressure2: HosePressureSelected
        })


        for (var i = 0; i < filter1.length; i++) {

            if (filter1[i].DIE_ID.length == 0) {
                delete filter1[i].DIE_ID
            }
            if (filter1[i].DESCRIPTION.length == 0) {
                delete filter1[i].DESCRIPTION
            }
            if (filter1[i].DASH_SIZE.length == 0) {
                delete filter1[i].DASH_SIZE
            }
            if (filter1[i].STEM_TYPE.length == 0) {
                delete filter1[i].STEM_TYPE
            }
            if (filter1[i].WORKING_PRESSURE.length == 0) {
                delete filter1[i].WORKING_PRESSURE
            }
            if (filter1[i].HOSE_TYPE_ID.length == 0) {
                delete filter1[i].HOSE_TYPE_ID
            }

        }

        var sizeofFilter1 = Object.keys(filter1[0]).length

        let FilteredResult = []
        if (!checkedonHoseMode) {
            if (sizeofFilter1 > 1) {

                FilteredResult = result.filter(obj =>
                    Object.entries(filter1[0]).every(([prop, find]) => find.includes(obj[prop])));
            } else {
                FilteredResult = result
            }
        }
        else {
            if (sizeofFilter1 > 0) {

                FilteredResult = result.filter(obj =>
                    Object.entries(filter1[0]).every(([prop, find]) => find.includes(obj[prop])));
            } else {
                FilteredResult = result
            }
        }


        let allInputs = ["filteredDataDies", "filteredDataHoseDescriptions", "filteredDataHoseDashes", "filteredDataStemTypes", "filteredDataPressures", "filteredHoseModes"];
        let allInputs_mod = ["DataDiesfiltered", "DataHoseDescriptionsfiltered", "DataHoseDashesfiltered", "DataStemTypesfiltered", "DataPressuresfiltered", "HoseModesfiltered"];
        var valuesFilter = allInputs
        var valuesFilter2 = allInputs_mod

        let stateToSet = {};
        let stateToSet2 = {};

        valuesFilter.map((value) => {
            let toFilter = '';


            if (value === "filteredDataDies") {
                toFilter = "DIE_ID";
            }

            if (value === "filteredDataHoseDescriptions") {
                toFilter = "DESCRIPTION";
            }

            if (value === "filteredDataHoseDashes") {
                toFilter = "DASH_SIZE";
            }

            if (value === "filteredDataStemTypes") {
                toFilter = "STEM_TYPE";
            }

            if (value === "filteredDataPressures") {
                toFilter = "WORKING_PRESSURE";
            }

            stateToSet[value] = FilteredResult.map(item => item[toFilter])
                .filter((value, index, self) => self.indexOf(value) === index)
        })

        valuesFilter2.map((value) => {
            let toFilter2 = '';


            if (value === "DataDiesfiltered") {
                toFilter2 = "DIE_ID";
            }

            if (value === "DataHoseDescriptionsfiltered") {
                toFilter2 = "DESCRIPTION";
            }

            if (value === "DataHoseDashesfiltered") {
                toFilter2 = "DASH_SIZE";
            }

            if (value === "DataStemTypesfiltered") {
                toFilter2 = "STEM_TYPE";
            }

            if (value === "DataPressuresfiltered") {
                toFilter2 = "WORKING_PRESSURE";
            }

            stateToSet2[value] = FilteredResult.map(item => item[toFilter2])
                .filter((value, index, self) => self.indexOf(value) === index)

        })

        var ArrayOfFilters = {}
        for (var i = 0; i < valuesFilter2.length; i++) {
            ArrayOfFilters[valuesFilter2[i]] = stateToSet2[valuesFilter2[i]].map(function (elem) {

                return { "name": elem, "isChecked": false };
            });
        }

        if (!checkedonDescription && !(filter1[0]["DESCRIPTION"] != null || filter1[0]["DESCRIPTION"] != undefined)) {


            for (var i = 0; i < HoseDescriptionSelected.length; i++) {
                if (HoseDescriptionSelected.length == 0) {
                    ArrayOfFilters["DataHoseDescriptionsfiltered"].isChecked = false
                }
                else {
                    if (HoseDescriptionSelected[i].name == ArrayOfFilters["DataHoseDescriptionsfiltered"][i].name) {
                        ArrayOfFilters["DataHoseDescriptionsfiltered"][i].isChecked = true
                    }
                }
            }
        }
        else {
            if (checkedonDescription) {
                ArrayOfFilters["DataHoseDescriptionsfiltered"] = DataHoseDescriptionsfiltered

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataHoseDescriptionsfiltered"].length; i++) {
                    for (var j = 0; j < DataHoseDescriptionsfiltered.length; j++) {
                        if (ArrayOfFilters["DataHoseDescriptionsfiltered"][i].name == DataHoseDescriptionsfiltered[j].name) {
                            ArrayOfFilters["DataHoseDescriptionsfiltered"][i].isChecked = true
                        }
                    }

                }
            }

        }


        if (!checkedonDashsize && !(filter1[0]["DASH_SIZE"] != null || filter1[0]["DASH_SIZE"] != undefined)) {
            for (var i = 0; i < HoseDashsizeSelected.length; i++) {
                if (HoseDashsizeSelected.length == 0) {
                    ArrayOfFilters["DataHoseDashesfiltered"].isChecked = false
                }
                else {
                    if (HoseDashsizeSelected[i].name == ArrayOfFilters["DataHoseDashesfiltered"][i].name) {
                        ArrayOfFilters["DataHoseDashesfiltered"][i].isChecked = true
                    }
                }
            }
        } else {

            if (checkedonDashsize) {
                ArrayOfFilters["DataHoseDashesfiltered"] = DataHoseDashesfiltered

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataHoseDashesfiltered"].length; i++) {
                    for (var j = 0; j < DataHoseDashesfiltered.length; j++) {
                        if (ArrayOfFilters["DataHoseDashesfiltered"][i].name == DataHoseDashesfiltered[j].name) {
                            ArrayOfFilters["DataHoseDashesfiltered"][i].isChecked = true
                        }
                    }

                }
            }

        }
        if (!checkedonDies && !(filter1[0]["DIE_ID"] != null || filter1[0]["DIE_ID"] != undefined)) {
            for (var i = 0; i < diesSelected.length; i++) {
                if (diesSelected.length == 0) {
                    ArrayOfFilters["DataDiesfiltered"].isChecked = false
                }
                else {
                    if (diesSelected[i].name == ArrayOfFilters["DataDiesfiltered"][i].name) {
                        ArrayOfFilters["DataDiesfiltered"][i].isChecked = true
                    }
                }
            }
        } else {

            if (checkedonDies) {
                ArrayOfFilters["DataDiesfiltered"] = DataDiesfiltered

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataDiesfiltered"].length; i++) {
                    for (var j = 0; j < DataDiesfiltered.length; j++) {
                        if (ArrayOfFilters["DataDiesfiltered"][i].name == DataDiesfiltered[j].name) {
                            ArrayOfFilters["DataDiesfiltered"][i].isChecked = true
                        }
                    }

                }
            }

        }
        if (!checkedonStemtype && !(filter1[0]["STEM_TYPE"] != null || filter1[0]["STEM_TYPE"] != undefined)) {
            for (var i = 0; i < stemTypeSelected.length; i++) {
                if (stemTypeSelected.length == 0) {
                    ArrayOfFilters["DataStemTypesfiltered"].isChecked = false
                }
                else {
                    if (stemTypeSelected[i].name == ArrayOfFilters["DataStemTypesfiltered"][i].name) {
                        ArrayOfFilters["DataStemTypesfiltered"][i].isChecked = true
                    }
                }
            }
        } else {

            if (checkedonStemtype) {
                ArrayOfFilters["DataStemTypesfiltered"] = DataStemTypesfiltered

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataStemTypesfiltered"].length; i++) {
                    for (var j = 0; j < DataStemTypesfiltered.length; j++) {
                        if (ArrayOfFilters["DataStemTypesfiltered"][i].name == DataStemTypesfiltered[j].name) {
                            ArrayOfFilters["DataStemTypesfiltered"][i].isChecked = true
                        }
                    }

                }
            }

        }
        if (!checkedonPressure && !(filter1[0]["WORKING_PRESSURE"] != null || filter1[0]["WORKING_PRESSURE"] != undefined))
            for (var i = 0; i < HosePressureSelected.length; i++) {
                if (HosePressureSelected.length == 0) {
                    ArrayOfFilters["DataPressuresfiltered"].isChecked = false
                }
                else {
                    if (HosePressureSelected[i].name == ArrayOfFilters["DataPressuresfiltered"][i].name) {
                        ArrayOfFilters["DataPressuresfiltered"][i].isChecked = true
                    }
                }
            }
        else {

            if (checkedonPressure) {
                ArrayOfFilters["DataPressuresfiltered"] = DataPressuresfiltered

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataPressuresfiltered"].length; i++) {
                    for (var j = 0; j < DataPressuresfiltered.length; j++) {
                        if (ArrayOfFilters["DataPressuresfiltered"][i].name == DataPressuresfiltered[j].name) {
                            ArrayOfFilters["DataPressuresfiltered"][i].isChecked = true
                        }
                    }

                }
            }
        }


        let filteredDataCrimpers = FilteredResult.map(item => item.CRIMPER_ID)
            .filter((value, index, self) => self.indexOf(value) === index);

        stateToSet['filteredDataCrimpersResponse'] = FilteredResult;
        stateToSet['filteredDataCrimpers'] = filteredDataCrimpers;

        this.setState(ArrayOfFilters)
        this.setState(stateToSet)
        if (selectedModesTypes.length > 0 || selecteddies.length > 0 || selectedHoseDescription.length > 0 || selectedHoseDashsize.length > 0 || selectedType.length > 0 || selectedHosePressure.length > 0) {
            this.setState({ appliedFilters: true, AtleastOneFilterSelected: true })
        }
        else {
            this.setState({ appliedFilters: false, AtleastOneFilterSelected: false })

        } if (Immediatefilter) {
            this.handleSubmit("typehoseMode", selecteddies, selectedHoseDescription, selectedHoseDashsize, selectedType, selectedHosePressure)
        }
    }

    handleValueChangesForaccesories = (type, e, data) => {
        // filters the data based on the applied filters
        const { Immediatefilter } = this.props.auth;

        const { ApplyFilters, accessoriesData, filteraccessoriesData, filterArray } = this.state;
        const { valuesDashsizeAcc, valuesDescriptionAcc, valuesFerruleAcc, valuesStemtypeAcc, selectedModesTypes } = this.state;
        const { valuesDashsizeAcc2, valuesDescriptionAcc2, valuesFerruleAcc2, valuesStemtypeAcc2, selectedModesTypesAcc } = this.state;

        const { DataHoseDescriptionsfilteredAcc, DataHoseDashesfilteredAcc, DataStemTypesfilteredAcc, DataFerrulefilteredAcc } = this.state
        var result = accessoriesData

        var response = [e.target.value]

        var removeMe = e.target.value;


        var checkedonDies = false
        var checkedonDescription = false
        var checkedonDashsize = false
        var checkedonStemtype = false
        var checkedonPressure = false
        var checkedonHoseMode = false


        var selectedHoseDescriptionAcc = valuesDescriptionAcc
        var selectedHoseDashsizeAcc = valuesDashsizeAcc
        var selectedTypeAcc = valuesStemtypeAcc
        var selectedFerruleAcc = valuesFerruleAcc
        var selectedMode = selectedModesTypes


        var HoseDescriptionSelectedAcc = valuesDescriptionAcc2
        var HoseDashsizeSelectedAcc = valuesDashsizeAcc2
        var stemTypeSelectedAcc = valuesStemtypeAcc2
        var HoseFerruleSelectedAcc = valuesFerruleAcc2

        if (type == 'hoseMode') {


            selectedHoseDescriptionAcc = []
            selectedHoseDashsizeAcc = []
            selectedTypeAcc = []
            selectedFerruleAcc = []
            selectedMode = []

            HoseDescriptionSelectedAcc = []
            HoseDashsizeSelectedAcc = []
            stemTypeSelectedAcc = []
            HoseFerruleSelectedAcc = []

            this.setValueforAccesories(accessoriesData)

            var result = data
            checkedonHoseMode = true
        }
        else {
            var result = filteraccessoriesData
        }

        if (type == 'hoseMode') {
            if (e.target.checked) {
                checkedonHoseMode = true
                selectedModesTypesAcc.push(e.target.value)
                selectedMode = selectedModesTypesAcc
            }
            else {
                checkedonHoseMode = false
                var valuesFilter = selectedModesTypesAcc.filter(s => s !== removeMe);
                this.setState({ selectedModesTypesAcc: valuesFilter })
                selectedMode = valuesFilter
            }
        }


        if (type == 'hoseDescriptions') {

            if (e.target.checked) {
                checkedonDescription = true
                for (var i = 0; i < DataHoseDescriptionsfilteredAcc.length; i++) {
                    if (DataHoseDescriptionsfilteredAcc[i].name === e.target.value) {
                        DataHoseDescriptionsfilteredAcc[i].isChecked = true
                    }
                }
                // getting checked values of hoseDescriptions

                valuesDescriptionAcc.push(e.target.value)
                selectedHoseDescriptionAcc = valuesDescriptionAcc

            }
            else {
                checkedonDescription = false
                for (var i = 0; i < DataHoseDescriptionsfilteredAcc.length; i++) {
                    if (DataHoseDescriptionsfilteredAcc[i].name === e.target.value) {
                        DataHoseDescriptionsfilteredAcc[i].isChecked = false
                    }
                }
                // filtering unchecked values of hoseDescriptions

                var valuesFilter = valuesDescriptionAcc.filter(s => s !== removeMe);
                this.setState({ valuesDescriptionAcc: valuesFilter })
                selectedHoseDescriptionAcc = valuesFilter

            }
            HoseDescriptionSelectedAcc = DataHoseDescriptionsfilteredAcc.filter(s => s.isChecked);

        }
        if (type == 'hoseDashes') {

            if (e.target.checked) {
                checkedonDashsize = true
                for (var i = 0; i < DataHoseDashesfilteredAcc.length; i++) {
                    if (DataHoseDashesfilteredAcc[i].name === e.target.value) {
                        DataHoseDashesfilteredAcc[i].isChecked = true
                        break
                    }
                }
                // getting checked values of hosedash

                valuesDashsizeAcc.push(e.target.value)
                selectedHoseDashsizeAcc = valuesDashsizeAcc

            }
            else {
                checkedonDashsize = false
                for (var i = 0; i < DataHoseDashesfilteredAcc.length; i++) {
                    if (DataHoseDashesfilteredAcc[i].name === e.target.value) {
                        DataHoseDashesfilteredAcc[i].isChecked = false
                    }
                }
                // filtering unchecked values of hosedash

                var valuesFilter = valuesDashsizeAcc.filter(s => s !== removeMe);
                this.setState({ valuesDashsizeAcc: valuesFilter })
                selectedHoseDashsizeAcc = valuesFilter

            }
            HoseDashsizeSelectedAcc = DataHoseDashesfilteredAcc.filter(s => s.isChecked);

        }
        if (type == 'stemTypes') {

            if (e.target.checked) {
                checkedonStemtype = true
                for (var i = 0; i < DataStemTypesfilteredAcc.length; i++) {
                    if (DataStemTypesfilteredAcc[i].name === e.target.value) {
                        DataStemTypesfilteredAcc[i].isChecked = true
                    }
                }
                // getting checked values of stremtype

                valuesStemtypeAcc.push(e.target.value)
                selectedTypeAcc = valuesStemtypeAcc

            }
            else {
                checkedonStemtype = false
                for (var i = 0; i < DataStemTypesfilteredAcc.length; i++) {
                    if (DataStemTypesfilteredAcc[i].name === e.target.value) {
                        DataStemTypesfilteredAcc[i].isChecked = false
                    }
                }
                // filtering unchecked values of hosedash

                var valuesFilter = valuesStemtypeAcc.filter(s => s !== removeMe);
                this.setState({ valuesStemtypeAcc: valuesFilter })
                selectedTypeAcc = valuesFilter


            }

            stemTypeSelectedAcc = DataStemTypesfilteredAcc.filter(s => s.isChecked);


        }
        if (type == 'pressures') {

            if (e.target.checked) {
                checkedonPressure = true
                for (var i = 0; i < DataFerrulefilteredAcc.length; i++) {
                    if (DataFerrulefilteredAcc[i].name === e.target.value) {
                        DataFerrulefilteredAcc[i].isChecked = true
                    }
                }
                // getting checked values of presuree

                valuesFerruleAcc.push(e.target.value)
                selectedFerruleAcc = valuesFerruleAcc
            }
            else {
                checkedonPressure = false
                for (var i = 0; i < DataFerrulefilteredAcc.length; i++) {
                    if (DataFerrulefilteredAcc[i].name === e.target.value) {
                        DataFerrulefilteredAcc[i].isChecked = false
                    }
                }
                // filtering unchecked values of presuree
                var valuesFilter = valuesFerruleAcc.filter(s => s !== removeMe);
                this.setState({ valuesFerruleAcc: valuesFilter })
                selectedFerruleAcc = valuesFilter

            }
            HoseFerruleSelectedAcc = DataFerrulefilteredAcc.filter(s => s.isChecked);

        }

        // storing all selected values in 'filter1' array
        let filter1 = [{
            "DESCRIPTION": selectedHoseDescriptionAcc,
            "DASH_SIZE": selectedHoseDashsizeAcc,
            "STEM_TYPE": selectedTypeAcc,
            "FERRULE_DETAIL": selectedFerruleAcc,
        }];
        // storing all selected values in setstate for each

        this.setState({
            valuesDashsizeAcc2: HoseDescriptionSelectedAcc,
            valuesDashsizeAcc2: HoseDashsizeSelectedAcc,
            valuesStemtypeAcc2: stemTypeSelectedAcc,
            valuesFerruleAcc2: HoseFerruleSelectedAcc
        })
        // filtering the options(eg: DIE_ID, DESCRIPTION,..) from 'filter1' Array to apply the selected filters only

        for (var i = 0; i < filter1.length; i++) {


            if (filter1[i].DESCRIPTION.length == 0) {
                delete filter1[i].DESCRIPTION
            }
            if (filter1[i].DASH_SIZE.length == 0) {
                delete filter1[i].DASH_SIZE
            }
            if (filter1[i].STEM_TYPE.length == 0) {
                delete filter1[i].STEM_TYPE
            }
            if (filter1[i].FERRULE_DETAIL.length == 0) {
                delete filter1[i].FERRULE_DETAIL
            }


        }
        // getting number of objects in 'filter1' array

        var sizeofFilter1 = Object.keys(filter1[0]).length
        let FilteredResult = []
        if (!checkedonHoseMode) {
            if (sizeofFilter1 >= 1) {
                // if only hose mode selected and some other options selclted

                FilteredResult = result.filter(obj =>
                    Object.entries(filter1[0]).every(([prop, find]) => find.includes(obj[prop])));
            }
            else {
                // if only hose mode selected and no other options selclted

                FilteredResult = result
            }
        }
        else {
            // if Hose mode is checked 

            if (sizeofFilter1 > 0) {

                FilteredResult = result.filter(obj =>
                    Object.entries(filter1[0]).every(([prop, find]) => find.includes(obj[prop])));
            } else {
                FilteredResult = result
            }
        }


        let allInputs = ["filteredDataHoseDescriptionsAcc", "filteredDataHoseDashesAcc", "filteredDataStemTypesAcc", "filteredFerruleAcc"];
        let allInputs_mod = ["DataHoseDescriptionsfilteredAcc", "DataHoseDashesfilteredAcc", "DataStemTypesfilteredAcc", "DataFerrulefilteredAcc"];

        var valuesFilter = allInputs
        var valuesFilter2 = allInputs_mod

        let stateToSet = {};
        let stateToSet2 = {};

        saveAccessoriesDataInIndexDB(FilteredResult);

        // localStorage.setItem("Filteredaccessories", JSON.stringify(FilteredResult))

        valuesFilter.map((value) => {
            let toFilter = '';



            if (value === "filteredDataHoseDescriptionsAcc") {
                toFilter = "DESCRIPTION";
            }

            if (value === "filteredDataHoseDashesAcc") {
                toFilter = "DASH_SIZE";
            }

            if (value === "filteredDataStemTypesAcc") {
                toFilter = "STEM_TYPE";
            }

            if (value === "filteredFerruleAcc") {
                toFilter = "FERRULE_DETAIL";
            }

            stateToSet[value] = FilteredResult.map(item => item[toFilter])
                .filter((value, index, self) => self.indexOf(value) === index)
        })

        valuesFilter2.map((value) => {
            let toFilter2 = '';




            if (value === "DataHoseDescriptionsfilteredAcc") {
                toFilter2 = "DESCRIPTION";
            }

            if (value === "DataHoseDashesfilteredAcc") {
                toFilter2 = "DASH_SIZE";
            }

            if (value === "DataStemTypesfilteredAcc") {
                toFilter2 = "STEM_TYPE";
            }

            if (value === "DataFerrulefilteredAcc") {
                toFilter2 = "FERRULE_DETAIL";
            }

            stateToSet2[value] = FilteredResult.map(item => item[toFilter2])
                .filter((value, index, self) => self.indexOf(value) === index)

        })

        var ArrayOfFilters = {}
        for (var i = 0; i < valuesFilter2.length; i++) {
            ArrayOfFilters[valuesFilter2[i]] = stateToSet2[valuesFilter2[i]].map(function (elem) {

                return { "name": elem, "isChecked": false };
            });
        }


        if (!checkedonDescription && !(filter1[0]["DESCRIPTION"] != null || filter1[0]["DESCRIPTION"] != undefined)) {
            for (var i = 0; i < HoseDescriptionSelectedAcc.length; i++) {
                if (HoseDescriptionSelectedAcc.length == 0) {
                    ArrayOfFilters["DataHoseDescriptionsfilteredAcc"].isChecked = false
                }
                else {
                    if (HoseDescriptionSelectedAcc[i].name == ArrayOfFilters["DataHoseDescriptionsfilteredAcc"][i].name) {
                        ArrayOfFilters["DataHoseDescriptionsfilteredAcc"][i].isChecked = true
                    }
                }
            }
        }
        else {
            if (checkedonDescription) {
                ArrayOfFilters["DataHoseDescriptionsfilteredAcc"] = DataHoseDescriptionsfilteredAcc

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataHoseDescriptionsfilteredAcc"].length; i++) {
                    for (var j = 0; j < DataHoseDescriptionsfilteredAcc.length; j++) {
                        if (ArrayOfFilters["DataHoseDescriptionsfilteredAcc"][i].name == DataHoseDescriptionsfilteredAcc[j].name) {
                            ArrayOfFilters["DataHoseDescriptionsfilteredAcc"][i].isChecked = true
                        }
                    }

                }
            }


        }


        if (!checkedonDashsize && !(filter1[0]["DASH_SIZE"] != null || filter1[0]["DASH_SIZE"] != undefined)) {


            for (var i = 0; i < HoseDashsizeSelectedAcc.length; i++) {
                if (HoseDashsizeSelectedAcc.length == 0) {
                    ArrayOfFilters["DataHoseDashesfilteredAcc"].isChecked = false
                }
                else {
                    if (HoseDashsizeSelectedAcc[i].name == ArrayOfFilters["DataHoseDashesfilteredAcc"][i].name) {
                        ArrayOfFilters["DataHoseDashesfilteredAcc"][i].isChecked = true
                    }
                }
            }
        } else {

            // ArrayOfFilters["DataHoseDashesfiltered"] = DataHoseDashesfiltered
            if (checkedonDashsize) {
                ArrayOfFilters["DataHoseDashesfilteredAcc"] = DataHoseDashesfilteredAcc

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataHoseDashesfilteredAcc"].length; i++) {
                    for (var j = 0; j < DataHoseDashesfilteredAcc.length; j++) {
                        if (ArrayOfFilters["DataHoseDashesfilteredAcc"][i].name == DataHoseDashesfilteredAcc[j].name) {
                            ArrayOfFilters["DataHoseDashesfilteredAcc"][i].isChecked = true
                        }
                    }

                }
            }

        }

        if (!checkedonStemtype && !(filter1[0]["STEM_TYPE"] != null || filter1[0]["STEM_TYPE"] != undefined)) {
            for (var i = 0; i < stemTypeSelectedAcc.length; i++) {
                if (stemTypeSelectedAcc.length == 0) {
                    ArrayOfFilters["DataStemTypesfilteredAcc"].isChecked = false
                }
                else {
                    if (stemTypeSelectedAcc[i].name == ArrayOfFilters["DataStemTypesfilteredAcc"][i].name) {
                        ArrayOfFilters["DataStemTypesfilteredAcc"][i].isChecked = true
                    }
                }
            }
        } else {

            if (checkedonStemtype) {
                ArrayOfFilters["DataStemTypesfilteredAcc"] = DataStemTypesfilteredAcc

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataStemTypesfilteredAcc"].length; i++) {
                    for (var j = 0; j < DataStemTypesfilteredAcc.length; j++) {
                        if (ArrayOfFilters["DataStemTypesfilteredAcc"][i].name == DataStemTypesfilteredAcc[j].name) {
                            ArrayOfFilters["DataStemTypesfilteredAcc"][i].isChecked = true
                        }
                    }

                }
            }

        }


        if (!checkedonPressure && !(filter1[0]["FERRULE_DETAIL"] != null || filter1[0]["FERRULE_DETAIL"] != undefined))
            for (var i = 0; i < HoseFerruleSelectedAcc.length; i++) {
                if (HoseFerruleSelectedAcc.length == 0) {
                    ArrayOfFilters["DataPressuresfiltered"].isChecked = false
                }
                else {
                    if (HoseFerruleSelectedAcc[i].name == ArrayOfFilters["DataFerrulefilteredAcc"][i].name) {
                        ArrayOfFilters["DataFerrulefilteredAcc"][i].isChecked = true
                    }
                }
            }
        else {

            if (checkedonPressure) {
                ArrayOfFilters["DataFerrulefilteredAcc"] = DataFerrulefilteredAcc

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataFerrulefilteredAcc"].length; i++) {
                    for (var j = 0; j < DataFerrulefilteredAcc.length; j++) {
                        if (ArrayOfFilters["DataFerrulefilteredAcc"][i].name == DataFerrulefilteredAcc[j].name) {
                            ArrayOfFilters["DataFerrulefilteredAcc"][i].isChecked = true
                        }
                    }

                }
            }
        }


        let filteredDataCrimpers = FilteredResult.map(item => item.CRIMPER_ID)
            .filter((value, index, self) => self.indexOf(value) === index);

        stateToSet['filteredDataCrimpersResponseAcc'] = FilteredResult;
        stateToSet['filteredDataCrimpers'] = filteredDataCrimpers;
        this.setState(ArrayOfFilters)
        this.setState(stateToSet)

        if (selectedModesTypesAcc.length > 0 || selectedHoseDescriptionAcc.length > 0 || selectedHoseDashsizeAcc.length > 0 || selectedTypeAcc.length > 0 || selectedFerruleAcc.length > 0) {
            this.setState({ appliedFilters: true, AtleastOneFilterSelected: true })
        }
        else {
            this.setState({ appliedFilters: false, AtleastOneFilterSelected: false })

        }
        if (Immediatefilter) {
            this.handleSubmitAccessories("typeaccessoriesMode", "", selectedHoseDescriptionAcc, selectedHoseDashsizeAcc, selectedTypeAcc, selectedFerruleAcc)
        }
        this.setValue(filterArray)

    }

    handleValueChangesForaccesoriesInsideFilter = (type, value) => {
        // filters the data based on the applied filters
        const { Immediatefilter } = this.props.auth;

        const { ApplyFilters, accessoriesData, filteraccessoriesData, filterArray } = this.state;
        const { valuesDashsizeAcc, valuesDescriptionAcc, valuesFerruleAcc, valuesStemtypeAcc, selectedModesTypes } = this.state;
        const { valuesDashsizeAcc2, valuesDescriptionAcc2, valuesFerruleAcc2, valuesStemtypeAcc2, selectedModesTypesAcc } = this.state;

        const { DataHoseDescriptionsfilteredAcc, DataHoseDashesfilteredAcc, DataStemTypesfilteredAcc, DataFerrulefilteredAcc } = this.state
        var result = accessoriesData

        var response = [value]

        var removeMe = value;


        var checkedonDies = false
        var checkedonDescription = false
        var checkedonDashsize = false
        var checkedonStemtype = false
        var checkedonPressure = false
        var checkedonHoseMode = false


        var selectedHoseDescriptionAcc = valuesDescriptionAcc
        var selectedHoseDashsizeAcc = valuesDashsizeAcc
        var selectedTypeAcc = valuesStemtypeAcc
        var selectedFerruleAcc = valuesFerruleAcc
        var selectedMode = selectedModesTypes


        var HoseDescriptionSelectedAcc = valuesDescriptionAcc2
        var HoseDashsizeSelectedAcc = valuesDashsizeAcc2
        var stemTypeSelectedAcc = valuesStemtypeAcc2
        var HoseFerruleSelectedAcc = valuesFerruleAcc2

        if (type == 'hoseDescriptions') {


            checkedonDescription = true
            for (var i = 0; i < DataHoseDescriptionsfilteredAcc.length; i++) {
                if (DataHoseDescriptionsfilteredAcc[i].name === value) {
                    DataHoseDescriptionsfilteredAcc[i].isChecked = false
                }
            }
            // filtering unchecked values of hoseDescriptions

            var valuesFilter = valuesDescriptionAcc.filter(s => s !== removeMe);
            this.setState({ valuesDescriptionAcc: valuesFilter })
            selectedHoseDescriptionAcc = valuesFilter

            HoseDescriptionSelectedAcc = DataHoseDescriptionsfilteredAcc.filter(s => s.isChecked);

        }
        if (type == 'hoseDashes') {


            checkedonDashsize = true
            for (var i = 0; i < DataHoseDashesfilteredAcc.length; i++) {
                if (DataHoseDashesfilteredAcc[i].name === value) {
                    DataHoseDashesfilteredAcc[i].isChecked = false
                }
            }
            // filtering unchecked values of hosedash

            var valuesFilter = valuesDashsizeAcc.filter(s => s !== removeMe);
            this.setState({ valuesDashsizeAcc: valuesFilter })
            selectedHoseDashsizeAcc = valuesFilter

            HoseDashsizeSelectedAcc = DataHoseDashesfilteredAcc.filter(s => s.isChecked);

        }
        if (type == 'stemTypes') {


            checkedonStemtype = true
            for (var i = 0; i < DataStemTypesfilteredAcc.length; i++) {
                if (DataStemTypesfilteredAcc[i].name === value) {
                    DataStemTypesfilteredAcc[i].isChecked = false
                }
            }
            // filtering unchecked values of hosedash

            var valuesFilter = valuesStemtypeAcc.filter(s => s !== removeMe);
            this.setState({ valuesStemtypeAcc: valuesFilter })
            selectedTypeAcc = valuesFilter



            stemTypeSelectedAcc = DataStemTypesfilteredAcc.filter(s => s.isChecked);


        }
        if (type == 'pressures') {


            checkedonPressure = true
            for (var i = 0; i < DataFerrulefilteredAcc.length; i++) {
                if (DataFerrulefilteredAcc[i].name === value) {
                    DataFerrulefilteredAcc[i].isChecked = false
                }
            }
            // filtering unchecked values of presuree
            var valuesFilter = valuesFerruleAcc.filter(s => s !== removeMe);
            this.setState({ valuesFerruleAcc: valuesFilter })
            selectedFerruleAcc = valuesFilter

            HoseFerruleSelectedAcc = DataFerrulefilteredAcc.filter(s => s.isChecked);

        }

        // storing all selected values in 'filter1' array
        let filter1 = [{
            "DESCRIPTION": selectedHoseDescriptionAcc,
            "DASH_SIZE": selectedHoseDashsizeAcc,
            "STEM_TYPE": selectedTypeAcc,
            "FERRULE_DETAIL": selectedFerruleAcc,
            // "HOSE_TYPE_ID": selectedMode
        }];
        // storing all selected values in setstate for each

        this.setState({
            valuesDashsizeAcc2: HoseDescriptionSelectedAcc,
            valuesDashsizeAcc2: HoseDashsizeSelectedAcc,
            valuesStemtypeAcc2: stemTypeSelectedAcc,
            valuesFerruleAcc2: HoseFerruleSelectedAcc
        })
        // filtering the options(eg: DIE_ID, DESCRIPTION,..) from 'filter1' Array to apply the selected filters only

        for (var i = 0; i < filter1.length; i++) {


            if (filter1[i].DESCRIPTION.length == 0) {
                delete filter1[i].DESCRIPTION
            }
            if (filter1[i].DASH_SIZE.length == 0) {
                delete filter1[i].DASH_SIZE
            }
            if (filter1[i].STEM_TYPE.length == 0) {
                delete filter1[i].STEM_TYPE
            }
            if (filter1[i].FERRULE_DETAIL.length == 0) {
                delete filter1[i].FERRULE_DETAIL
            }


        }
        // getting number of objects in 'filter1' array

        var sizeofFilter1 = Object.keys(filter1[0]).length
        let FilteredResult = []
        if (!checkedonHoseMode) {
            if (sizeofFilter1 >= 1) {
                // if only hose mode selected and some other options selclted

                FilteredResult = result.filter(obj =>
                    Object.entries(filter1[0]).every(([prop, find]) => find.includes(obj[prop])));
            }
            else {
                // if only hose mode selected and no other options selclted

                FilteredResult = result
            }
        }
        else {
            // if Hose mode is checked 

            if (sizeofFilter1 > 0) {

                FilteredResult = result.filter(obj =>
                    Object.entries(filter1[0]).every(([prop, find]) => find.includes(obj[prop])));
            } else {
                FilteredResult = result
            }
        }


        let allInputs = ["filteredDataHoseDescriptionsAcc", "filteredDataHoseDashesAcc", "filteredDataStemTypesAcc", "filteredFerruleAcc"];
        let allInputs_mod = ["DataHoseDescriptionsfilteredAcc", "DataHoseDashesfilteredAcc", "DataStemTypesfilteredAcc", "DataFerrulefilteredAcc"];

        var valuesFilter = allInputs
        var valuesFilter2 = allInputs_mod

        let stateToSet = {};
        let stateToSet2 = {};

        valuesFilter.map((value) => {
            let toFilter = '';



            if (value === "filteredDataHoseDescriptionsAcc") {
                toFilter = "DESCRIPTION";
            }

            if (value === "filteredDataHoseDashesAcc") {
                toFilter = "DASH_SIZE";
            }

            if (value === "filteredDataStemTypesAcc") {
                toFilter = "STEM_TYPE";
            }

            if (value === "filteredFerruleAcc") {
                toFilter = "FERRULE_DETAIL";
            }

            stateToSet[value] = FilteredResult.map(item => item[toFilter])
                .filter((value, index, self) => self.indexOf(value) === index)
        })

        valuesFilter2.map((value) => {
            let toFilter2 = '';




            if (value === "DataHoseDescriptionsfilteredAcc") {
                toFilter2 = "DESCRIPTION";
            }

            if (value === "DataHoseDashesfilteredAcc") {
                toFilter2 = "DASH_SIZE";
            }

            if (value === "DataStemTypesfilteredAcc") {
                toFilter2 = "STEM_TYPE";
            }

            if (value === "DataFerrulefilteredAcc") {
                toFilter2 = "FERRULE_DETAIL";
            }

            stateToSet2[value] = FilteredResult.map(item => item[toFilter2])
                .filter((value, index, self) => self.indexOf(value) === index)

        })

        var ArrayOfFilters = {}
        for (var i = 0; i < valuesFilter2.length; i++) {
            ArrayOfFilters[valuesFilter2[i]] = stateToSet2[valuesFilter2[i]].map(function (elem) {

                return { "name": elem, "isChecked": false };
            });
        }


        if (!checkedonDescription && !(filter1[0]["DESCRIPTION"] != null || filter1[0]["DESCRIPTION"] != undefined)) {
            for (var i = 0; i < HoseDescriptionSelectedAcc.length; i++) {
                if (HoseDescriptionSelectedAcc.length == 0) {
                    ArrayOfFilters["DataHoseDescriptionsfilteredAcc"].isChecked = false
                }
                else {
                    if (HoseDescriptionSelectedAcc[i].name == ArrayOfFilters["DataHoseDescriptionsfilteredAcc"][i].name) {
                        ArrayOfFilters["DataHoseDescriptionsfilteredAcc"][i].isChecked = true
                    }
                }
            }
        }
        else {
            if (checkedonDescription) {
                ArrayOfFilters["DataHoseDescriptionsfilteredAcc"] = DataHoseDescriptionsfilteredAcc

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataHoseDescriptionsfilteredAcc"].length; i++) {
                    for (var j = 0; j < DataHoseDescriptionsfilteredAcc.length; j++) {
                        if (ArrayOfFilters["DataHoseDescriptionsfilteredAcc"][i].name == DataHoseDescriptionsfilteredAcc[j].name) {
                            ArrayOfFilters["DataHoseDescriptionsfilteredAcc"][i].isChecked = true
                        }
                    }

                }
            }


        }


        if (!checkedonDashsize && !(filter1[0]["DASH_SIZE"] != null || filter1[0]["DASH_SIZE"] != undefined)) {


            for (var i = 0; i < HoseDashsizeSelectedAcc.length; i++) {
                if (HoseDashsizeSelectedAcc.length == 0) {
                    ArrayOfFilters["DataHoseDashesfilteredAcc"].isChecked = false
                }
                else {
                    if (HoseDashsizeSelectedAcc[i].name == ArrayOfFilters["DataHoseDashesfilteredAcc"][i].name) {
                        ArrayOfFilters["DataHoseDashesfilteredAcc"][i].isChecked = true
                    }
                }
            }
        } else {

            // ArrayOfFilters["DataHoseDashesfiltered"] = DataHoseDashesfiltered
            if (checkedonDashsize) {
                ArrayOfFilters["DataHoseDashesfilteredAcc"] = DataHoseDashesfilteredAcc

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataHoseDashesfilteredAcc"].length; i++) {
                    for (var j = 0; j < DataHoseDashesfilteredAcc.length; j++) {
                        if (ArrayOfFilters["DataHoseDashesfilteredAcc"][i].name == DataHoseDashesfilteredAcc[j].name) {
                            ArrayOfFilters["DataHoseDashesfilteredAcc"][i].isChecked = true
                        }
                    }

                }
            }

        }

        if (!checkedonStemtype && !(filter1[0]["STEM_TYPE"] != null || filter1[0]["STEM_TYPE"] != undefined)) {
            for (var i = 0; i < stemTypeSelectedAcc.length; i++) {
                if (stemTypeSelectedAcc.length == 0) {
                    ArrayOfFilters["DataStemTypesfilteredAcc"].isChecked = false
                }
                else {
                    if (stemTypeSelectedAcc[i].name == ArrayOfFilters["DataStemTypesfilteredAcc"][i].name) {
                        ArrayOfFilters["DataStemTypesfilteredAcc"][i].isChecked = true
                    }
                }
            }
        } else {

            if (checkedonStemtype) {
                ArrayOfFilters["DataStemTypesfilteredAcc"] = DataStemTypesfilteredAcc

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataStemTypesfilteredAcc"].length; i++) {
                    for (var j = 0; j < DataStemTypesfilteredAcc.length; j++) {
                        if (ArrayOfFilters["DataStemTypesfilteredAcc"][i].name == DataStemTypesfilteredAcc[j].name) {
                            ArrayOfFilters["DataStemTypesfilteredAcc"][i].isChecked = true
                        }
                    }

                }
            }

        }


        if (!checkedonPressure && !(filter1[0]["FERRULE_DETAIL"] != null || filter1[0]["FERRULE_DETAIL"] != undefined))
            for (var i = 0; i < HoseFerruleSelectedAcc.length; i++) {
                if (HoseFerruleSelectedAcc.length == 0) {
                    ArrayOfFilters["DataPressuresfiltered"].isChecked = false
                }
                else {
                    if (HoseFerruleSelectedAcc[i].name == ArrayOfFilters["DataFerrulefilteredAcc"][i].name) {
                        ArrayOfFilters["DataFerrulefilteredAcc"][i].isChecked = true
                    }
                }
            }
        else {

            if (checkedonPressure) {
                ArrayOfFilters["DataFerrulefilteredAcc"] = DataFerrulefilteredAcc

            }
            else {
                for (var i = 0; i < ArrayOfFilters["DataFerrulefilteredAcc"].length; i++) {
                    for (var j = 0; j < DataFerrulefilteredAcc.length; j++) {
                        if (ArrayOfFilters["DataFerrulefilteredAcc"][i].name == DataFerrulefilteredAcc[j].name) {
                            ArrayOfFilters["DataFerrulefilteredAcc"][i].isChecked = true
                        }
                    }

                }
            }
        }
        // localStorage.setItem("Filteredaccessories", JSON.stringify(FilteredResult))
        saveAccessoriesDataInIndexDB(FilteredResult);


        let filteredDataCrimpers = FilteredResult.map(item => item.CRIMPER_ID)
            .filter((value, index, self) => self.indexOf(value) === index);

        stateToSet['filteredDataCrimpersResponseAcc'] = FilteredResult;
        stateToSet['filteredDataCrimpers'] = filteredDataCrimpers;
        this.setState(ArrayOfFilters)
        this.setState(stateToSet)

        // if (selectedModesTypes.length > 0 || selecteddies.length > 0 || selectedHoseDescription.length > 0 || selectedHoseDashsize.length > 0 || selectedType.length > 0 || selectedHosePressure.length > 0) {
        //     this.setState({ appliedFilters: true, AtleastOneFilterSelected: true })
        // }
        // else {
        //     this.setState({ appliedFilters: false, AtleastOneFilterSelected: false })

        // }
        if (Immediatefilter) {
            this.handleSubmitAccessories("typeaccessoriesMode", "", selectedHoseDescriptionAcc, selectedHoseDashsizeAcc, selectedTypeAcc, selectedFerruleAcc)
        }
    }

    async handleSubmit(Typeofmodes, selecteddies, selectedHoseDescription, selectedHoseDashsize, selectedType, selectedHosePressure) {
        try {

            await clearAppliedNotesData();
            await clearNotesData();
            // Gets the data based on the applied filters
            const { Immediatefilter } = this.props.auth;
            const { selectedCrimper } = this.state;
            const { valuesDies, valuesDashsize, valuesDescription, valuesPressure, valuesStemtype, selectedModesTypes } = this.state;
            const { valuesDashsizeAcc, valuesDescriptionAcc, valuesFerruleAcc, valuesStemtypeAcc } = this.state;
            const { DataHoseDescriptionsfiltered, DataDiesfiltered, DataStemTypesfiltered, DataHoseDashesfiltered } = this.state;
            this.setState({ ApplyFilters: true, FilteredNotesImage: [], AppliedNotesData: [] })
            var params = '';
            var parArr = [];
            var NotesparArr = [];
            var tofilter = ["diesParams", "descriptionParams", "sizeParams", "typeParams", "pressureParams", "hoseTypeModes"]
            var tofilterNotes = ["NotesdiesParams", "NotesdescriptionParams", "NotesStem", "NotesDashSize"]


            var diesParams = "";
            var NotesdiesParams = "";
            var descriptionParams = "";
            var NotesdescriptionParams = "";
            var NotesStem = "";
            var NotesDashSize = "";
            var sizeParams = "";
            var typeParams = "";
            var pressureParams = "";
            var hoseTypeModes = "";
            var ferruleParams = "";
            let finalNotesQuery
            params += `CRIMPER_ID eq '${selectedCrimper}'`;
            const region = this.props.region;


            // var userData = JSON.parse(localStorage.getItem("userData"))
            var userData = await getuserDataFromIndexDB()
            const d = new Date();
            let timeDate = d.toISOString();

            if (userData != null) {
                var payload = {
                    "entries": [{
                        Userid: userData.userId,
                        Datetime: timeDate,
                        CRIMPER_ID: selectedCrimper,
                        HOSE_MODE: selectedModesTypes,
                        DESCRIPTION: valuesDescription,
                        DIE_ID: valuesDies,
                        DASH_SIZE: valuesDashsize,
                        STEM_TYPE: valuesStemtype,
                        WORKING_PRESSURE: valuesPressure

                    }
                    ]
                }
            }
            else {
                var payload = {
                    "entries": [{
                        Userid: "Anonymous",
                        Datetime: timeDate,
                        CRIMPER_ID: selectedCrimper,
                        HOSE_MODE: selectedModesTypes,
                        DESCRIPTION: valuesDescription,
                        DIE_ID: valuesDies,
                        DASH_SIZE: valuesDashsize,
                        STEM_TYPE: valuesStemtype,
                        WORKING_PRESSURE: valuesPressure

                    }
                    ]
                }
            }
            var urlPayload = API.URL + "data-collection/search-filters"
            ApiServices.httpPOST(urlPayload, payload, (err, response) => {

                if (response) {

                    var data = response;
                };
            });


            if (Typeofmodes == "typehoseMode") {
                if (selectedModesTypes.length != 0) {
                    selectedModesTypes.forEach((value, index) => {
                        if (index == 0) {
                            hoseTypeModes += ` and HOSE_TYPE eq '${value}'`;
                        } else {
                            hoseTypeModes += ` or HOSE_TYPE eq '${value}'`;
                        }
                    })
                }
            }

            if (Immediatefilter) {
                if (Typeofmodes == "typehoseMode") {
                    if (selecteddies.length != 0) {
                        selecteddies.forEach((value, index) => {
                            if (index == 0) {
                                diesParams += ` and DIE_ID eq '${value}'`;
                            } else {
                                diesParams += ` or DIE_ID eq '${value}'`;
                            }
                        })
                    }
                    if (selecteddies.length != 0) {
                        selecteddies.forEach((value, index) => {
                            if (index == 0) {
                                NotesdiesParams += ` and DIE_ID eq '${value}' or DIE_ID eq 'Any'`;
                            } else {
                                NotesdiesParams += ` or DIE_ID eq '${value}' or DIE_ID eq 'Any'`;
                            }
                        })
                    }

                    // params += diesInput.length > 0 ? '&dies=' + diesInput.join('|') : ''
                    if (selectedHoseDescription.length != 0) {
                        selectedHoseDescription.forEach((value, index) => {
                            if (index == 0) {
                                descriptionParams += ` and HOSE_DISPLAY_NAME eq '${value}'`;
                            } else {
                                descriptionParams += ` or HOSE_DISPLAY_NAME eq '${value}'`;
                            }
                        })
                    }

                    if (selectedHoseDescription.length != 0) {
                        selectedHoseDescription.forEach((value, index) => {
                            if (index == 0) {
                                NotesdescriptionParams += ` and DESCRIPTION eq '${value}' or DESCRIPTION eq 'Any'`;
                            } else {
                                NotesdescriptionParams += ` or DESCRIPTION eq '${value}' or DESCRIPTION eq 'Any'`;
                            }
                        })
                    }
                    if (selectedHoseDashsize.length != 0) {
                        selectedHoseDashsize.forEach((value, index) => {
                            if (index == 0) {
                                sizeParams += ` and DASH_SIZE eq ${value}M`;
                            } else {
                                sizeParams += ` or DASH_SIZE eq ${value}M`;
                            }
                        })
                    }

                    if (selectedHoseDashsize.length != 0) {
                        selectedHoseDashsize.forEach((value, index) => {
                            if (index == 0) {
                                NotesDashSize += ` and STEM_DASH_SIZE eq ${value}`;
                            } else {
                                NotesDashSize += ` or STEM_DASH_SIZE eq ${value}`;
                            }
                        })
                    }

                    if (selectedType.length != 0) {
                        selectedType.forEach((value, index) => {
                            if (index == 0) {
                                typeParams += ` and STEM_TYPE eq '${value}'`;
                            } else {
                                typeParams += ` or STEM_TYPE eq '${value}'`;
                            }
                        })
                    }

                    if (selectedType.length != 0) {
                        selectedType.forEach((value, index) => {
                            if (index == 0) {
                                NotesStem += ` and STEM_TYPE eq '${value}'  or STEM_TYPE eq 'Any'`;
                            } else {
                                NotesStem += ` or STEM_TYPE eq '${value}'  or STEM_TYPE eq 'Any'`;
                            }
                        })
                    }

                    if (selectedHosePressure.length != 0) {
                        selectedHosePressure.forEach((value, index) => {
                            if (index == 0) {
                                pressureParams += ` and WORKING_PRESSURE eq ${value}`;
                            } else {
                                pressureParams += ` or WORKING_PRESSURE eq ${value}`;
                            }
                        })
                    }


                }
            }
            else {

                if (Typeofmodes == "typehoseMode") {
                    if (valuesDies.length != 0) {
                        valuesDies.forEach((value, index) => {
                            if (index == 0) {
                                diesParams += ` and DIE_ID eq '${value}'`;
                            } else {
                                diesParams += ` or DIE_ID eq '${value}'`;
                            }
                        })
                    }

                    // params += diesInput.length > 0 ? '&dies=' + diesInput.join('|') : ''
                    if (valuesDescription.length != 0) {
                        valuesDescription.forEach((value, index) => {
                            if (index == 0) {
                                descriptionParams += ` and HOSE_DISPLAY_NAME eq '${value}'`;
                            } else {
                                descriptionParams += ` or HOSE_DISPLAY_NAME eq '${value}'`;
                            }
                        })
                    }




                    if (valuesStemtype.length != 0) {
                        valuesStemtype.forEach((value, index) => {
                            if (index == 0) {
                                typeParams += ` and STEM_TYPE eq '${value}'`;
                            } else {
                                typeParams += ` or STEM_TYPE eq '${value}'`;
                            }
                        })
                    }



                    if (valuesPressure.length != 0) {
                        valuesPressure.forEach((value, index) => {
                            if (index == 0) {
                                pressureParams += ` and WORKING_PRESSURE eq ${value}`;
                            } else {
                                pressureParams += ` or WORKING_PRESSURE eq ${value}`;
                            }
                        })
                    }

                    if (valuesDashsize.length != 0) {
                        valuesDashsize.forEach((value, index) => {
                            if (index == 0) {
                                sizeParams += ` and DASH_SIZE eq ${value}M`;
                            } else {
                                sizeParams += ` or DASH_SIZE eq ${value}M`;
                            }
                        })
                    }
                    var cloneDataHoseDescriptionsfiltered = isEmpty(valuesDescription) ? [...(DataHoseDescriptionsfiltered || [])].map(item => item["name"]) : valuesDescription
                    var cloneDataDiesfiltered = isEmpty(valuesDies) ? [...(DataDiesfiltered || [])].map(item => item["name"]) : valuesDies
                    var cloneDataStemTypesfiltered = isEmpty(valuesStemtype) ? [...(DataStemTypesfiltered || [])].map(item => item["name"]) : valuesStemtype
                    var cloneDataHoseDashesfiltered = isEmpty(valuesDashsize)  ?[...(DataHoseDashesfiltered || [])].map(item => item["name"]) : valuesDashsize

                    var uniqueDataHoseDescriptionsfiltered = cloneDataHoseDescriptionsfiltered
                        .filter((value, index, self) => self.indexOf(value) === index)
                    const descriptionsQuery = [...uniqueDataHoseDescriptionsfiltered, 'Any'].map(e => `DESCRIPTION eq '${e}'`).join(' or ')
                    var uniqueDataDiesfiltered = cloneDataDiesfiltered
                    .filter((value, index, self) => self.indexOf(value) === index)
                    const diesQuery = [...uniqueDataDiesfiltered, 'Any'].map(e => `DIE_ID eq '${e}'`).join(' or ')
                    var uniqueDataHoseDashesfiltered = cloneDataHoseDashesfiltered
                    .filter((value, index, self) => self.indexOf(value) === index)
                    const dashSizesQuery = [...uniqueDataHoseDashesfiltered, 'Any'].map(e => {
                        if(e === 'Any') return `STEM_DASH_SIZE eq '${e}'`
                        const parsed = parseInt(e)
                        if(String(parsed) === String(e)) return `STEM_DASH_SIZE eq '${e}'`
                        return `STEM_DASH_SIZE eq '${e}' or STEM_DASH_SIZE eq '${parsed}'`
                    }).join(' or ')
                    var uniqueDataStemTypesfiltered = cloneDataStemTypesfiltered
                    .filter((value, index, self) => self.indexOf(value) === index)
                    const stemTypesQuery = [...uniqueDataStemTypesfiltered, 'Any'].map(e => `STEM_TYPE eq '${e}'`).join(' or ')
                    

                    finalNotesQuery = [descriptionsQuery, diesQuery, dashSizesQuery, stemTypesQuery].map(e => `(${e})`).join(' and ')

                    /*Tested - Notes Query New Filter*/


                    // HOSE DESCRIPTION
                    if (valuesDescription.length != 0) {
                        valuesDescription.forEach((value, index) => {
                            if (index == 0) {
                                NotesdescriptionParams += ` and DESCRIPTION eq '${value}'`;
                            } else {
                                NotesdescriptionParams += ` or DESCRIPTION eq '${value}'`;
                            }
                        })
                        if(valuesDescription.length > 0) {
                            NotesdescriptionParams += " or DESCRIPTION eq 'Any'"
                        }
                    }
                    else {
                        uniqueDataHoseDescriptionsfiltered.forEach((value, index) => {
                            if (index == 0) {
                                NotesdescriptionParams += ` and DESCRIPTION eq '${value}'`;
                            } else {
                                NotesdescriptionParams += ` or DESCRIPTION eq '${value}'`;
                            }
                        })
                        if(uniqueDataHoseDescriptionsfiltered.length > 0){
                            NotesdescriptionParams += " or DESCRIPTION eq 'Any'"
                        }
                    }
                    // HOSE DIESET


                    if (valuesDies.length != 0) {
                        valuesDies.forEach((value, index) => {
                            if (index == 0) {
                                NotesdiesParams += ` and DIE_ID eq '${value}'`;
                            } else {
                                NotesdiesParams += ` or DIE_ID eq '${value}'`;
                            }
                        })
                        if (valuesDies.length > 0) {
                            NotesdiesParams += " or DIE_ID eq 'Any'"
                        }
                    }
                    else {
                        uniqueDataDiesfiltered.forEach((value, index) => {
                            if (index == 0) {
                                NotesdiesParams += ` and DIE_ID eq '${value}'`;
                            } else {
                                NotesdiesParams += ` or DIE_ID eq '${value}'`;
                            }
                        })
                        if (uniqueDataDiesfiltered.length > 0) {
                            NotesdiesParams += " or DIE_ID eq 'Any'"
                        }
                    }

                    // DASH SIZE
                    if (valuesDashsize.length != 0) {
                        valuesDashsize.forEach((value, index) => {
                            if (index == 0) {
                                NotesDashSize += ` and STEM_DASH_SIZE eq '${value}'`;
                            } else {
                                NotesDashSize += ` or STEM_DASH_SIZE eq '${value}'`;
                            }
                        })
                        if (valuesDashsize.length > 0) {
                            NotesDashSize += " or STEM_DASH_SIZE eq 'Any'"
                        }
                    }

                    else {
                        uniqueDataHoseDashesfiltered.forEach((value, index) => {
                            if (index == 0) {
                                NotesDashSize += ` and STEM_DASH_SIZE eq '${value}'`;
                            } else {
                                NotesDashSize += ` or STEM_DASH_SIZE eq '${value}'`;
                            }
                        })
                        if (uniqueDataHoseDashesfiltered.length > 0) {
                            NotesDashSize += " or STEM_DASH_SIZE eq 'Any'"
                        }
                    }
                    // STEM TYPE
                    if (valuesStemtype.length != 0) {
                        valuesStemtype.forEach((value, index) => {
                            if (index == 0) {
                                NotesStem += ` and STEM_TYPE eq '${value}'`;
                            } else {
                                NotesStem += ` or STEM_TYPE eq '${value}'`;
                            }
                        })
                        if (valuesStemtype.length > 0) {
                            NotesStem += " or STEM_TYPE eq 'Any'"
                        }
                    }

                    else {

                        uniqueDataStemTypesfiltered.forEach((value, index) => {
                            if (index == 0) {
                                NotesStem += ` and STEM_TYPE eq '${value}'`;
                            } else {
                                NotesStem += ` or STEM_TYPE eq '${value}'`;
                            }
                        })
                        if (uniqueDataStemTypesfiltered.length > 0) {
                            NotesStem += " or STEM_TYPE eq 'Any'"
                        }
                    }
                }

            }

            if (Typeofmodes == "typehoseMode") {
                parArr.push({ "hoseTypeModes": hoseTypeModes, "diesParams": diesParams, "descriptionParams": descriptionParams, "sizeParams": sizeParams, "typeParams": typeParams, "pressureParams": pressureParams })
                NotesparArr.push({ "NotesdiesParams": NotesdiesParams, "NotesdescriptionParams": NotesdescriptionParams, "NotesStem": NotesStem, "NotesDashSize": NotesDashSize })
            }



            if (Typeofmodes == "typehoseMode") {
                for (var i = 0; i < 6; i++) {
                    var a = parArr[0][tofilter[i]];
                    var b = " (";
                    var c = ")";
                    var position = 5;
                    var output1 = [a.slice(0, position), b, a.slice(position)].join('');
                    var output2 = [output1.slice(0, output1.length), c, output1.slice(output1.length)].join('');
                    parArr[0][tofilter[i]] = output2
                    if (parArr[0][tofilter[i]].length < 6) {
                        parArr[0][tofilter[i]] = ""
                    }
                }
                for (var i = 0; i < 4; i++) {
                    var a = NotesparArr[0][tofilterNotes[i]];
                    var b = " (";
                    var c = ")";
                    var position = 4;
                    var output1 = [a.slice(0, position), b, a.slice(position)].join('');
                    var output2 = [output1.slice(0, output1.length), c, output1.slice(output1.length)].join('');
                    NotesparArr[0][tofilterNotes[i]] = output2
                    if (NotesparArr[0][tofilterNotes[i]].length < 6) {
                        NotesparArr[0][tofilterNotes[i]] = ""
                    }
                }
            }

            let query
            if (selectedModesTypes.length == 2) {
                query = params + parArr[0].diesParams + parArr[0].descriptionParams + parArr[0].sizeParams + parArr[0].typeParams + parArr[0].pressureParams;

            }
            else {
                query = params + parArr[0].hoseTypeModes + parArr[0].diesParams + parArr[0].descriptionParams + parArr[0].sizeParams + parArr[0].typeParams + parArr[0].pressureParams;

            }
            var noteparams = `(REGION_ID eq '${region}') and (IS_LIFEGUARD eq 'FALSE') and (CRIMPER_ID eq '${selectedCrimper}' or CRIMPER_ID eq 'Any')`;
            var Notesquery = `${noteparams} and ${finalNotesQuery}`
            var urlNotes = API.URL + `notes-v2?$filter=` + Notesquery + `&$format=json`
            console.log(urlNotes)
            await ApiServices.httpGET(urlNotes, async (err, response) => {
                if (response !== null) {

                    if (response.data.d.results) {
                        var result = response.data.d.results;
                        console.log("result", result)
                        const MESSAGE = [...new Set(result.map(item => item.MESSAGE))];
                        await savefinalNotesDashboardDataInIndexDB(result)
                        await saveAppliedNotesDataDataInIndexDB(MESSAGE)
                        var NotesImages = []
                        for (var i = 0; i < result.length; i++) {
                            if (result[i].IMAGE_URL != null) {
                                NotesImages.push(result[i])
                            }
                        }

                        var FinalfilteredNotesImage = []
                        const filteredNotesImage = [...new Set(NotesImages.map(item => item.IMAGE_URL))];
                        for (var i = 0; i < filteredNotesImage.length; i++) {
                            var splits = filteredNotesImage[i].split(";");
                            for (var ij = 0; ij < splits.length; ij++) {

                                FinalfilteredNotesImage.push(splits[ij])
                            }
                        }

                        this.setState({ AppliedNotesData: MESSAGE, originalFilterDta: result, FilteredNotesImage: FinalfilteredNotesImage })

                    };
                }
            });


            let url = API.URL + `crimpers/specifications?$filter=` + query + `&$format=json`

            ApiServices.httpGET(url, async (err, response) => {


                if (response?.data?.d?.results) {

                    let tableData = response.data.d.results;
                    var hideSkive = 0
                    var hideSkiveDia = 0
                    var hidecrimpins = 0
                    var hidecrimplen = 0
                    var hidecrimptail = 0
                    var ConvertedTableData = UnitConversion(tableData)

                    for (var i = 0; i < ConvertedTableData.length; i++) {
                        if (ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH == "NONE" || ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH == "FULL" || ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH == "null" || ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH == "N/A") {
                            hideSkive = hideSkive + 1
                        }
                        if (ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH == "NONE" || ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH == "null" || ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH == "FULL" || ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH == "N/A") {
                            hideSkiveDia = hideSkiveDia + 1
                        }
                        if (ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH == "NONE" || ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH == "null" || ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH == "FULL" || ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH == "N/A") {
                            hidecrimplen = hidecrimplen + 1
                        }
                        if (ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH == "NONE" || ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH == "null" || ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH == "FULL" || ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH == "N/A") {
                            hidecrimpins = hidecrimpins + 1
                        }
                        if (ConvertedTableData[i].TAIL_LENGTH_INENGLISH == "NONE" || ConvertedTableData[i].TAIL_LENGTH_INENGLISH == "null" || ConvertedTableData[i].TAIL_LENGTH_INENGLISH == "FULL" || ConvertedTableData[i].TAIL_LENGTH_INENGLISH == "N/A") {
                            hidecrimptail = hidecrimptail + 1
                        }
                    }
                    if (hideSkive == ConvertedTableData.length) {
                        for (var i = 0; i < ConvertedTableData.length; i++) {

                            delete ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH
                            delete ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INMETRICS

                        }
                        this.setState({ HideSkiveLength: true })
                    }
                    if (hideSkiveDia == ConvertedTableData.length) {

                        for (var i = 0; i < ConvertedTableData.length; i++) {

                            delete ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH
                            delete ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INMETRICS

                        }
                        this.setState({ HideSkiveDiameter: true })

                    }
                    if (hidecrimplen == ConvertedTableData.length) {

                        for (var i = 0; i < ConvertedTableData.length; i++) {

                            delete ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH
                            delete ConvertedTableData[i].LENGTH_DISPLAY_INMETRICS
                        }
                        this.setState({ Hidecrimplength: true })

                    }

                    if (hidecrimpins == ConvertedTableData.length) {

                        for (var i = 0; i < ConvertedTableData.length; i++) {

                            delete ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH
                            delete ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INMETRICS
                        }
                        this.setState({ Hidecrimpinsertion: true })

                    }

                    if (hidecrimptail == ConvertedTableData.length) {

                        for (var i = 0; i < ConvertedTableData.length; i++) {

                            delete ConvertedTableData[i].TAIL_LENGTH_INENGLISH
                            delete ConvertedTableData[i].TAIL_LENGTH_INMETRICS
                        }
                        this.setState({ HIdecrimpTail: true })

                    }
                    var fav = await getFavoritesDataFromIndexDB();
                    for (var i = 0; i < ConvertedTableData.length; i++) {

                        if (fav.length != 0) {
                            for (var j = 0; j < fav.length; j++) {
                                if (ConvertedTableData[i].MSUID == fav[j].MSUID && ConvertedTableData[i].CSUID == fav[j].CSUID) {
                                    ConvertedTableData[i].isFavorite = true
                                    ConvertedTableData[i].order = 1
                                    ConvertedTableData[i].FAVORITE_ID = fav[j].FAVORITE_ID
                                    break
                                }
                                else {
                                    ConvertedTableData[i].isFavorite = false
                                    ConvertedTableData[i].order = 0
                                    ConvertedTableData[i].FAVORITE_ID = null
                                }
                            }
                        }
                        else {
                            ConvertedTableData[i].isFavorite = false
                            ConvertedTableData[i].order = 0
                            ConvertedTableData[i].FAVORITE_ID = null

                        }
                    }
                    var notesdata = await getfinalNotesDashboardDataFromIndexDB()
                    var displayingNotes = await getAppliedNotesDataDataFromIndexDB()

                    // comparing the Filtered Notes API 
                    const Any = 'Any'

                    if (notesdata.length > 0) {
                        for (var i = 0; i < notesdata.length; i++) {

                            for (var j = 0; j < ConvertedTableData.length; j++) {
                                // Note data
                                const noteData = notesdata[i]
                                const noteCrimper = String(noteData.CRIMPER_ID)
                                const noteDescription = String(noteData.DESCRIPTION)
                                const noteStemType = String(noteData.STEM_TYPE)
                                const _dashSize = noteData.STEM_DASH_SIZE
                                const noteDashSize = _dashSize === Any ? _dashSize : Number(_dashSize)
                                const noteDieId = String(noteData.DIE_ID)

                                const message = noteData.MESSAGE
                                const Index = displayingNotes.indexOf(message) + 1
                                
                                // Table data
                                const convertedData = ConvertedTableData[j];
                                const convertedCrimper = String(convertedData.CRIMPER_ID)
                                const convertedDescription = String(convertedData.HOSE_DISPLAY_NAME)
                                const convertedStemType = String(convertedData.STEM_TYPE)
                                const convertedDashSize = Number(convertedData.DASH_SIZE)
                                const convertedDieId = String(convertedData.DIE_ID)
                                const hasNotesIndexes = 'NOTES_INDEXES' in ConvertedTableData[j]
                                if(!hasNotesIndexes) ConvertedTableData[j].NOTES_INDEXES = {}
                                const descriptionMatches = noteDescription === convertedDescription && ((noteStemType === convertedStemType || noteStemType === Any) && (noteDashSize === convertedDashSize || noteDashSize === Any)) && (noteDieId === convertedDieId || noteDieId === Any)
                                const stemTypeMatches = (noteStemType === convertedStemType && (noteDashSize === convertedDashSize || noteDashSize === Any)) && (noteDescription === Any || noteDescription === convertedDescription) && (noteDieId === Any || noteDieId === convertedDieId)
                                const dieIdMatches = String(noteDieId) === String(convertedDieId) && ((noteDescription === convertedDescription || noteDescription === Any) && ((noteStemType === convertedStemType || noteStemType === Any) && (noteDashSize === convertedDashSize || noteDashSize === Any)) && (noteCrimper === convertedCrimper || noteCrimper === Any))
                                if(descriptionMatches) {
                                    console.log(convertedDescription)
                                    ConvertedTableData[j].NOTES_INDEXES.HOSE_DISPLAY_NAME = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.HOSE_DISPLAY_NAME || []), Index])] 
                                } 
                                if(stemTypeMatches) {
                                    console.log(convertedDescription, convertedDashSize + convertedStemType)
                                    ConvertedTableData[j].NOTES_INDEXES.COUPLING_ID = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.COUPLING_ID || []), Index])]
                                    ConvertedTableData[j].NOTES_INDEXES.STEM_TYPE = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.STEM_TYPE || []), Index])]
                                }
                                if(dieIdMatches) {
                                    console.log(convertedDescription, convertedDieId)
                                    ConvertedTableData[j].NOTES_INDEXES.DIE_ID = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.DIE_ID || []), Index])]
                                }

                            }


                        }
                    }

                    ConvertedTableData.sort(function (a, b) {
                        var textA = a.HOSE_DISPLAY_NAME;
                        var textB = b.HOSE_DISPLAY_NAME;
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    this.setState({
                        tableData2: response.data.d.results,
                        tableData: ConvertedTableData,
                        tabledataforPDF: ConvertedTableData,
                        totalTableData: ConvertedTableData.length,
                        enableDownload: true,
                        ApplyFilters: false
                    })

                };
            });
            document.getElementById("filterhoseDescriptions").value = null;
            document.getElementById("filterhoseDashes").value = null;
            document.getElementById("filterstemTypes").value = null;
            document.getElementById("filterdies").value = null;
            document.getElementById("filterpressures").value = null;
        } catch (error) {
            console.log(error)
        }

    }

    handleSubmitAccessories = () => {
        const { accessoriesData, filteredDataHoseDescriptionsAcc, filteredDataHoseDashesAcc, filteredDataStemTypesAcc } = this.state
        var ConvertedTableData = UnitConversion(accessoriesData)

        console.log(this.state.filteredDataHoseDashesAcc)

        ConvertedTableData.sort(function (a, b) {
            var textA = a.HOSE_DISPLAY_NAME;
            var textB = b.HOSE_DISPLAY_NAME;
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        const filteredData = [...ConvertedTableData].filter(data => {
            let hoseDescription = true
            let hoseDashes = true
            let stemTypes = true

            if (filteredDataHoseDescriptionsAcc) {
                hoseDescription = [...filteredDataHoseDescriptionsAcc].includes(data["DESCRIPTION"])
            }
            if (filteredDataHoseDashesAcc) {
                hoseDashes = [...filteredDataHoseDashesAcc].includes(data["DASH_SIZE"])
            }
            if (filteredDataStemTypesAcc) {
                stemTypes = [...filteredDataStemTypesAcc].includes(data["STEM_TYPE"])
            }

            return hoseDescription && hoseDashes && stemTypes
        })

        if (ConvertedTableData != null) {
            this.setState({
                tableData2ForAccessories: accessoriesData,
                tableDataForAccessories: filteredData,
                totalTableDataForAccessories: filteredData.length,
                enableDownload: true,
                ApplyFilters: false
            })
        }
        document.getElementById("filterhoseDescriptionsAcc").value = null;
        document.getElementById("filterhoseDashesAcc").value = null;
        document.getElementById("filterstemTypesAcc").value = null;
        document.getElementById("filterferruleAcc").value = null;



        console.log("indexedDB Data....", accessoriesData);



    }

    getFavoritesloader = async () => {
        // force reload the tabledata to get the isfavorite true / false
        this.setState({
            tableData: []
        });

        const { tableData2 } = this.state;
        var tableData = tableData2
        var fav = await getFavoritesDataFromIndexDB();

        for (var i = 0; i < tableData.length; i++) {
            if (fav.length != 0) {
                for (var j = 0; j < fav.length; j++) {
                    if (tableData[i].MSUID == fav[j].MSUID && tableData[i].CSUID == fav[j].CSUID) {
                        tableData[i].isFavorite = true
                        tableData[i].order = 1
                        tableData[i].FAVORITE_ID = fav[j].FAVORITE_ID
                        break
                    }
                    else {
                        tableData[i].isFavorite = false
                        tableData[i].order = 0
                        tableData[i].FAVORITE_ID = null

                    }
                }
            }
            else {
                tableData[i].isFavorite = false
                tableData[i].order = 0
                tableData[i].FAVORITE_ID = null

            }

        }
        this.setState({
            tableData: tableData
        });

    }
    selecCheckBox = (e, cellInfo, type) => {

        // gets the selected row details and storing it in an array
        const { selectedDatatoPrint, tableData, tableDataForAccessories, activeEventKey } = this.state
        const selectedrow = [...selectedDatatoPrint]
        const checked = e.target.checked
        const tableRows = activeEventKey == "Hoses" ? tableData : tableDataForAccessories;
        if (type == "All") {
            if (checked) {
                for (var i = 0; i < tableRows.length; i++) {
                    tableRows[i].isChecked = true
                    selectedrow.push(tableRows[i])

                }
            } else {
                for (var i = 0; i < tableRows.length; i++) {
                    tableRows[i].isChecked = false
                }
                selectedrow.length = 0;

            }
        }

        if (type == "one") {
            tableRows[cellInfo.index].isChecked = checked
            if (checked) {
                selectedrow.push(cellInfo.original)
            }
            else {
                for (let i = 0; i < selectedrow.length; i++) {
                    const element = selectedrow[i];
                    if (element.Id === cellInfo.original.Id) {
                        selectedrow.splice(i, 1)
                    }
                }
            }
        }

        if (selectedrow.length > 0) {
            this.setState({ enableDownloadSelected: true })
        } else {
            this.setState({ enableDownloadSelected: false })

        }

        this.setState({ selectedDatatoPrint: selectedrow })

    }
    renderCheckboxAll = (cellInfo) => {
        // renders the checkbox in the table

        return (
            <div className="center-checkbox">
                <label>Select<br /> All</label>
                <br />
                <input type="checkbox" onClick={(e) => this.selecCheckBox(e, cellInfo, "All")} />
            </div>
        )
    }
    renderCheckbox = (cellInfo) => {
        // renders the checkbox in the table

        return (
            <div className="center-checkbox">
                <input type="checkbox" checked={cellInfo.original.isChecked} onClick={(e) => this.selecCheckBox(e, cellInfo, "one")}
                />
            </div>
        )
    }
    addfavourite = (cellInfo) => {
        // displays the heart icon
        var checked = cellInfo.original.isFavorite
        if (!checked) {
            return (

                <div className="star-div">
                    <input className="fav-heart cursor" type="checkbox" checked={cellInfo.original.isFavorite}
                        onClick={(e) => this.checkFav(cellInfo, e)}
                    />
                </div>
            )
        } else {
            return (

                <div className="star-div">
                    <input className="fav-heart-remove cursor" type="checkbox" checked={cellInfo.original.isFavorite}
                        onClick={(e) => this.showFav(cellInfo, e)}
                    />
                </div>
            )
        }
    }
    checkFav = (cellInfo, e) => {

        Ui.Clear();
        //  Adds the selected row to favorites
        this.getFavorites()
        if (e.target.checked) {

            var email = Util.getCookie("email")
            //const d = new Date();
            //const month = ["01","02","03","04","05","06","07","08","09","10","11","12"];   
            //const date = d.getFullYear()  + "-" + month[d.getMonth()]+ "-" + d.getDate();

            const d = new Date();
            var dataISO = d.toISOString();
            var ValidDashSize = cellInfo.original.DASH_SIZE < 10 ? '0' + cellInfo.original.DASH_SIZE : cellInfo.original.DASH_SIZE
            var data = {
                "entries": [{
                    "FAVORITE_ID": 0,
                    "APPROXIMATE_DIGITAL_READOUT": cellInfo.original.APPROXIMATE_DIGITAL_READOUT,
                    "CRIMPER_ID": cellInfo.original.CRIMPER_ID,
                    "CRIMPER_IMG_URL": cellInfo.original.CRIMPER_IMG_URL,
                    "EMAIL": email,
                    "DASH_SIZE": ValidDashSize,
                    "DIE_ID": cellInfo.original.DIE_ID,
                    "DIE_IMG_URL": cellInfo.original.DIE_IMG_URL,
                    "FERRULE_DETAIL": cellInfo.original.FERRULE_DETAIL,
                    "DESCRIPTION": cellInfo.original.DESCRIPTION,
                    "HOSE_DISPLAY_NAME": cellInfo.original.HOSE_DISPLAY_NAME,
                    "HOSE_ID": cellInfo.original.HOSE_ID,
                    "HOSE_IMG_URL": cellInfo.original.HOSE_IMG_URL,
                    "HOSE_TYPE": cellInfo.original.HOSE_TYPE,
                    "INSERTION_LENGTH": cellInfo.original.INSERTION_LENGTH,
                    "INSERTION_LENGTH_DISPLAY": cellInfo.original.INSERTION_LENGTH_DISPLAY,
                    "IS_OBSOLETE": cellInfo.original.IS_OBSOLETE,
                    "LENGTH": cellInfo.original.LENGTH,
                    "LENGTH_DISPLAY": cellInfo.original.LENGTH_DISPLAY,
                    "NOTE": cellInfo.original.NOTE,
                    "NOTE_IMG_URL": cellInfo.original.NOTE_IMAGE_URL,
                    "OUTSIDE_DIAMETER": cellInfo.original.OUTSIDE_DIAMETER,
                    "OUTSIDE_DIAMETER_DISPLAY": cellInfo.original.OUTSIDE_DIAMETER_DISPLAY,
                    "OUTSIDE_DIAMETER_TOLERANCE": cellInfo.original.OUTSIDE_DIAMETER_TOLERANCE,
                    "OUTSIDE_DIAMETER_TOLERANCE_DISPLAY": cellInfo.original.OUTSIDE_DIAMETER_TOLERANCE_DISPLAY,
                    "SIZE": cellInfo.original.SIZE,
                    "SKIVE_DIAMETER": cellInfo.original.SKIVE_DIAMETER,
                    "SKIVE_DIAMETER_DISPLAY": cellInfo.original.SKIVE_DIAMETER_DISPLAY,
                    "SKIVE_LENGTH": cellInfo.original.SKIVE_LENGTH,
                    "SKIVE_LENGTH_DISPLAY": cellInfo.original.SKIVE_LENGTH_DISPLAY,
                    "STEM_TYPE": cellInfo.original.STEM_TYPE,
                    "TAIL_LENGTH": cellInfo.original.TAIL_LENGTH,
                    "WORKING_PRESSURE": cellInfo.original.WORKING_PRESSURE,
                    "WORKING_PRESSURE_SI": cellInfo.original.WORKING_PRESSURE_SI,
                    "DATE_CREATED": dataISO,
                    "DATE_UPDATED": dataISO,
                    "MSUID": cellInfo.original.MSUID,
                    "CSUID": cellInfo.original.CSUID
                }]
            }


            var url = API.URL + "users/favorites"
            ApiServices.httpPOST(url, data, (err, response) => {
                if (response) {
                    var datares = response;
                    if (response.status == "200") {
                        Ui.showSuccess("Added to Favorites")
                        this.getFavorites(true);
                    }
                };
            });
        }

    }

    showFav = (cellInfo, e) => {
        const { valuesDies, valuesDashsize, valuesDescription, valuesPressure, valuesStemtype } = this.state;

        var checked = e.target.checked
        if (!checked) {
            var FavId = cellInfo.original.FAVORITE_ID;
            // Shows the selected popup
            this.setState({ openFav: true, removeSelectedFav: FavId });
        }
    }

    removeFav = (removeSelected) => {
        var FavId = removeSelected;
        var url = API.URL + `users/favorites/` + FavId
        const { valuesDies, valuesDashsize, valuesDescription, valuesPressure, valuesStemtype } = this.state;

        ApiServices.httpDELETE(url, (err, response) => {
            if (response) {
                var datares = response;
                if (response.status == "200") {

                    Ui.showSuccess("Removed from Favorites")
                    this.getFavorites(true);
                    this.hideFav();
                }
            };
        });
    }
    hideFav = (cellInfo) => {
        // Hides the selected popup
        this.setState({ openFav: false, removeSelectedFav: null });
    }

    reset = async () => {
        this.props.history.replace({
            search: ""
        })
        this.props.reset()
        this.props.loadInitialData()
    }

    DownloadPDF = (computeTableData, typeoforientation, type) => {
        this.setState({ openOrientation: false });

        // downloads the entire/selected data in table as PDF
        const { activeEventKey, AppliedNotesData, Toggle } = this.state;
        const { currentSortedData, selectedHeader, HideSkiveDiameter, HideSkiveLength, Hidecrimplength, Hidecrimpinsertion, HIdecrimpTail } = this.state

        if (currentSortedData.length != 0 && type != "selected") {
            var sortedData = currentSortedData

        } else {
            var sortedData = computeTableData
        }

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        if (typeoforientation == "landscape") {

            var orientation = typeoforientation; // portrait or landscape

        } else {
            var orientation = typeoforientation; // portrait or landscape

        }

        const doc = new jsPDF(orientation, unit, size);

        let gatesLogo = new Image();
        gatesLogo.src = '/images/Gates-PDF-LOGO.png';

        let eCrimpLogo = new Image();
        eCrimpLogo.src = '/images/Gates-Ecrimp-PDF-LOGO.png';

        let printedOnDate = new Date();


        //FranklinGothic- Book
        doc.addFileToVFS('FranklinGothic-normal.ttf', font);
        doc.addFont('FranklinGothic-normal.ttf', 'FranklinGothic', 'normal');

        //FranklinGothic- heavy
        doc.addFileToVFS('FranklinGothic-Bold-bold.ttf', fontBold);
        doc.addFont('FranklinGothic-Bold-bold.ttf', 'FranklinGothic-Bold', 'normal');

        //FranklinGothic- demi

        doc.addFileToVFS('FranklinGothic-normal.ttf', fontDemi);
        doc.addFont('FranklinGothic-normal.ttf', 'FranklinGothic-Demi', 'normal');
        //FranklinGothic- italic

        doc.addFileToVFS('FranklinGothic-Italic-normal.ttf', fontItalic);
        doc.addFont('FranklinGothic-Italic-normal.ttf', 'FranklinGothic-Italic', 'normal');


        let pdfSelectedCrimper = this.state.selectedCrimper;


        let finalY = doc.lastAutoTable.finalY || 10;
        var marLeft = 0;
        doc.autoTable({
            head: headRows(),
            body: bodyRows(),
            showHead: 'everyPage',
            margin: { top: 150, bottom: 100 },
            headStyles: {
                fillColor: [117, 120, 123],
                lineColor: [0, 0, 0],
                lineWidth: 1,
            },
            bodyStyles: {
                lineColor: [0, 0, 0],
                lineWidth: 1,
                fillColor: [255, 255, 255],
                halign: 'center',
                valign: 'middle',
            },
            footStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0]
            },
            alternateRowStyles: {
                fillColor: [200, 201, 199]
            },

            didDrawPage: function (data) {

                let pageSize = doc.internal.pageSize;
                let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

                doc.addImage(gatesLogo, 'png', data.settings.margin.left, 15, 115, 65, 'Gates');
                doc.setFontSize(21).setFont("FranklinGothic-Bold");

                doc.text('Crimp Specifications', pageWidth / 2, 50, 'center');
                doc.addImage(eCrimpLogo, 'png', pageWidth - 180, 35, 92, 24, 'eCrimp');

                doc.setFontSize(21).setFont("FranklinGothic-Demi");

                doc.text(pdfSelectedCrimper, pageWidth / 2, 100, 'center');
                doc.setFontSize(15).setFont("FranklinGothic-Demi");
                doc.text('Printed on ' + printedOnDate.toLocaleDateString(), pageWidth - 215, 100);

                doc.setFontSize(8).setFont('FranklinGothic-Italic');
                let footerText = 'Refer to electronic crimp data at ecrimp.gates.com for the current crimp settings. Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All Settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.';
                let splitFooterText = doc.splitTextToSize(footerText, (pageWidth - data.settings.margin.left - 40));
                doc.text(splitFooterText, data.settings.margin.left, pageHeight - 60);
                var copyrightText = '\u00A9' + ' ' + printedOnDate.getFullYear() + ' Gates Corporation All Rights Reserved';
                doc.text(copyrightText, pageWidth / 2.5, pageHeight - 30);
                marLeft = data.settings.margin.left
            }
        })

        let pageSize = doc.internal.pageSize;
        let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        doc.addPage();
        doc.addImage(gatesLogo, 'png', marLeft, 15, 115, 65, 'Gates');
        doc.setFontSize(21).setFont("FranklinGothic-Bold");
        doc.text('Crimp Specifications', pageWidth / 2, 50, 'center');
        doc.addImage(eCrimpLogo, 'png', pageWidth - 180, 35, 92, 24, 'eCrimp');

        doc.setFontSize(21).setFont("FranklinGothic-Demi");
        doc.text(pdfSelectedCrimper, pageWidth / 2, 100, 'center');

        doc.setFontSize(15).setFont("FranklinGothic-Demi");
        doc.text('Printed on ' + printedOnDate.toLocaleDateString(), pageWidth - 215, 100);

        doc.setFontSize(8).setFont('FranklinGothic-Italic');


        let footerText = 'Refer to electronic crimp data at ecrimp.gates.com for the current crimp settings. Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All Settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.';

        let splitFooterText = doc.splitTextToSize(footerText, (pageWidth - marLeft - 40));
        doc.text(splitFooterText, marLeft, pageHeight - 60);
        var copyrightText = '\u00A9' + ' ' + printedOnDate.getFullYear() + ' Gates Corporation All Rights Reserved';
        doc.text(copyrightText, pageWidth / 2.5, pageHeight - 30);
        doc.setFontSize(15).setFont("FranklinGothic");
        doc.text('Notes : ', marLeft, 130);
        for (var i = 0; i < AppliedNotesData.length; i++) {
            doc.setFontSize(12)
            let text = i + 1 + ". " + AppliedNotesData[i];
            let textLine = doc.splitTextToSize(text, (pageWidth - marLeft - 40));
            if (i != 0) {
                doc.text(textLine, marLeft, 160 + (30 * i))

            }
            else {
                doc.text(textLine, marLeft, 160)
            }
        }

        doc.save("report.pdf");
        // doc.output('dataurlnewwindow');

        function headRows() {

            var datahead_English = [
                "Dash Size", "Description", "Size (in)", "Working Pressure (psi)", "Type", "Description", "Skive Length (in) (+/- 0.03)",
                "Skive Diameter (in)", "Crimp Insertion Length (in) (+/- 0.03)", "Crimp Length (in) (+/- 0.03)", "Crimp Outside Diameter (in)",
                "Crimp Outside Diameter Tolerance (+/-)", "Crimp Tail Length (in) (+/- 0.1)", "Die Set", "Approximate Setting"
            ]
            var datahead_Metrics = [
                "Dash Size", "Description", "Size (mm)", "Working Pressure (MPa)", "Type", "Description", "Skive Length (mm) (+/- 0.03)",
                "Skive Diameter (mm)", "Crimp Insertion Length (mm) (+/- 0.03)", "Crimp Length (mm) (+/- 0.03)", "Crimp Outside Diameter (mm)",
                "Crimp Outside Diameter Tolerance (+/-)", "Crimp Tail Length (mm) (+/- 0.1)", "Die Set", "Approximate Setting"
            ]
            var lengthColspan = 0
            if (HideSkiveLength) {
                datahead_English = datahead_English.filter(s => s !== "Skive Length (in) (+/- 0.03)");
                datahead_Metrics = datahead_Metrics.filter(s => s !== "Skive Length (mm) (+/- 0.03)");
                lengthColspan = lengthColspan + 1
            }
            if (HideSkiveDiameter) {
                datahead_English = datahead_English.filter(s => s !== "Skive Diameter (in)");
                datahead_Metrics = datahead_Metrics.filter(s => s !== "Skive Diameter (mm)");

                lengthColspan = lengthColspan + 1

            }
            if (Hidecrimpinsertion) {
                datahead_English = datahead_English.filter(s => s !== "Crimp Insertion Length (in) (+/- 0.03)");
                datahead_Metrics = datahead_Metrics.filter(s => s !== "Crimp Insertion Length (mm) (+/- 0.03)");
                lengthColspan = lengthColspan + 1

            }
            if (Hidecrimplength) {
                datahead_English = datahead_English.filter(s => s !== "Crimp Length (in) (+/- 0.03)");
                datahead_Metrics = datahead_Metrics.filter(s => s !== "Crimp Length (mm) (+/- 0.03)");
                lengthColspan = lengthColspan + 1

            }
            if (HIdecrimpTail) {
                datahead_English = datahead_English.filter(s => s !== "Crimp Tail Length (in) (+/- 0.1)");
                datahead_Metrics = datahead_Metrics.filter(s => s !== "Crimp Tail Length (mm) (+/- 0.1)");
                lengthColspan = lengthColspan + 1

            }

            if (activeEventKey == "Hoses" && !Toggle) {

                return [
                    [
                        { content: 'HOSE', colSpan: 4, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'STEM', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'FERRULE', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'SPECIFICATION', colSpan: 9 - lengthColspan, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                    ],
                    datahead_English
                ]
            }
            if (activeEventKey == "Hoses" && Toggle) {

                return [
                    [

                        { content: 'HOSE', colSpan: 4, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'STEM', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'FERRULE', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'SPECIFICATION', colSpan: 9 - lengthColspan, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                    ],
                    datahead_Metrics
                ]
            }
            if (activeEventKey == "Accessories" && !Toggle) {
                return [
                    [
                        { content: 'HOSE', colSpan: 4, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'STEM', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'FERRULE', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'SPECIFICATION', colSpan: 5, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                    ],

                    [
                        "Dash Size", "Description", "Size (in)", "Working Pressure", "Type", "Description", "Sleeve", "Clamp Collar", "Crimp Outside Diameter (in)",
                        "Die Set", "Approximate Setting"
                    ]
                ]
            }
            if (activeEventKey == "Accessories" && Toggle) {
                return [
                    [
                        { content: 'HOSE', colSpan: 4, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'STEM', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'FERRULE', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        { content: 'SPECIFICATION', colSpan: 5, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                    ],

                    [
                        "Dash Size", "Description", "Size (mm)", "Working Pressure", "Type", "Description", "Sleeve", "Clamp Collar", "Crimp Outside Diameter (mm)",
                        "Die Set", "Approximate Setting"
                    ]
                ]
            }
        }

        function bodyRows() {

            var databody_English = sortedData.map(elt =>
                [
                    elt.DASH_SIZE,
                    addPowers(elt.HOSE_DISPLAY_NAME, elt.NOTES_INDEXES?.HOSE_DISPLAY_NAME || []),
                    elt.SIZE_INENGLISH,
                    elt.WORKING_PRESSURE_INENGLISH,
                    addPowers(elt.STEM_TYPE, elt.NOTES_INDEXES?.STEM_TYPE || []),
                    elt.FERRULE_DETAIL,
                    elt.SKIVE_LENGTH_DISPLAY_INENGLISH,
                    elt.SKIVE_DIAMETER_DISPLAY_INENGLISH,
                    elt.INSERTION_LENGTH_DISPLAY_INENGLISH,
                    elt.LENGTH_DISPLAY_INENGLISH,
                    elt.OUTSIDE_DIAMETER_DISPLAY_INENGLISH,
                    elt.OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH,
                    elt.TAIL_LENGTH_INENGLISH,
                    addPowers(elt.DIE_ID, elt.NOTES_INDEXES?.DIE_ID || []),
                    elt.APPROXIMATE_DIGITAL_READOUT
                ]);


            var databody_Metrics = sortedData.map(elt =>
                [
                    elt.DASH_SIZE,
                    addPowers(elt.HOSE_DISPLAY_NAME, elt.NOTES_INDEXES?.HOSE_DISPLAY_NAME || []),
                    elt.SIZE_INMETRICS,
                    elt.WORKING_PRESSURE_INMETRICS,
                    addPowers(elt.STEM_TYPE, elt.NOTES_INDEXES?.STEM_TYPE || []),
                    elt.FERRULE_DETAIL,
                    elt.SKIVE_LENGTH_DISPLAY_INMETRICS,
                    elt.SKIVE_DIAMETER_DISPLAY_INMETRICS,
                    elt.INSERTION_LENGTH_DISPLAY_INMETRICS,
                    elt.LENGTH_DISPLAY_INMETRICS,
                    elt.OUTSIDE_DIAMETER_DISPLAY_INMETRICS,
                    elt.OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS,
                    elt.TAIL_LENGTH_INMETRICS,
                    addPowers(elt.DIE_ID, elt.NOTES_INDEXES?.DIE_ID || []),
                    elt.APPROXIMATE_DIGITAL_READOUT
                ]);

            for (var i = 0; i < databody_English.length; i++) {
                if (HideSkiveLength) {
                    databody_English[i].splice(databody_English[i].length - 8 - 1, 1);
                    databody_Metrics[i].splice(databody_Metrics[i].length - 8 - 1, 1);

                }
                if (HideSkiveDiameter) {

                    databody_English[i].splice(databody_English[i].length - 7 - 1, 1);
                    databody_Metrics[i].splice(databody_Metrics[i].length - 7 - 1, 1);

                }
                if (Hidecrimpinsertion) {
                    databody_English[i].splice(databody_English[i].length - 6 - 1, 1);
                    databody_Metrics[i].splice(databody_Metrics[i].length - 6 - 1, 1);

                }
                if (Hidecrimplength) {
                    databody_English[i].splice(databody_English[i].length - 5 - 1, 1);
                    databody_Metrics[i].splice(databody_Metrics[i].length - 5 - 1, 1);


                }
                if (HIdecrimpTail) {
                    databody_English[i].splice(databody_English[i].length - 2 - 1, 1);
                    databody_Metrics[i].splice(databody_Metrics[i].length - 2 - 1, 1);


                }
            }

            if (activeEventKey == "Hoses" && !Toggle) {
                return databody_English;
            }
            if (activeEventKey == "Hoses" && Toggle) {


                return databody_Metrics;
            }
            if (activeEventKey == "Accessories" && !Toggle) {
                const data = sortedData.map(elt =>
                    [
                        elt.DASH_SIZE,
                        elt.DESCRIPTION,
                        elt.SIZE_INENGLISH,
                        elt.WORKING_PRESSURE_INENGLISH,
                        elt.STEM_DASH_SIZE + elt.STEM_TYPE,
                        elt.DESCRIPTION,
                        elt.SLEEVE_ID,
                        elt.COLLAR_ID,
                        elt.OUTSIDE_DIAMETER_INENGLISH,
                        elt.DIE_ID,
                        elt.APPROXIMATE_SETTING
                    ]);

                return data;
            }
            if (activeEventKey == "Accessories" && Toggle) {
                const data = sortedData.map(elt =>
                    [
                        elt.DASH_SIZE,
                        elt.DESCRIPTION,
                        elt.SIZE_INMETRICS,
                        elt.WORKING_PRESSURE_INMETRICS,
                        elt.STEM_DASH_SIZE + elt.STEM_TYPE,
                        elt.DESCRIPTION,
                        elt.SLEEVE_ID,
                        elt.COLLAR_ID,
                        elt.OUTSIDE_DIAMETER_INMETRICS,
                        elt.DIE_ID,
                        elt.APPROXIMATE_SETTING
                    ]);

                return data;
            }

        }



    }


    show = (type) => {
        // Shows the selected popup
        if (type == "crimpers") {
            this.setState({ open: true });
        }
        if (type == "checkfilters") {
            this.setState({ openFilters: true });
        }
        if (type == "addcrimp") {
            this.setState({ openAddFilter: true });
        }
        if (type == "hose") {
            this.setState({ OpenHoseSpec: true });
        }
        if (type == "notes") {
            this.setState({ openNotes: true });
        }
        if (type == "hoseSize") {
            this.setState({ openHoseSize: true });
        }
        if (type == "orientation") {
            this.setState({ openOrientation: true });
        }

    }
    hide = (type) => {
        // Hides the selected popup
        if (type == "crimpers") {

            this.setState({ open: false });
        }
        if (type == "checkfilters") {
            this.setState({ openFilters: false });
        }
        if (type == "addcrimp") {
            this.setState({ openAddFilter: false });
        }
        if (type == "hose") {
            this.setState({ OpenHoseSpec: false });
        }
        if (type == "notes") {
            this.setState({ openNotes: false });
        }
        if (type == "hoseSize") {
            this.setState({ openHoseSize: false });
        }
        if (type == "orientation") {
            this.setState({ openOrientation: false });
        }
    }
    handleCrimpName = (e) => {
        // Stores the crimp sheet name 
        this.setState({ crimperSheetName: e.target.value })
    }
    addToCrimperSheets = (id) => {
        Ui.Clear();
        // Add the selected rows to crimpo sheets
        const { selectedDatatoPrint } = this.state;
        var email = Util.getCookie("email")
        var values = selectedDatatoPrint
        if (selectedDatatoPrint.length == 0) {
            Ui.showErrors("Select checkbox to add into Crimpsheets")
            return false;
        }
        const d = new Date();
        let ConvertedDate = d.toISOString();
        var FinalData = [];
        for (var i = 0; i < values.length; i++) {
            var ValidDashSize = values[i].DASH_SIZE < 10 ? '0' + values[i].DASH_SIZE : values[i].DASH_SIZE
            var data = {
                "CRIMPSHEET_ID": id,
                "APPROXIMATE_DIGITAL_READOUT": values[i].APPROXIMATE_DIGITAL_READOUT,
                "CRIMPER_ID": values[i].CRIMPER_ID,
                "CRIMPER_IMG_URL": values[i].CRIMPER_IMG_URL,
                "EMAIL": email,
                "DASH_SIZE": ValidDashSize,
                "DIE_ID": values[i].DIE_ID,
                "DIE_IMG_URL": values[i].DIE_IMG_URL,
                "FERRULE_DETAIL": values[i].FERRULE_DETAIL,
                "DESCRIPTION": values[i].DESCRIPTION,
                "HOSE_DISPLAY_NAME": values[i].HOSE_DISPLAY_NAME,
                "HOSE_ID": values[i].HOSE_ID,
                "HOSE_IMG_URL": values[i].HOSE_IMG_URL,
                "HOSE_TYPE": values[i].HOSE_TYPE,
                "INSERTION_LENGTH": values[i].INSERTION_LENGTH,
                "INSERTION_LENGTH_DISPLAY": values[i].INSERTION_LENGTH_DISPLAY,
                "IS_OBSOLETE": values[i].IS_OBSOLETE,
                "LENGTH": values[i].LENGTH,
                "LENGTH_DISPLAY": values[i].LENGTH_DISPLAY,
                "NOTE": values[i].NOTE,
                "NOTE_IMG_URL": values[i].NOTE_IMAGE_URL,
                "OUTSIDE_DIAMETER": values[i].OUTSIDE_DIAMETER,
                "OUTSIDE_DIAMETER_DISPLAY": values[i].OUTSIDE_DIAMETER_DISPLAY,
                "OUTSIDE_DIAMETER_TOLERANCE": values[i].OUTSIDE_DIAMETER_TOLERANCE,
                "OUTSIDE_DIAMETER_TOLERANCE_DISPLAY": values[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY,
                "SIZE": values[i].SIZE,
                "SKIVE_DIAMETER": values[i].SKIVE_DIAMETER,
                "SKIVE_DIAMETER_DISPLAY": values[i].SKIVE_DIAMETER_DISPLAY,
                "SKIVE_LENGTH": values[i].SKIVE_LENGTH,
                "SKIVE_LENGTH_DISPLAY": values[i].SKIVE_LENGTH_DISPLAY,
                "STEM_TYPE": values[i].STEM_TYPE,
                "TAIL_LENGTH": values[i].TAIL_LENGTH,
                "WORKING_PRESSURE": values[i].WORKING_PRESSURE,
                "WORKING_PRESSURE_SI": values[i].WORKING_PRESSURE_SI,
                "DATE_CREATED": ConvertedDate,
                "DATE_UPDATED": ConvertedDate,
                "MSUID": values[i].MSUID,
                "CSUID": values[i].CSUID
            }

            FinalData.push(data)
        }
        var Final = {
            "entries": FinalData
        }

        var url = API.URL + "users/crimpsheets/details"
        ApiServices.httpPOST(url, Final, (err, response) => {
            if (response) {
                if (response.status == "200") {
                    Ui.showSuccess("Added to Crimp Sheet")
                    this.setState({ selectedDatatoPrint: [] })
                    this.getCrimpSheets();
                }
            };
        });

    }

    changeToggle = (e) => {
        this.setState({ Toggle: !e })
    }

    ChangeModes = (e, type) => {
        Ui.Clear();
        const { valuesDies, valuesDashsize, valuesDescription, valuesPressure, valuesStemtype, AtleastOneFilterSelected, selectedModesTypes, filterArray } = this.state;

        var Flag = e.target.checked
        var removeMe = type;
        var valuesFilter = selectedModesTypes
        if (type == "HYD") {

            if (Flag) {

                selectedModesTypes.push(type)
            }
            else {
                var unique = selectedModesTypes.filter((v, i, a) => a.indexOf(v) === i);

                if (unique.length > 1) {


                    var valuesFilter = unique.filter(s => s !== removeMe);
                    this.setState({ selectedModesTypes: valuesFilter })

                }
                else {
                    alert("You must select at least one hose search option.")
                    return false

                }

            }
            let filter1 = [{
                "HOSE_TYPE_ID": valuesFilter
            }];
            var data = filterArray.filter(obj =>
                Object.entries(filter1[0]).every(([prop, find]) => find.includes(obj[prop])));
            this.setState({ filterArray2: data })
            this.handleValueChanges("hoseMode", e, data)
            this.setState({ IsHydraulic: e.target.checked })
        }

        if (type == "IND") {
            if (Flag) {
                selectedModesTypes.push(type)
            }
            else {
                var unique = selectedModesTypes.filter((v, i, a) => a.indexOf(v) === i);

                if (unique.length > 1) {


                    var valuesFilter = unique.filter(s => s !== removeMe);
                    this.setState({ selectedModesTypes: valuesFilter })

                }
                else {
                    alert("You must select at least one hose search option.")
                    return false

                }
            }
            let filter1 = [{
                "HOSE_TYPE_ID": valuesFilter
            }];
            var data = filterArray.filter(obj =>
                Object.entries(filter1[0]).every(([prop, find]) => find.includes(obj[prop])));
            this.setState({ filterArray2: data })
            this.handleValueChanges("hoseMode", e, data)

            this.setState({ IsIndustrial: e.target.checked })
        }

        if (valuesFilter.length > 0 || valuesDies.length > 0 || valuesDescription.length > 0 || valuesDashsize.length > 0 || valuesStemtype.length > 0 || valuesPressure.length > 0) {
            this.setState({ appliedFilters: true, AtleastOneFilterSelected: true })
        }
        else {
            this.setState({ appliedFilters: false, AtleastOneFilterSelected: false })

        }
    }

    ChangeModesAccessories = (e, type) => {
        Ui.Clear();
        const { valuesDashsizeAcc, valuesDescriptionAcc, valuesFerruleAcc, valuesStemtypeAcc, AtleastOneFilterSelected, selectedModesTypesAcc, accessoriesData, filteraccessoriesData } = this.state;

        var Flag = e.target.checked
        var removeMe = type;
        var valuesFilter = selectedModesTypesAcc
        var arr = [];


        if (type == "LG") {

            if (Flag) {

                selectedModesTypesAcc.push(type)
            }
            else {
                var unique = selectedModesTypesAcc.filter((v, i, a) => a.indexOf(v) === i);

                if (unique.length > 1) {


                    var valuesFilter = unique.filter(s => s !== removeMe);
                    this.setState({ selectedModesTypesAcc: valuesFilter })

                }
                else {
                    alert("You must select at least one accessory search option.")
                    return false

                }

            }
            let filter1 = [{
                "HOSE_TYPE_ID": valuesFilter
            }];

            for (var i = 0; i < accessoriesData.length; i++) {
                for (var j = 0; j < valuesFilter.length; j++) {
                    var string = accessoriesData[i].SLEEVE_ID
                    var substring = valuesFilter[j];
                    if (string.includes(substring)) {
                        arr.push(accessoriesData[i])
                    }
                }
            }

            this.setState({ filteraccessoriesData: arr })
            this.handleValueChangesForaccesories("hoseMode", e, arr)
            this.setState({ IsLifeGuard: e.target.checked })
        }

        if (type == "HG") {
            if (Flag) {
                selectedModesTypesAcc.push(type)
            }
            else {
                var unique = selectedModesTypesAcc.filter((v, i, a) => a.indexOf(v) === i);

                if (unique.length > 1) {


                    var valuesFilter = unique.filter(s => s !== removeMe);
                    this.setState({ selectedModesTypesAcc: valuesFilter })

                }
                else {
                    alert("You must select at least one accessory search option.")
                    return false

                }
            }
            for (var i = 0; i < accessoriesData.length; i++) {
                for (var j = 0; j < valuesFilter.length; j++) {
                    var string = accessoriesData[i].SLEEVE_ID
                    var substring = valuesFilter[j];
                    if (string.includes(substring)) {
                        arr.push(accessoriesData[i])
                    }
                }
            }

            this.setState({ filteraccessoriesData: arr })
            this.handleValueChangesForaccesories("hoseMode", e, arr)

            this.setState({ IsNylon: e.target.checked })
        }

        if (valuesFilter.length > 0 || valuesDescriptionAcc.length > 0 || valuesDashsizeAcc.length > 0 || valuesStemtypeAcc.length > 0 || valuesFerruleAcc.length > 0) {
            this.setState({ appliedFilters: true, AtleastOneFilterSelected: true })
        }
        else {
            this.setState({ appliedFilters: false, AtleastOneFilterSelected: false })

        }
    }

    checkCondition() {
        const { valuesDies, valuesDashsize, valuesDescription, valuesPressure, valuesStemtype, AtleastOneFilterSelected, selectedModesTypes } = this.state;

        if (selectedModesTypes.length > 0 || valuesDies.length > 0 || valuesDescription.length > 0 || valuesDashsize.length > 0 || valuesStemtype.length > 0 || valuesPressure.length > 0) {
            this.setState({ appliedFilters: true, AtleastOneFilterSelected: true })
        }
        else {
            this.setState({ appliedFilters: false, AtleastOneFilterSelected: false })

        }
    }
    redirectDetails = (type, selected) => {

        if (type == "crimper") {
            var url = API.URL + `product-url?country=US&language=EN&type=Crimper&id=` + selected
            ApiServices.httpGET(url, (err, response) => {

                if (response) {
                    let url = response.data.gatesUrl;
                    window.open(url, '_blank')

                };
            });
        }
        if (type == "hose") {
            var url = API.URL + `product-url?country=US&language=EN&type=Hose&id=` + selected
            ApiServices.httpGET(url, (err, response) => {
                if (response) {
                    let url = response.data.gatesUrl;
                    window.open(url, '_blank')

                };
            });
        }

    }

    openNotesPopup = (cellInfo, crimper, die, hose, coupling) => {

        var size = cellInfo.original.SIZE
        var url = API.URL + 'notes?crimper=' + crimper + '&die=' + die + '&hose=' + hose + '&coupling=' + coupling;
        console.log(url);
        ApiServices.httpGET(url, (err, response) => {

            if (response) {

                var result = response.data.records;
                const MESSAGE = [...new Set(result.map(item => item.MESSAGE))];
                const URL = [...new Set(result.map(item => item.URL))];

                this.setState({ dataForNotesLink: result, NotesMessage: MESSAGE, NotesURL: URL, openNotes: true, selectedHoseforLink: hose, selectedSizeforLink: size })
            };
        });
    }

    activeTab = (event) => {
        this.setState({ activeEventKey: event })
    }
    displayNotesHose = (cellInfo) => {
        const { AppliedNotesData } = this.state
        const FinalData = cellInfo.original.NOTES_INDEXES?.HOSE_DISPLAY_NAME || []

        if (this.state.activeEventKey == "Hoses") {
            return (
                <div > {cellInfo.original.HOSE_DISPLAY_NAME}
                    {FinalData.map((value) => <span className="notes-font cursor" title={AppliedNotesData[value - 1]}>{value}</span>)}
                </div>
            )
        }
        else {
            return (
                <div >
                    {cellInfo.original.DESCRIPTION}
                    {FinalData.map((value) => <span className="notes-font cursor" title={AppliedNotesData[value - 1]}>{value}</span>)}
                </div>
            )
        }



    }
    displayNotesCoupling = (cellInfo) => {
        const { AppliedNotesData } = this.state

        const data = cellInfo.original.NOTES_INDEXES?.STEM_TYPE || []

        return (
            <div >
                {`${cellInfo.original.STEM_DASH_SIZE}${cellInfo.original.STEM_TYPE}`}
                {data.map((value) => <span className="notes-font cursor" title={AppliedNotesData[value - 1]}>{value}</span>)}
            </div>
        )
        
        
        
    }
    displayNotesDie = (cellInfo) => {
        const { AppliedNotesData } = this.state
        const data = cellInfo.original.NOTES_INDEXES?.DIE_ID || []

        return (
            <div > 
                {cellInfo.original.DIE_ID}
                {data.map((value) => <span className="notes-font cursor" title={AppliedNotesData[value - 1]}>{value}</span>)}
            </div>
        )




    }

    selectOrientation = (data) => {

        const { HideSkiveDiameter, HideSkiveLength, Hidecrimplength, Hidecrimpinsertion, HIdecrimpTail, tableData, activeEventKey, tableDataForAccessories } = this.state
        this.setState({ openOrientation: false });

        if (HideSkiveDiameter && HideSkiveLength && Hidecrimplength && Hidecrimpinsertion && HIdecrimpTail) {
            this.setState({ openOrientation: true });

        } else {
            if (activeEventKey == "Hoses") {
                this.DownloadPDF(data, "landscape", "all")

            } else {
                this.DownloadPDF(data, "landscape", "all")

            }
        }
    }

    fetch(state) {
        var newData = []
        var data = state.resolvedData;
        for (var i = 0; i < data.length; i++) {
            newData.push(data[i]._original)
        }
        this.setState({ currentSortedData: newData })
    }
    onFinishedEditOrDelete() {
        this.fetch(this.table.current.state);
    }

    render() {
        const { Immediatefilter } = this.props.auth;
        const { isLoggedIn } = this.context
        const { crimperSheetName, crimpsheets, selectedDatatoPrint, appliedFilters, selectedCrimper, open, openFilters, openAddFilter, crimpersResponse, enableDownload, enableDownloadSelected, tableData } = this.state;
        const { crimpersLoading } = this.state;
        const { filteredDataHoseDescriptions } = this.state;
        const { NotesMessage, NotesURL, selectedHoseforLink, selectedSizeforLink, openNotes, valuesDies, valuesDashsize, valuesDescription, valuesPressure, valuesStemtype, Toggle, AtleastOneFilterSelected, invalidCrimpname, IsIndustrial, IsHydraulic, OpenHoseSpec, openHoseSize } = this.state;
        const { accessoriesData, isLoading, removeSelectedFav, openFav, DataDiesfiltered, DataHoseDescriptionsfiltered, DataHoseDashesfiltered, DataStemTypesfiltered, DataPressuresfiltered } = this.state
        const { IsLifeGuard, IsNylon, DataHoseDescriptionsfilteredAcc, DataHoseDashesfilteredAcc, DataStemTypesfilteredAcc, DataFerrulefilteredAcc } = this.state
        const { hideSelectionbtns, openOrientation, AppliedNotesData, hoseSizeTbl, tableDataForAccessories, activeEventKey, valuesDescriptionAcc, valuesDashsizeAcc, valuesStemtypeAcc, valuesFerruleAcc } = this.state;
        const { FilteredNotesImage, HideSkiveDiameter, HideSkiveLength, Hidecrimplength, Hidecrimpinsertion, HIdecrimpTail } = this.state
        var str = ""
        if (NotesURL.length != 0) {
            var str = NotesURL[0].substring(NotesURL[0].length - 3)

        }

        // let arr = [];
        // tableData.length > 0 && tableData.forEach((value) => {
        //     arr.push({
        //         dashSize: value[5],
        //         description: value[4],
        //         size: value[6],
        //         workingPressure: value[8],
        //         stemtype: value[12],
        //         dieset: value[1]
        //     })
        // })
        const handleChange = (event, type) => {
            // Search filter
            var filterquery = '#filter' + type;
            var elsquery = '#list' + type + ' > div'
            const filterEl = document.querySelector(filterquery);
            const els = Array.from(document.querySelectorAll(elsquery));
            const labels = els.map(el => el.textContent);
            const handler = value => {
                const matching = labels.map((label, idx, arr) => label.toLowerCase().includes(value.toLowerCase()) ? idx : null).filter(el => el != null);

                els.forEach((el, idx) => {

                    if (matching.includes(idx)) {
                        els[idx].style.display = 'block';
                    } else {
                        els[idx].style.display = 'none';
                    }
                });
            };

            filterEl.addEventListener('keyup', () => handler.call(null, filterEl.value));
        }
        const redirectUrl = (url) => {
            if (url == 'managecrimp') {
                this.props.history.push('/ManageCrimpSheets')

            }
        }
        var SortedDataHoseDescriptionsfiltered = _.sortBy(DataHoseDescriptionsfiltered, 'name');
        var SortedDataHoseDashesfiltered = []
        var SortedDataDiesfiltered = _.sortBy(DataDiesfiltered, 'name');
        var SortedDataStemTypesfiltered = _.sortBy(DataStemTypesfiltered, 'name');
        var SortedDataPressuresfiltered = _.sortBy(DataPressuresfiltered, 'name');

        var SortedDataHoseDescriptionsfilteredAcc = _.sortBy(DataHoseDescriptionsfilteredAcc, 'name');
        var SortedDataHoseDashesfilteredAcc = []
        var SortedDataStemTypesfilteredAcc = _.sortBy(DataStemTypesfilteredAcc, 'name');
        var SortedDataFerrulefilteredAcc = _.sortBy(DataFerrulefilteredAcc, 'name');
        if (DataHoseDashesfiltered) {
            var SortedDataHoseDashesfiltered = DataHoseDashesfiltered.sort((a, b) => Number(a.name) > Number(b.name) ? 1 : -1);
        }
        if (DataHoseDashesfilteredAcc) {
            var SortedDataHoseDashesfilteredAcc = DataHoseDashesfilteredAcc.sort((a, b) => Number(a.name) > Number(b.name) ? 1 : -1);
        }

        const data = tableData.map(row => {
            const isFavorite = this.state.favoritesData.some(fav => fav.CSUID === row.CSUID && fav.MSUID === row.MSUID)

            return { ...row, isFavorite, }
        })

        return (
            <>
                <Observer observable={this.props.pressureType} onChange={() => this.setState({ Toggle: this.props.pressureType === PressureTypes.mpa })} />
                <div className="dashboardContainer">
                    <div className={(isLoading ? 'show' : 'hide') + ' partial-loader'} id="loading">
                        <div className="loading-message"></div>
                    </div>
                    <ToastContainer />
                    <div className="row page-title">
                        <div className="col-md-12">
                            <div className="choose-crimper redline displayflex">
                                <h5 className="cursor" onClick={() => this.show("crimpers")}>
                                    <b >{selectedCrimper}
                                    </b>
                                    <MoreHorizIcon className="color-black cursor" />
                                </h5>
                                {appliedFilters ? <p className="display-filter filter-box cursor" onClick={() => this.show("checkfilters")}>{T("Applied Filters")} </p> : ""}
                            </div>
                        </div>
                        <div className="col-md-2 tab-width-25">
                            <div className="tab-18 tab-scroll-Accordion">
                                <div className="tab-nav">
                                    <Tabs defaultActiveKey={"Hoses"} onSelect={(event) => this.activeTab(event)}>
                                        <Tab eventKey="Hoses" title="HOSES">
                                            <div className="notranslate">
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel3a-content"
                                                        id="panel3a-header"
                                                    >
                                                        <Typography>Hose Description<HelpIcon className="color-white hose-spec-help" onClick={() => this.show("hose")} /></Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>

                                                            <div className="hoses-tab">
                                                                <input type="checkbox" id="Hydraulic" name="Hydraulic" checked={IsHydraulic} value={"HYD"} onClick={(e) => this.ChangeModes(e, "HYD")} />
                                                                <label className="tabs-checkbox">Hydraulic</label>
                                                                <input type="checkbox" id="Industrial" name="Industrial" checked={IsIndustrial} value={"IND"} onClick={(e) => this.ChangeModes(e, "IND")} />
                                                                <label className="tabs-checkbox"> Industrial</label>
                                                            </div>
                                                            <input id="filterhoseDescriptions" type="text" placeholder="Search..." className="filter" onChange={(e) => handleChange(e, "hoseDescriptions")} />
                                                            <br /><br />
                                                            <div className="Accordion-scroll" id="listhoseDescriptions">

                                                                {SortedDataHoseDescriptionsfiltered ? SortedDataHoseDescriptionsfiltered.map((value, index) =>
                                                                    <div className="margin-bottom-6" id="listboxes" >
                                                                        <input className="checkbox-size" type="checkbox" id="checkedbox" checked={value.isChecked} onChange={this.handleValueChanges.bind(this, "hoseDescriptions")} value={value.name} />
                                                                        {value.name}
                                                                    </div>

                                                                ) : ""}
                                                            </div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel4a-content"
                                                        id="panel4a-header"
                                                    >
                                                        <Typography>Hose Dash Size <HelpIcon className="color-white hose-spec-help" onClick={() => this.show("hoseSize")} /></Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <input id="filterhoseDashes" type="text" className="filter" placeholder="Search..." onChange={(e) => handleChange(e, "hoseDashes")} />
                                                            <br /><br />
                                                            <div className="Accordion-scroll" id="listhoseDashes">

                                                                {SortedDataHoseDashesfiltered ? SortedDataHoseDashesfiltered.map((value, index) =>
                                                                    <div className="margin-bottom-6" id="listboxes" >
                                                                        <input className="checkbox-size" type="checkbox" id="checkedbox" checked={value.isChecked} onChange={this.handleValueChanges.bind(this, "hoseDashes")} value={value.name} />
                                                                        {value.name}
                                                                    </div>

                                                                ) : ""}
                                                            </div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel5a-content"
                                                        id="panel5a-header"
                                                    >
                                                        <Typography>Stem Type</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <input id="filterstemTypes" type="text" className="filter" placeholder="Search..." onChange={(e) => handleChange(e, "stemTypes")} />
                                                            <br /><br />
                                                            <div className="Accordion-scroll" id="liststemTypes">

                                                                {SortedDataStemTypesfiltered ? SortedDataStemTypesfiltered.map((value, index) =>
                                                                    <div className="margin-bottom-6" id="listboxes" >
                                                                        <input className="checkbox-size" type="checkbox" id="checkedbox" checked={value.isChecked} onChange={this.handleValueChanges.bind(this, "stemTypes")} value={value.name} />
                                                                        {value.name}
                                                                    </div>

                                                                ) : ""}
                                                            </div>

                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel2a-content"
                                                        id="panel2a-header"
                                                    >
                                                        <Typography>Die Set</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <input id="filterdies" type="text" placeholder="Search..." className="filter" onChange={(e) => handleChange(e, "dies")} />
                                                            <br /><br />
                                                            <div className="Accordion-scroll" id="listdies">

                                                                {SortedDataDiesfiltered ? SortedDataDiesfiltered.map((value, index) =>
                                                                    <div className="margin-bottom-6" id="listboxes" >
                                                                        <input className="checkbox-size" type="checkbox" id="checkedbox" checked={value.isChecked} onChange={this.handleValueChanges.bind(this, "dies")} value={value.name} />
                                                                        {value.name}
                                                                    </div>

                                                                ) : ""}
                                                            </div>

                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel6a-content"
                                                        id="panel6a-header"
                                                    >
                                                        <Typography> Working Pressure</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <input id="filterpressures" type="text" className="filter" placeholder="Search..." onChange={(e) => handleChange(e, "pressures")} />
                                                            <br /><br />
                                                            <div className="Accordion-scroll" id="listpressures">

                                                                {SortedDataPressuresfiltered ? SortedDataPressuresfiltered.map((value, index) =>
                                                                    <div className="margin-bottom-6" id="listboxes" >
                                                                        <input className="checkbox-size" type="checkbox" id="checkedbox" checked={value.isChecked} onChange={this.handleValueChanges.bind(this, "pressures")} value={value.name} />
                                                                        {!Toggle ? value.name : (value.name / 145.038).toFixed(2)}
                                                                    </div>
                                                                ) : ""}
                                                            </div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </Tab>
                                        {accessoriesData.length != 0 ? <Tab eventKey="Accessories" title="ACCESSORIES">
                                            <div className="notranslate">
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel3a-content"
                                                        id="panel3a-header"
                                                    >
                                                        <Typography>Hose Description<HelpIcon className="color-white hose-spec-help" onClick={() => this.show("hose")} /></Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>

                                                            <div className="hoses-tab">
                                                                <input type="checkbox" id="LifeGuard" name="LifeGuard" checked={IsLifeGuard} value={"LG"} onClick={(e) => this.ChangeModesAccessories(e, "LG")} />
                                                                <label className="tabs-checkbox">LifeGuard</label>
                                                                <input type="checkbox" id="Nylon" name="Nylon" checked={IsNylon} value={"HG"} onClick={(e) => this.ChangeModesAccessories(e, "HG")} />
                                                                <label className="tabs-checkbox"> Nylon</label>
                                                            </div>
                                                            <input id="filterhoseDescriptionsAcc" type="text" placeholder="Search..." className="filter" onChange={(e) => handleChange(e, "hoseDescriptionsAcc")} />
                                                            <br /><br />
                                                            <div className="Accordion-scroll" id="listhoseDescriptionsAcc">

                                                                {SortedDataHoseDescriptionsfilteredAcc ? SortedDataHoseDescriptionsfilteredAcc.map((value, index) =>
                                                                    <div className="margin-bottom-6" id="listboxes" >
                                                                        <input className="checkbox-size" type="checkbox" id="checkedbox" checked={value.isChecked} onChange={this.handleValueChangesForaccesories.bind(this, "hoseDescriptions")} value={value.name} />
                                                                        {value.name}
                                                                    </div>

                                                                ) : ""}
                                                            </div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel4a-content"
                                                        id="panel4a-header"
                                                    >
                                                        <Typography>Hose Dash Size <HelpIcon className="color-white hose-spec-help" onClick={() => this.show("hoseSize")} /></Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <input id="filterhoseDashesAcc" type="text" className="filter" placeholder="Search..." onChange={(e) => handleChange(e, "hoseDashesAcc")} />
                                                            <br /><br />
                                                            <div className="Accordion-scroll" id="listhoseDashesAcc">

                                                                {SortedDataHoseDashesfilteredAcc ? SortedDataHoseDashesfilteredAcc.map((value, index) =>
                                                                    <div className="margin-bottom-6" id="listboxes" >
                                                                        <input className="checkbox-size" type="checkbox" id="checkedbox" checked={value.isChecked} onChange={this.handleValueChangesForaccesories.bind(this, "hoseDashes")} value={value.name} />
                                                                        {value.name}
                                                                    </div>

                                                                ) : ""}
                                                            </div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel5a-content"
                                                        id="panel5a-header"
                                                    >
                                                        <Typography>Stem Type</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <input id="filterstemTypesAcc" type="text" className="filter" placeholder="Search..." onChange={(e) => handleChange(e, "stemTypesAcc")} />
                                                            <br /><br />
                                                            <div className="Accordion-scroll" id="liststemTypesAcc">

                                                                {SortedDataStemTypesfilteredAcc ? SortedDataStemTypesfilteredAcc.map((value, index) =>
                                                                    <div className="margin-bottom-6" id="listboxes" >
                                                                        <input className="checkbox-size" type="checkbox" id="checkedbox" checked={value.isChecked} onChange={this.handleValueChangesForaccesories.bind(this, "stemTypes")} value={value.name} />
                                                                        {value.name}
                                                                    </div>

                                                                ) : ""}
                                                            </div>

                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel6a-content"
                                                        id="panel6a-header"
                                                    >
                                                        <Typography> FERRULE DESCRIPTION</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <input id="filterferruleAcc" type="text" className="filter" placeholder="Search..." onChange={(e) => handleChange(e, "ferruleAcc")} />
                                                            <br /><br />
                                                            <div className="Accordion-scroll" id="listferruleAcc">

                                                                {SortedDataFerrulefilteredAcc ? SortedDataFerrulefilteredAcc.map((value, index) =>
                                                                    <div className="margin-bottom-6" id="listboxes" >
                                                                        <input className="checkbox-size" type="checkbox" id="checkedbox" checked={value.isChecked} onChange={this.handleValueChangesForaccesories.bind(this, "pressures")} value={value.name} />
                                                                        {value.name}
                                                                    </div>
                                                                ) : ""}
                                                            </div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </Tab> : ""}
                                    </Tabs>
                                </div>
                            </div>
                            {!Immediatefilter && AtleastOneFilterSelected ?
                                <div className="">
                                    {activeEventKey == "Hoses" ?
                                        <button className="apply-btn" type="button" onClick={this.handleSubmit.bind(this, "typehoseMode")}>{T("Apply")}</button> :
                                        <button className="apply-btn" type="button" onClick={this.handleSubmitAccessories.bind(this, "typeaccessoriesMode")}>{T("Apply")}</button>}

                                    <button className="cancel-btn" type="button" onClick={this.reset.bind(this)}>{T("Reset")}</button>
                                </div> : ""}
                        </div>
                        <div className="col-md-10 tab-width-75">
                            <div className="table-div">
                                <div className=" margin-bottom-1 displayflex">
                                    {enableDownload && isLoggedIn ? <button className="download-btn color-white" onClick={() => this.selectOrientation(activeEventKey == "Hoses" ? tableData : tableDataForAccessories)}>{T("Download PDF")}</button> : ""}
                                    {enableDownloadSelected ? <button className="download-btn color-white margin-left-1" onClick={() => this.DownloadPDF(selectedDatatoPrint, "landscape", "selected")}>{T("Download Selected")}</button> : ""}
                                    {isLoggedIn ? <button className="view-btn color-white zindex margin-left-1" type="button" onClick={() => redirectUrl("managecrimp")}>{T("View All Crimp Sheets")}</button> : ""}
                                    {isLoggedIn && tableData.length > 0 && activeEventKey == "Hoses" ? <div className="btn-group add-crimp">
                                        <button type="button" className="btn btn-secondary dropdown-toggle add-btn color-white margin-left-7 " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {T("Add to Crimp Sheet")}
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right z-index">
                                            {crimpsheets ? crimpsheets.map((value, index) => <button className="dropdown-item color-black" type="button" onClick={() => this.addToCrimperSheets(value.CRIMPSHEET_ID)}>{value.NAME}</button>) : ""}
                                            <a className="color-red" onClick={() => this.setState({ openAddFilter: true })}>{T("Create New Crimp Sheet")}</a>
                                        </div>
                                    </div> : ""}
                                </div>

                                {tableData ?
                                    <>
                                        <div className="recordCountUomContainer">
                                            {activeEventKey == "Hoses" ?
                                                <div className="recordCount">{T("Total records found")} : {tableData.length}  </div> :
                                                <div className="recordCount">{T("Total records found")} : {tableDataForAccessories.length}  </div>}
                                            <div className="Uom displayflex">
                                                <span className="margin-left-1">{'Show English Units'}</span>
                                                <div className="margin-left-1">
                                                    <ToggleButton
                                                        inactiveLabel={''}
                                                        activeLabel={''}
                                                        colors={{
                                                            activeThumb: {
                                                                base: 'rgb(0 0 0)',
                                                            },
                                                            inactiveThumb: {
                                                                base: 'rgb(0 0 0)',
                                                            },
                                                            active: {
                                                                base: 'rgb(193 33 38)',
                                                                hover: 'rgb(193 33 38)',
                                                            },
                                                            inactive: {
                                                                base: 'rgb(193 33 38)',
                                                                hover: 'rgb(193 33 38)',
                                                            }
                                                        }} value={Toggle} onToggle={(value) => { this.changeToggle(value); }} />
                                                </div>
                                                <span className="margin-left-1"> {'Show Metric Units'} </span>
                                            </div>
                                        </div>

                                    </>
                                    : ""}
                                <ReactTable className="notranslate"
                                    ref={this.table}
                                    onFetchData={this.fetch}
                                    onSortedChange={(newSorted, column, shiftKey) => {
                                        console.log({ newSorted, column, shiftKey });
                                        this.setState({
                                            selectedHeader: newSorted
                                        })
                                    }}
                                    data={activeEventKey == "Hoses" ? data : tableDataForAccessories}
                                    columns={[
                                        {

                                            columns: [
                                                {
                                                    Header: activeEventKey == "Hoses" ? tableData.length > 0 ? this.renderCheckboxAll : "" : tableDataForAccessories.length > 0 ? this.renderCheckboxAll : "",
                                                    Cell: this.renderCheckbox,
                                                    maxWidth: 60,
                                                    show: isLoggedIn
                                                }
                                            ]
                                        },
                                        {

                                            columns: [
                                                {
                                                    Header: 'Favorite',
                                                    accessor: 'isFavorite',
                                                    Cell: this.addfavourite,
                                                    maxWidth: 60,
                                                    show: isLoggedIn && activeEventKey == "Hoses",
                                                    sortMethod: (a, b) => {

                                                        a = Number(a); // Converting into number
                                                        b = Number(b); // Converting into number
                                                        if (a.length === b.length) {
                                                            return a > b ? 1 : -1; // same length condition
                                                        }
                                                        return a.length > b.length ? 1 : -1; // comparing length of string
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            Header: 'Hose',

                                            columns: [
                                                {
                                                    Header: 'Description',
                                                    accessor: 'HOSE_DISPLAY_NAME',
                                                    Cell: this.displayNotesHose,
                                                    minWidth: 150
                                                },
                                                {
                                                    Header: <div >Dash<br /> Size</div>,
                                                    accessor: 'DASH_SIZE',
                                                    minWidth: 50,
                                                    sortMethod: (a, b) => {
                                                        a = Number(a);
                                                        b = Number(b);
                                                        if (a.length === b.length) {
                                                            return a > b ? 1 : -1;
                                                        }
                                                        return a.length > b.length ? 1 : -1;
                                                    }


                                                },

                                                {

                                                    Header: <div>Size <br />{!Toggle ? '(in)' : '(mm)'}</div>,
                                                    accessor: !Toggle ? 'SIZE_INENGLISH' : 'SIZE_INMETRICS',
                                                    minWidth: 50
                                                },
                                                {
                                                    Header: <div>Working <br />Pressure<br /> {!Toggle ? '(psi)' : '(MPa)'}</div>,
                                                    accessor: !Toggle ? 'WORKING_PRESSURE_INENGLISH' : 'WORKING_PRESSURE_INMETRICS',
                                                    minWidth: 80

                                                },
                                            ]
                                        },
                                        {
                                            Header: 'Stem',

                                            columns: [
                                                {
                                                    Header: "Type",
                                                    accessor: 'STEM_DASH_SIZE',
                                                    Cell: this.displayNotesCoupling,
                                                    minWidth: 80
                                                    // accessor: activeEventKey == "Hoses" ? "COUPLING_ID" : "STEM_TYPE",

                                                },

                                            ]

                                        },
                                        {
                                            Header: 'Ferrule',
                                            columns: [
                                                {
                                                    Header: "Description",
                                                    accessor: activeEventKey == "Hoses" ? "DESCRIPTION" : "FERRULE_DETAIL",
                                                    minWidth: 85
                                                },

                                            ]
                                        },
                                        {
                                            Header: 'Specifications',

                                            columns: [
                                                {
                                                    Header: <div>Skive <br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.03)' : '(+/- 0.75)'}</div>,
                                                    accessor: !Toggle ? "SKIVE_LENGTH_DISPLAY_INENGLISH" : "SKIVE_LENGTH_DISPLAY_INMETRICS",
                                                    show: activeEventKey == "Hoses" && !HideSkiveLength,
                                                    minWidth: 80
                                                },
                                                {
                                                    Header: <div>Skive <br />Diameter<br />{!Toggle ? '(in)' : '(mm)'}</div>,
                                                    accessor: !Toggle ? "SKIVE_DIAMETER_DISPLAY_INENGLISH" : "SKIVE_DIAMETER_DISPLAY_INMETRICS",
                                                    show: activeEventKey == "Hoses" && !HideSkiveDiameter,
                                                    minWidth: 80
                                                },
                                                {
                                                    Header: <div>Crimp<br />Insertion <br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.03)' : '(+/- 0.75)'}</div>,
                                                    accessor: !Toggle ? "INSERTION_LENGTH_DISPLAY_INENGLISH" : "INSERTION_LENGTH_DISPLAY_INMETRICS",
                                                    show: activeEventKey == "Hoses" && !Hidecrimpinsertion,
                                                    minWidth: 80

                                                },
                                                {
                                                    Header: <div>Crimp <br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.03)' : '(+/- 0.75)'}</div>,
                                                    accessor: !Toggle ? "LENGTH_DISPLAY_INENGLISH" : "LENGTH_DISPLAY_INMETRICS",
                                                    show: activeEventKey == "Hoses" && !Hidecrimplength,
                                                    minWidth: 80

                                                },
                                                {
                                                    Header: 'Sleeve',
                                                    accessor: 'SLEEVE_ID',
                                                    show: activeEventKey != "Hoses",
                                                    minWidth: 80
                                                },
                                                {
                                                    Header: 'Clamp Collar',
                                                    accessor: 'COLLAR_ID',
                                                    show: activeEventKey != "Hoses",
                                                    minWidth: 80
                                                },
                                                {
                                                    Header: <div>Crimp<br />Outside<br />Diameter<br />{!Toggle ? '(in)' : '(mm)'}</div>,
                                                    accessor: !Toggle ? "OUTSIDE_DIAMETER_DISPLAY_INENGLISH" : "OUTSIDE_DIAMETER_DISPLAY_INMETRICS",
                                                    show: activeEventKey == "Hoses",
                                                    minWidth: 80
                                                },
                                                {
                                                    Header: <div>Crimp<br />Outside<br />Diameter<br />{!Toggle ? '(in)' : '(mm)'}</div>,
                                                    accessor: !Toggle ? "OUTSIDE_DIAMETER_INENGLISH" : "OUTSIDE_DIAMETER_INMETRICS",
                                                    show: activeEventKey != "Hoses",
                                                    minWidth: 80
                                                },

                                                {
                                                    Header: <div>Crimp<br />Outside<br />Diameter<br /> Tolerance<br /> {!Toggle ? '(in)' : '(mm)'} <br />(+/-)</div>,
                                                    accessor: !Toggle ? "OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH" : "OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS",
                                                    show: activeEventKey == "Hoses",
                                                    minWidth: 80,
                                                    sortMethod: (a, b) => {
                                                        a = Number(a);
                                                        b = Number(b);
                                                        if (a.length === b.length) {
                                                            return a > b ? 1 : -1;
                                                        }
                                                        return a.length > b.length ? 1 : -1;

                                                    }
                                                },
                                                {
                                                    Header: <div>Crimp<br />Tail<br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.1)' : '(+/- 2.5)'}</div>,
                                                    accessor: !Toggle ? "TAIL_LENGTH_INENGLISH" : "TAIL_LENGTH_INMETRICS",
                                                    show: activeEventKey == "Hoses" && !HIdecrimpTail,
                                                    minWidth: 80,
                                                    sortMethod: (a, b) => {
                                                        a = Number(a);
                                                        b = Number(b);
                                                        if (a.length === b.length) {
                                                            return a > b ? 1 : -1;
                                                        }
                                                        return a.length > b.length ? 1 : -1;

                                                    }
                                                },
                                                {
                                                    Header: <div>Die Set</div>,
                                                    accessor: "DIE_ID",
                                                    Cell: this.displayNotesDie,
                                                    minWidth: 80,
                                                    sortMethod: (a, b) => {
                                                        a = Number(a);
                                                        b = Number(b);
                                                        if (a.length === b.length) {
                                                            return a > b ? 1 : -1;
                                                        }
                                                        return a.length > b.length ? 1 : -1;
                                                    }
                                                },
                                                {
                                                    Header: <div>Approximate<br /> Setting</div>,
                                                    accessor: activeEventKey == "Hoses" ? "APPROXIMATE_DIGITAL_READOUT" : "APPROXIMATE_SETTING",
                                                    minWidth: 85,
                                                    sortMethod: (a, b) => {
                                                        a = Number(a);
                                                        b = Number(b);
                                                        if (a.length === b.length) {
                                                            return a > b ? 1 : -1;
                                                        }
                                                        return a.length > b.length ? 1 : -1;
                                                    }
                                                },

                                            ]
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    minRows={5}
                                    showPageSizeOptions={true}
                                />
                            </div>
                            <div className="margin-top-3" id="notesdata">
                                {AppliedNotesData[0] != "" ? <ol>
                                    {AppliedNotesData ? AppliedNotesData.map((value, index) =>
                                        <li className="margin-bottom-1">{value}</li>)
                                        : ""}
                                </ol> : ""}
                            </div>

                            <div className="margin-top-3" id="notesImage">

                                {FilteredNotesImage ? FilteredNotesImage.map((value, index) =>
                                    <img className="margin-bottom-1" src={value} />)

                                    : ""}
                            </div>

                        </div>


                    </div>

                    <Modal
                        show={open}
                        onHide={open}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header >
                            <Modal.Title id="example-custom-modal-styling-title">
                                <div className="underlinestyle text-Uppercase"><b><h4>{T("Select Your Crimper")}</h4></b></div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                {crimpersLoading ? null : crimpersResponse ? crimpersResponse.map((value, index) =>

                                    <div className="box-border-crimper align-center focus-crimp">
                                        <img className="crimpers-img" src={value.URL} alt="" onClick={(e) => this.handleCrimperValueChanges("crimper", value.CRIMPER_ID)} />
                                        <label className="box-crimper-label" onClick={(e) => this.handleCrimperValueChanges("crimper", value.CRIMPER_ID)}>{value.CRIMPER_ID}</label>
                                        {/*<br />
                                        {currentCountry == 'US' ? <a className="details-link" onClick={() => this.redirectDetails("crimper", value.CRIMPER_ID)}>Details</a> : ""}*/}
                                    </div>


                                ) : null}
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            {!hideSelectionbtns ? <button className="cancel-btn-modal" type="button" onClick={() => this.hide("crimpers")}>{T("Cancel")}</button> : ""}

                            {/* {!hideSelectionbtns ? <button className="apply-btn-modal" type="button" onClick={(e) => this.handleCrimperValueChanges("crimper", selectedCrimper)}>{T("Apply")}</button> : ""} */}
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={openFilters}
                        onHide={openFilters}
                        aria-labelledby="example-custom-modal-styling-title"
                    >

                        <Modal.Header >
                            <Modal.Title id="example-custom-modal-styling-title" className='custom-title'>

                                <div className='header-padding'>
                                    <div className="underlinestyle text-Uppercase"><b><h4>{T("Applied Filters")}</h4></b></div>

                                </div>

                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="filters-container">
                                <h2 className="textcenter">Hoses Filter</h2>
                                {valuesDies.length == 0 && valuesDescription.length == 0 && valuesDashsize.length == 0 && valuesStemtype.length == 0 && valuesPressure.length == 0
                                    ? <p className="textcenter"> No filters applied</p> : ""}
                                {valuesDies.length != 0 ? <h5 className="textcenter">Dies</h5> : ""}
                                <div className="text-center">

                                    {valuesDies.length != 0 ? valuesDies.map((value, index) =>

                                        <p className="display-filter filter-box" onClick={() => this.handleValueChangesInsideFilter("dies", value)}>{value} <CloseIcon className="color-black-faded" /></p>

                                    ) : ""}
                                </div>

                                {valuesDescription.length != 0 ? <h5 className="textcenter">Hose Description</h5> : ""}
                                <div className="text-center">

                                    {valuesDescription.length != 0 ? valuesDescription.map((value, index) =>



                                        <p className="display-filter filter-box" onClick={() => this.handleValueChangesInsideFilter("hoseDescriptions", value)}>{value} <CloseIcon className="color-black-faded" /></p>


                                    ) : ""}
                                </div>
                                {valuesDashsize.length != 0 ? <h5 className="textcenter">Hose Dash Size</h5> : ""}
                                <div className="text-center">

                                    {valuesDashsize.length != 0 ? valuesDashsize.map((value, index) =>


                                        <p className="display-filter filter-box" onClick={() => this.handleValueChangesInsideFilter("hoseDashes", value)}>{value} <CloseIcon className="color-black-faded" /></p>



                                    ) : ""}
                                </div>
                                {valuesStemtype.length != 0 ? <h5 className="textcenter">Stem Type</h5> : ""}
                                <div className="text-center">

                                    {valuesStemtype.length != 0 ? valuesStemtype.map((value, index) =>


                                        <p className="display-filter filter-box" onClick={() => this.handleValueChangesInsideFilter("stemTypes", value)}>{value} <CloseIcon className="color-black-faded" /></p>


                                    ) : ""}
                                </div>
                                {valuesPressure.length != 0 ? <h5 className="textcenter">Pressure</h5> : ""}
                                <div className="text-center">

                                    {valuesPressure.length != 0 ? valuesPressure.map((value, index) =>
                                        <p className="display-filter filter-box" onClick={() => this.handleValueChangesInsideFilter("pressures", value)}>{value} <CloseIcon className="color-black-faded" /></p>
                                    ) : ""}
                                </div>

                                <h2 className="textcenter">Accessories Filter</h2>

                                {valuesDescriptionAcc.length == 0 && valuesDashsizeAcc.length == 0 && valuesStemtypeAcc.length == 0 && valuesFerruleAcc.length == 0
                                    ? <p className="textcenter">No filters applied</p> : ""}

                                {valuesDescriptionAcc.length != 0 ? <h5 className="textcenter">Hose Description</h5> : ""}
                                <div className="text-center">

                                    {valuesDescriptionAcc.length != 0 ? valuesDescriptionAcc.map((value, index) =>



                                        <p className="display-filter filter-box" onClick={() => this.handleValueChangesForaccesoriesInsideFilter("hoseDescriptions", value)}>{value} <CloseIcon className="color-black-faded" /></p>
                                    ) : ""}
                                </div>
                                {valuesDashsizeAcc.length != 0 ? <h5 className="textcenter">Hose Dash Size</h5> : ""}
                                <div className="text-center">

                                    {valuesDashsizeAcc.length != 0 ? valuesDashsizeAcc.map((value, index) =>



                                        <p className="display-filter filter-box" onClick={() => this.handleValueChangesForaccesoriesInsideFilter("hoseDashes", value)}>{value} <CloseIcon className="color-black-faded" /></p>
                                    ) : ""}
                                </div>
                                {valuesStemtypeAcc.length != 0 ? <h5 className="textcenter">Stem Type</h5> : ""}
                                <div className="text-center">

                                    {valuesStemtypeAcc.length != 0 ? valuesStemtypeAcc.map((value, index) =>

                                        <p className="display-filter filter-box" onClick={() => this.handleValueChangesForaccesoriesInsideFilter("stemTypes", value)}>{value} <CloseIcon className="color-black-faded" /></p>
                                    ) : ""}
                                </div>
                                {valuesFerruleAcc.length != 0 ? <h5 className="textcenter">Ferrule Detail</h5> : ""}
                                <div className="text-center">

                                    {valuesFerruleAcc.length != 0 ? valuesFerruleAcc.map((value, index) =>
                                        <p className="display-filter filter-box" onClick={() => this.handleValueChangesForaccesoriesInsideFilter("pressures", value)}>{value} <CloseIcon className="color-black-faded" /></p>
                                    ) : ""}
                                </div>

                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button className="cancel-btn-modal" type="button" onClick={() => this.hide("checkfilters")}>{T("Close")}</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={openAddFilter}
                        onHide={openAddFilter}
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >
                        <Modal.Header >
                            <Modal.Title id="example-custom-modal-styling-title">
                                <div className="underlinestyle"><b>{T("Add New Crimp Sheet")}</b></div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className="form-group top-margn" style={{ marginLeft: "1%", padding: "5%" }}>
                                    <label >Name <span className="colon"></span></label>
                                    <input type="text" id="txt_name" placeholder="Enter Crimp sheet name" className="input-box-add" value={crimperSheetName ? crimperSheetName : ""} onChange={(e) => this.handleCrimpName(e)} />
                                    <p className="text-danger">{invalidCrimpname}</p>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="cancel-btn-modal" type="button" onClick={() => this.hide("addcrimp")}>{T("Cancel")}</button>
                            <button className="apply-btn-modal" type="button" onClick={() => this.createCrimpsheets()}>{T("Save")}</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={OpenHoseSpec}
                        onHide={OpenHoseSpec}
                        dialogClassName="modal-60W"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header >
                            <Modal.Title id="example-custom-modal-styling-title">
                                <div className="underlinestyle"><b>{T("Specifications")}</b></div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="Hose-spec-container tab-scroll">
                                {filteredDataHoseDescriptions ? filteredDataHoseDescriptions.map((value, index) =>

                                    <div className="hose-specs cursor" title={T("Click to view Specifications")}>
                                        <li className="" onClick={(e) => this.redirectDetails("hose", value)}>{value}</li>
                                        <br />
                                    </div>
                                ) : ""}
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button className="cancel-btn-modal" type="button" onClick={() => this.hide("hose")}>{T("Close")}</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={openNotes}
                        onHide={openNotes}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header >
                            <Modal.Title id="example-custom-modal-styling-title">
                                <div className="underlinestyle"><b>Crimper Spec for {selectedHoseforLink} ({Number(selectedSizeforLink).toFixed(2)}in)</b></div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {NotesMessage.length != 0 || NotesURL.length != 0 ?
                                <div>
                                    {NotesMessage[0] != "" || str != "ZZZ" ?
                                        <div>


                                            {str != "ZZZ" ? NotesURL.map((value, index) => <img className="notes-container" src={value} alt="crimper-images" />)
                                                : ""}

                                            <div className="margin-top-3">
                                                {NotesMessage[0] != "" ? <ol>
                                                    {NotesMessage ? NotesMessage.map((value, index) =>
                                                        <li className="margin-bottom-1">{value}</li>)
                                                        : ""}
                                                </ol> : ""}
                                            </div>
                                        </div> :
                                        <div>
                                            No Notes found
                                        </div>}
                                </div> : <div>
                                    No Notes found
                                </div>}

                        </Modal.Body>
                        <Modal.Footer>
                            <button className="cancel-btn-modal" type="button" onClick={() => this.hide("notes")}>{T("Close")}</button>

                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={openFav}
                        onHide={openFav}
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >
                        <Modal.Header >
                            <Modal.Title id="example-custom-modal-styling-title">
                                <div className="underlinestyle">{T("Confirmation")}</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                {T("Do you want to remove from Favorites")}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="cancel-btn-modal" type="button" onClick={() => this.hideFav()}>{T("Cancel")}</button>
                            <button className="apply-btn-modal" type="button" onClick={() => this.removeFav(removeSelectedFav)}>{T("Yes")}</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={openHoseSize}
                        onHide={openHoseSize}
                        dialogClassName="modal-60W"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header >
                            <Modal.Title id="example-custom-modal-styling-title">
                                <div className="underlinestyle"><b>{T("Units")}</b></div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="Hose-size-table">
                                <ReactTable
                                    className="fullwidth-table hidepagination"
                                    data={hoseSizeTbl}
                                    columns={[
                                        {
                                            Header: 'DASH SIZE',
                                            accessor: 'DASH_SIZE'
                                        },
                                        {
                                            Header: 'IN',
                                            accessor: 'INCH'
                                        },
                                        {
                                            Header: 'MM',
                                            accessor: 'MILLIMETER'
                                        }

                                    ]}
                                    showPageSizeOptions={false}
                                />
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button className="cancel-btn-modal" type="button" onClick={() => this.hide("hoseSize")}>{T("Close")}</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={openOrientation}
                        onHide={openOrientation}
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >
                        <Modal.Header >
                            <Modal.Title id="example-custom-modal-styling-title">
                                <div className="underlinestyle">{T("Confirmation")}</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h4 className="textalign-center">Please select the orientation </h4>
                            <div id="centered">
                                <div className="textalign-center select-options margin-5 cursor" onClick={() => this.DownloadPDF(activeEventKey == "Hoses" ? tableData : tableDataForAccessories, "portrait", "all")}> <CropPortraitIcon className="color-black iconsize-45" /><label >PORTRAIT</label></div>
                                <div className="textalign-center select-options margin-5 cursor" onClick={() => this.DownloadPDF(activeEventKey == "Hoses" ? tableData : tableDataForAccessories, "landscape", "all")}> <CropLandscapeIcon className="color-black iconsize-45" /><label >LANDSCAPE</label></div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button className="cancel-btn-modal" type="button" onClick={() => this.hide("orientation")}>{T("Cancel")}</button>
                        </Modal.Footer>
                    </Modal>
                </div >
            </>
        );
    }
}

MainDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connectDataContext(
    connect(mapStateToProps, {})(
        MainDashboard
    )
);
