const res = {
    "0": "⁰ ",
    "1": "¹ ",
    "2": "² ",
    "3": "³ ",
    "4": "⁴ ",
    "5": "⁵ ",
    "6": "⁶ ",
    "7": "⁷ ",
    "8": "⁸ ",
    "9": "⁹ ",
};

const _parseNumbers = numbers => {
    if (!Array.isArray(numbers) || !numbers) return ''
    const strNumbers = numbers.map(String)
    const replacement = strNumbers.map(num => {
        const splitted = num.split('').map(e => res[e])
        return splitted.join('')
    })

    return replacement.join(' ')
}

export const addPowers = (str, numbers) => {
    return `${str} ${_parseNumbers(numbers)}`
}

export const isEmpty = (data) => {
    if(Array.isArray(data) || typeof data === 'string') return data.length === 0
    return false
}