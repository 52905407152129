import React, { useState, useContext } from "react";
import { AccountContext } from '../../Accounts';

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const { getSession, authenticate } = useContext(AccountContext);

  const onSubmit = event => {
    setError("");
    event.preventDefault();

    getSession().then(({ user, email }) => {
      authenticate(email, password).then(() => {
        user.changePassword(password, newPassword, (err, result) => {
          if (err) {
            setError(err?.message)
          }
          setMessage(result)
        });
      }).catch(err => {
        setError(err?.message)
        console.error('Failed to login!', err);
      })
    });
  };

  return (
    <div className="body-section page-content">
      <div className="container">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <input type="password" className="form-control" id="pwd" name="password" value={password}
              onChange={event => setPassword(event.target.value)}
              placeholder="Old Password" />
          </div>
          <div className="form-group">
            <input type="password" className="form-control" id="pwd" name="password" value={newPassword}
              onChange={event => setNewPassword(event.target.value)}
              placeholder="New Password" />
          </div>
          <button type="submit" className="btn btn-primary" style={{ marginBottom: "10px" }} id="submit" onClick={onSubmit}>Change Password</button>
          <p className="text-danger">{error}</p>
          <p className="text-info">{message}</p>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;