import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import banner from '../../images/resources-banner.jpg'
import pipe from '../../images/pipe1.png'
import { T } from "react-translator-component";
import ApiServices from '../../utils/ApiServices';
import { Carousel, Tabs, Tab } from "react-bootstrap";
import API from './../../utils/config.json'
import Modal from 'react-bootstrap/Modal'
import crimpimg from '../../images/HoseMan_Homepage_Img.jpeg'
import { saveDataInIndexDB } from './../../IndexedDB/Utility'
import { getDataFromIndexDB } from './../../IndexedDB/Utility'
import ReactTooltip from "react-tooltip";
import Observer from './Observer';
import { connectDataContext } from '../common/DataProvider';


class StartupPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showActivityIndicator: true,
            crimpersResponse: [],
            bannerDetails: [],
            removeSelectedFav: false,
            dataVersion: []
        };
    }

    componentDidMount() {
        console.log(this.props)
        this.GetCrimpersDta();
        this.getBanner();
        this.setState({ showActivityIndicator: false });
        const date = "/Date(" + Date.now() + ")/";
        this.getDataversion();
    }
    getDataversion = () => {
        const { region } = this.props;
        if (region === "NA") {
            var url = API.URL + "versions?$orderby=UPDATE_TIME desc";
            console.log(url);
        }
        if (region === "EU") {
            var url = API.URL + "versions/eu?$orderby=UPDATE_TIME desc";
            console.log(url);

        }
        ApiServices.httpGET(url, (err, response) => {
            if (response?.data?.d?.results) {
                let filteredData = response.data.d.results[0];

                var str = filteredData.UPDATE_TIME
                var num = parseInt(str.replace(/[^0-9]/g, ""));
                var date = new Date(num).toISOString().split('T')[0];
                filteredData["UPDATE_TIME"] = date
                if (region === "NA") {
                    filteredData["CurrentVersion"] = filteredData.VERSION_ID + '.' + date
                }
                if (region === "EU") {
                    filteredData["CurrentVersion"] = filteredData.ID + '.' + date

                }
                this.setState({
                    dataVersion: filteredData,
                });
            };
        });
    }


    reDirectToFavorites = () => { this.redirect("Favorites") }
    reDirectToManageCrimpSheets = () => { this.redirect("ManageCrimpSheets") }

    GetCrimpersDta = () => {

        // Get the Crimpers data
        const region = this.props.region;
        var url = API.URL + "crimpers?$format=json&$filter=REGION_ID eq '" + region + "' and URL ne 'null'&$select=CRIMPER_ID,URL"

        ApiServices.httpGET(url, (err, response) => {
            if (response?.data?.d?.results) {

                let responseCrimpers = response.data.d.results;

                let filteredDatacrimpers = responseCrimpers.map(item => item.CRIMPER_ID)
                    .filter((value, index, self) => self.indexOf(value) === index)
                this.setState({
                    crimpersResponse: responseCrimpers,
                });
            };
        });
    }
    getBanner = () => {
        var url = API.URL + 'new-products?$format=json'
        ApiServices.httpGET(url, (err, response) => {
            if (response) {
                let responseCrimpers = response.data.d.results;
                this.setState({
                    bannerDetails: responseCrimpers,
                });
            };
        });
    }
    redirect = (page) => {
        console.log(this.props)
        this.props.history.push(page)
    }
    imageClick = async (detailsURL) => {

        var data = detailsURL;
        var win = window.open(data);
    }

    show = () => {
        this.setState({ open: true })
    }

    hide = () => {
        this.setState({ open: false })
    }



    render() {
        const { user, isAuthenticated } = this.props.auth;
        const { dataVersion, bannerDetails, open } = this.state;
        const data = [
            { Title: "Lookup a Crimper Spec", page: '/Dashboard', Body: "This is a brief description about this action" },
            { Title: "Create a Crimper Sheet", page: '', Body: "This is a brief description about this action" },
            { Title: "Search for a Crimper", page: '', Body: "This is a brief description about this action" },
            { Title: "Lookup Hose Spec", page: '', Body: "This is a brief description about this action" },
            { Title: "View Tutorials", page: '', Body: "This is a brief description about this action" },
            { Title: "Create a Profile", page: '', Body: "This is a brief description about this action" },
            { Title: "Search for a Profile", page: '', Body: "This is a brief description about this action" },
            { Title: "Manage Crimp Sheets", page: '', Body: "This is a brief description about this action" },

        ]


        return (
            <>
                <Observer observable={this.props.region} onChange={this.getDataversion} />
                <div className="section-container">
                    <div className='redline'>
                        <h2 > {T("What Do You Want To Do?")}  </h2>
                    </div>
                    <div className='row center-div' >
                        <ReactTooltip id="registerTip" effect="float">
                            Click to Find Crimp Specifications
                        </ReactTooltip>
                        <div data-tip data-for="registerTip" className="card-body-with-slider cursor find-crimp" onClick={() => this.redirect("Dashboard")}>
                            <div className='cursor highlight-Text-bg' onClick={() => this.redirect("Dashboard")}>
                                <div className='Find-crimp-txt'>
                                    <div className='redline'>
                                        <h6 className='h7'> {T("FIND CRIMP SPECS")}</h6>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='card-box'>
                            <div className="card-body cursor" onClick={() => this.reDirectToFavorites()}>
                                <div className='redline'>
                                    <h6  ><b>{T("FAVORITES")}</b></h6>

                                </div>

                                <label className="box-crimper-label">{T("Save favorites to your eCrimp account to save time retrieving data on your most used specifications.")} </label>

                            </div>
                            <div className="card-body cursor" onClick={() => this.reDirectToManageCrimpSheets()}>
                                <div className='redline'>
                                    <h6  ><b>{T("SAVED CRIMP SHEETS")}</b></h6>

                                </div>
                                <label className="box-crimper-label"> {T("Save crimp sheet to your eCrimp account. You can view anytime or print a copy.")}</label>

                            </div>
                            {!isAuthenticated ?
                                <div className="card-body cursor" onClick={this.show}>
                                    <div className='redline'>
                                        <h6  ><b>{T("KNOWLEDGE CENTER")}</b></h6>

                                    </div>
                                    <label className="box-crimper-label">{T("Find tutorials, product catalogs, training resources and more.")}</label>

                                </div> : ""}
                            {!isAuthenticated ?
                                <div className="card-body cursor" onClick={() => this.redirect("Signin")}>
                                    <div className='redline'>
                                        <h6  ><b>{T("LOGIN / SIGN-UP")}</b></h6>

                                    </div>
                                    <label className="box-crimper-label">{T("Set up a free account to access helpful site functions, such as saving favorites and creating Crimp Sheet.")}</label>

                                </div> : ""}
                            {isAuthenticated ?
                                <div className='card-body-loggedin cursor'>
                                    <div className='row col-md-10'>
                                        <div className='col-md-6' onClick={this.show}>

                                            <div className='redline'>
                                                <h6 className='margin-top-10' ><b>{T("KNOWLEDGE CENTER")}</b></h6>

                                            </div>


                                            <label className="box-crimper-label">{T("Find tutorials, product catalogs, training resources and more.")}</label>
                                        </div>

                                        <div className='col-md-4'>

                                            <img className='img-slider-banner ' src={banner} alt='crimpers' />
                                        </div>
                                    </div>
                                </div> : ""}
                        </div>


                        <div className="card-body-notes cursor notes-customwidth" >
                            {dataVersion.length !== 0 ? <div className=" view-ticket-col">
                                <div className="displayflex">
                                    <label className='view-ticket-details'>
                                        {T("Current Revision")}
                                    </label>
                                    <h6 className='margin-left-1 lh-unset'><a target='_self' href={"/Dataversion"}>{": " + dataVersion.CurrentVersion}</a></h6>
                                </div>
                                <div className="displayflex">
                                    <label className='view-ticket-details'>
                                        {T("Added Components")}
                                    </label>
                                    <h6 className='margin-left-1 lh-unset'>{": " + dataVersion.ADDED_COMPONENTS}</h6>
                                </div>
                                <div className="displayflex">
                                    <label className='view-ticket-details'>
                                        {T("Changed Components")}
                                    </label>
                                    <h6 className='margin-left-1 lh-unset'>{": " + dataVersion.CHANGED_COMPONENTS}</h6>
                                </div>
                                <div className="displayflex">
                                    <label className='view-ticket-details'>
                                        {T("Notes")}
                                    </label>
                                    <h6 className='margin-left-1 lh-unset'>{": " + dataVersion.NOTES}</h6>
                                </div>
                            </div> : ""}

                        </div>
                        <div className='card-box-searchhose'>
                            <div className="card-body-searchhose cursor searchhose-customwidth" onClick={() => this.redirect("HoseFiltersfromDashboard")}>
                                <div className='redline'>
                                    <h6  ><b>{T("Search By Hose")}</b></h6>
                                </div>
                                <label className="box-crimper-label">{T("Find a crimper and associated information by selecting a hose type.")}</label>

                            </div>
                        </div>
                    </div>
                </div >
                <div className='banner'>

                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            {bannerDetails ? bannerDetails.map((value, i) => <div
                                key={i}
                                className={i === 0 ? "carousel-item active" : "carousel-item "}>
                                <div className="carousel-caption">

                                    <div className='center-banner'>
                                        <img className="d-block w-100 home-Slide h-70" src={value.URL} onClick={() => this.imageClick("https://www.gates.com/us/en/solutions/product-innovations.html")} />

                                    </div>


                                </div>
                            </div>) : ""
                            }

                        </div>

                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <Modal
                    show={open}
                    onHide={open}
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >

                    <Modal.Header >
                        <Modal.Title id="example-custom-modal-styling-title" className='custom-title'>
                            <div className='header-padding'>
                                <h6 className='margin-top-10 underlinestyle text-Uppercase' ><b><h2>{T("Knowledge Center")}</h2></b></h6>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='knowlege-container margin-left-10'>
                            <i><b>{T("Click on a link below to view the desired Gates eCrimp document.")} </b></i>
                            <br />
                            <br />
                            <ul>
                                <li><a href="https://www.gates.com/content/dam/gates/home/knowledge-center/resource-library/catalogs/industrial-hose-and-couplings-catalog.pdf" target="_blank">Industrial Hose Catalog</a></li>
                                <li><a href="https://www.gates.com/content/dam/gates/home/knowledge-center/resource-library/catalogs/35093-and-431-2080-gates-2019-hydraulic-catalog-en.pdf" target="_blank">Hydraulic Hose Catalog</a></li>
                                <li><a href="https://www.gatesaustralia.com.au/~/media/files/gates-au/hydraulics/brochures-and-marketing-collateral/manual_gates-gc16xd_b.pdf" target="_blank">GC16 Crimper Manual</a></li>
                                <li><a href="https://assets.gates.com/content/dam/gates/home/knowledge-center/resource-library/operating-manuals/ecrimp/gc96crimpersafetyoperatingmanual.pdf" target="_blank">GC96 Crimper Manual</a></li>
                                <li><a href="https://assets.gates.com/content/dam/gates/home/knowledge-center/resource-library/operating-manuals/ecrimp/gc32xdcrimpersafetyoperatingmanual.pdf" target="_blank">GC32-XD Crimper Manual</a></li>
                                <li><a href="https://assets.gates.com/content/dam/gates/home/knowledge-center/resource-library/operating-manuals/ecrimp/omnicrimp21safetyoperatingmanual.pdf" target="_blank">OmniCrimp 21 Manual</a></li>
                                <li><a href="https://assets.gates.com/content/dam/gates/home/knowledge-center/resource-library/operating-manuals/ecrimp/sc32crimpermanual.pdf" target="_blank">SC32 Shop Crimper Manual</a></li>
                                <li><a href="https://assets.gates.com/content/dam/gates/home/knowledge-center/resource-library/operating-manuals/ecrimp/power-crimp-3000b-manual.pdf" target="_blank">Power Crimp 3000B Manual</a></li>
                                <li><a href="https://assets.gates.com/content/dam/gates/home/knowledge-center/resource-library/operating-manuals/ecrimp/power-crimp-707-manual.pdf" target="_blank">Power Crimp 707 Manual</a></li>
                                <li><a href="https://assets.gates.com/content/dam/gates/home/knowledge-center/resource-library/operating-manuals/ecrimp/mobilecrimp420safetyoperatingmanualdigitaldial.pdf" target="_blank">MC4-20 Digital Dial Manual</a></li>
                                <li><a href="https://assets.gates.com/content/dam/gates/home/knowledge-center/resource-library/operating-manuals/ecrimp/mobilecrimp420safetyoperatingmanualpositivestopmanualcrimper.pdf" target="_blank">MC4-20 Positive Stop Manual</a></li>
                                <li><a href="https://assets.gates.com/content/dam/gates/home/knowledge-center/resource-library/operating-manuals/ecrimp/gc32flex-c-manual.pdf" target="_blank">GC32FLEX & GC32FLEX TSI</a></li>
                                <li><a href="https://assets.gates.com/content/dam/gates/home/knowledge-center/resource-library/operating-manuals/ecrimp/gc32-tsi-safety-and-operating-manual.pdf" target="_blank">GC32 TSI CRIMPER</a></li>
                                <li><a href="https://www.gates.com/content/dam/cortex/gc20/knowledge-center/documents/gc96tsi-A-manual.pdf" target="_blank">GC96 TSI CRIMPER</a></li>
                                <li><a href="https://www.gates.com/content/dam/cortex/gc20/knowledge-center/documents/gc20-safety-operations-manual.pdf" target="_blank">GC20 Cortex Safety and Operations Manual</a></li>


                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.hide()}>{T("Close")}</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
StartupPage.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connectDataContext(
    connect(mapStateToProps, {})(
        StartupPage
    )
);


