import React, { useEffect } from "react"
import { Decryption, Encryption } from "../utils/SecurePassword"

const EncryptionService = () => {

    // text is a base64 encoded string
    // returns an encrypted string
    const encrypt = text => {
        const decoded = atob(text)
        return Encryption(decoded)
    }
    // text is an encrypted string
    // returns a base64 encoded string
    const decrypt = text => {
        const decrypted = Decryption(text)
        const encoded = String(btoa(String(decrypted)))
        return encoded
    }

    useEffect(() => {
        window.encrypt = encrypt;
        window.decrypt = decrypt;
        
        return () => {
            window.encrypt = null;
            window.decrypt = null;
        }
    }, [])

    return (
        <></>
    )
}

export default EncryptionService