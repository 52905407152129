import { DIGITAL_DATA } from "../actions/types";
import Util from '../utils/Util';

let digitaldata = Util.getCookie("digital_data")

const initialState = {
    digitaldata: digitaldata,
}
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DIGITAL_DATA:
      return {
        ...state,
        digitaldata: payload.digitaldata,
      };
    default:
      return state;
  }
}
export const Getdigitaldata = (state) => state.digitaldata.digitaldata

