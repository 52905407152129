import appDB from './indexedDB'

const database = appDB.db

// dataManageCrimpsheets

export async function savedataManageCrimpsheets(data) {
  if (data) {
    if (database.dataManageCrimpsheets) {
      await database.dataManageCrimpsheets.clear()
      await database.dataManageCrimpsheets.add({ dataManageCrimpsheets: 'dataManageCrimpsheets', data })
    }
  }
}

export async function getdataManageCrimpsheets() {
  const testData = await database.dataManageCrimpsheets
    .where('dataManageCrimpsheets')
    .equals('dataManageCrimpsheets')
    .toArray()
  if (testData && testData.length > 0) {
    return testData[0].data
  }
  return null
}

export async function cleardataManageCrimpsheets() {
    await database.dataManageCrimpsheets.clear()
}

