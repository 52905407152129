import React, { useCallback, useContext, useEffect, useState } from "react";
import "./DeleteAccount.scss";
import { T } from "react-translator-component";
import Modal from "react-bootstrap/Modal";
import ApiServices from "../../../utils/ApiServices";
import Util from "../../../utils/Util";
import { Encryption } from "../../../utils/SecurePassword";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { cleardataHoseFilters } from "../../../IndexedDB/userDataDB";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../../components/auth-hook/auth-context";
import API from "../../../utils/config.json";

const DeleteAccount = ({ history }) => {
  const { logout: clearUser, isLoggedIn } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [hasWebAccount, setHasWebAccount] = useState(false);
  const [hasMobileAccount, setHasMobileAccount] = useState(false);
  const [deleteWebAccount, setDeleteWebAccount] = useState(false);
  const [deleteMobileAccount, setDeleteMobileAccount] = useState(false);
  const [email, setEmail] = useState(Util.getCookie("email"));
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkUserAccounts = useCallback(async () => {
    if (!email) return;
    setLoading(true);
    const url = `${API.URL}users/checkusrAct?email=${email}`;
    await ApiServices.httpGET(url, (err, response) => {
      if (response && response.status < 400) {
        if (response.data.web) setHasWebAccount(true);
        if (response.data.mobile) setHasMobileAccount(true);

        if (!response.data.web && !response.data.mobile) {
          toast.error("No account found");
          setTimeout(() => {
            logout(true);
          }, 2000);
        }
      }
    });
    setLoading(false);
  }, [email]);

  useEffect(() => {
    if (email) {
      checkUserAccounts();
    }
  }, [email]);

  const deleteAccount = useCallback(
    async (pass, mail, retry = false) => {
      if (!pass || !mail) return;
      setLoading(true);
      const encryptedPassword = Encryption(pass);
      const finalEncryptedPassword = retry
        ? `"${encryptedPassword}"`
        : encryptedPassword;
      const url = `${API.URL}users/checkusrAct?email=${mail}&password=${finalEncryptedPassword}&web=${deleteWebAccount}&mobile=${deleteMobileAccount}`;
      await ApiServices.httpDELETE(url, async (err, response) => {
        if (response && response.status < 400) {
          if (deleteMobileAccount) {
            if (
              !response.data.userExistsInUSERS ||
              !response.data.deletedInUSERS
            ) {
              if (retry) {
                toast.error("Error deleting mobile account");
                setOpen(false);
                setLoading(false);
                return;
              }

              await deleteAccount(pass, mail, true);
            }
          }
          if (deleteWebAccount) {
            if (
              !response.data.userExistsInUSERSWEB ||
              !response.data.deletedInUSERSWEB
            ) {
              toast.error("Error deleting web account");
              setOpen(false);
              setLoading(false);
              return;
            }
          }
          toast.success("Account deleted successfully");
          setTimeout(() => {
            logout();
          }, 2000);
        } else {
          toast.error("Error deleting account");
          setLoading(false);
        }
      });
    },
    [deleteMobileAccount, deleteWebAccount, password]
  );

  const logout = async (force = false) => {
    if (force || deleteWebAccount) {
      await clearUser();
      await cleardataHoseFilters();
    }
    history.replace("/");
  };

  return (
    <>
      <ToastContainer newestOnTop={true} />
      <div className="delete-container">
        <div className="card">
          <div className="delete-container">
            <h4>Delete eCrimp Account:</h4>
            {email ? (
              <div className="delete-row space-between">
                <p className="email-title">{email}</p>
                {!isLoggedIn && (
                  <button
                    className="close-button"
                    onClick={() => setEmail(null)}
                  >
                    X
                  </button>
                )}
              </div>
            ) : null}
            <div className="spacer-24" />
            <SetEmailForm
              email={email}
              setEmail={setEmail}
              setEmailError={setEmailError}
              emailError={emailError}
            />
            <div className="spacer-16" />
            {hasWebAccount && (
              <label htmlFor="web" className="delete-row">
                <input
                  id="web"
                  name="ecrimpWeb"
                  type="checkbox"
                  checked={deleteWebAccount}
                  onChange={() => setDeleteWebAccount((prev) => !prev)}
                />
                <div className="spacer-8" />
                <p className="expanded">Delete eCrimp account for Web</p>
              </label>
            )}
            <div className="spacer-16" />
            {hasMobileAccount && (
              <label htmlFor="mobile" className="delete-row">
                <input
                  id="mobile"
                  name="ecrimpMobile"
                  type="checkbox"
                  checked={deleteMobileAccount}
                  onChange={() => setDeleteMobileAccount((prev) => !prev)}
                />
                <div className="spacer-8" />
                <p className="expanded">Delete eCrimp account for Mobile</p>
              </label>
            )}
            <div className="spacer-32" />
            {email && (
              <button
                className="delete-button"
                disabled={
                  (!hasMobileAccount && !hasWebAccount) ||
                  (!deleteMobileAccount && !deleteWebAccount)
                }
                onClick={() => setOpen(true)}
              >
                {T("Confirm")}
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal show={open} onBackdropClick={() => setOpen(false)}>
        {loading ? (
          <Modal.Body>
            <div className="delete-row center">
              <LoadingSpinner />
            </div>
          </Modal.Body>
        ) : (
          <>
            <Modal.Header>
              <h5>{T("Do you really want to delete your account?")}</h5>
            </Modal.Header>
            <Modal.Body>
              <div className="delete-container">
                <label htmlFor="password" className="email">
                  <p>Insert your password to confirm</p>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="cancel-button" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button
                className="confirm-button"
                disabled={!password}
                onClick={() => deleteAccount(password, email)}
              >
                Confirm
              </button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

const SetEmailForm = ({ email, setEmail, emailError, setEmailError }) => {
  const [innerEmail, setInnerEmail] = useState();
  if (email) {
    return <></>;
  }
  return (
    <>
      <div className="email-row">
        <label htmlFor="email" className="email">
          <p>{T("Email")}</p>
          <input
            type="email"
            name="email"
            id="email"
            onChange={(e) => {
              setEmailError(!Util.validateEmail(e.target.value));
              setInnerEmail(e.target.value);
            }}
          />
        </label>
        <button
          disabled={!innerEmail || emailError}
          className="email-button"
          onClick={() => setEmail(innerEmail)}
        >
          Continue
        </button>
      </div>
      {emailError && (
        <div className="email-row">
          <p className="error">{T("Invalid Email")}</p>
        </div>
      )}
    </>
  );
};

export default DeleteAccount;
