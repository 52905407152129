module.exports = {
  "Dashboard": "Tableau de bord",
  "FAVORITES": "FAVORIS",
  "SAVED CRIMP SHEETS": "Données de sertissage enregistrées",
  "Crimp Sheets": "Données de sertissage",
  "KNOWLEDGE CENTER": "CENTRE DE CONNAISSANCES",
  "LOGIN / SIGN-UP": "SE CONNECTER / S'INSCRIRE",
  "FIND CRIMP SPECS": "Trouvez vos données de sertissage",
  "What Do You Want To Do?": "Que voulez-vous faire ?",
  "Select Your Crimper": "Sélectionnez votre sertisseuse",
  "CHOOSE YOUR REGION OR LANGUAGE": "Choisissez le pays et la langue",
  "Email Address": "Adresse e-mail",
  "Password": "Mot de passe",
  "FORGOT PASSWORD": "MOT DE PASSE OUBLIÉ",
  "Logout": "Se déconnecter",
  "Login": "Se connecter",
  "Common.LOGIN": "SE CONNECTER",
  "Common.Signup": "S'inscrire",
  "Common.SIGNUP": "S'INSCRIRE",
  "SIGN UP": "S'INSCRIRE",
  "Don't have an account": "Je n'ai pas de compte",
  "Close": "Fermer",
  "Enter the email address you used to sign up with below, and we\'ll send a reset link.": "Entrez ci-dessous l'adresse e-mail que vous avez utilisée pour vous inscrire et nous vous enverrons un lien de réinitialisation.",
  "First Name": "Prénom",
  "Last Name": "Nom de famille",
  "Company": "Entreprise",
  "Country": "Pays",
  "Apply": "Appliquer",
  "Save": "Sauvegarder",
  "Cancel": "Annuler",
  "Name": "Nom",
  "Reset":"Réinitialiser",
  "Send":"Envoyer",
  "Yes":"Oui",
  "Do you wan't to delete":"Voulez-vous supprimer",
  "SELECT YOUR CRIMPER": "Sélectionnez votre sertisseuse",
  "Field Attachable": "Embout à visser",

  "DISCLAIMER": "CLAUSE DE NON-RESPONSABILITÉ",



  "Save favorites to your eCrimp account to save time retrieving data on your most used specifications.":"Sauvegardez vos favoris dans votre compte Ecrimp pour gagner du temps",
  "Save crimp sheet to your eCrimp account. You can view anytime or print a copy.":"Sauvegardez vos feuilles de sertissage dans votre compte Ecrimp pour les consulter ou les imprimer",
  "Find tutorials, product catalogs, training resources and more.":"Consultez des tutoriels, le catalogue des produits, des formations techniques et plus encore",
  "Set up a free account to access helpful site functions, such as saving favorites and creating Crimp Sheet.":"Créez gratuitement un compte pour avoir accès aux fonctions utiles du site telles que la sauvegarde de vos favoris et la création de feuilles de sertissage",
  "Manage Crimp Sheets":"Gérer les feuilles de sertissage",
  "Rename Crimp Sheet":"Renommer la feuille de sertissage",

  "Favorites":"Favoris",
  "Save the crimp specifications you use most often for quick access and ease of use.": "Sauvegardez vos feuilles de sertissage favorites pour un accès rapide",
  "Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.":"Les données de sertissage peuvent changer. Les données imprimées peuvent être obsolètes et devraient être réimprimées tous les six mois ou à chaque fois qu'une nouvelle version d'Ecrimp est publiée. Les valeurs de réglages sont données à titre indicatif. Vérifiez toujours le diamètre serti pour vous assurer que le sertissage a été effectué correctement.",
  "Do you want to remove from Favorites":"Voulez-vous supprimer des Favoris",

  "Download PDF": "Télécharger le PDF",
  "Download Selected": "Téléchargez la sélection",
  "View All Crimp Sheets": "Consultez toutes les feuilles de sertissages",
  "Add to Crimp Sheet": "Ajouter à la feuille de sertissage",
  "Create New Crimp Sheet":"Créer une nouvelle feuille de sertissage",
  "Show Metric Units":"Unités Métriques",
  "Show English Units":"Unités Impériales",
  "Total records found":"Total des enregistrements trouvés",
};