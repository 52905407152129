import axios from 'axios'
import API from './config.json'


export const header = {
  headers: {
    Accept: 'application/json',
    Authorization: `Basic ${btoa(
      `${process.env.REACT_APP_APIUSER}:${process.env.REACT_APP_APICREDENTIAL}`
    )}`,
    'Content-Type': 'application/json'
  }
}

export const isProd = () => API.URL === API.ProdURL

export default class ApiServices {
  static async httpGET(endPoint, callback) {
    const apiURL = endPoint
    try {
      const res = await axios
        .get(apiURL, header)
      if (callback) callback(null, res)
      return res
    } catch (err) {
      if (callback) callback(err, null)
    }
  }

  static async httpGETAddParams(endPoint, params, callback) {
    try {
      const res = await axios
        .get(`${endPoint}?${params}`)
      if (callback) callback(null, res)
      return res
    } catch (err) {
      if (callback) callback(err, null)
    }
  }

  static async httpPOST(endPoint, postObj, callback) {
    try {
      const res = await axios
        .post(endPoint, postObj, header)
      if (callback) callback(null, res)
      return res
    } catch (err) {
      if (callback) callback(err, null)
    }
  }

  static async httpPUT(endPoint, postObj, callback) {
    try {
      const res = await axios
        .put(endPoint, postObj, header)
      if (callback) callback(null, res)
      return res
    } catch (err) {
      if (callback) callback(err, null)
    }
  }

  static async httpPATCH(endPoint, patchObj, callback) {
    try {
      const res = await axios
        .patch(endPoint, patchObj, header)
      if (callback) callback(null, res)
      return res
    } catch (err) {
      if (callback) callback(err, null)
    }
  }

  static async httpDELETE(endPoint, callback) {
    try {
      const res = await axios
        .delete(endPoint, header)
      if (callback) callback(null, res)
      return res
    } catch (err) {
      if (callback) callback(err, null)
    }
  }
}
