
import CryptoJS from 'crypto-js'


var key = CryptoJS.enc.Utf8.parse('Z0tzXBdF1AKrgsgCsSki66NYbJBw7rbS');     // Use Utf8-Encoder. 
var iv = CryptoJS.enc.Base64.parse("AAAAAAAAAAAAAAAAAAAAAA==")             // Use Utf8-Encoder

export function Encryption(Password) {
    // Password Encryption
    var encrypt = CryptoJS.AES.encrypt(Password, key, { iv: iv });
    var encryptedPassword = encrypt.toString();
    return encryptedPassword
}

export function Decryption(Password) {
    // Password Decryption
    var decryptedWA = CryptoJS.AES.decrypt(Password, key, { iv: iv });
    var decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8);
    return decryptedUtf8
}