import React, { Component } from 'react';
import Card from "react-bootstrap/Card";
import { Button } from 'react-bootstrap';
import { history } from '../../utils/history'
import { Route, Redirect } from 'react-router'
import ReactTable from "react-table-v6";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ApiServices from '../../utils/ApiServices';
import Modal from 'react-bootstrap/Modal'
import jsPDF from "jspdf";
import ToggleButton from 'react-toggle-button';
import API from './../../utils/config.json'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import axios from "axios";
import { T } from "react-translator-component";
import { queryFunc1, queryFunc2, queryFunc3, queryFunc4, queryFunc5, queryFunc6 } from './../Favorites/queryFunc'
import { font } from "./../FranklinGothic-normal";
import { fontBold } from "../FranklinGothic-Heavy";
import { fontDemi } from "../FranklinGothic-Demi";
import { fontItalic } from "../FranklinGothic-italic";
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import { HeaderAuth } from './../../utils/Header'
import Util from '../../utils/Util';
import { cleardataManageCrimpsheets, getdataManageCrimpsheets, savedataManageCrimpsheets } from '../../IndexedDB/ManageCrimpDB';
import { UnitConversion } from './UnitConversion'
import { PressureTypes, connectDataContext } from '../common/DataProvider';
import { addPowers } from '../common/stringUtils';

class ManageCrimp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            crimpsheets: [],
            selectedCrimpsheets: [],
            openTable: false,
            openRenamePopup: false,
            crimperSheetName: "",
            crimpsheetid: null,
            selectedDatatoPrint: [],
            Toggle: this.props.unit === PressureTypes.mpa,
            openRemove: false,
            openRemoveRow: false,
            removeSelected: null,
            removeSelectedRow: null,
            invalidCrimpname: "",
            crimpsheetid: null,
            originalFilterDta: [],
            openOrientation: false,
            HideSkiveLength: false,
            HideSkiveDiameter: false,
            Hidecrimplength: false,
            Hidecrimpinsertion: false,
            HIdecrimpTail: false,
            isLoading: false,
            FilteredNotesImage: [],
            selectedDatatoPrint: [],
            enableDownloadSelected: false,
            selectedHeader: null
        };
    }

    componentDidMount() {
        this.getCrimpSheets();
    }
    getCrimpSheets = () => {
        // get all the crimpsheet details
        var email = Util.getCookie("email")
        var url = API.URL + "users/crimpsheets?$format=json&$filter=EMAIL eq '" + email + "'"
        ApiServices.httpGET(url, (err, response) => {
            if (response?.data?.d?.results) {
                var crimpsheets = response.data.d.results;
                this.setState({ crimpsheets })
            };
        });
    }
    getListofCrimpSheets = async (id) => {
        // to get the data inside each crimpsheet
        var url = API.URL + "users/crimpsheets/details?$filter=CRIMPSHEET_ID eq " + id

        const response = await axios.get(url, HeaderAuth);
        if (response?.data?.d?.results) {
            var crimpsheets = response.data.d.results;

            await this.convertionData(crimpsheets, "", "refresh", "");

            // this.setState({ selectedCrimpsheets: response.data.d.results })
        };
    }

    //Deleting data in Crimpsheet Details
    removedatafromCrimp = async (cellInfo) => {
        // Delete data inside the crimpsheet
        var CrimpId = cellInfo;
        const { crimpsheetid } = this.state;

        var url = API.URL + "/users/crimpsheets/details/" + CrimpId + "";
        const response = await axios.delete(url, HeaderAuth);
        if (response) {
            //console.log(response);
            var datares = response;
            if (response.status === 200) {
                this.hide("removeRow")
                this.getListofCrimpSheets(crimpsheetid)
            }
        };
    }


    removeFav = async (removeSelected) => {
        // Deletes the crimp sheets

        var CrimpId = removeSelected;
        //console.log(CrimpId);
        var email = Util.getCookie("email")
        const { selectedCrimpsheets } = this.state;

        //console.log(selectedCrimpsheets);
        // // link to delete crimp sheet

        var link = API.URL + "users/crimpsheets/" + CrimpId;
        //console.log(link);

        ApiServices.httpDELETE(link, (err, response) => {


            if (response) {
                var datares = response;

                //console.log(response);

                if (response.status === 200) {
                    // if crimp sheet has data in else will close the popup
                    if (selectedCrimpsheets.length > 0) {

                        this.Loopdelete()
                    }
                    else {
                        this.hide("remove")
                        this.getCrimpSheets()
                    }
                }
            }
        });
    }

    renderRemoveMultiple = async () => {
        const { selectedDatatoPrint, crimpsheetid } = this.state

        this.setState({ isLoading: true })
        var urlArray = [];
        for (var i = 0; i < selectedDatatoPrint.length; i++) {

            var CrimpId = selectedDatatoPrint[i].CRIMPSHEET_DETAIL_ID
            var link = API.URL + "users/crimpsheets/details/" + CrimpId + "";
            urlArray.push(link)
        }
        await axios.all(urlArray.map((endpoint) => axios.delete(endpoint, HeaderAuth))).then(
            async (data) => {

                if (data[0].status === 200) {
                    this.setState({ isLoading: false, enableDownloadSelected: false, originalFilterDta: [], FilteredNotesImage: [], })
                    await this.getListofCrimpSheets(crimpsheetid)
                }

            }
        )
            .catch(function (error) {
                console.log(error);
            });

    }

    Loopdelete = async () => {

        // Looping the API call to delete multiple rows inside crimpsheet
        const { selectedCrimpsheets } = this.state;

        var urlArray = [];
        for (var i = 0; i < selectedCrimpsheets.length; i++) {
            var link = API.URL + "/users/crimpsheets/details/" + selectedCrimpsheets[i].CRIMPSHEET_DETAIL_ID + "";
            urlArray.push(link)
        }

        let promises = []

        // Pushing promises into urlArray 
        urlArray.forEach((url) => promises.push(axios.delete(url, HeaderAuth)))
        axios.all(promises).then(axios.spread(function (res) {
        }));

        this.hide("remove")
        this.getCrimpSheets();
    }

    ManageCrimp = async (cellInfo, type) => {
        // localStorage.removeItem("finalNotesCrimpsheets")
        // localStorage.removeItem("dataManageCrimpsheets")

        await cleardataManageCrimpsheets()

        if (type === "name") {
            this.setState({ selectedCrimpsheets: [], originalFilterDta: [], FilteredNotesImage: [] })
        }
        var id = cellInfo.original.CRIMPSHEET_ID
        var name = cellInfo.original.NAME
        const { selectedCrimpsheets } = this.state
        var email = Util.getCookie("email")
        var url = API.URL + "users/crimpsheets/details?$filter=CRIMPSHEET_ID eq " + id

        ApiServices.httpGET(url, (err, response) => {
            var data = response.data.d.results
            if (data) {

                this.convertionData(data, cellInfo, type, name);

            };
        });
    }
    renderName = (cellInfo) => {
        return (
            <div>
                <div className='color-red cursor' onClick={() => this.ManageCrimp(cellInfo, "name")} >
                    {cellInfo.original.NAME}
                </div>
            </div>
        )
    }
    renameCrimpSheet = (cellInfo) => {

        var name = cellInfo.original.NAME
        var id = cellInfo.original.CRIMPSHEET_ID
        this.setState({
            crimperSheetName: name,
            crimpsheetid: id,
            openRenamePopup: true
        })
    }
    renderButton = (cellInfo) => {
        var header = cellInfo.column.Header
        var fav = cellInfo.original.FAVORITE_ID
        if (header === "Edit") {
            return (
                <div>
                    <EditIcon className='color-black cursor' onClick={() => this.renameCrimpSheet(cellInfo)} />
                </div>
            )
        }
        if (header === "Print") {
            return (
                <div>
                    <PrintIcon className='color-black cursor' onClick={() => this.ManageCrimp(cellInfo, "print")} />
                </div>
            )
        }
        if (header === "Duplicate") {
            return (
                <div>
                    <ContentCopyIcon className='color-black cursor' />
                </div>
            )
        }
        if (header === "Remove") {
            return (
                <div>
                    <DeleteIcon className='color-black cursor' onClick={() => this.show("remove", cellInfo)} />
                </div>
            )

        }
    }

    renderButtoninCrimpsheet = (cellInfo) => {
        return (
            <div>
                <DeleteIcon className='color-black cursor' onClick={() => this.show("removeRow", cellInfo)} />
            </div>
        )
    }
    show = (type, cellInfo) => {
        // Shows the selected popup
        if (type === "viewsheet") {
            var Id = cellInfo.original.CRIMPSHEET_ID

            this.setState({ openTable: true, crimpsheetid: Id });
        }
        if (type === "edit") {
            this.setState({ openRenamePopup: true });
        }
        if (type === "remove") {
            var Id = cellInfo.original.CRIMPSHEET_ID
            this.getListofCrimpSheets(Id)
            this.setState({ openRemove: true, removeSelected: Id });
        }
        if (type === "removeRow") {
            var Id = cellInfo.original.CRIMPSHEET_DETAIL_ID
            this.setState({ openRemoveRow: true, removeSelectedRow: Id });
        }
        if (type === "orientation") {
            this.setState({ openOrientation: true });
        }

    }
    hide = (type) => {
        // Hides the selected popup
        if (type === "viewsheet") {

            this.setState({ openTable: false, crimpsheetid: null });
        }
        if (type === "edit") {
            this.setState({ openRenamePopup: false });
        }
        if (type === "remove") {
            this.setState({ openRemove: false, removeSelected: null });
        }
        if (type === "removeRow") {
            this.setState({ openRemoveRow: false, removeSelectedRow: null });

        }
        if (type === "orientation") {
            this.setState({ openOrientation: false });
        }

    }

    print = (cellInfo) => {
        var id = cellInfo.original.CRIMPSHEET_ID

    }

    DownloadPDF = (computeTableData, typeoforientation) => {
        // downloads the entire/selected data in table as PDF
        this.setState({ openOrientation: false });

        const { originalFilterDta, Toggle, activeEventKey } = this.state
        const { selectedHeader, HideSkiveDiameter, HideSkiveLength, Hidecrimplength, Hidecrimpinsertion, HIdecrimpTail } = this.state
        if (selectedHeader !== null) {
            var id = selectedHeader[0].id
            if (selectedHeader[0].desc) {
                const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })
                var sortedData = computeTableData.sort((a, b) => collator.compare(b[id], a[id]))
            } else {
                const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })
                var sortedData = computeTableData.sort((a, b) => collator.compare(a[id], b[id]))
            }
        } else {
            var sortedData = computeTableData
        }

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        if (typeoforientation === "landscape") {

            var orientation = typeoforientation; // portrait or landscape

        } else {
            var orientation = typeoforientation; // portrait or landscape

        }
        const doc = new jsPDF(orientation, unit, size);

        let gatesLogo = new Image();
        gatesLogo.src = '/images/Gates-PDF-LOGO.png';

        let eCrimpLogo = new Image();
        eCrimpLogo.src = '/images/Gates-Ecrimp-PDF-LOGO.png';

        let printedOnDate = new Date();
        var marLeft = 0;

        //FranklinGothic- Book
        doc.addFileToVFS('FranklinGothic-normal.ttf', font);
        doc.addFont('FranklinGothic-normal.ttf', 'FranklinGothic', 'normal');

        //FranklinGothic- heavy
        doc.addFileToVFS('FranklinGothic-Bold-bold.ttf', fontBold);
        doc.addFont('FranklinGothic-Bold-bold.ttf', 'FranklinGothic-Bold', 'normal');

        //FranklinGothic- demi

        doc.addFileToVFS('FranklinGothic-normal.ttf', fontDemi);
        doc.addFont('FranklinGothic-normal.ttf', 'FranklinGothic-Demi', 'normal');
        //FranklinGothic- italic

        doc.addFileToVFS('FranklinGothic-Italic-normal.ttf', fontItalic);
        doc.addFont('FranklinGothic-Italic-normal.ttf', 'FranklinGothic-Italic', 'normal');


        var DistinctForCrimpers = [...new Set(sortedData.map(item => item.CRIMPER_ID))];
        for (var i = 0; i < DistinctForCrimpers.length; i++) {
            let finalY = doc.lastAutoTable.finalY || 10;
            const Uniquetable = sortedData.filter((user) => user.CRIMPER_ID === DistinctForCrimpers[i]);

            var lastcrimper = ""

            doc.autoTable({
                //startY: finalY + 120,
                head: headRows(),
                body: bodyRows(),
                //rowPageBreak: 'auto',
                // foot: footRows(),
                showHead: 'everyPage',
                margin: { top: 150, bottom: 100 },
                headStyles: {
                    fillColor: [117, 120, 123],
                    lineColor: [0, 0, 0],
                    lineWidth: 1,
                    //cellWidth: 'auto'
                },
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    lineWidth: 1,
                    fillColor: [255, 255, 255],
                    halign: 'center',
                    valign: 'middle',
                    //cellWidth: 'auto'
                },
                footStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0]
                },
                alternateRowStyles: {
                    fillColor: [200, 201, 199]
                },

                didDrawPage: function (data) {

                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                    let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();


                    doc.addImage(gatesLogo, 'png', data.settings.margin.left, 15, 115, 65, 'Gates');
                    doc.setFontSize(21).setFont("FranklinGothic-Bold");

                    doc.text('Crimp Specifications', pageWidth / 2, 50, 'center');
                    doc.addImage(eCrimpLogo, 'png', pageWidth - 180, 35, 92, 24, 'eCrimp');

                    doc.setFontSize(21).setFont("FranklinGothic-Demi");
                    doc.text(DistinctForCrimpers[i], pageWidth / 2, 100, 'center');

                    doc.setFontSize(15).setFont("FranklinGothic-Demi");
                    doc.text('Printed on ' + printedOnDate.toLocaleDateString(), pageWidth - 215, 100);

                    doc.setFontSize(8).setFont('FranklinGothic-Italic');
                    let footerText = 'Refer to electronic crimp data at ecrimp.gates.com for the current crimp settings. Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All Settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.';
                    let splitFooterText = doc.splitTextToSize(footerText, (pageWidth - data.settings.margin.left - 40));
                    doc.text(splitFooterText, data.settings.margin.left, pageHeight - 60);
                    var copyrightText = '\u00A9' + ' ' + printedOnDate.getFullYear() + ' Gates Corporation All Rights Reserved';
                    doc.text(copyrightText, pageWidth / 2.5, pageHeight - 30);
                    marLeft = data.settings.margin.left
                }
            })

            if (DistinctForCrimpers.length === i + 1) {

                let pageSize = doc.internal.pageSize;
                let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
                if (originalFilterDta.length !== 0) {
                    doc.addPage();
                }
                doc.addImage(gatesLogo, 'png', marLeft, 15, 115, 65, 'Gates');
                doc.setFontSize(21).setFont("FranklinGothic-Bold");

                doc.text('Crimp Specifications', pageWidth / 2, 50, 'center');
                doc.addImage(eCrimpLogo, 'png', pageWidth - 180, 35, 92, 24, 'eCrimp');

                // doc.setFontSize(21).setFont("FranklinGothic-Demi");
                // doc.text(DistinctForCrimpers[i], pageWidth / 2, 100, 'center');

                doc.setFontSize(15).setFont("FranklinGothic-Demi");
                doc.text('Printed on ' + printedOnDate.toLocaleDateString(), pageWidth - 215, 100);

                doc.setFontSize(8).setFont('FranklinGothic-Italic');
                let footerText = 'Refer to electronic crimp data at ecrimp.gates.com for the current crimp settings. Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All Settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.';

                let splitFooterText = doc.splitTextToSize(footerText, (pageWidth - marLeft - 40));
                doc.text(splitFooterText, marLeft, pageHeight - 60);
                var copyrightText = '\u00A9' + ' ' + printedOnDate.getFullYear() + ' Gates Corporation All Rights Reserved';
                doc.text(copyrightText, pageWidth / 2.5, pageHeight - 30);
                if (originalFilterDta.length !== 0) {
                    doc.setFontSize(15).setFont(undefined, 'bold');
                    doc.text('Notes : ', marLeft, 130);
                    for (var j = 0; j < originalFilterDta.length; j++) {
                        doc.setFontSize(12).setFont(undefined, 'Regular');
                        let text = j + 1 + ". " + originalFilterDta[j];
                        let textLine = doc.splitTextToSize(text, (pageWidth - marLeft - 40));
                        if (j !== 0) {
                            doc.text(textLine, marLeft, 150 + (30 * j))

                        }
                        else {
                            doc.text(textLine, marLeft, 150)
                        }
                    }
                }
            } else {
                // if (originalFilterDta.length !== 0) {
                doc.addPage();
                // }

            }



            function headRows() {
                var datahead_English = [
                    "Dash Size", "Description", "Size (in)", "Working Pressure (psi)", "Type", "Description", "Skive Length (in) (+/- 0.03)",
                    "Skive Diameter (in)", "Crimp Insertion Length (in) (+/- 0.03)", "Crimp Length (in) (+/- 0.03)", "Crimp Outside Diameter (in)",
                    "Crimp Outside Diameter Tolerance (+/-)", "Crimp Tail Length (in) (+/- 0.1)", "Die Set", "Approximate Setting"
                ]
                var datahead_Metrics = [
                    "Dash Size", "Description", "Size (mm)", "Working Pressure (MPa)", "Type", "Description", "Skive Length (mm) (+/- 0.03)",
                    "Skive Diameter (mm)", "Crimp Insertion Length (mm) (+/- 0.03)", "Crimp Length (mm) (+/- 0.03)", "Crimp Outside Diameter (mm)",
                    "Crimp Outside Diameter Tolerance (+/-)", "Crimp Tail Length (mm) (+/- 0.1)", "Die Set", "Approximate Setting"
                ]
                var lengthColspan = 0
                if (HideSkiveLength) {
                    datahead_English = datahead_English.filter(s => s !== "Skive Length (in) (+/- 0.03)");
                    datahead_Metrics = datahead_Metrics.filter(s => s !== "Skive Length (mm) (+/- 0.03)");
                    lengthColspan = lengthColspan + 1
                }
                if (HideSkiveDiameter) {
                    datahead_English = datahead_English.filter(s => s !== "Skive Diameter (in)");
                    datahead_Metrics = datahead_Metrics.filter(s => s !== "Skive Diameter (mm)");

                    lengthColspan = lengthColspan + 1

                }
                if (Hidecrimpinsertion) {
                    datahead_English = datahead_English.filter(s => s !== "Crimp Insertion Length (in) (+/- 0.03)");
                    datahead_Metrics = datahead_Metrics.filter(s => s !== "Crimp Insertion Length (mm) (+/- 0.03)");
                    lengthColspan = lengthColspan + 1

                }
                if (Hidecrimplength) {
                    datahead_English = datahead_English.filter(s => s !== "Crimp Length (in) (+/- 0.03)");
                    datahead_Metrics = datahead_Metrics.filter(s => s !== "Crimp Length (mm) (+/- 0.03)");
                    lengthColspan = lengthColspan + 1

                }
                if (HIdecrimpTail) {
                    datahead_English = datahead_English.filter(s => s !== "Crimp Tail Length (in) (+/- 0.1)");
                    datahead_Metrics = datahead_Metrics.filter(s => s !== "Crimp Tail Length (mm) (+/- 0.1)");
                    lengthColspan = lengthColspan + 1

                }

                if (!Toggle) {

                    return [
                        [
                            { content: '', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },

                            { content: 'HOSE', colSpan: 4, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'STEM', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'FERRULE', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'SPECIFICATION', colSpan: 8 - lengthColspan, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        ],

                        // [
                        //     "Dash Size", "Description", "Size (in)", "Working Pressure", "Type", "Description", "Skive Length (in) (+/- 0.03)",
                        //     "Skive Diameter (in)", "Crimp Insertion Length (in) (+/- 0.03)", "Crimp Length (in) (+/- 0.03)", "Crimp Outside Diameter (in)",
                        //     "Crimp Outside Diameter Tolerance (+/-)", "Crimp Tail Length (in) (+/- 0.1)", "Die Set", "Approximate Setting"
                        // ]
                        datahead_English
                    ]
                }
                else {
                    return [
                        [
                            { content: '', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },

                            { content: 'HOSE', colSpan: 4, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'STEM', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'FERRULE', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'SPECIFICATION', colSpan: 8 - lengthColspan, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        ],

                        // [
                        //     "Dash Size", "Description", "Size (mm)", "Working Pressure", "Type", "Description", "Skive Length (mm) (+/- 0.03)",
                        //     "Skive Diameter (mm)", "Crimp Insertion Length (mm) (+/- 0.03)", "Crimp Length (mm) (+/- 0.03)", "Crimp Outside Diameter (mm)",
                        //     "Crimp Outside Diameter Tolerance (+/-)", "Crimp Tail Length (mm) (+/- 0.1)", "Die Set", "Approximate Setting"
                        // ]
                        datahead_Metrics
                    ]
                }
            }


            function bodyRows() {
                var databody_English = Uniquetable.map(elt =>
                    [
                        elt.DASH_SIZE,
                        addPowers(elt.HOSE_DISPLAY_NAME, elt.NOTES_INDEXES?.HOSE_DISPLAY_NAME || []),
                        elt.SIZE_INENGLISH,
                        elt.WORKING_PRESSURE_INENGLISH,
                        addPowers(elt.STEM_TYPE, elt.NOTES_INDEXES?.STEM_TYPE || []),
                        elt.FERRULE_DETAIL,
                        elt.SKIVE_LENGTH_DISPLAY_INENGLISH,
                        elt.SKIVE_DIAMETER_DISPLAY_INENGLISH,
                        elt.INSERTION_LENGTH_DISPLAY_INENGLISH,
                        elt.LENGTH_DISPLAY_INENGLISH,
                        elt.OUTSIDE_DIAMETER_DISPLAY_INENGLISH,
                        elt.OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH,
                        elt.TAIL_LENGTH_INENGLISH,
                        addPowers(elt.DIE_ID, elt.NOTES_INDEXES?.DIE_ID || []),
                        elt.APPROXIMATE_DIGITAL_READOUT
                    ]);


                var databody_Metrics = Uniquetable.map(elt =>
                    [
                        elt.DASH_SIZE,
                        addPowers(elt.HOSE_DISPLAY_NAME, elt.NOTES_INDEXES?.HOSE_DISPLAY_NAME || []),
                        elt.SIZE_INMETRICS,
                        elt.WORKING_PRESSURE_INMETRICS,
                        addPowers(elt.STEM_TYPE, elt.NOTES_INDEXES?.STEM_TYPE || []),
                        elt.FERRULE_DETAIL,
                        elt.SKIVE_LENGTH_DISPLAY_INMETRICS,
                        elt.SKIVE_DIAMETER_DISPLAY_INMETRICS,
                        elt.INSERTION_LENGTH_DISPLAY_INMETRICS,
                        elt.LENGTH_DISPLAY_INMETRICS,
                        elt.OUTSIDE_DIAMETER_DISPLAY_INMETRICS,
                        elt.OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS,
                        elt.TAIL_LENGTH_INMETRICS,
                        addPowers(elt.DIE_ID, elt.NOTES_INDEXES?.DIE_ID || []),
                        elt.APPROXIMATE_DIGITAL_READOUT
                    ]);

                // var databody = JSON.parse(JSON.stringify(databody))

                for (var k = 0; k < databody_English.length; k++) {
                    if (HideSkiveLength) {
                        //    var test = databody[i].splice(6, 1)
                        // databody = databody[i].filter(s => indexOf(s) !== 6);
                        // databody[i] = databody[i].filter((_, index) => index !== -5);

                        databody_English[k].splice(databody_English[k].length - 8 - 1, 1);
                        databody_Metrics[k].splice(databody_Metrics[k].length - 8 - 1, 1);

                    }
                    if (HideSkiveDiameter) {
                        // var test =  databody[i].splice(7, 1)
                        // databody[i] = databody[i].filter((_, index) => index !== -4);
                        databody_English[k].splice(databody_English[k].length - 7 - 1, 1);
                        databody_Metrics[k].splice(databody_Metrics[k].length - 7 - 1, 1);

                    }
                    if (Hidecrimpinsertion) {
                        // var test = databody_English[i].splice(8, 1)
                        // databody_English[i] = databody_English[i].filter((_, index) => index !== -3);
                        databody_English[k].splice(databody_English[k].length - 6 - 1, 1);
                        databody_Metrics[k].splice(databody_Metrics[k].length - 6 - 1, 1);

                    }
                    if (Hidecrimplength) {
                        // var test = databody_English[i].splice(9, 1)
                        // databody_English[i] = databody_English[i].filter((_, index) => index !== -2);
                        databody_English[k].splice(databody_English[k].length - 5 - 1, 1);
                        databody_Metrics[k].splice(databody_Metrics[k].length - 5 - 1, 1);


                    }
                    if (HIdecrimpTail) {
                        // var test =  databody_English[i].splice(12, 1)
                        // databody_English[i] = databody_English[i].filter((_, index) => index !== -1);
                        databody_English[k].splice(databody_English[k].length - 2 - 1, 1);
                        databody_Metrics[k].splice(databody_Metrics[k].length - 2 - 1, 1);


                    }
                }
                if (!Toggle) {


                    return databody_English;
                } else {


                    return databody_Metrics;
                }
            }
        }
        doc.save("report.pdf");

    }

    handleCrimpName = (e) => {
        // Stores the crimp sheet name 
        this.setState({ crimperSheetName: e.target.value })
    }
    updateCrimpsheets = () => {
        //  Create crimp sheets 
        const { crimpsheets, crimperSheetName, crimpsheetid } = this.state;
        var id = crimpsheetid
        var name = crimperSheetName
        var email = Util.getCookie("email")

        this.setState({ invalidCrimpname: "" })

        if (crimperSheetName.trimStart().trimEnd() === "") {
            this.setState({ invalidCrimpname: "Name cannot be empty" })
            return false
        }

        var data = {

            "NAME": crimperSheetName
        }
        var url = API.URL + 'users/crimpsheets/' + id;
        ApiServices.httpPATCH(url, data, (err, response) => {
            if (response) {


                var datares = response;

                if (response.status === 200) {
                    this.getCrimpSheets();
                    this.setState({ openRenamePopup: false, crimperSheetName: "", crimpsheetid: null })
                }
            };
        });
    }

    changeToggle = (e) => {
        this.setState({ Toggle: !e })
    }

    convertionData = async (data, cellInfo, type, name) => {
        if (data !== null && data !== undefined && Array.isArray(data)) {

            var tableData = data;
            var mm = 25.4; // 1 inch = 25.4 mm
            var mpa = 145.038 // 1 psi = 145.038 MPa
            var ConvertedTableData = UnitConversion(tableData)
            /* for (var i = 0; i < crimpsheets.length; i++) {
                 crimpsheets[i].HOSE_DISPLAY_NAME_NOTES = ""
                 if (crimpsheets[i].APPROXIMATE_DIGITAL_READOUT.match(/[a-z]/i)) {
                     crimpsheets[i].APPROXIMATE_DIGITAL_READOUT = crimpsheets[i].APPROXIMATE_DIGITAL_READOUT
                 }
                 else {
                     crimpsheets[i].APPROXIMATE_DIGITAL_READOUT = Number(crimpsheets[i].APPROXIMATE_DIGITAL_READOUT).toFixed(2)
 
                 }
                 //SIZE
                 crimpsheets[i].SIZE_INENGLISH = Number(crimpsheets[i].SIZE).toFixed(2)
                 //crimpsheets[i].SIZE_INMETRICS = Number(crimpsheets[i].SIZE * mm).toFixed(2)
                 // This is the Hose Size whhc should be based on Dash size as the Hana table hose_size_SI_Conv for Metric units
                 crimpsheets[i].SIZE_INMETRICS = DashSizeInMetrics(crimpsheets[i].DASH_SIZE,crimpsheets[i].SIZE)
 
                 // WORKING_PRESSURE
                 crimpsheets[i].WORKING_PRESSURE_INENGLISH = crimpsheets[i].WORKING_PRESSURE
                 crimpsheets[i].WORKING_PRESSURE_INMETRICS = Math.round(crimpsheets[i].WORKING_PRESSURE / mpa).toFixed(1)
 
                 // SKIVE_LENGTH_DISPLAY
                 if (crimpsheets[i].SKIVE_LENGTH_DISPLAY === "0.000") {
 
                     crimpsheets[i].SKIVE_LENGTH_DISPLAY_INENGLISH = "NONE"
                     crimpsheets[i].SKIVE_LENGTH_DISPLAY_INMETRICS = "NONE"
                 }
                 else if (crimpsheets[i].SKIVE_LENGTH_DISPLAY === null) {
                     crimpsheets[i].SKIVE_LENGTH_DISPLAY_INENGLISH = "null"
                     crimpsheets[i].SKIVE_LENGTH_DISPLAY_INMETRICS = "null"
                 }
                 else if (crimpsheets[i].SKIVE_LENGTH_DISPLAY.match(/[a-z]/i)) {
                     crimpsheets[i].SKIVE_LENGTH_DISPLAY_INENGLISH = crimpsheets[i].SKIVE_LENGTH_DISPLAY
                     crimpsheets[i].SKIVE_LENGTH_DISPLAY_INMETRICS = crimpsheets[i].SKIVE_LENGTH_DISPLAY
                 }
                 else {
                     // crimpsheets[i].SKIVE_LENGTH_DISPLAY = (crimpsheets[i].SKIVE_LENGTH_DISPLAY).toFixed(2)
                     crimpsheets[i].SKIVE_LENGTH_DISPLAY_INENGLISH = Number(crimpsheets[i].SKIVE_LENGTH_DISPLAY).toFixed(2)
                     crimpsheets[i].SKIVE_LENGTH_DISPLAY_INMETRICS = Number(crimpsheets[i].SKIVE_LENGTH_DISPLAY * mm).toFixed(2)
 
                 }
 
                 // SKIVE_DIAMETER_DISPLAY
 
                 if (crimpsheets[i].SKIVE_DIAMETER_DISPLAY === "0.000") {
 
                     crimpsheets[i].SKIVE_DIAMETER_DISPLAY_INENGLISH = "NONE"
                     crimpsheets[i].SKIVE_DIAMETER_DISPLAY_INMETRICS = "NONE"
 
                 }
                 else if (crimpsheets[i].SKIVE_DIAMETER_DISPLAY === null) {
 
                     crimpsheets[i].SKIVE_DIAMETER_DISPLAY_INENGLISH = "null"
                     crimpsheets[i].SKIVE_DIAMETER_DISPLAY_INMETRICS = "null"
 
                 }
                 else if (crimpsheets[i].SKIVE_DIAMETER_DISPLAY.match(/[a-z]/i)) {
                     crimpsheets[i].SKIVE_DIAMETER_DISPLAY_INENGLISH = crimpsheets[i].SKIVE_DIAMETER_DISPLAY
                     crimpsheets[i].SKIVE_DIAMETER_DISPLAY_INMETRICS = crimpsheets[i].SKIVE_DIAMETER_DISPLAY
                 }
                 else {
                     // crimpsheets[i].SKIVE_DIAMETER_DISPLAY = (crimpsheets[i].SKIVE_DIAMETER_DISPLAY).toFixed(2)
                     crimpsheets[i].SKIVE_DIAMETER_DISPLAY_INENGLISH = Number(crimpsheets[i].SKIVE_DIAMETER_DISPLAY).toFixed(2)
 
                     crimpsheets[i].SKIVE_DIAMETER_DISPLAY_INMETRICS = Number(crimpsheets[i].SKIVE_DIAMETER_DISPLAY * mm).toFixed(2)
 
                 }
                 // CRIMP INSERTION LENGTH
                 if (crimpsheets[i].INSERTION_LENGTH_DISPLAY === "0.000") {
 
                     crimpsheets[i].INSERTION_LENGTH_DISPLAY_INENGLISH = "NONE"
                     crimpsheets[i].INSERTION_LENGTH_DISPLAY_INMETRICS = "NONE"
 
                 }
                 else if (crimpsheets[i].INSERTION_LENGTH_DISPLAY === null) {
 
                     crimpsheets[i].INSERTION_LENGTH_DISPLAY_INENGLISH = "null"
                     crimpsheets[i].INSERTION_LENGTH_DISPLAY_INMETRICS = "null"
 
                 }
                 else if (crimpsheets[i].INSERTION_LENGTH_DISPLAY.match(/[a-z]/i)) {
                     crimpsheets[i].INSERTION_LENGTH_DISPLAY_INENGLISH = crimpsheets[i].INSERTION_LENGTH_DISPLAY
                     crimpsheets[i].INSERTION_LENGTH_DISPLAY_INMETRICS = crimpsheets[i].INSERTION_LENGTH_DISPLAY
                 }
                 else {
                     // crimpsheets[i].INSERTION_LENGTH_DISPLAY = (crimpsheets[i].INSERTION_LENGTH_DISPLAY).toFixed(2)
                     crimpsheets[i].INSERTION_LENGTH_DISPLAY_INENGLISH = Number(crimpsheets[i].INSERTION_LENGTH_DISPLAY).toFixed(2)
                     crimpsheets[i].INSERTION_LENGTH_DISPLAY_INMETRICS = Number(crimpsheets[i].INSERTION_LENGTH_DISPLAY * mm).toFixed(2)
 
                 }
 
                 // CRIMP LENGTH
                 if (crimpsheets[i].LENGTH_DISPLAY === "0.000") {
 
                     crimpsheets[i].LENGTH_DISPLAY_INENGLISH = "NONE"
                     crimpsheets[i].LENGTH_DISPLAY_INMETRICS = "NONE"
 
                 }
                 else if (crimpsheets[i].LENGTH_DISPLAY === null) {
 
                     crimpsheets[i].LENGTH_DISPLAY_INENGLISH = "null"
                     crimpsheets[i].LENGTH_DISPLAY_INMETRICS = "null"
 
                 }
                 else if (crimpsheets[i].LENGTH_DISPLAY.match(/[a-z]/i)) {
                     crimpsheets[i].LENGTH_DISPLAY_INENGLISH = crimpsheets[i].LENGTH_DISPLAY
                     crimpsheets[i].LENGTH_DISPLAY_INMETRICS = crimpsheets[i].LENGTH_DISPLAY
                 }
                 else {
                     // crimpsheets[i].LENGTH_DISPLAY = (crimpsheets[i].LENGTH_DISPLAY).toFixed(2)
                     crimpsheets[i].LENGTH_DISPLAY_INENGLISH = Number(crimpsheets[i].LENGTH_DISPLAY).toFixed(2)
                     crimpsheets[i].LENGTH_DISPLAY_INMETRICS = Number(crimpsheets[i].LENGTH_DISPLAY * mm).toFixed(2)
 
                 }
 
                 // OUTSIDE_DIAMETER_DISPLAY
 
                 if (crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY === "0.000") {
 
                     crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY_INENGLISH = "NONE"
                     crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY_INMETRICS = "NONE"
 
                 }
                 else if (crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY === null) {
 
                     crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY_INENGLISH = "null"
                     crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY_INMETRICS = "null"
 
                 }
                 else if (crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY.match(/[a-z]/i)) {
                     crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY_INENGLISH = crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY
                     crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY_INMETRICS = crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY
                 }
                 else {
 
                     // crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY = Number(crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY).toFixed(2)
                     crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY_INENGLISH = Number(crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY).toFixed(3)
                     //crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY_INMETRICS = Number(crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY * mm).toFixed(2)
                     crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY_INMETRICS = Number(Math.round((Number(crimpsheets[i].OUTSIDE_DIAMETER_DISPLAY * mm))/0.05)*0.05).toFixed(2);
 
 
                 }
                 //OUTSIDE_DIAMETER_TOLERANCE_DISPLAY
 
                 if (crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY === "0.000") {
 
                     crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH = "NONE"
                     crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS = "NONE"
 
                 }
                 else if (crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY === null) {
 
                     crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH = "null"
                     crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS = "null"
 
                 } else if (crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY.match(/[a-z]/i)) {
                     crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH = crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY
                     crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS = crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY
                 }
                 else {
                     // crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY = (crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY).toFixed(2)
                     crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH = Number(crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY).toFixed(3)
                     crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS = Number(crimpsheets[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY * mm).toFixed(2)
 
                 }
                 //TAIL_LENGTH
 
                 if (crimpsheets[i].TAIL_LENGTH === "0") {
 
                     crimpsheets[i].TAIL_LENGTH_INENGLISH = "NONE"
                     crimpsheets[i].TAIL_LENGTH_INMETRICS = "NONE"
 
                 }
                 else if (crimpsheets[i].TAIL_LENGTH === null) {
 
                     crimpsheets[i].TAIL_LENGTH_INENGLISH = "null"
                     crimpsheets[i].TAIL_LENGTH_INMETRICS = "null"
 
                 } else if (crimpsheets[i].TAIL_LENGTH.match(/[a-z]/i)) {
                     crimpsheets[i].TAIL_LENGTH_INENGLISH = crimpsheets[i].TAIL_LENGTH
                     crimpsheets[i].TAIL_LENGTH_INMETRICS = crimpsheets[i].TAIL_LENGTH
                 }
                 else {
                     // crimpsheets[i].TAIL_LENGTH = (crimpsheets[i].TAIL_LENGTH).toFixed(2)
                     crimpsheets[i].TAIL_LENGTH_INENGLISH = Number(crimpsheets[i].TAIL_LENGTH).toFixed(2)
                     crimpsheets[i].TAIL_LENGTH_INMETRICS = Number(crimpsheets[i].TAIL_LENGTH * mm).toFixed(2)
 
                 }
             }
             var ConvertedTableData = crimpsheets*/
            var hideSkive = 0
            var hideSkiveDia = 0
            var hidecrimpins = 0
            var hidecrimplen = 0
            var hidecrimptail = 0
            for (var i = 0; i < ConvertedTableData.length; i++) {
                if (ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH === "NONE" || ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH === "FULL" || ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH === "null" || ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH === "N/A") {
                    hideSkive = hideSkive + 1
                }
                if (ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH === "NONE" || ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH === "null" || ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH === "FULL" || ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH === "N/A") {
                    hideSkiveDia = hideSkiveDia + 1
                }
                if (ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH === "NONE" || ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH === "null" || ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH === "FULL" || ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH === "N/A") {
                    hidecrimplen = hidecrimplen + 1
                }
                if (ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH === "NONE" || ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH === "null" || ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH === "FULL" || ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH === "N/A") {
                    hidecrimpins = hidecrimpins + 1
                }
                if (ConvertedTableData[i].TAIL_LENGTH_INENGLISH === "NONE" || ConvertedTableData[i].TAIL_LENGTH_INENGLISH === "null" || ConvertedTableData[i].TAIL_LENGTH_INENGLISH === "FULL" || ConvertedTableData[i].TAIL_LENGTH_INENGLISH === "N/A") {
                    hidecrimptail = hidecrimptail + 1
                }
            }
            if (hideSkive === ConvertedTableData.length) {
                for (var i = 0; i < ConvertedTableData.length; i++) {

                    delete ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH
                    delete ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INMETRICS

                }
                this.setState({ HideSkiveLength: true })
            }
            if (hideSkiveDia === ConvertedTableData.length) {

                for (var i = 0; i < ConvertedTableData.length; i++) {

                    delete ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH
                    delete ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INMETRICS

                }
                this.setState({ HideSkiveDiameter: true })

            }
            if (hidecrimplen === ConvertedTableData.length) {

                for (var i = 0; i < ConvertedTableData.length; i++) {

                    delete ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH
                    delete ConvertedTableData[i].LENGTH_DISPLAY_INMETRICS
                }
                this.setState({ Hidecrimplength: true })

            }

            if (hidecrimpins === ConvertedTableData.length) {

                for (var i = 0; i < ConvertedTableData.length; i++) {

                    delete ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH
                    delete ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INMETRICS
                }
                this.setState({ Hidecrimpinsertion: true })

            }

            if (hidecrimptail === ConvertedTableData.length) {

                for (var i = 0; i < ConvertedTableData.length; i++) {

                    delete ConvertedTableData[i].TAIL_LENGTH_INENGLISH
                    delete ConvertedTableData[i].TAIL_LENGTH_INMETRICS
                }
                this.setState({ HIdecrimpTail: true })

            }

            // localStorage.setItem("dataManageCrimpsheets", JSON.stringify(data))
            await savedataManageCrimpsheets(data)

            if (type === "name") {
                var Id = cellInfo.original.CRIMPSHEET_ID
                this.getNotesData()

                this.setState({ crimperSheetName: name, openTable: true, crimpsheetid: Id })
            }
            if (type === "refresh") {
                this.setState({ selectedCrimpsheets: data })
                this.getNotesData()
            }
            if (type === "print") {
                this.getNotesData("print")

            }
        };
    }


    getNotesData = async (type) => {
        this.setState({ isLoading: true })
        // this is the data in the table

        // var dataFavorites = JSON.parse(localStorage.getItem("dataManageCrimpsheets"))
        var dataFavorites = await getdataManageCrimpsheets();
        // getting crimper list

        var crimper = dataFavorites.map(item => item.CRIMPER_ID)
            .filter((value, index, self) => self.indexOf(value) === index)


        var urlArray = [];
        // looping through each crimper

        for (var i = 0; i < crimper.length; i++) {

            const currentCrimper = crimper[i]

            const data = dataFavorites.filter(fav => fav.CRIMPER_ID === currentCrimper)
            //getting description 
            const DESCRIPTION = data.map(item => item.HOSE_DISPLAY_NAME)
                .filter((value, index, self) => self.indexOf(value) === index)
            const queryDescription = [...DESCRIPTION, 'Any'].map(value => `DESCRIPTION eq '${value}'`).join(' or ')
            //getting die set 
            
            const DIE_ID = data.map(item => item.DIE_ID)
            .filter((value, index, self) => self.indexOf(value) === index)
            const queryDieId = [...DIE_ID, 'Any'].map(value => `DIE_ID eq '${value}'`).join(' or ')


            //getting Dash Size  

            const DASH_SIZE = data.map(item => item.DASH_SIZE)
                .filter((value, index, self) => self.indexOf(value) === index)
            const queryDashSize = [...DASH_SIZE, 'Any'].map(value => {
                if(value === 'Any') return `STEM_DASH_SIZE eq '${value}'`
                return `STEM_DASH_SIZE eq '${String(value).padStart(2, '0')}' or STEM_DASH_SIZE eq '${parseInt(value)}'`
            }).join(' or ')
                
                //getting STEM_TYPE
                // 
                
            const STEM_TYPE = data.map(item => item.STEM_TYPE)
            .filter((value, index, self) => self.indexOf(value) === index)
            const queryStemType = [...STEM_TYPE, 'Any'].map(value => `STEM_TYPE eq '${value}'`).join(' or ')

            const allQueries = [queryDescription, queryDieId, queryDashSize, queryStemType].map(e => `(${e})`).join(' and ')

            const urlNotes = `${API.URL}notes-v2?$filter=(CRIMPER_ID eq '${currentCrimper}' or CRIMPER_ID eq 'Any') and ${allQueries}&$format=json`
            console.log(urlNotes)
            urlArray.push(urlNotes)

        }

        var fetchedResult = []
        await axios.all(urlArray.map((endpoint) => axios.get(endpoint, HeaderAuth))).then(function (response) {
            // localStorage.setItem("finalNotesCrimpsheets", JSON.stringify(response))
            fetchedResult = response
        }).catch(function (error) {
            console.log(error);
        });
        let NotesImage = []

        for (var i = 0; i < fetchedResult.length; i++) {
            var getImage = fetchedResult[i].data.d.results
            for (var j = 0; j < getImage.length; j++) {

                if (getImage[j].IMAGE_URL !== null) {
                    NotesImage.push(getImage[j])
                }
            }
        }
        var FinalfilteredNotesImage = []
        const filteredNotesImage = [...new Set(NotesImage.map(item => item.IMAGE_URL))];
        for (var i = 0; i < filteredNotesImage.length; i++) {
            var splits = filteredNotesImage[i].split(";");
            for (var ij = 0; ij < splits.length; ij++) {

                FinalfilteredNotesImage.push(splits[ij])
            }
        }
        // var fetchedResult = JSON.parse(localStorage.getItem("finalNotesCrimpsheets"))

        this.setState({ crimperList: crimper, DataofFilteredCrimpers: fetchedResult, FilteredNotesImage: FinalfilteredNotesImage })

        var finalResult = []
        // organizing the crimpers and their notes data

        for (var i = 0; i < crimper.length; i++) {
            var res = JSON.parse(JSON.stringify(fetchedResult[i].data.d.results))
            var messageFilter = [...new Set(res.map(item => item.MESSAGE))];
            var data = {
                "CRIMPER": crimper[i],
                "data": messageFilter
            }
            finalResult.push(data)
        }

        var listOfnotes = []
        for (var i = 0; i < finalResult.length; i++) {
            if (finalResult[i].data.length >= 1 && finalResult[i].data[0] !== "") {
                listOfnotes.push(finalResult[i].data)
            }

        }
        // combing all the messages

        var listofMessages = [];
        for (var i = 0; i < listOfnotes.length; i++) {
            listofMessages = listofMessages.concat(listOfnotes[i]);
        }

        var ConvertedTableData = dataFavorites

        let uniqueMessages = [...new Set(listofMessages)];

        //console.log("url", urlArray)

        this.setState({ originalFilterDta: uniqueMessages })

        // comparing the Filtered Notes API data and table data
        var lastMessage = ""
        var getMessage = ""
        var dataNotes = []

        for (var ij = 0; ij < fetchedResult.length; ij++) {
            dataNotes.push(fetchedResult[ij].data.d.results)

        }

        const combinedNotes = dataNotes.flat();

        const notesdata = combinedNotes.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === combinedNotes.findIndex(obj => {
                return JSON.stringify(obj) === _value;
            });
        });

        const Any = 'Any'
        if (notesdata.length !== 0) {
            for (var i = 0; i < notesdata.length; i++) {
                for (var j = 0; j < ConvertedTableData.length; j++) {
                     // Note data
                     const noteData = notesdata[i]
                     const noteCrimper = String(noteData.CRIMPER_ID)
                     const noteDescription = String(noteData.DESCRIPTION)
                     const noteStemType = String(noteData.STEM_TYPE)
                     const _dashSize = noteData.STEM_DASH_SIZE
                     const noteDashSize = _dashSize === Any ? _dashSize : Number(_dashSize)
                     const noteDieId = String(noteData.DIE_ID)

                     const message = noteData.MESSAGE
                     const Index = uniqueMessages.indexOf(message) + 1
                     
                     // Table data
                     const convertedData = ConvertedTableData[j];
                     const convertedCrimper = String(convertedData.CRIMPER_ID)
                     const convertedDescription = String(convertedData.HOSE_DISPLAY_NAME)
                     const convertedStemType = String(convertedData.STEM_TYPE)
                     const convertedDashSize = Number(convertedData.DASH_SIZE)
                     const convertedDieId = String(convertedData.DIE_ID)
                     const hasNotesIndexes = 'NOTES_INDEXES' in ConvertedTableData[j]
                     if(!hasNotesIndexes) ConvertedTableData[j].NOTES_INDEXES = {}
                     const crimperMatches = noteCrimper === convertedCrimper && ((noteDescription === convertedDescription || noteDescription === Any) || ((noteStemType === convertedStemType || noteStemType === Any) && (noteDashSize === convertedDashSize || noteDashSize === Any)) && (noteDieId === convertedDieId || noteDieId === Any))
                     const descriptionMatches = noteDescription === convertedDescription && ((noteStemType === convertedStemType || noteStemType === Any) && (noteDashSize === convertedDashSize || noteDashSize === Any)) && (noteDieId === convertedDieId || noteDieId === Any)
                     const stemTypeMatches = (noteStemType === convertedStemType && noteDashSize === convertedDashSize) && (noteDescription === Any || noteDescription === convertedDescription) && (noteDieId === Any || noteDieId === convertedDieId)
                     const dieIdMatches = String(noteDieId) === String(convertedDieId) && ((noteDescription === convertedDescription || noteDescription === Any) && ((noteStemType === convertedStemType || noteStemType === Any) && (noteDashSize === convertedDashSize || noteDashSize === Any)) && (noteCrimper === convertedCrimper || noteCrimper === Any))
                     if(crimperMatches) {
                         ConvertedTableData[j].NOTES_INDEXES.CRIMPER_ID = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.CRIMPER_ID || []), Index])]
                     } 
                     if(descriptionMatches) {
                         ConvertedTableData[j].NOTES_INDEXES.HOSE_DISPLAY_NAME = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.HOSE_DISPLAY_NAME || []), Index])] 
                     } 
                     if(stemTypeMatches) {
                         ConvertedTableData[j].NOTES_INDEXES.COUPLING_ID = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.COUPLING_ID || []), Index])]
                         ConvertedTableData[j].NOTES_INDEXES.STEM_TYPE = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.STEM_TYPE || []), Index])]
                     }
                     if(dieIdMatches) {
                         ConvertedTableData[j].NOTES_INDEXES.DIE_ID = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.DIE_ID || []), Index])]
                     }

                }

            }

        }

        ConvertedTableData.sort(function (a, b) {
            var textA = a.HOSE_DISPLAY_NAME;
            var textB = b.HOSE_DISPLAY_NAME;
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        this.setState({ selectedCrimpsheets: ConvertedTableData })
        //console.log("ConvertedTableData", ConvertedTableData)

        if (type === "print") {
            this.selectOrientation(ConvertedTableData)
        }
        this.setState({ isLoading: false })


    }

    displayNotesCrimper = (cellInfo) => {
        const { originalFilterDta } = this.state
        const FinalData = cellInfo.original.NOTES_INDEXES?.CRIMPER_ID || []
        
        return (
            <div > 
                {cellInfo.original.CRIMPER_ID}
                {FinalData.map((value) => <span className="notes-font cursor" title={originalFilterDta[value - 1]}>{value}</span>)}
            </div>
        )
    }
    displayNotesHose = (cellInfo) => {
        const { originalFilterDta, tableData } = this.state
        const FinalData = cellInfo.original.NOTES_INDEXES?.HOSE_DISPLAY_NAME || []
        
        return (
            <div > 
                {cellInfo.original.HOSE_DISPLAY_NAME}
                {FinalData.map((value) => <span className="notes-font cursor" title={originalFilterDta[value - 1]}>{value}</span>)}
            </div>
        )
    }
    displayNotesCoupling = (cellInfo) => {
        const { originalFilterDta } = this.state

        // var str = cellInfo.original.STEM_TYPE;
        // var find = str.includes('[');
        // var numbers = []
        // var FinalData = []

        // var COUPLING_ID = str
        // if (find) {
        //     // numbers = str.match(/(?<=\[)[^\][]*(?=])/g);
        //     var regex = /\[([^\][]*)]/g;
        //     var results = [], m;
        //     while (m = regex.exec(str)) {
        //         numbers.push(m[1]);
        //     }
        //     var text = COUPLING_ID.replace(/ *\[[^\]]*]/g, '');
        //     COUPLING_ID = text.replace(/ +/g, "");
        //     for (var i = 0; i < numbers.length; i++) {
        //         var index = Number(numbers[i]) - 1
        //         var data = {
        //             index: numbers[i],
        //             message: originalFilterDta[index]
        //         }
        //         FinalData.push(data)

        //     }
        // }
        const data = cellInfo.original.NOTES_INDEXES?.STEM_TYPE || []

        return (
            <div >
                {`${cellInfo.original.STEM_TYPE}`}
                {data.map((value) => <span className="notes-font cursor" title={originalFilterDta[value - 1]}>{value}</span>)}
            </div>
        )
    }
    displayNotesDie = (cellInfo) => {
        const { originalFilterDta } = this.state

        // var str = cellInfo.original.DIE_ID;
        // var find = str.includes('[');
        // var numbers = []
        // var FinalData = []

        // var DIE_ID = str
        // if (find) {
        //     // numbers = str.match(/(?<=\[)[^\][]*(?=])/g);
        //     var regex = /\[([^\][]*)]/g;
        //     var results = [], m;
        //     while (m = regex.exec(str)) {
        //         numbers.push(m[1]);
        //     }
        //     var text = DIE_ID.split(']', 2);
        //     DIE_ID = text[1].trimStart()
        //     for (var i = 0; i < numbers.length; i++) {
        //         var index = Number(numbers[i]) - 1
        //         var data = {
        //             index: numbers[i],
        //             message: originalFilterDta[index]
        //         }
        //         FinalData.push(data)

        //     }
        // }

        const data = cellInfo.original.NOTES_INDEXES?.DIE_ID || []

        return (
            <div > 
                {cellInfo.original.DIE_ID}
                {data.map((value) => <span className="notes-font cursor" title={originalFilterDta[value - 1]}>{value}</span>)}
            </div>
        )
    }

    selectOrientation = (data) => {

        const { HideSkiveDiameter, HideSkiveLength, Hidecrimplength, Hidecrimpinsertion, HIdecrimpTail, selectedCrimpsheets } = this.state
        this.setState({ openOrientation: false });

        if (HideSkiveDiameter && HideSkiveLength && Hidecrimplength && Hidecrimpinsertion && HIdecrimpTail) {
            this.setState({ openOrientation: true });

        } else {
            this.DownloadPDF(data, "landscape")


        }
    }


    renderCheckboxAll = (cellInfo) => {
        // renders the checkbox in the table

        return (
            <div className="center-checkbox cursor">
                <label>Select<br /> All</label>
                <br />
                <input type="checkbox" onClick={(e) => this.selecCheckBox(e, cellInfo, "All")} />
            </div>
        )
    }

    renderCheckbox = (cellInfo) => {
        // renders the checkbox in the table
        console.log(cellInfo);
        return (
            <div className="center-checkbox cursor">
                <input type="checkbox" checked={cellInfo.original.isChecked} onClick={(e) => this.selecCheckBox(e, cellInfo, "one")}
                />
            </div>
        )
    }

    selecCheckBox = (e, cellInfo, type) => {

        // gets the selected row details and storing it in an array
        const { selectedDatatoPrint, selectedCrimpsheets } = this.state
        var selectedrow = selectedDatatoPrint
        var checked = e.target.checked
        var data = [];
        if (type === "All") {
            data = selectedCrimpsheets

            if (checked) {
                for (var i = 0; i < data.length; i++) {
                    data[i].isChecked = true
                    selectedrow.push(data[i])

                }
            } else {
                for (var i = 0; i < data.length; i++) {
                    data[i].isChecked = false


                }
                var filtered = data.filter(s => s.isChecked);
                selectedrow = filtered

            }
        }

        if (type === "one") {
            var Id = cellInfo.original.Id;
            var data = cellInfo.original;

            if (checked) {
                data["isChecked"] = checked
                selectedrow.push(data)
            }
            else {
                data["isChecked"] = checked
                var filtered = selectedrow.filter(s => s.Id !== Id);
                selectedrow = filtered
            }
        }
        if (selectedrow.length > 0) {
            this.setState({ enableDownloadSelected: true })
        } else {
            this.setState({ enableDownloadSelected: false })

        }

        this.setState({ selectedDatatoPrint: selectedrow })

    }

    sortTableData(sortColumn, desc) {
        const { selectedCrimpsheets } = this.state
        selectedCrimpsheets.sort((a, b) => {
            const valueA = a[sortColumn]
            const valueB = b[sortColumn]
            if (typeof valueA === 'string') {
                const isNumber = !isNaN(Number(valueA))
                return desc ? valueA.localeCompare(valueB, undefined, {
                    numeric: isNumber,
                }) : valueB.localeCompare(valueA, undefined, {
                    numeric: isNumber,
                })
            }
            return desc ? valueA - valueB : valueB - valueA
        })
        this.setState({
            selectedCrimpsheets: selectedCrimpsheets
        })

    }

    render() {
        const { isAuthenticated } = this.props.auth;
        const { isLoading, openOrientation, crimpsheets, selectedCrimpsheets, openTable, openRenamePopup, crimperSheetName, Toggle, openRemove, removeSelected, openRemoveRow, removeSelectedRow, invalidCrimpname, originalFilterDta, FilteredNotesImage } = this.state;
        const data = [
            { NAME: "Crimper", CREATED_DATE: "02-05-2022 04:00", LAST_MODIFIED: "02-05-2022 05:00" }
        ]
        const { crimpsheetid, enableDownloadSelected, HideSkiveDiameter, HideSkiveLength, Hidecrimplength, Hidecrimpinsertion, HIdecrimpTail } = this.state
        return (
            <div className="section-container">
                <div className={(isLoading ? 'show' : 'hide') + ' partial-loader'} id="loading">
                    <div className="loading-message"></div>
                </div>
                <a href="/Dashboard" className="color-black">
                    <ArrowBackIos className="backmenu cursor" />
                    <label className="cursor">{T("Back to Dashboard")}</label>

                </a>
                <br />
                <br />
                <div className='displayflex'>

                    <div className='redline text-Uppercase'>
                        <b><h4>{T("Manage Crimp Sheets")} </h4></b>


                    </div>
                </div>

                <div className="managecrimp-table-div">
                    <ReactTable
                        className="fullwidth-table hidepagination"
                        data={crimpsheets}
                        columns={[
                            {
                                Header: 'Edit',
                                Cell: this.renderButton
                            },
                            {
                                Header: 'Name',
                                Cell: this.renderName
                            },
                            {
                                Header: 'Created Date',
                                accessor: 'DATE_CREATED',
                                show: false

                            },
                            {
                                Header: "Last Modified",
                                accessor: "DATE_UPDATED",
                                show: false

                            },
                            {
                                Header: 'Print',
                                Cell: this.renderButton
                            },
                            {
                                Header: 'Duplicate',
                                Cell: this.renderButton,
                                show: false

                            },
                            {
                                Header: 'Remove',
                                Cell: this.renderButton
                            }
                        ]}
                        defaultPageSize={50}
                        minRows={5}
                        showPageSizeOptions={true}
                        sortable={false}
                    />
                    <br />
                    <div className="">
                        <h6> <b>{T("DISCLAIMER")} : </b>{T("Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.")}</h6>
                    </div>
                </div>

                <Modal
                    show={openTable}
                    onHide={() => this.hide("viewsheet")}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header >
                        <Modal.Title id="example-custom-modal-styling-title">
                            <div className="underlinestyle">{crimperSheetName}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div >
                            {enableDownloadSelected ? <button className="delete-selected-btn color-white" onClick={() => this.renderRemoveMultiple(crimpsheetid)}>{T("Remove Selected")}</button> : ""}
                            <div className="records displayflex">

                                <div className="Uom displayflex margin-left-70">
                                    <span className="margin-left-1">{T('Show English Units')}</span>
                                    <div className="margin-left-1">
                                        <ToggleButton
                                            inactiveLabel={''}
                                            activeLabel={''}
                                            colors={{
                                                activeThumb: {
                                                    base: 'rgb(0 0 0)',
                                                },
                                                inactiveThumb: {
                                                    base: 'rgb(0 0 0)',
                                                },
                                                active: {
                                                    base: 'rgb(193 33 38)',
                                                    hover: 'rgb(193 33 38)',
                                                },
                                                inactive: {
                                                    base: 'rgb(193 33 38)',
                                                    hover: 'rgb(193 33 38)',
                                                }
                                            }} value={Toggle} onToggle={(value) => { this.changeToggle(value); }} />
                                    </div>
                                    <span className="margin-left-1"> {T('Show Metric Units')} </span>
                                </div>
                            </div>
                            <ReactTable
                                manual
                                onSortedChange={(newSorted, column, shiftKey) => {
                                    this.setState({
                                        selectedHeader: newSorted
                                    })
                                    this.sortTableData(newSorted[0].id, newSorted[0].desc)
                                }}
                                data={selectedCrimpsheets}
                                columns={[
                                    {

                                        columns: [
                                            {
                                                Header: selectedCrimpsheets.length > 0 ? this.renderCheckboxAll : "",
                                                Cell: this.renderCheckbox,
                                                maxWidth: 60,
                                                show: isAuthenticated
                                            }
                                        ]
                                    },
                                    {
                                        columns: [
                                            {
                                                Header: 'Remove',
                                                Cell: this.renderButtoninCrimpsheet,
                                                minWidth: 70

                                            }
                                        ]
                                    },
                                    {
                                        Header: 'Crimper',
                                        accessor: 'CRIMPER_ID',
                                        minWidth: 100

                                    },
                                    {
                                        Header: 'Hose',
                                        columns: [
                                            {
                                                Header: <div>Dash <br />Size</div>,
                                                accessor: 'DASH_SIZE',
                                                minWidth: 50

                                            },
                                            {
                                                Header: 'Description',
                                                accessor: 'HOSE_DISPLAY_NAME',
                                                Cell: this.displayNotesHose,
                                                minWidth: 150
                                            },
                                            {
                                                Header: <div>Size <br />{!Toggle ? '(in)' : '(mm)'}</div>,
                                                accessor: !Toggle ? 'SIZE_INENGLISH' : 'SIZE_INMETRICS',
                                                minWidth: 50
                                            },
                                            {
                                                Header: <div>Working <br />Pressure<br /> {!Toggle ? '(psi)' : '(MPa)'}</div>,
                                                accessor: !Toggle ? 'WORKING_PRESSURE_INENGLISH' : 'WORKING_PRESSURE_INMETRICS',
                                                minWidth: 80

                                            },
                                        ]
                                    },
                                    {
                                        Header: 'Stem',
                                        // headerClassName: 'PercentWidth100 large-font-header center-align',
                                        // className: 'PercentWidth100 large-font text-align-left',
                                        columns: [
                                            {
                                                Header: "Type",
                                                accessor: "STEM_TYPE",
                                                Cell: this.displayNotesCoupling,
                                                minWidth: 80

                                                // headerClassName: 'PercentWidth100  center-align',
                                                // className: 'PercentWidth100 large-font-header text-align-left',
                                            },

                                        ]

                                    },
                                    {
                                        Header: 'Ferrule',
                                        columns: [
                                            {
                                                Header: "Description",
                                                accessor: "FERRULE_DETAIL",
                                                minWidth: 80
                                            },

                                        ]
                                    },
                                    {
                                        Header: 'Specifications',
                                        columns: [
                                            {
                                                Header: <div>Skive <br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.03)' : '(+/- 0.75)'}</div>,
                                                accessor: !Toggle ? "SKIVE_LENGTH_DISPLAY_INENGLISH" : "SKIVE_LENGTH_DISPLAY_INMETRICS",
                                                minWidth: 80,
                                                show: !HideSkiveLength

                                            },
                                            {
                                                Header: <div>Skive <br />Diameter<br />{!Toggle ? '(in)' : '(mm)'}</div>,
                                                accessor: !Toggle ? "SKIVE_DIAMETER_DISPLAY_INENGLISH" : "SKIVE_DIAMETER_DISPLAY_INMETRICS",
                                                minWidth: 80,
                                                show: !HideSkiveDiameter

                                            },
                                            {
                                                Header: <div>Crimp<br />Insertion <br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.03)' : '(+/- 0.75)'}</div>,
                                                accessor: !Toggle ? "INSERTION_LENGTH_DISPLAY_INENGLISH" : "INSERTION_LENGTH_DISPLAY_INMETRICS",
                                                minWidth: 80,
                                                show: !Hidecrimpinsertion

                                            },
                                            {
                                                Header: <div>Crimp <br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.03)' : '(+/- 0.75)'}</div>,
                                                accessor: !Toggle ? "LENGTH_DISPLAY_INENGLISH" : "LENGTH_DISPLAY_INMETRICS",
                                                minWidth: 80,
                                                show: !Hidecrimplength

                                            },
                                            {
                                                Header: <div>Crimp<br />Outside<br />Diameter<br />{!Toggle ? '(in)' : '(mm)'}</div>,
                                                accessor: !Toggle ? "OUTSIDE_DIAMETER_DISPLAY_INENGLISH" : "OUTSIDE_DIAMETER_DISPLAY_INMETRICS",
                                                minWidth: 80

                                            },
                                            {
                                                Header: <div>Crimp<br />Outside<br />Diameter<br /> Tolerance<br />(+/-)</div>,
                                                accessor: !Toggle ? "OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH" : "OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS",
                                                minWidth: 80


                                            },
                                            {
                                                Header: <div>Crimp<br />Tail<br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.1)' : '(+/- 2.5)'}</div>,
                                                accessor: !Toggle ? "TAIL_LENGTH_INENGLISH" : "TAIL_LENGTH_INMETRICS",
                                                minWidth: 80,
                                                show: !HIdecrimpTail

                                            },
                                            {
                                                Header: "Die Set",
                                                accessor: "DIE_ID",
                                                Cell: this.displayNotesDie,
                                                minWidth: 80,
                                                sortMethod: (a, b) => {
                                                    a = Number(a);
                                                    b = Number(b);
                                                    if (a.length === b.length) {
                                                        return a > b ? 1 : -1;
                                                    }
                                                    return a.length > b.length ? 1 : -1;
                                                }

                                            },
                                            {
                                                Header: <div>Approximate<br /> Setting</div>,
                                                accessor: "APPROXIMATE_DIGITAL_READOUT",
                                                minWidth: 85,
                                                sortMethod: (a, b) => {
                                                    a = Number(a);
                                                    b = Number(b);
                                                    if (a.length === b.length) {
                                                        return a > b ? 1 : -1;
                                                    }
                                                    return a.length > b.length ? 1 : -1;
                                                }

                                            },
                                        ]
                                    }
                                ]}
                                defaultPageSize={50}
                                minRows={5}
                                showPageSizeOptions={true}
                            />
                            <div className="margin-top-3" id="notesdata">
                                {originalFilterDta.length !== 0 ? <ol>
                                    {originalFilterDta ? originalFilterDta.map((value, index) =>
                                        <li className="margin-bottom-1">{value}</li>)
                                        : ""}
                                </ol> : ""}
                            </div>
                            <div className="margin-top-3" id="notesImage">
                                {FilteredNotesImage ? FilteredNotesImage.map((value, index) =>
                                    <img className="margin-bottom-1" src={value} />)
                                    : ""}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.hide("viewsheet")}>{T('Close')}</button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={openRenamePopup}
                    onHide={() => this.hide("edit")}
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="example-custom-modal-styling-title">
                            <div className="underlinestyle">{T("Rename Crimp Sheet")}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="form-group top-margn" style={{ marginLeft: "1%", padding: "5%" }}>
                                <label >Name <span className="colon"></span></label>
                                <input type="text" id="txt_name" placeholder="Enter Crimp sheet name" className="input-box-add" value={crimperSheetName ? crimperSheetName : ""} onChange={(e) => this.handleCrimpName(e)} />
                                <p className="text-danger">{invalidCrimpname}</p>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.hide("edit")}>{T("Close")}</button>
                        <button className="apply-btn-modal" type="button" onClick={() => this.updateCrimpsheets()}>{T("Save")}</button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={openRemove}
                    onHide={openRemove}
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="example-custom-modal-styling-title">
                            <div className="underlinestyle">{T("Delete Confirmation")}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {T("Do you want to delete this Crimp sheet")}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.hide("remove")}>{T("Cancel")}</button>
                        <button className="apply-btn-modal" type="button" onClick={() => this.removeFav(removeSelected)}>{T("Yes")}</button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={openRemoveRow}
                    onHide={openRemoveRow}
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="example-custom-modal-styling-title">
                            <div className="underlinestyle">{T("Delete Confirmation")}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {T("Do you want to delete this record")}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.hide("removeRow")}>{T("No")}</button>
                        <button className="apply-btn-modal" type="button" onClick={() => this.removedatafromCrimp(removeSelectedRow)}>{T("Yes")}</button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={openOrientation}
                    onHide={openOrientation}
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="example-custom-modal-styling-title">
                            <div className="underlinestyle">{T("Confirmation")}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4 className="textalign-center">{T('Please select the orientation')} </h4>
                        <div id="centered">
                            <div className="textalign-center select-options margin-5 cursor" onClick={() => this.DownloadPDF(selectedCrimpsheets, "portrait")}> <CropPortraitIcon className="color-black iconsize-45" /><label >PORTRAIT</label></div>
                            <div className="textalign-center select-options margin-5 cursor" onClick={() => this.DownloadPDF(selectedCrimpsheets, "landscape")}> <CropLandscapeIcon className="color-black iconsize-45" /><label >LANDSCAPE</label></div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.hide("orientation")}>{T("Cancel")}</button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
ManageCrimp.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connectDataContext(
    connect(mapStateToProps, {})(
        ManageCrimp
    )
);

