import { REGION_SELECTION } from "../actions/types";
import Util from './../utils/Util'
let rgn = Util.getCookie("region");
let country = Util.getCookie("country");
let unit = Util.getCookie("unit");

if (rgn === null || rgn === undefined) {
  document.cookie = 'region=NA'
  rgn = 'NA'
}

if (country === null || country === undefined) {
  document.cookie = 'country=US'
  country = 'US'
}

if (unit === null || unit === undefined) {
  document.cookie = 'unit=I'
  unit = 'I'
}

// let rgn = localStorage.getItem("region");
// let country = localStorage.getItem("country");
// let unit = localStorage.getItem("unit");

const initialState = {
  region: rgn,
  country: country,
  flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/" + country + ".svg",
  unit: unit
}

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGION_SELECTION:
      return {
        ...state,
        region: payload.region,
        country: payload.country,
        flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/" + payload.country + ".svg",
        unit: payload.unit
      };
    default:
      return state;
  }
}