import React, { useState, useContext } from 'react';
import "react-toastify/dist/ReactToastify.css";
import ApiServices from '../../../utils/ApiServices';
import { AuthContext } from './../../auth-hook/auth-context';
import { } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import './../authStyles.css'
import { Decryption } from '../../../utils/SecurePassword'
import API from './../../../utils/config.json'
import { T } from 'react-translator-component'
import Util from './../../../utils/Util';



export default ({location, history}) => {
    const [open, setOpen] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    // const { authenticate } = useContext(AccountContext);
    const auth = useContext(AuthContext);
    const getDetails = async result => {

        const date = "/Date(" + Date.now() + ")/";

        {/*var data = {
                "FIRST_NAME": result.FIRST_NAME,
                "LAST_NAME": result.LAST_NAME,
                "COMPANY": result.COMPANY,
                "PASSWORD": result.PASSWORD,
                "NEWSLETTER": result.NEWSLETTER,
                "COUNTRY_NAME": result.COUNTRY_NAME,
                "POLICY": result.POLICY,
                "POLICY_ACCEPT_DATE": result.POLICY_ACCEPT_DATE,
                "LAST_LOGGED_IN": date,
                "CREATED_DATE": result.CREATED_DATE,
                "LAST_MODIFIED": result.LAST_MODIFIED
            }*/}

        var data = {
            "LAST_LOGGED_IN": date
        }
        //var url = API.URL+ `users('` + email + `')`;

        var url = API.URL + 'users/' + email;

        await ApiServices.httpPATCH(url, data, (err, response) => {
            if (response) {
                var data = response;
                if (response.status === 200) {
                }
            };
        });
    }


    const onSubmit = async event => {
        setError("");
        const url = API.URL + "users?$format=json&$filter=EMAIL eq '" + email + "'";
        await ApiServices.httpGET(url, async (err, response) => {
            if (response?.data?.d?.results) {
                let result = response.data.d.results;
                if (result.length !== 0) {

                    var Entereduserpassword = password;
                    var encryptedCP = result[0].PASSWORD;
                    // Password Decryption
                    var decryptedUtf8 = Decryption(encryptedCP)

                    if (decryptedUtf8 === Entereduserpassword) {
                        var digital_data = {
                           firstname: result[0].FIRST_NAME,
                           lastname: result[0].LAST_NAME,
                           company: result[0].COMPANY,
                           email: result[0].EMAIL,
                           country: result[0].COUNTRY_NAME,
                           createdDate: result[0].CREATED_DATE,
                        }
                        var stringify = JSON.stringify(digital_data)
                        await auth.login(result[0].EMAIL, result[0].FIRST_NAME);
                        Util.setCookie("digital_data",stringify)
                        localStorage.setItem('userData', stringify)
                        await getDetails(result[0])
                        setMessage("SUCCESS")
                        let next = '/'
                        if(location.search){
                            const search = new URLSearchParams(location.search)
                            const redirectTo = search.get('redirectTo')
                            if(redirectTo) {
                                next = redirectTo
                            }
                        }
                        history.replace(next)
                    }
                    else {
                        setError("INCORRECT PASSWORD")
                        console.error('INCORRECT password', err);
                    }

                }
                else {
                    setError("INCORRECT CREDENTIALS")
                    console.error('Failed to login!', err);
                }
            }
        })
    };

    const changeemail = (event) => {
        setEmail(event.target.value.toLowerCase());
    }

    const changepassword = (event) => {
        setPassword(event.target.value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }

    return (
        <div className="section-container ">
            <Modal
                show={open}
                onHide={open}
                dialogClassName="modal-40w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header >
                    <Modal.Title id="example-custom-modal-styling-title" className='custom-title'>

                        <div className='header-padding'>
                            <h2 className='underlinestyle'>{T("LOGIN")}</h2>

                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='sigin-container'>
                        <form onSubmit={(e) => e.preventDefault()} id="log-frm">

                            <div className="form-group  " style={{ marginLeft: "1%" }}>
                                <label className=" col-form-label"><b>{T("Email Address")} </b></label>
                                <div className="" >
                                    <input type="text" id="txt_name" className="form-control text-box-border" value={email} onChange={changeemail} onKeyPress={handleKeyPress} />
                                </div>
                            </div>
                            <div className="form-group  " style={{ marginLeft: "1%" }}>
                                <label className=" col-form-label"><b>{T("Password")}</b> </label>
                                <div className="" >
                                    <input type="password" id="txt_name" className="form-control text-box-border" value={password} onChange={changepassword} onKeyPress={handleKeyPress} />
                                </div>
                            </div>
                            <label className="forgot "><a className='text-red ' href="/Forgotpassword">{T("FORGOT PASSWORD")}</a></label>
                            <p className="text-danger text-center">{error}</p>
                            <p className="text-info text-center">{message}</p>

                            <br />
                            <div className="form-group text-center " style={{ marginLeft: "1%" }}>

                                <button className="change-btn " type="button" onClick={onSubmit} >{T("Login")}</button>
                            </div>
                            <div className="form-group text-center">
                                <label className="signup">{T("Don't have an account")} <a className='text-red' href={`/Registration${location.search}`}><b>{T("SIGN UP")}</b></a></label>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="cancel-btn-modal" type="button" onClick={() => history.replace("/")}>{T("Close")}</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

