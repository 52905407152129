import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import ApiServices from '../../../utils/ApiServices';
import API from '../../../utils/config.json';
import './../authStyles.css'
import { T } from "react-translator-component";

class ManageForgot extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            open: true,
            email: "",
            message: ""
        };
    }

    show = (type) => {
        // Shows the selected popup
        this.setState({ open: true });
    }

    hide = (type) => {
        // Hides the selected popup
        this.setState({ open: false });
    }

    changeemail = (event) => {
        this.setState({ email: event.target.value });
    }

    redirect = (Topage) => {
        var hostname = window.location.hostname;
        var page = Topage;
        var goto = page
        window.location = goto
    }

    onSubmit = () => {
        var checkUserUrl = API.URL + "users?$filter=EMAIL eq '" + this.state.email + "'";
        ApiServices.httpGET(checkUserUrl, (err, response) => {
            if (response?.data?.d?.results[0].EMAIL === this.state.email) {
                var url = API.URL + "users/forgot-password?$format=json&emailid=" + this.state.email;
                ApiServices.httpGET(url, (err, response) => {
                    if ((response?.data).includes("250 Ok")) {
                        this.setState({ message: T("ForgotPassword.EmailInstructions") });
                    }
                    else {
                        this.setState({ message: T("ForgotPassword.TechnicalDifficulty") });
                    }
                })
            }
            else {
                this.setState({ message: T("ForgotPassword.NoAccount") });
            }
        })
    }

    render() {

        const { firstname, lastname, company, country, email, password, agreemnt, termsofservice, privacy } = this.state;

        const { open } = this.state;

        return (
            <div className="section-container ">
                <Modal
                    show={open}
                    onHide={open}
                    dialogClassName="modal-40w"
                    aria-labelledby="example-custom-modal-styling-title"
                >

                    <Modal.Header >
                        <Modal.Title id="example-custom-modal-styling-title" className='custom-title'>
                            <div className='header-padding'>
                                <h2 className='underlinestyle'>{T("FORGOT PASSWORD")}</h2>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className=''>
                            <div className='sigin-container'>
                                <p>{T('Enter the email address you used to sign up with below, and we\'ll send a reset link.')}</p>
                                <div className="form-group  " style={{ marginLeft: "1%" }}>
                                    <label className=" col-form-label"><b>{T('Email Address')}</b></label>
                                    <div className="" >
                                        <input type="text" id="txt_name" className="form-control text-box-border" placeholder={T('Email Address')} value={email} onChange={this.changeemail} />
                                    </div>
                                </div>
                                <br />
                                <div className="form-group text-center " style={{ marginLeft: "1%" }}>
                                    <button className="change-btn " type="button" onClick={() => this.onSubmit()} >{T('SEND')}</button>
                                </div>
                                <div className="form-group text-center " style={{ marginLeft: "1%" }}>
                                    <span className='text-red'>{this.state.message}</span>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.redirect("Signin")}>{T("Back")}</button>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.redirect("/")}>{T('Close')}</button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}


export default ManageForgot;


