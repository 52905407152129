export const GET_ERRORS = "GET_ERRORS";
export const LOADING = "LOADING";
export const RESET_FORM = "RESET_FORM";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const REGION_SELECTION = "REGION_SELECTION";
export const LANGUAGE_SELECTION = "LANGUAGE_SELECTION";
export const DIGITAL_DATA = "DIGITAL_DATA";

/* Dashboard */

/* End of Dashboard */

