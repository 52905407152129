import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { clearUserdata, getuserDataFromIndexDB, saveUserdataInIndexDB } from '../../IndexedDB/Utility';
import Util from '../../utils/Util';

export const AuthContext = createContext();

let logoutTimer;

export const AuthProvider = ({children}) => {
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);

  const login = useCallback(async (uid, token, expirationDate) => {
    setToken(token);
    setUserId(uid);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpirationDate(tokenExpirationDate);

    document.cookie = "accessToken=" + uid+";max-age="+(3600*24*7)
    document.cookie = "idToken=" + token+";max-age="+(3600*24*7)
    document.cookie = "refreshToken=" + expirationDate+";max-age="+(3600*24*7)
    document.cookie = "email=" + uid+";max-age="+(3600*24*7)

    // localStorage.setItem("accessToken", uid);
    // localStorage.setItem("idToken", token);
    // localStorage.setItem("refreshToken", expirationDate);
    // localStorage.setItem("email", uid);
    // localStorage.setItem(
    //   'userData',
    //   JSON.stringify({
    //     userId: uid,
    //     token: token,
    //     expiration: tokenExpirationDate.toISOString()
    //   })
    // );
    var userdata = {
      userId: uid,
      token: token,
      expiration: tokenExpirationDate.toISOString()
    }

    await saveUserdataInIndexDB(userdata)
  }, []);

  const logout = useCallback(async () => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);

    // localStorage.removeItem('userData');
    await clearUserdata()
    Util.clearCookies()
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const setup = async () => {
      const storedData = await getuserDataFromIndexDB();
      if (
        storedData &&
        storedData.token &&
        new Date(storedData.expiration) > new Date()
      ) {
        await login(storedData.userId, storedData.token, new Date(storedData.expiration));
      }
      setInitialLoaded(true)
    }

    setup()
  }, [login]);

  const authState = useMemo(() => ({
    isLoggedIn: !!token,
    initialLoaded,
    token,
    userId,
    login,
    logout
  }), [userId, token, initialLoaded])

  return (
    <AuthContext.Provider value={authState}>
      {children}
    </AuthContext.Provider>
  )
}
