import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import API from './../../utils/config.json';
import ApiServices from '../../utils/ApiServices';
import ReactTable from "react-table-v6";
import Observer from '../dashboard/Observer';
import { connectDataContext } from './DataProvider';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { T } from 'react-translator-component';


class Dataversion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            open: false,
            VersionData: []
        }
    }


    componentDidMount() {
        const { region } = this.state;
        if (region === "NA" || region === "EU") {
            this.getRegions();
        }
    }

    getRegions = () => {
        const { region } = this.props;
        if (region !== "NA" && region !== "EU") return
        if (region === "NA") {
            var url = API.DevURL + "versions?$orderby=UPDATE_TIME desc";
        }
        if (region === "EU") {
            var url = API.DevURL + "versions/eu?$orderby=UPDATE_TIME desc";
        }

        ApiServices.httpGET(url, (err, response) => {
            if (response?.data?.d?.results) {
                var data = response.data.d.results;
                for (var i = 0; i < data.length; i++) {
                    var str = data[i].UPDATE_TIME
                    var num = parseInt(str.replace(/[^0-9]/g, ""));
                    var date = new Date(num).toISOString().split('T')[0];
                    data[i].UPDATE_TIME = date
                }
                this.setState({ VersionData: data });
            };
        })
    }



    render() {

        const { isAuthenticated } = this.props.auth;
        const { VersionData } = this.state;
        var version="";
        if(this.props.region === "EU")
        {
             version="ID"
        }
        else
        {
             version="VERSION_ID"
        }

        return (
            <>
                <Observer observable={this.props.region} onChange={this.getRegions} />
                <div className='dataversion-tbl'>
                <div onClick={() => this.props.history.goBack()}>
                    <ArrowBackIos className="backmenu cursor" />
                    <label className="cursor">{T("Back to Dashboard")}</label>

                </div>
                <br />
                <br />
                    <ReactTable
                        className="fullwidth-table hidepagination"
                        data={VersionData}
                        columns={[
                            {
                                width: 90,
                                Header: 'VERSION',
                                accessor: version,
                                style: { textAlign: "left" }
                            },

                            {
                                Header: 'ADDED COMPONENTS',
                                accessor: 'ADDED_COMPONENTS',
                                style: { 'white-space': 'unset', textAlign: "left" }
                            },
                            {
                                Header: 'CHANGED COMPONENTS',
                                accessor: 'CHANGED_COMPONENTS',
                                style: { 'white-space': 'unset', textAlign: "left" }
                            },
                            {
                                width: 230,
                                Header: 'NOTES',
                                accessor: 'NOTES',
                                style: { 'white-space': 'unset', textAlign: "left" }
                            },

                            {
                                width: 190,
                                Header: 'UPDATE TIME',
                                accessor: 'UPDATE_TIME',
                                style: {  textAlign: "left" }
                            },

                        ]}
                        defaultPageSize={500}
                        minRows={5}
                        showPageSizeOptions={false}
                    />
                </div>
            </>
        );
    }
}

Dataversion.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    flag: state.region.flag
});

export default connectDataContext(
    connect(mapStateToProps, {})(
        Dataversion
    )
);
