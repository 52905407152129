import appDB from './indexedDB'
const db = appDB.db
// dataFavorites
export async function savedataFavorites(data) {
  if (data) {
    if (db.dataFavorites) {
      await db.dataFavorites.clear()
      await db.dataFavorites.add({ dataFavorites: 'dataFavorites', data })
    }
  }
}

export async function getdataFavorites() {
  const testData = await db.dataFavorites.toArray()
  if (testData && testData.length > 0) {
    return testData[0].data
  }
  return []
}

export async function cleardataFavorites() {
  await db.dataFavorites.clear()
}
