module.exports = {
  "FERRULE DESCRIPTION": "Descripción de férula",
  "Find a crimper and associated information by selecting a hose type": "Encuentra una crimpadora y las especificaciones de crimpado asociadas seleccionando un tipo de manguera.",
  "Region": "Región",
  "Language": "Idioma",
  "LOGIN": "ACCESO",
  "Common.USIGNUP": "REGÍSTRATE",
  "No": "No",
  "MyAccount": "MI CUENTA",
  "Click to view Specifications": "Haga clic para ver las especificaciones",
  "Confirmation": "Confirmación",
  "Delete Confirmation": "Confirmación de eliminación",
  "Do you want to delete this record": "¿Quieres borrar este registro?",
  "Units": "Units",
  "Add New Crimp Sheet": "Agregar nueva hoja de crimpado",
  "Please check your email for password reset instructions.": "Revise su correo electrónico para obtener instrucciones para restablecer la contraseña",
  "We are experiencing a technical difficulty. Please try again after sometime.": "Estamos experimentando una dificultad técnica. Vuelva a intentarlo después de un tiempo",
  "No account associated with the email. Please use SIGN UP to register to the site.": "No hay cuenta asociada con el correo electrónico. Utilice REGISTRARSE para registrarse en el sitio",
  "Email and token verification failed.": "Error en la verificación de correo electrónico y token.",
  "The new password and confirm password should match.": "La nueva contraseña y la contraseña confirmada deben coincidir",
  "Password was successfully updated.": "La contraseña se actualizó con éxito.",
  "We are experiencing a technical difficulty. Please try again later.": "Estamos experimentando una dificultad técnica. Vuelva a intentarlo más tarde",
  "Please enter values for new password and confirm password.": "Por favor, introduzca los valores de la nueva contraseña y confirme la contraseña.",
  "Knowledge Center": "Centro de Conocimiento",
  "Click on a link below to view the desired Gates eCrimp document.": "Haga clic en un enlace a continuación para ver el documento Gates eCrimp deseado.",
  "Do you want to delete this Crimp sheet": "¿Desea eliminar esta hoja Crimp?",
  "Dashboard": "Panel de control",
  "FAVORITES": "FAVORITOS",
  "SAVED CRIMP SHEETS": "HOJAS DE CRIMPADO GUARDADAS",
  "Crimp Sheets": "Hojas de Crimpado",
  "KNOWLEDGE CENTER": "CENTRO DE CONOCIMIENTO",
  "LOGIN / SIGN-UP": "ACCESO/REGÍSTRATE",
  "FIND CRIMP SPECS": "ENCUENTRA LAS ESPECIFICACIONES DE CRIMPADO",
  "What Do You Want To Do?": "¿Qué quieres hacer?",
  "Select Your Crimper": "SELECCIONA TU CRIMPADORA",
  "CHOOSE YOUR REGION OR LANGUAGE": "ELIGE TU REGIÓN O IDIOMA",
  "Email Address": "Correo electrónico",
  "Password": "Contraseña",
  "FORGOT PASSWORD": "¿Olvidaste tu contraseña?",
  "Logout": "Cerrar sesión",
  "Login": "Ingresar",
  "Common.LOGIN": "ACCESO",
  "Common.Signup": "REGÍSTRATE",
  "Common.SIGNUP": "REGÍSTRATE",
  "SIGN UP": "REGÍSTRATE",
  "Enter the email address you used to sign up with below, and we'll send a reset link.": "Ingresa el correo electrónico con el que te registrate y te enviaremos un enlace para restablecerla.",
  "Don't have an account": "No tengo una cuenta",
  "Close": "Cerrar",
  "First Name": "Primer nombre",
  "Last Name": "Apellido",
  "Company": "Compañía",
  "Country": "País",
  "Apply": "Aplicar",
  "Save": "Guardar",
  "Cancel": "Cancelar",
  "Name": "Nombre",
  "Reset": "Reiniciar",
  "Send": "Enviar",
  "Yes": "Sí",
  "Do you wan't to delete": "Confirmación de eliminación",
  "SELECT YOUR CRIMPER": "SELECCIONA TU CRIMPADORA",
  "Field Attachable": "Conexiones reusables",
  "DISCLAIMER": "DESCARGO DE RESPONSABILIDAD",
  "Save favorites to your eCrimp account to save time retrieving data on your most used specifications.": "Guarda favoritos en tu cuenta eCrimp para ahorrar tiempo recuperando datos sobre tus especificaciones más utilizadas.",
  "Save crimp sheet to your eCrimp account. You can view anytime or print a copy.": "Guarda hojas de crimpado en tu cuenta de eCrimp. Puedes verlas en cualquier momento o imprimir una copia.",
  "Find tutorials, product catalogs, training resources and more.": "Encuentra tutoriales, catálogos, recursos de capacitación y más.",
  "Set up a free account to access helpful site functions, such as saving favorites and creating Crimp Sheet.": "Configura una cuenta gratuita para acceder a funciones útiles del sitio, como guardar productos y especificaciones favoritas o crear hojas de crimpado.",
  "Manage Crimp Sheets": "Administrar hojas de crimpado",
  "Rename Crimp Sheet": "CAMBIAR EL NOMBRE DE LA HOJA DE CRIMP",
  "Welcome to the NEW eCrimp website.  It has all of the functionality of the previous eCrimp website, plus much more!\nPlease set up a user account, which will allow you to use new features and capabilities, such as selecting and keeping favorites, and saving and printing spec sheets.": "Bienvenido al NUEVO sitio web eCrimp. Tiene todas las funciones del sitio web eCrimp anterior y mucho más!\nConfigura una cuenta de usuario, ésta te permiterá usar nuevas funciones como seleccionar y mantener favoritos, y guardar e imprimir hojas de especificaciones.",
  "WELCOME": "Bienvenido",
  "Terms of Use": "Términos de uso",
  "Privacy Policy": "Política de privacidad",
  "Favorites": "Favoritos",
  "Save the crimp specifications you use most often for quick access and ease of use.": "Guarda las especificaciones de crimpado que utilizas con más frecuencia para un acceso rápido y fácil.",
  "Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.": "Las especificaciones de crimpado están sujetas a cambios. Para asegurar un engaste exitoso, actualice sus Favoritos con frecuencia utilizando la opción de actualización O.",
  "Do you want to remove from Favorites": "¿Quieres eliminar de Favoritos?",
  "Download Selected": "Descargar PDF seleccionado",
  "View All Crimp Sheets": "VER TODAS LAS HOJAS DE CRIMPADO",
  "Add to Crimp Sheet": "AÑADIR A HOJAS DE CRIMPADO",
  "Create New Crimp Sheet": "Crear nueva hoja de crimpado",
  "Show Metric Units": "Mostrar unidades métricas",
  "Show English Units": "Mostrar unidades inglesas",
  "Total records found": "Registros totales encontrados",
  "Gates.com": "Gates.com.mx",
  "HOSES": "MANGUERAS",
  "Hose Description": "Descripción de manguera",
  "Hydraulic": "Hidráulica",
  "Insdutrial": "Industrial",
  "Stem Type": "Tipo de conexión",
  "Search": "Buscar",
  "ACCESORIES": "ACCESORIOS",
  "LifeGuard": "LifeGuard",
  "Nylon": "Nylon",
  "Hose Dash Size": "Diámetro de la manguera",
  "Stem type": "Tipo de conexión",
  "Ferrule Description": "Descripción de férula",
  "Applied Filters": "Filtros Aplicados",
  "Hoses Filter": "Filtro de mangueras",
  "Dies": "Dados",
  "Pressure": "Presión",
  "Ferrule Detail": "Detalle de férula",
  "Accesories Filter": "Filtro de accesorios",
  "Download PDF": "Descargar PDF",
  "Create a New Crimp Sheet": "Crear nueva hoja de crimpado",
  "Enter Crimp sheet name": "Ingresar nombre de hoja de crimpado",
  "Select all": "Seleccionar todos",
  "Favorite": "Favorito",
  "Hose": "Manguera",
  "Dash Size": "Diámetro",
  "Size": "Tamaño",
  "Working Pressure": "Presión de trabajo",
  "Stem": "Espiga",
  "Type": "Tipo",
  "Ferrule": "Férula",
  "Description": "Descripción",
  "Specifications": "Especificaciones",
  "Skive Length": "Longitud de pelado",
  "Skive Diameter": "Longitud de pelado",
  "Crimp insertion Length": "Longitud de inserción de crimpado",
  "Crimp Length": "Longitud de crimpado",
  "Crimp Outside Diameter": "Diámetro exterior de crimpado",
  "Crimp Outsite Diameter Tolerance": "Tolerancia del diámetro de crimpado",
  "Crimp Tail Length": "Longitud del faldón del crimpado",
  "Die Set": "Juego de dados",
  "Approximate Setting": "Ajuste aproximado ",
  "Previous": "Anterior",
  "Next": "Siguiente",
  "Back to Dashboard": "Volver al panel",
  "MANAGE CRIMP SHEETS": "ADMINISTRAR HOJAS DE CRIMPADO",
  "Edit": "Editar",
  "Print": "Imprimir",
  "Remove": "Eliminar",
  "DISCLAIMER: Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed": "Aviso: los datos de crimpado a veces pueden cambiar. Las especificaciones de crimpado no están crontroladas y deben reimprimirse cada 6 meses o cada vez que se publique una nueva versión de eCrimp. Todos los ajustes son APROXIMADOS. Siempre verifique el diámetro exterior del crimpado final para asegurar que se haya hecho correctamente. ",
  "Buff required only if hose OD prohibits insertion.": "Se requiere pulido solo si el diámetro exterior prohibe la inserción.",
  "When using GC-32XD dies (32-XX) in the GC96 a spacer insert must be installed first.": "Cuando se utiliza los dados (32-XX) de la crimpadora GC-32XD en la crimpadora GC96 debe instalase primero un espaciador.",
  "To achieve the proper tail length, line up the hose end of the ferrule with \"locating step\" on the die inside diameter (see figure) prior to crimp.": "Para lograr la longitud del faldón adecuada, alinea el extremo de la manguera de la férula con el diámetro interno del dado (ver figura) antes de crimpar.",
  "Settings for the PC2001 are the crimp outside diameter in mm. If your machine is set to english settings use the crimp outside diameter.": "Los ajustes para la crimpadora PC2001 son el diámetro exterior de crimpado en mm. Si la máquina está configurada en unidades inglesas utilice el diámetro exterior de crimpado.",
  "PC2001 settings maybe used for the Finn Power 120 crimper.": "Los ajustes de la crimpadora PC2001 pueden usarse para la crimpadora Finn Power 120."
};