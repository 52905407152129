import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap';
import ReactDom from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './reactCustom.css'
import './media.css'
import Navigation from './Navigation';
import { Translator } from "react-translator-component";
import { Provider } from "react-redux";
import store from "./store.js";
import DataContextProvider from './components/common/DataProvider.js';

ReactDom.render(
    <Translator>
        <DataContextProvider>
            <Provider store={store}>
                <Navigation />
            </Provider>
        </DataContextProvider>
    </Translator>,
    document.getElementById('root')
)