import { LANGUAGE_SELECTION } from "../actions/types";
import Util from '../utils/Util';

let lang = Util.getCookie("language")
if (lang === null || lang === undefined) {
  document.cookie = 'language=en'
  lang = "en"
}




const initialState = {
  language: lang,
}

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LANGUAGE_SELECTION:
      return {
        ...state,
        language: payload.language,
      };
    default:
      return state;
  }
}
