import React from 'react'

const Observer = ({ observable, onChange }) => {
    React.useEffect(() => {
        onChange(observable)

    }, [observable])

    return null
}

export default Observer