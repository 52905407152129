import { combineReducers } from "redux";
import authReducer from "./authReducer.js";
import loadingReducer from "./loadingReducer.js";
import formResetReducer from "./formResetReducer.js";
import regionReducer from "./regionReducer.js";
import languageReducer from "./languageReducer.js";
import dataReducer from "./dataReducer.js";


export default combineReducers({
	auth: authReducer,
	loading: loadingReducer,
	resetform: formResetReducer,
	region: regionReducer,
	language: languageReducer,
	digitaldata:dataReducer

});
