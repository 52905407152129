import Dexie from 'dexie'

// applyEncryptionMiddleware(
//   db,
//   keyPair.publicKey,
//   {
//     accessories: cryptoOptions.ENCRYPT_LIST,
//     favoritesData:cryptoOptions.ENCRYPT_LIST,
//     responseCrimpers:cryptoOptions.ENCRYPT_LIST,
//     finalNotesDashboard:cryptoOptions.ENCRYPT_LIST,
//     AppliedNotesData:cryptoOptions.ENCRYPT_LIST,
//     dataFavorites:cryptoOptions.ENCRYPT_LIST,
//     userData:cryptoOptions.ENCRYPT_LIST,
//     dataManageCrimpsheets:cryptoOptions.ENCRYPT_LIST,

//   },
//   clearAllTables
// )
class AppDB {
    db = new Dexie('ECrimpWeb')
    ready = false
    constructor() {
        this.db.version(1).stores({
            testData: '++id,datakey',
            accessories: '++id,Filteredaccessories',
            AppliedNotesData: '++id,AppliedNotesData',
            favoritesData: '++id,favoritesData',
            responseCrimpers: '++id,responseCrimpers',
            finalNotesDashboard: '++id,finalNotesDashboard',
            userData: '++id,userData',
            dataFavorites: '++id,dataFavorites',
            dataHoseFilters: '++id,dataHoseFilters',
            dataManageCrimpsheets: '++id,dataManageCrimpsheets',
        })
        this.db.version(2).stores({
            selectedCrimper: 'selectedCrimper',
            selectedRegion: 'region',
            selectedLanguage: 'language',
            selectedCountry: 'country',
        })
        this.db.on('ready', () => {
            this.ready = true
        })
    }
}

export default new AppDB()
