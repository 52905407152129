import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'
import "react-toastify/dist/ReactToastify.css";
import ApiServices from '../../utils/ApiServices';
import './FavoriteStyles.css'
import "bootstrap/dist/css/bootstrap.min.css";
import DeleteIcon from '@mui/icons-material/Delete';
import API from './../../utils/config.json'
import ToggleButton from 'react-toggle-button';
import Modal from 'react-bootstrap/Modal'
import { T } from "react-translator-component";
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import { queryFunc1 } from './queryFunc'
import { queryFunc2 } from './queryFunc'
import { queryFunc3 } from './queryFunc'
import { queryFunc4 } from './queryFunc'
import { queryFunc5 } from './queryFunc'
import { queryFunc6 } from './queryFunc'
import { font } from "./../FranklinGothic-normal";
import { fontBold } from "../FranklinGothic-Heavy";
import { fontDemi } from "../FranklinGothic-Demi";
import { fontItalic } from "../FranklinGothic-italic";
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import { HeaderAuth } from './../../utils/Header'
import Util from '../../utils/Util';
import { getdataFavorites, savedataFavorites } from "../../IndexedDB/FavoritesIndexedDB";
import { UnitConversion } from './../dashboard/UnitConversion'
import { connectDataContext } from "../common/DataProvider";
import { AuthContext } from "../auth-hook/auth-context";
import { addPowers } from "../common/stringUtils";
class Favorites extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {

            tableData: [],
            Toggle: this.props.unit === "M" ? true : false,
            open: false,
            removeSelected: null,
            crimperList: [],
            DataofFilteredCrimpers: [],
            originalFilterDta: [],
            openOrientation: false,
            HideSkiveLength: false,
            HideSkiveDiameter: false,
            Hidecrimplength: false,
            Hidecrimpinsertion: false,
            HIdecrimpTail: false,
            FilteredNotesImage: [],
            selectedDatatoPrint: [],
            enableDownloadSelected: false,
            isLoading: false,
            selectedHeader: null
        }
    }

    componentDidMount() {
        this.getFavoritelength();
    }




    getFavoritelength = () => {
        var email = Util.getCookie("email")

        var url = API.URL + "users/favorites?$format=json&$filter=EMAIL eq '" + email + "'"
        ApiServices.httpGET(url, async (err, response) => {
            if (response?.data?.d?.results) {
                var tableData = response.data.d.results;
                for (var jk = 0; jk < tableData.length; jk++) {
                    if (tableData[jk].DASH_SIZE !== null) {

                        if (tableData[jk].DASH_SIZE < 10) {

                            tableData[jk].DASH_SIZE = '0' + tableData[jk].DASH_SIZE

                        }
                    }
                }
                var ConvertedTableData = UnitConversion(tableData)
                var hideSkive = 0
                var hideSkiveDia = 0
                var hidecrimpins = 0
                var hidecrimplen = 0
                var hidecrimptail = 0

                for (var i = 0; i < ConvertedTableData.length; i++) {
                    if (ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH === "NONE" || ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH === "FULL" || ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH === "null" || ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH === "N/A") {
                        hideSkive = hideSkive + 1
                    }
                    if (ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH === "NONE" || ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH === "null" || ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH === "FULL" || ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH === "N/A") {
                        hideSkiveDia = hideSkiveDia + 1
                    }
                    if (ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH === "NONE" || ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH === "null" || ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH === "FULL" || ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH === "N/A") {
                        hidecrimplen = hidecrimplen + 1
                    }
                    if (ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH === "NONE" || ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH === "null" || ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH === "FULL" || ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH === "N/A") {
                        hidecrimpins = hidecrimpins + 1
                    }
                    if (ConvertedTableData[i].TAIL_LENGTH_INENGLISH === "NONE" || ConvertedTableData[i].TAIL_LENGTH_INENGLISH === "null" || ConvertedTableData[i].TAIL_LENGTH_INENGLISH === "FULL" || ConvertedTableData[i].TAIL_LENGTH_INENGLISH === "N/A") {
                        hidecrimptail = hidecrimptail + 1
                    }
                }
                if (hideSkive === ConvertedTableData.length) {
                    for (var i = 0; i < ConvertedTableData.length; i++) {

                        delete ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH
                        delete ConvertedTableData[i].SKIVE_LENGTH_DISPLAY_INMETRICS

                    }
                    this.setState({ HideSkiveLength: true })
                }
                if (hideSkiveDia === ConvertedTableData.length) {

                    for (var i = 0; i < ConvertedTableData.length; i++) {

                        delete ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH
                        delete ConvertedTableData[i].SKIVE_DIAMETER_DISPLAY_INMETRICS

                    }
                    this.setState({ HideSkiveDiameter: true })

                }
                if (hidecrimplen === ConvertedTableData.length) {

                    for (var i = 0; i < ConvertedTableData.length; i++) {

                        delete ConvertedTableData[i].LENGTH_DISPLAY_INENGLISH
                        delete ConvertedTableData[i].LENGTH_DISPLAY_INMETRICS
                    }
                    this.setState({ Hidecrimplength: true })

                }

                if (hidecrimpins === ConvertedTableData.length) {

                    for (var i = 0; i < ConvertedTableData.length; i++) {

                        delete ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH
                        delete ConvertedTableData[i].INSERTION_LENGTH_DISPLAY_INMETRICS
                    }
                    this.setState({ Hidecrimpinsertion: true })

                }

                if (hidecrimptail === ConvertedTableData.length) {

                    for (var i = 0; i < ConvertedTableData.length; i++) {

                        delete ConvertedTableData[i].TAIL_LENGTH_INENGLISH
                        delete ConvertedTableData[i].TAIL_LENGTH_INMETRICS
                    }
                    this.setState({ HIdecrimpTail: true })

                }
                ConvertedTableData.sort(function (a, b) {
                    var textA = a.HOSE_DISPLAY_NAME;
                    var textB = b.HOSE_DISPLAY_NAME;
                    return textA.localeCompare(textB);
                });

                this.setState({ tableData: response.data.d.results })
                await savedataFavorites(response.data.d.results)
                // localStorage.setItem("dataFavorites", JSON.stringify(response.data.d.results))
                //console.log("t")
                this.getNotesData()

            };
        });
    }
    addfavourite = (cellInfo) => {
        return (
            <div className="star-div"> <input className="fav-heart-checked " type="checkbox"
            /></div>
        )
    }



    removeFav = (removeSelected) => {
        var FavId = removeSelected;

        //old api

        // var url = "https://cpdp710uv33ud.us3.hana.ondemand.com:443/ecrimpwebsite/odata/services.xsodata/Favorites(FAVORITE_ID="
        //     + FavId + ",EMAIL='" + email + "')";
        // new api
        var url = API.URL + `users/favorites/` + FavId

        ApiServices.httpDELETE(url, (err, response) => {
            if (response) {
                var datares = response;
                if (response.status === 200) {
                    this.getFavoritelength();
                    this.hide();
                }
            };
        });
    }

    renderRemoveMultiple = async () => {
        const { selectedDatatoPrint } = this.state

        this.setState({ isLoading: true })
        var urlArray = [];
        for (var i = 0; i < selectedDatatoPrint.length; i++) {

            var FavId = selectedDatatoPrint[i].FAVORITE_ID
            var url = API.URL + `users/favorites/` + FavId
            urlArray.push(url)
        }
        await axios.all(urlArray.map((endpoint) => axios.delete(endpoint, HeaderAuth))).then(
            (data) => {
                if (data.some(res => res.status === 200)) {
                    this.setState({ isLoading: false, enableDownloadSelected: false })
                    this.getFavoritelength();
                }

            }
        )
            .catch(function (error) {
                console.log(error);
            });

    }

    renderRemove = (cellInfo) => {
        return (
            <div className="cursor">
                <DeleteIcon className="color-black"
                    onClick={() => this.show(cellInfo)}

                />
            </div>
        )
    }

    changeToggle = (e) => {
        this.setState({ Toggle: !e })
    }
    show = (cellInfo) => {

        var FavId = cellInfo.original.FAVORITE_ID;

        // Shows the selected popup
        this.setState({ open: true, removeSelected: FavId });


    }
    hide = (type) => {
        // Hides the selected popup
        this.setState({ open: false, removeSelected: null });
        if (type === "orientation") {
            this.setState({ openOrientation: false });
        }
    }

    DownloadPDF = (computeTableData, typeoforientation) => {
        // downloads the entire/selected data in table as PDF
        this.setState({ openOrientation: false });

        const { originalFilterDta, Toggle, activeEventKey } = this.state
        const { selectedHeader, HideSkiveDiameter, HideSkiveLength, Hidecrimplength, Hidecrimpinsertion, HIdecrimpTail } = this.state
        if (selectedHeader !== null) {
            var id = selectedHeader[0].id
            if (selectedHeader[0].desc) {
                const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })
                var sortedData = computeTableData.sort((a, b) => collator.compare(b[id], a[id]))
            } else {
                const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })
                var sortedData = computeTableData.sort((a, b) => collator.compare(a[id], b[id]))
            }
        } else {
            var sortedData = computeTableData
        }

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        if (typeoforientation === "landscape") {

            var orientation = typeoforientation; // portrait or landscape

        } else {
            var orientation = typeoforientation; // portrait or landscape

        }
        const doc = new jsPDF(orientation, unit, size);

        let gatesLogo = new Image();
        gatesLogo.src = '/images/Gates-PDF-LOGO.png';

        let eCrimpLogo = new Image();
        eCrimpLogo.src = '/images/Gates-Ecrimp-PDF-LOGO.png';

        let printedOnDate = new Date();
        var marLeft = 0;

        //FranklinGothic- Book
        doc.addFileToVFS('FranklinGothic-normal.ttf', font);
        doc.addFont('FranklinGothic-normal.ttf', 'FranklinGothic', 'normal');

        //FranklinGothic- heavy
        doc.addFileToVFS('FranklinGothic-Bold-bold.ttf', fontBold);
        doc.addFont('FranklinGothic-Bold-bold.ttf', 'FranklinGothic-Bold', 'normal');

        //FranklinGothic- demi

        doc.addFileToVFS('FranklinGothic-normal.ttf', fontDemi);
        doc.addFont('FranklinGothic-normal.ttf', 'FranklinGothic-Demi', 'normal');
        //FranklinGothic- italic

        doc.addFileToVFS('FranklinGothic-Italic-normal.ttf', fontItalic);
        doc.addFont('FranklinGothic-Italic-normal.ttf', 'FranklinGothic-Italic', 'normal');


        var DistinctForCrimpers = [...new Set(sortedData.map(item => item.CRIMPER_ID))];
        for (var i = 0; i < DistinctForCrimpers.length; i++) {
            const Uniquetable = sortedData.filter((user) => user.CRIMPER_ID === DistinctForCrimpers[i]);

            doc.autoTable({
                //startY: finalY + 120,
                head: headRows(),
                body: bodyRows(),
                //rowPageBreak: 'auto',
                // foot: footRows(),
                showHead: 'everyPage',
                margin: { top: 150, bottom: 100 },
                headStyles: {
                    fillColor: [117, 120, 123],
                    lineColor: [0, 0, 0],
                    lineWidth: 1,
                    //cellWidth: 'auto'
                },
                bodyStyles: {
                    lineColor: [0, 0, 0],
                    lineWidth: 1,
                    fillColor: [255, 255, 255],
                    halign: 'center',
                    valign: 'middle',
                    //cellWidth: 'auto'
                },
                footStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0]
                },
                alternateRowStyles: {
                    fillColor: [200, 201, 199]
                },

                didDrawPage: function (data) {

                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                    let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();


                    doc.addImage(gatesLogo, 'png', data.settings.margin.left, 15, 115, 65, 'Gates');
                    doc.setFontSize(21).setFont("FranklinGothic-Bold");

                    doc.text('Crimp Specifications', pageWidth / 2, 50, 'center');
                    doc.addImage(eCrimpLogo, 'png', pageWidth - 180, 35, 92, 24, 'eCrimp');

                    doc.setFontSize(21).setFont("FranklinGothic-Demi");
                    doc.text(DistinctForCrimpers[i], pageWidth / 2, 100, 'center');

                    doc.setFontSize(15).setFont("FranklinGothic-Demi");
                    doc.text('Printed on ' + printedOnDate.toLocaleDateString(), pageWidth - 215, 100);

                    doc.setFontSize(8).setFont('FranklinGothic-Italic');
                    let footerText = 'Refer to electronic crimp data at ecrimp.gates.com for the current crimp settings. Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All Settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.';
                    let splitFooterText = doc.splitTextToSize(footerText, (pageWidth - data.settings.margin.left - 40));
                    doc.text(splitFooterText, data.settings.margin.left, pageHeight - 60);
                    var copyrightText = '\u00A9' + ' ' + printedOnDate.getFullYear() + ' Gates Corporation All Rights Reserved';
                    doc.text(copyrightText, pageWidth / 2.5, pageHeight - 30);
                    marLeft = data.settings.margin.left
                }
            })

            if (DistinctForCrimpers.length === i + 1) {

                let pageSize = doc.internal.pageSize;
                let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
                if (originalFilterDta.length !== 0) {
                    doc.addPage();
                }
                doc.addImage(gatesLogo, 'png', marLeft, 15, 115, 65, 'Gates');
                doc.setFontSize(21).setFont("FranklinGothic-Bold");

                doc.text('Crimp Specifications', pageWidth / 2, 50, 'center');
                doc.addImage(eCrimpLogo, 'png', pageWidth - 180, 35, 92, 24, 'eCrimp');

                // doc.setFontSize(21).setFont("FranklinGothic-Demi");
                // doc.text(DistinctForCrimpers[i], pageWidth / 2, 100, 'center');

                doc.setFontSize(15).setFont("FranklinGothic-Demi");
                doc.text('Printed on ' + printedOnDate.toLocaleDateString(), pageWidth - 215, 100);

                doc.setFontSize(8).setFont('FranklinGothic-Italic');
                let footerText = 'Refer to electronic crimp data at ecrimp.gates.com for the current crimp settings. Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All Settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.';

                let splitFooterText = doc.splitTextToSize(footerText, (pageWidth - marLeft - 40));
                doc.text(splitFooterText, marLeft, pageHeight - 60);
                var copyrightText = '\u00A9' + ' ' + printedOnDate.getFullYear() + ' Gates Corporation All Rights Reserved';
                doc.text(copyrightText, pageWidth / 2.5, pageHeight - 30);
                if (originalFilterDta.length !== 0) {
                    doc.setFontSize(15).setFont(undefined, 'bold');
                    doc.text('Notes : ', marLeft, 130);
                    for (var j = 0; j < originalFilterDta.length; j++) {
                        doc.setFontSize(12).setFont(undefined, 'Regular');
                        const text = `${j + 1}. ${originalFilterDta[j]}`
                        let textLine = doc.splitTextToSize(text, (pageWidth - marLeft - 40));
                        if (j !== 0) {
                            doc.text(textLine, marLeft, 150 + (30 * j))

                        }
                        else {
                            doc.text(textLine, marLeft, 150)
                        }
                    }
                }
            } else {
                // if (originalFilterDta.length !== 0) {
                doc.addPage();
                // }

            }



            function headRows() {
                var datahead_English = [
                    "Dash Size", "Description", "Size (in)", "Working Pressure (psi)", "Type", "Description", "Skive Length (in) (+/- 0.03)",
                    "Skive Diameter (in)", "Crimp Insertion Length (in) (+/- 0.03)", "Crimp Length (in) (+/- 0.03)", "Crimp Outside Diameter (in)",
                    "Crimp Outside Diameter Tolerance (+/-)", "Crimp Tail Length (in) (+/- 0.1)", "Die Set", "Approximate Setting"
                ]
                var datahead_Metrics = [
                    "Dash Size", "Description", "Size (mm)", "Working Pressure (MPa)", "Type", "Description", "Skive Length (mm) (+/- 0.03)",
                    "Skive Diameter (mm)", "Crimp Insertion Length (mm) (+/- 0.03)", "Crimp Length (mm) (+/- 0.03)", "Crimp Outside Diameter (mm)",
                    "Crimp Outside Diameter Tolerance (+/-)", "Crimp Tail Length (mm) (+/- 0.1)", "Die Set", "Approximate Setting"
                ]
                var lengthColspan = 0
                if (HideSkiveLength) {
                    datahead_English = datahead_English.filter(s => s !== "Skive Length (in) (+/- 0.03)");
                    datahead_Metrics = datahead_Metrics.filter(s => s !== "Skive Length (mm) (+/- 0.03)");
                    lengthColspan = lengthColspan + 1
                }
                if (HideSkiveDiameter) {
                    datahead_English = datahead_English.filter(s => s !== "Skive Diameter (in)");
                    datahead_Metrics = datahead_Metrics.filter(s => s !== "Skive Diameter (mm)");

                    lengthColspan = lengthColspan + 1

                }
                if (Hidecrimpinsertion) {
                    datahead_English = datahead_English.filter(s => s !== "Crimp Insertion Length (in) (+/- 0.03)");
                    datahead_Metrics = datahead_Metrics.filter(s => s !== "Crimp Insertion Length (mm) (+/- 0.03)");
                    lengthColspan = lengthColspan + 1

                }
                if (Hidecrimplength) {
                    datahead_English = datahead_English.filter(s => s !== "Crimp Length (in) (+/- 0.03)");
                    datahead_Metrics = datahead_Metrics.filter(s => s !== "Crimp Length (mm) (+/- 0.03)");
                    lengthColspan = lengthColspan + 1

                }
                if (HIdecrimpTail) {
                    datahead_English = datahead_English.filter(s => s !== "Crimp Tail Length (in) (+/- 0.1)");
                    datahead_Metrics = datahead_Metrics.filter(s => s !== "Crimp Tail Length (mm) (+/- 0.1)");
                    lengthColspan = lengthColspan + 1

                }

                if (!Toggle) {

                    return [
                        [
                            { content: '', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },

                            { content: 'HOSE', colSpan: 4, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'STEM', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'FERRULE', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'SPECIFICATION', colSpan: 8 - lengthColspan, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        ],

                        // [
                        //     "Dash Size", "Description", "Size (in)", "Working Pressure", "Type", "Description", "Skive Length (in) (+/- 0.03)",
                        //     "Skive Diameter (in)", "Crimp Insertion Length (in) (+/- 0.03)", "Crimp Length (in) (+/- 0.03)", "Crimp Outside Diameter (in)",
                        //     "Crimp Outside Diameter Tolerance (+/-)", "Crimp Tail Length (in) (+/- 0.1)", "Die Set", "Approximate Setting"
                        // ]
                        datahead_English
                    ]
                }
                else {
                    return [
                        [
                            { content: '', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },

                            { content: 'HOSE', colSpan: 4, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'STEM', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'FERRULE', colSpan: 1, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                            { content: 'SPECIFICATION', colSpan: 8 - lengthColspan, styles: { halign: 'center', fillColor: [192, 32, 38] } },
                        ],

                        // [
                        //     "Dash Size", "Description", "Size (mm)", "Working Pressure", "Type", "Description", "Skive Length (mm) (+/- 0.03)",
                        //     "Skive Diameter (mm)", "Crimp Insertion Length (mm) (+/- 0.03)", "Crimp Length (mm) (+/- 0.03)", "Crimp Outside Diameter (mm)",
                        //     "Crimp Outside Diameter Tolerance (+/-)", "Crimp Tail Length (mm) (+/- 0.1)", "Die Set", "Approximate Setting"
                        // ]
                        datahead_Metrics
                    ]
                }
            }


            function bodyRows() {
                
                var databody_English = Uniquetable.map(elt =>
                    [
                        elt.DASH_SIZE,
                        addPowers(elt.HOSE_DISPLAY_NAME, elt.NOTES_INDEXES?.HOSE_DISPLAY_NAME || []),
                        elt.SIZE_INENGLISH,
                        elt.WORKING_PRESSURE_INENGLISH,
                        addPowers(elt.STEM_TYPE, elt.NOTES_INDEXES?.STEM_TYPE || []),
                        elt.FERRULE_DETAIL,
                        elt.SKIVE_LENGTH_DISPLAY_INENGLISH,
                        elt.SKIVE_DIAMETER_DISPLAY_INENGLISH,
                        elt.INSERTION_LENGTH_DISPLAY_INENGLISH,
                        elt.LENGTH_DISPLAY_INENGLISH,
                        elt.OUTSIDE_DIAMETER_DISPLAY_INENGLISH,
                        elt.OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH,
                        elt.TAIL_LENGTH_INENGLISH,
                        addPowers(elt.DIE_ID, elt.NOTES_INDEXES?.DIE_ID || []),
                        elt.APPROXIMATE_DIGITAL_READOUT
                    ]);


                var databody_Metrics = Uniquetable.map(elt =>
                    [
                        elt.DASH_SIZE,
                        addPowers(elt.HOSE_DISPLAY_NAME, elt.NOTES_INDEXES?.HOSE_DISPLAY_NAME || []),
                        elt.SIZE_INMETRICS,
                        elt.WORKING_PRESSURE_INMETRICS,
                        addPowers(elt.STEM_TYPE, elt.NOTES_INDEXES?.STEM_TYPE || []),
                        elt.FERRULE_DETAIL,
                        elt.SKIVE_LENGTH_DISPLAY_INMETRICS,
                        elt.SKIVE_DIAMETER_DISPLAY_INMETRICS,
                        elt.INSERTION_LENGTH_DISPLAY_INMETRICS,
                        elt.LENGTH_DISPLAY_INMETRICS,
                        elt.OUTSIDE_DIAMETER_DISPLAY_INMETRICS,
                        elt.OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS,
                        elt.TAIL_LENGTH_INMETRICS,
                        addPowers(elt.DIE_ID, elt.NOTES_INDEXES?.DIE_ID || []),
                        elt.APPROXIMATE_DIGITAL_READOUT
                    ]);

                // var databody = JSON.parse(JSON.stringify(databody))

                for (var k = 0; k < databody_English.length; k++) {
                    if (HideSkiveLength) {
                        //    var test = databody[i].splice(6, 1)
                        // databody = databody[i].filter(s => indexOf(s) !== 6);
                        // databody[i] = databody[i].filter((_, index) => index !== -5);

                        databody_English[k].splice(databody_English[k].length - 8 - 1, 1);
                        databody_Metrics[k].splice(databody_Metrics[k].length - 8 - 1, 1);

                    }
                    if (HideSkiveDiameter) {
                        // var test =  databody[i].splice(7, 1)
                        // databody[i] = databody[i].filter((_, index) => index !== -4);
                        databody_English[k].splice(databody_English[k].length - 7 - 1, 1);
                        databody_Metrics[k].splice(databody_Metrics[k].length - 7 - 1, 1);

                    }
                    if (Hidecrimpinsertion) {
                        // var test = databody_English[i].splice(8, 1)
                        // databody_English[i] = databody_English[i].filter((_, index) => index !== -3);
                        databody_English[k].splice(databody_English[k].length - 6 - 1, 1);
                        databody_Metrics[k].splice(databody_Metrics[k].length - 6 - 1, 1);

                    }
                    if (Hidecrimplength) {
                        // var test = databody_English[i].splice(9, 1)
                        // databody_English[i] = databody_English[i].filter((_, index) => index !== -2);
                        databody_English[k].splice(databody_English[k].length - 5 - 1, 1);
                        databody_Metrics[k].splice(databody_Metrics[k].length - 5 - 1, 1);


                    }
                    if (HIdecrimpTail) {
                        // var test =  databody_English[i].splice(12, 1)
                        // databody_English[i] = databody_English[i].filter((_, index) => index !== -1);
                        databody_English[k].splice(databody_English[k].length - 2 - 1, 1);
                        databody_Metrics[k].splice(databody_Metrics[k].length - 2 - 1, 1);


                    }
                }
                if (!Toggle) {


                    return databody_English;
                } else {


                    return databody_Metrics;
                }
            }
        }
        doc.save("report.pdf");

    }

    getallnotes = () => {
        var url = API.URL + "notes-v2"
        ApiServices.httpGET(url, (err, response) => {

            if (response?.data?.d?.results) {

                let notesdata = response.data.d.results;
                // localStorage.setItem("notesdata", JSON.stringify(notesdata))

            };
        });
    }

    getNotesData = async (favorites) => {

        // this is the data in the table

        var dataFavorites = favorites ?? await getdataFavorites()
        // var dataFavorites = JSON.parse(localStorage.getItem("dataFavorites"))

        // getting crimper list
        const crimper = dataFavorites.map(item => item.CRIMPER_ID)
            .filter((value, index, self) => self.indexOf(value) === index)


        const urlArray = [];
        // looping through each crimper
        for (var i = 0; i < crimper.length; i++) {

            const currentCrimper = crimper[i]

            const data = dataFavorites.filter(fav => fav.CRIMPER_ID === currentCrimper)
            //getting description 
            const DESCRIPTION = data.map(item => item.HOSE_DISPLAY_NAME)
                .filter((value, index, self) => self.indexOf(value) === index)
            const queryDescription = [...DESCRIPTION, 'Any'].map(value => `DESCRIPTION eq '${value}'`).join(' or ')
            //getting die set 
            
            const DIE_ID = data.map(item => item.DIE_ID)
            .filter((value, index, self) => self.indexOf(value) === index)
            const queryDieId = [...DIE_ID, 'Any'].map(value => `DIE_ID eq '${value}'`).join(' or ')


            //getting Dash Size  

            const DASH_SIZE = data.map(item => item.DASH_SIZE)
                .filter((value, index, self) => self.indexOf(value) === index)
            const queryDashSize = [...DASH_SIZE, 'Any'].map(value => {
                if(value === 'Any') return `STEM_DASH_SIZE eq '${value}'`
                return `STEM_DASH_SIZE eq '${String(value).padStart(2, '0')}' or STEM_DASH_SIZE eq '${parseInt(value)}'`
            }).join(' or ')
                
                //getting STEM_TYPE
                // 
                
            const STEM_TYPE = data.map(item => item.STEM_TYPE)
            .filter((value, index, self) => self.indexOf(value) === index)
            const queryStemType = [...STEM_TYPE, 'Any'].map(value => `STEM_TYPE eq '${value}'`).join(' or ')

            const allQueries = [queryDescription, queryDieId, queryDashSize, queryStemType].map(e => `(${e})`).join(' and ')

            const urlNotes = `${API.URL}notes-v2?$filter=(CRIMPER_ID eq '${currentCrimper}' or CRIMPER_ID eq 'Any') and ${allQueries}&$format=json`
            console.log(urlNotes)
            urlArray.push(urlNotes)

        }

        var fetchedResult = []
        await axios.all(urlArray.map((endpoint) => axios.get(endpoint, HeaderAuth))).then(

            (data) =>
                fetchedResult = data
        ).catch(function (error) {
            console.log(error);
        });

        let NotesImage = []

        for (var i = 0; i < fetchedResult.length; i++) {
            var getImage = fetchedResult[i].data.d.results
            for (var j = 0; j < getImage.length; j++) {

                if (getImage[j].IMAGE_URL !== null) {
                    NotesImage.push(getImage[j])
                }
            }
        }

        var FinalfilteredNotesImage = []
        const filteredNotesImage = [...new Set(NotesImage.map(item => item.IMAGE_URL))];
        for (var i = 0; i < filteredNotesImage.length; i++) {
            var splits = filteredNotesImage[i].split(";");
            for (var ij = 0; ij < splits.length; ij++) {

                FinalfilteredNotesImage.push(splits[ij])
            }
        }
        // var fetchedResult = JSON.parse(localStorage.getItem("finalNotes"))
        this.setState({ crimperList: crimper, DataofFilteredCrimpers: fetchedResult, FilteredNotesImage: FinalfilteredNotesImage })

        var finalResult = []

        // organizing the crimpers and their notes data
        for (var i = 0; i < crimper.length; i++) {
            var res = JSON.parse(JSON.stringify(fetchedResult[i].data.d.results))
            var messageFilter = [...new Set(res.map(item => item.MESSAGE))];
            var data = {
                "CRIMPER": crimper[i],
                "data": messageFilter
            }
            finalResult.push(data)
        }
        var listOfnotes = []
        for (var i = 0; i < finalResult.length; i++) {
            if (finalResult[i].data.length >= 1 && finalResult[i].data[0] !== "") {
                listOfnotes.push(finalResult[i].data)
            }

        }
        // combing all the messages

        var listofMessages = [];
        for (var i = 0; i < listOfnotes.length; i++) {
            listofMessages = listofMessages.concat(listOfnotes[i]);
        }

        var ConvertedTableData = dataFavorites
        // console.log("url", urlArray)
        let uniqueMessages = [...new Set(listofMessages)];

        // console.log("url", urlArray)

        this.setState({ originalFilterDta: uniqueMessages })


        // comparing the Filtered Notes API data and table data

        var lastMessage = ""
        var getMessage = ""
        var dataNotes = []

        for (var ij = 0; ij < fetchedResult.length; ij++) {
            dataNotes.push(fetchedResult[ij].data.d.results)

        }

        const combinedNotes = dataNotes.flat();

        const notesdata = combinedNotes.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === combinedNotes.findIndex(obj => {
                return JSON.stringify(obj) === _value;
            });
        });

        //console.log("combinedNotes", combinedNotes)
        //console.log("uniqueArray", notesdata)
        const Any = 'Any'
        if (notesdata.length !== 0) {
            for (var i = 0; i < notesdata.length; i++) {
                for (var j = 0; j < ConvertedTableData.length; j++) {
                     // Note data
                     const noteData = notesdata[i]
                     const noteCrimper = String(noteData.CRIMPER_ID)
                     const noteDescription = String(noteData.DESCRIPTION)
                     const noteStemType = String(noteData.STEM_TYPE)
                     const _dashSize = noteData.STEM_DASH_SIZE
                     const noteDashSize = _dashSize === Any ? _dashSize : Number(_dashSize)
                     const noteDieId = String(noteData.DIE_ID)

                     const message = noteData.MESSAGE
                     const Index = uniqueMessages.indexOf(message) + 1
                     
                     // Table data
                     const convertedData = ConvertedTableData[j];
                     const convertedCrimper = String(convertedData.CRIMPER_ID)
                     const convertedDescription = String(convertedData.HOSE_DISPLAY_NAME)
                     const convertedStemType = String(convertedData.STEM_TYPE)
                     const convertedDashSize = Number(convertedData.DASH_SIZE)
                     const convertedDieId = String(convertedData.DIE_ID)
                     const hasNotesIndexes = 'NOTES_INDEXES' in ConvertedTableData[j]
                     if(!hasNotesIndexes) ConvertedTableData[j].NOTES_INDEXES = {}
                     const crimperMatches = noteCrimper === convertedCrimper && ((noteDescription === convertedDescription || noteDescription === Any) || ((noteStemType === convertedStemType || noteStemType === Any) && (noteDashSize === convertedDashSize || noteDashSize === Any)) && (noteDieId === convertedDieId || noteDieId === Any))
                     const descriptionMatches = noteDescription === convertedDescription && ((noteStemType === convertedStemType || noteStemType === Any) && (noteDashSize === convertedDashSize || noteDashSize === Any)) && (noteDieId === convertedDieId || noteDieId === Any)
                     const stemTypeMatches = (noteStemType === convertedStemType && noteDashSize === convertedDashSize) && (noteDescription === Any || noteDescription === convertedDescription) && (noteDieId === Any || noteDieId === convertedDieId)
                     const dieIdMatches = String(noteDieId) === String(convertedDieId) && ((noteDescription === convertedDescription || noteDescription === Any) && ((noteStemType === convertedStemType || noteStemType === Any) && (noteDashSize === convertedDashSize || noteDashSize === Any)) && (noteCrimper === convertedCrimper || noteCrimper === Any))
                     if(crimperMatches) {
                         ConvertedTableData[j].NOTES_INDEXES.CRIMPER_ID = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.CRIMPER_ID || []), Index])]
                     } 
                     if(descriptionMatches) {
                         ConvertedTableData[j].NOTES_INDEXES.HOSE_DISPLAY_NAME = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.HOSE_DISPLAY_NAME || []), Index])] 
                     } 
                     if(stemTypeMatches) {
                         ConvertedTableData[j].NOTES_INDEXES.COUPLING_ID = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.COUPLING_ID || []), Index])]
                         ConvertedTableData[j].NOTES_INDEXES.STEM_TYPE = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.STEM_TYPE || []), Index])]
                     }
                     if(dieIdMatches) {
                         ConvertedTableData[j].NOTES_INDEXES.DIE_ID = [...new Set([...(ConvertedTableData[j].NOTES_INDEXES.DIE_ID || []), Index])]
                     }

                }

            }

        }


        this.setState({ tableData: ConvertedTableData })
        //console.log("ConvertedTableData", ConvertedTableData)



    }
    displayNotesCrimper = (cellInfo) => {
        const { originalFilterDta } = this.state
        const FinalData = cellInfo.original.NOTES_INDEXES?.CRIMPER_ID || []
        
        return (
            <div > 
                {cellInfo.original.CRIMPER_ID}
                {FinalData.map((value) => <span className="notes-font cursor" title={originalFilterDta[value - 1]}>{value}</span>)}
            </div>
        )
    }
    displayNotesHose = (cellInfo) => {
        const { originalFilterDta, tableData } = this.state
        const FinalData = cellInfo.original.NOTES_INDEXES?.HOSE_DISPLAY_NAME || []
        
        return (
            <div > 
                {cellInfo.original.HOSE_DISPLAY_NAME}
                {FinalData.map((value) => <span className="notes-font cursor" title={originalFilterDta[value - 1]}>{value}</span>)}
            </div>
        )
    }
    displayNotesCoupling = (cellInfo) => {
        const { originalFilterDta } = this.state

        // var str = cellInfo.original.STEM_TYPE;
        // var find = str.includes('[');
        // var numbers = []
        // var FinalData = []

        // var COUPLING_ID = str
        // if (find) {
        //     // numbers = str.match(/(?<=\[)[^\][]*(?=])/g);
        //     var regex = /\[([^\][]*)]/g;
        //     var results = [], m;
        //     while (m = regex.exec(str)) {
        //         numbers.push(m[1]);
        //     }
        //     var text = COUPLING_ID.replace(/ *\[[^\]]*]/g, '');
        //     COUPLING_ID = text.replace(/ +/g, "");
        //     for (var i = 0; i < numbers.length; i++) {
        //         var index = Number(numbers[i]) - 1
        //         var data = {
        //             index: numbers[i],
        //             message: originalFilterDta[index]
        //         }
        //         FinalData.push(data)

        //     }
        // }
        const data = cellInfo.original.NOTES_INDEXES?.STEM_TYPE || []

        return (
            <div >
                {`${cellInfo.original.STEM_TYPE}`}
                {data.map((value) => <span className="notes-font cursor" title={originalFilterDta[value - 1]}>{value}</span>)}
            </div>
        )
    }
    displayNotesDie = (cellInfo) => {
        const { originalFilterDta } = this.state

        // var str = cellInfo.original.DIE_ID;
        // var find = str.includes('[');
        // var numbers = []
        // var FinalData = []

        // var DIE_ID = str
        // if (find) {
        //     // numbers = str.match(/(?<=\[)[^\][]*(?=])/g);
        //     var regex = /\[([^\][]*)]/g;
        //     var results = [], m;
        //     while (m = regex.exec(str)) {
        //         numbers.push(m[1]);
        //     }
        //     var text = DIE_ID.split(']', 2);
        //     DIE_ID = text[1].trimStart()
        //     for (var i = 0; i < numbers.length; i++) {
        //         var index = Number(numbers[i]) - 1
        //         var data = {
        //             index: numbers[i],
        //             message: originalFilterDta[index]
        //         }
        //         FinalData.push(data)

        //     }
        // }

        const data = cellInfo.original.NOTES_INDEXES?.DIE_ID || []

        return (
            <div >
                {cellInfo.original.DIE_ID}
                {data.map((value) => <span className="notes-font cursor" title={originalFilterDta[value - 1]}>{value}</span>)}
            </div>
        )
    }

    selectOrientation = (data) => {

        const { HideSkiveDiameter, HideSkiveLength, Hidecrimplength, Hidecrimpinsertion, HIdecrimpTail } = this.state
        this.setState({ openOrientation: false });

        if (HideSkiveDiameter && HideSkiveLength && Hidecrimplength && Hidecrimpinsertion && HIdecrimpTail) {
            this.setState({ openOrientation: true });

        } else {
            this.DownloadPDF(data, "landscape")


        }
    }

    renderCheckboxAll = (cellInfo) => {
        // renders the checkbox in the table

        return (
            <div className="center-checkbox">
                <label>Select<br /> All</label>
                <br />
                <input type="checkbox" onClick={(e) => this.selecCheckBox(e, cellInfo, "All")} />
            </div>
        )
    }

    renderCheckbox = (cellInfo) => {
        // renders the checkbox in the table

        return (
            <div className="center-checkbox">
                <input type="checkbox" checked={cellInfo.original.isChecked} onClick={(e) => this.selecCheckBox(e, cellInfo, "one")}
                />
            </div>
        )
    }


    selecCheckBox = (e, cellInfo, type) => {

        // gets the selected row details and storing it in an array
        const { selectedDatatoPrint, tableData } = this.state
        const selectedRow = [...selectedDatatoPrint]
        var checked = e.target.checked
        var data = [];
        if (type === "All") {
            data = tableData

            if (checked) {
                for (var i = 0; i < data.length; i++) {
                    data[i].isChecked = true
                    selectedRow.push(data[i])

                }
            } else {
                for (var i = 0; i < data.length; i++) {
                    data[i].isChecked = false


                }
                var filtered = data.filter(s => s.isChecked);
                selectedRow = filtered

            }
        }

        if (type === "one") {
            var Id = cellInfo.original.Id;
            var data = cellInfo.original;
            data["isChecked"] = checked
            if (checked) {
                selectedRow.push(data)
            }
            else {
                for (let i = 0; i < selectedRow.length; i++) {
                    const element = selectedRow[i];
                    if (element.Id === Id) {
                        selectedRow.splice(i, 1)
                    }
                }
            }
        }

        if (selectedRow.length > 0) {
            this.setState({ enableDownloadSelected: true })
        } else {
            this.setState({ enableDownloadSelected: false })

        }

        this.setState({ selectedDatatoPrint: selectedRow })

    }

    sortTableData(sortColumn, desc) {
        const { tableData } = this.state
        tableData.sort((a, b) => {
            const valueA = a[sortColumn]
            const valueB = b[sortColumn]
            if (typeof valueA === 'string') {
                const isNumber = !isNaN(Number(valueA))
                return desc ? valueA.localeCompare(valueB, undefined, {
                    numeric: isNumber,
                }) : valueB.localeCompare(valueA, undefined, {
                    numeric: isNumber,
                })
            }
            return desc ? valueA - valueB : valueB - valueA
        })
        this.setState({
            tableData: tableData
        })

    }

    render() {
        const { isLoggedIn } = this.context
        const { openOrientation, tableData, Toggle, open, removeSelected, originalFilterDta, FilteredNotesImage } = this.state
        const { isLoading, enableDownloadSelected, HideSkiveDiameter, HideSkiveLength, Hidecrimplength, Hidecrimpinsertion, HIdecrimpTail } = this.state

        return (
            <div className="margin-2">
                <div className={(isLoading ? 'show' : 'hide') + ' partial-loader'} id="loading">
                    <div className="loading-message"></div>
                </div>
                <div className="col-md-12 ">
                    <a href="/Dashboard" className="color-black">
                        <ArrowBackIos className="backmenu cursor" />
                        <label className="cursor">{T("Back to Dashboard")}</label>

                    </a>
                    <br />
                    <br />
                    <div className='redline'>
                        <b> <h4>{T("FAVORITES")} </h4></b>


                    </div>


                    <br />

                    <h6>{T("Save the crimp specifications you use most often for quick access and ease of use.")} </h6>
                    <br />

                    <div className="w-100">
                        <div className="fav-row-options">
                            <div className="displayflex width-100">

                                {tableData.length !== 0 ? <button className="download-btn color-white" onClick={() => this.selectOrientation(tableData)}>{T("Download PDF")}</button> : ""}

                                {enableDownloadSelected ? <button className="delete-selected-btn color-white margin-left-3" onClick={() => this.renderRemoveMultiple()}>{T("Remove Selected")}</button> : ""}
                            </div>

                            <div className="Uom displayflex">
                                <span className="margin-left-1">{T('Show English Units')}</span>
                                <div className="margin-left-1">
                                    <ToggleButton
                                        inactiveLabel={''}
                                        activeLabel={''}
                                        colors={{
                                            activeThumb: {
                                                base: 'rgb(0 0 0)',
                                            },
                                            inactiveThumb: {
                                                base: 'rgb(0 0 0)',
                                            },
                                            active: {
                                                base: 'rgb(193 33 38)',
                                                hover: 'rgb(193 33 38)',
                                            },
                                            inactive: {
                                                base: 'rgb(193 33 38)',
                                                hover: 'rgb(193 33 38)',
                                            }
                                        }} value={Toggle} onToggle={(value) => { this.changeToggle(value); }} />
                                </div>
                                <span className="margin-left-1"> {T('Show Metric Units')} </span>
                            </div>
                        </div>

                        <ReactTable
                            manual
                            onSortedChange={(newSorted, column, shiftKey) => {
                                console.log({ newSorted, column, shiftKey });
                                this.setState({
                                    selectedHeader: newSorted
                                })
                                this.sortTableData(newSorted[0].id, newSorted[0].desc)
                            }}
                            data={tableData}
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: tableData.length > 0 ? this.renderCheckboxAll : "",
                                            Cell: this.renderCheckbox,
                                            maxWidth: 60,
                                            show: isLoggedIn
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            Header: 'Favorite',
                                            Cell: this.renderRemove,
                                            show: isLoggedIn
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            Header: 'Crimper',
                                            accessor: 'CRIMPER_ID',
                                            Cell: this.displayNotesCrimper,
                                            minWidth: 80

                                        }
                                    ]
                                },
                                {
                                    Header: 'Hose',
                                    columns: [
                                        {
                                            Header: <div>Dash<br /> Size</div>,
                                            accessor: 'DASH_SIZE',
                                            minWidth: 50
                                        },
                                        {
                                            Header: 'Description',
                                            accessor: 'HOSE_DISPLAY_NAME',
                                            Cell: this.displayNotesHose,
                                            minWidth: 150
                                        },
                                        {
                                            Header: <div>Size <br />{!Toggle ? '(in)' : '(mm)'}</div>,
                                            accessor: !Toggle ? 'SIZE_INENGLISH' : 'SIZE_INMETRICS',
                                            minWidth: 50
                                        },
                                        {
                                            Header: <div>Working <br />Pressure<br /> {!Toggle ? '(psi)' : '(MPa)'}</div>,
                                            accessor: !Toggle ? 'WORKING_PRESSURE_INENGLISH' : 'WORKING_PRESSURE_INMETRICS',
                                            minWidth: 80

                                        },
                                    ]
                                },
                                {
                                    Header: 'Stem',
                                    columns: [
                                        {
                                            Header: "Type",
                                            accessor: "STEM_TYPE",
                                            Cell: this.displayNotesCoupling,
                                            minWidth: 80,
                                            sortMethod: (a, b) => a.localeCompare(b)
                                        },

                                    ]

                                },
                                {
                                    Header: 'Ferrule',
                                    columns: [
                                        {
                                            Header: "Description",
                                            accessor: "FERRULE_DETAIL",
                                            minWidth: 80
                                        },

                                    ]
                                },
                                {
                                    Header: 'Specifications',
                                    columns: [
                                        {
                                            Header: <div>Skive <br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.03)' : '(+/- 0.75)'}</div>,
                                            accessor: !Toggle ? "SKIVE_LENGTH_DISPLAY_INENGLISH" : "SKIVE_LENGTH_DISPLAY_INMETRICS",
                                            show: !HideSkiveLength,
                                            minWidth: 80


                                        },
                                        {
                                            Header: <div>Skive <br />Diameter<br />{!Toggle ? '(in)' : '(mm)'}</div>,
                                            accessor: !Toggle ? "SKIVE_DIAMETER_DISPLAY_INENGLISH" : "SKIVE_DIAMETER_DISPLAY_INMETRICS",
                                            show: !HideSkiveDiameter,
                                            minWidth: 80


                                        },
                                        {
                                            Header: <div>Crimp<br />Insertion <br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.03)' : '(+/- 0.75)'}</div>,
                                            accessor: !Toggle ? "INSERTION_LENGTH_DISPLAY_INENGLISH" : "INSERTION_LENGTH_DISPLAY_INMETRICS",
                                            show: !Hidecrimpinsertion,
                                            minWidth: 80


                                        },
                                        {
                                            Header: <div>Crimp <br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.03)' : '(+/- 0.75)'}</div>,
                                            accessor: !Toggle ? "LENGTH_DISPLAY_INENGLISH" : "LENGTH_DISPLAY_INMETRICS",
                                            show: !Hidecrimplength,
                                            minWidth: 80


                                        },
                                        {
                                            Header: <div>Crimp<br />Outside<br />Diameter<br />{!Toggle ? '(in)' : '(mm)'}</div>,
                                            accessor: !Toggle ? "OUTSIDE_DIAMETER_DISPLAY_INENGLISH" : "OUTSIDE_DIAMETER_DISPLAY_INMETRICS",
                                            minWidth: 80

                                        },
                                        {
                                            Header: <div>Crimp<br />Outside<br />Diameter<br /> Tolerance<br />(+/-)</div>,
                                            accessor: !Toggle ? "OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH" : "OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS",
                                            minWidth: 80

                                        },
                                        {
                                            Header: <div>Crimp<br />Tail<br />Length<br />{!Toggle ? '(in)' : '(mm)'}<br />{!Toggle ? '(+/- 0.1)' : '(+/- 2.5)'}</div>,
                                            accessor: !Toggle ? "TAIL_LENGTH_INENGLISH" : "TAIL_LENGTH_INMETRICS",
                                            show: !HIdecrimpTail,
                                            minWidth: 80


                                        },
                                        {
                                            Header: "Die Set",
                                            accessor: "DIE_ID",
                                            Cell: this.displayNotesDie,
                                            minWidth: 80,
                                            sortMethod: (a, b) => {
                                                a = Number(a);
                                                b = Number(b);
                                                if (a.length === b.length) {
                                                    return a > b ? 1 : -1;
                                                }
                                                return a.length > b.length ? 1 : -1;
                                            }

                                        },
                                        {
                                            Header: <div>Approximate<br /> Setting</div>,
                                            accessor: "APPROXIMATE_DIGITAL_READOUT",
                                            minWidth: 80,
                                            sortMethod: (a, b) => {
                                                a = Number(a);
                                                b = Number(b);
                                                if (a.length === b.length) {
                                                    return a > b ? 1 : -1;
                                                }
                                                return a.length > b.length ? 1 : -1;
                                            }
                                        },



                                    ]
                                }
                            ]}
                            defaultPageSize={50}
                            minRows={5}
                            showPageSizeOptions={true}
                        />
                    </div>
                    <div className="margin-top-3" id="notesdata">
                        {originalFilterDta.length !== 0 ? <ol>
                            {originalFilterDta ? originalFilterDta.map((value, index) =>
                                <li className="margin-bottom-1">{value}</li>)
                                : ""}
                        </ol> : ""}


                    </div>

                </div>
                <div className="margin-top-3" id="notesImage">

                    {FilteredNotesImage ? FilteredNotesImage.map((value, index) =>
                        <img className="margin-bottom-1" src={value} />)

                        : ""}
                </div>

                <div className="Fav-disclaimer">
                    <h6> <b>{T("DISCLAIMER")} : </b>{T("Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.")}</h6>
                </div>
                <Modal
                    show={open}
                    onHide={open}
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="example-custom-modal-styling-title">
                            <div className="underlinestyle">{T("Confirmation")}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {T("Do you want to remove from Favorites")}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.hide()}>{T("Cancel")}</button>
                        <button className="apply-btn-modal" type="button" onClick={() => this.removeFav(removeSelected)}>{T("Yes")}</button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={openOrientation}
                    onHide={openOrientation}
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="example-custom-modal-styling-title">
                            <div className="underlinestyle">{T("Confirmation")}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4 className="textalign-center">{T('Please select the orientation')} </h4>
                        <div id="centered">
                            <div className="textalign-center select-options margin-5 cursor" onClick={() => this.DownloadPDF(tableData, "portrait")}> <CropPortraitIcon className="color-black iconsize-45" /><label >PORTRAIT</label></div>
                            <div className="textalign-center select-options margin-5 cursor" onClick={() => this.DownloadPDF(tableData, "landscape")}> <CropLandscapeIcon className="color-black iconsize-45" /><label >LANDSCAPE</label></div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.hide("orientation")}>{T("Cancel")}</button>
                    </Modal.Footer>
                </Modal>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    flag: state.region.flag,
    unit: state.region.unit
});

export default connectDataContext(
    connect(mapStateToProps, {})(
        Favorites
    )
);
