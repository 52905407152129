import { SET_CURRENT_USER } from "../actions/types.js";
import Util from '../utils/Util';
import jwt_decode from "jwt-decode";
const token = Util.getCookie("accessToken") || null;
//const Region = localStorage.getItem("Region") || "NA";

const initialState = {
	isAuthenticated: token ? true : false,
	//SelectedRegion: Region,
	Immediatefilter:false
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_USER:
			return {
				...state,
				isAuthenticated: !Util.isEmpty(action.payload),
			};

		default:
			return state;
	}
}
