import Cookies from 'universal-cookie';
import axios from "axios";

const cookies = new Cookies();

var Globals = require('./Globals');

export default class Util {

    static setAuthToken(token) {
        if(token) {
            return axios.defaults.headers.common["Authorization"] = token;
        } else {
            return delete axios.defaults.headers.common["Authorization"];
        }
    };

    // Check mime type
    static checkMimeType(files) {
        //define message container
        let err = [];

        // list allow mime type
        const types = ['xls', 'xlsx', 'officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

        // loop access array
        for(var x = 0; x<files.length; x++) {

            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {

                // create error message and assign to container   
                err[x] = files[x].name+' file is not in supported format\n';
            }
        };

        return err;
    }

    static isEmpty(value) {
        return (
            value === undefined ||
            value === null ||
            (typeof value === "object" && Object.keys(value).length === 0) ||
            (typeof value === "string" && value.trim().length === 0)
        );
    };


    static cloneArray(baseArray) {
        if (null === baseArray || "object" !== typeof baseArray) return baseArray;
        var copy = baseArray.constructor();

        for (var attr in baseArray) {
            if (baseArray.hasOwnProperty(attr)) copy[attr] = this.cloneArray(baseArray[attr]);
        }

        return copy;
    }

    static generateBaseAPIURLWithUserToken(userCoreProperties, endpoint) {
        var apiURL = Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].BACKEND_BASE_URL;
        apiURL += endpoint;
        apiURL += '?userID=' + userCoreProperties.userID;
        apiURL += '&accessToken=' + userCoreProperties.accessToken;
        apiURL += '&platform=' + 'web';
        apiURL += '&client=' + Globals.APP_NAME;

        return apiURL;
    }

    static reportError(error) {

        var apiURL = Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].BACKEND_BASE_URL;
        apiURL += 'Miscellaneous/reportError';

        fetch(apiURL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(error),
        })
            .then(response => {
                //console.log("response: " + response);
            })
            .catch(error => {
                console.log('Something bad happened ' + error);
            });
    }

    static validateUserName(username) {

        if (typeof username !== 'string' || username.match(/[^a-z0-9 ]/i))
            return false;
        else
            return true;
    }

    static validateName(username) {
        if (typeof username !== 'string' || !username.match(/^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$/i))
            return false;
        else
            return true;
    }

    static validateEmail(email) {
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return email.match(regex);
    }

    static validateNumber(input) {
        var regex = /^[0-9\b]+$/;
        if (input !== '' && regex.test(input)) {
            return true;
        } else {
            return false;
        }
    }

    static validatePassword(pass) {
        var regex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
        return regex.test(pass);
    }

    static setCookie(cName, cValue) {
        cookies.set(cName, cValue, { Domain: '*.triumphhq.com', path: '/' });
    }

    static getCookie(cName) {
        return cookies.get(cName);
    }

    static removeCookie(cName) {
        cookies.remove(cName)
    }

    static clearCookies() {
        const allCookies = cookies.getAll();
        for (const cookie in allCookies) {
            cookies.remove(cookie);
        }
        const documentCookies = document.cookie.split(";");
        for (let i = 0; i < documentCookies.length; i++) {
            document.cookie = `${documentCookies[i].split("=")[0]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
    }

    static lessThanOneDay(date) {
        const DAY = 1000 * 60 * 60 * 24;
        const aDayAgo = Date.now() - DAY;

        return date > aDayAgo;
    }

    static getQueryStringParams(query) {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
                )
            : {}
    };

}