import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import JSON from '../../locales/en/tranJson.json'
import { connectDataContext } from '../common/DataProvider';



class GoogleTranslator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [JSON]
        };
    }
    googleTranslateElementInit = () => {
        
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                autoDisplay: true
            },
            "google_translate_element"
        );
    };

    componentDidMount() {
        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = this.googleTranslateElementInit;
    }

    render() {
        const { data } = this.state;
        return (

            <div className="section-container">
                <div id="google_translate_element"></div>
                <div className='row  col-md-12'>

                    <div className=' col-md-6 '>
                        {/* <tr>

                        <th>Country</th>
                    </tr> */}
                        <tbody>
                            {data[0].map(values =>
                                <tr>

                                    <td className=''>
                                        {values.Translate}
                                    </td>

                                </tr>)}
                        </tbody>

                    </div>
                    <div className=' col-md-6 notranslate'>
                        {/* <tr>

                        <th>Country</th>
                    </tr> */}
                        <tbody>
                            {data[0].map(values =>
                                <tr>

                                    <td className=''>
                                        {values.Translate}
                                    </td>

                                </tr>)}
                        </tbody>

                    </div>
                </div>
            </div >

        );
    }
}
GoogleTranslator.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    flag: state.region.flag,
    unit: state.region.unit
});

export default connectDataContext(
    connect(mapStateToProps, {})(
        GoogleTranslator
    )
);

