import React, { Component } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { history } from '../../utils/history';
import Modalbootstrap from 'react-bootstrap/Modal'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {
    T,
    LanguageList,
    Config
} from "react-translator-component";
import IdleTimer from 'react-idle-timer';
import API from './../../utils/config.json';
import ApiServices from '../../utils/ApiServices';
import PersonIcon from '@mui/icons-material/Person';
import Util from '../../utils/Util';
import { createCookies } from './createCookies';
import { cleardataHoseFilters } from '../../IndexedDB/userDataDB';
import TempLogo from '../../images/temp-logo.png'
import { connectDataContext } from '../common/DataProvider';
import { AuthContext } from '../auth-hook/auth-context';

Config.default = "en";

Config.list = {

    "en": {
        text: "English",
        file: require("../../locales/en/tran")
    },
    "es": {
        text: "Español",
        file: require("../../locales/es/tran")
    },
    "fr": {
        text: "Français",
        file: require("../../locales/fr/tran")
    },
    "de": {
        text: "Deutsch",
        file: require("../../locales/de/tran")
    },
    "ja": {
        text: "Japanese",
        file: require("../../locales/js/tran")
    },
    "pl": {
        text: "Polski",
        file: require("../../locales/pl/tran")
    },
    "pt": {
        text: "Portuguese",
        file: require("../../locales/pt/tran")
    },
    "ru": {
        text: "Russian",
        file: require("../../locales/ru/tran")
    },
    "zh-CN": {
        text: "Chinese",
        file: require("../../locales/zh/tran")
    }
};



class NavBar extends Component {
    static contextType = AuthContext
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.logoutTimer = null;
        this.handleOnAction = this.handleOnAction.bind(this)
        this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.handleApply = this.handleApply.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);

        var CurrentUnit = Util.getCookie('unit');
        this.state = {
            isOpen: false,
            open: false,
            selectedCountry: props.country,
            selectedLanguage: props.language,
            selectedRegion: props.region,
            defaultUnit: (CurrentUnit ? CurrentUnit : ""),
            showTimeoutModal: false,
            display: 'none',
            regions: [],
            regionLangSelected: false
        }
    }

    googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "auto",
                autoDisplay: true
            },
            "google_translate_element"
        );
    };


    async componentDidMount() {
        await this.getRegions();

        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = this.googleTranslateElementInit;

    }

    getRegions = async () => {
        var url = API.URL + "countries?$orderby=COUNTRY_NAME";
        await ApiServices.httpGET(url, (err, response) => {
            if (response?.data?.d?.results) {

                var regions = response.data.d.results;
                for (var i = 0; i < regions.length; i++) {
                    if (regions[i].COUNTRY_CODE === "BU") {
                        regions[i].COUNTRY_CODE = "BN"
                    }
                    if (regions[i].COUNTRY_CODE === "CB") {
                        regions[i].COUNTRY_CODE = "KH"
                    }
                    if (regions[i].COUNTRY_CODE === "DR") {
                        regions[i].COUNTRY_CODE = "DO"
                    }
                    if (regions[i].COUNTRY_CODE === "HA") {
                        regions[i].COUNTRY_CODE = "HT"
                    }
                    if (regions[i].COUNTRY_CODE === "HO") {
                        regions[i].COUNTRY_CODE = "HN"
                    }
                    if (regions[i].COUNTRY_CODE === "PU") {
                        regions[i].COUNTRY_CODE = "PE"
                    }
                    if (regions[i].COUNTRY_CODE === "PU") {
                        regions[i].COUNTRY_CODE = "PE"
                    }
                    if (regions[i].COUNTRY_CODE === "UR") {
                        regions[i].COUNTRY_CODE = "UY"
                    }
                    if (regions[i].COUNTRY_CODE === "VT") {
                        regions[i].COUNTRY_CODE = "VN"
                    }
                }
                this.setState({ regions: response.data.d.results });
            };
        })
    }

    navigate(link) {
        this.props.history.replace(link)
    }

    // Render private links
    renderNavbarAuthLinks() {
        var email = Util.getCookie('email')
        return (
            <Nav className="ml-auto" navbar >
                <NavItem>
                    <div className="btn-group">
                        <NavItem>

                            <NavLink>

                                {this.renderRegionselector()}
                            </NavLink>
                        </NavItem>

                        {/* <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <PersonIcon />
                        </button> */}



                        <NavItem>
                            <NavLink>


                                <PersonIcon className='flag-pos cursor person-icon' onClick={this.handleDisplay} />
                            </NavLink>

                        </NavItem>

                        <div className="dropdown-menu dropdown-menu-right custom-dropmenu" style={{ display: this.state.display }}>
                            <span><b>{T('WELCOME')}, {email}</b></span>
                            <hr />
                            {/* <NavLink tag={RRNavLink} exact to="/Dashboard">Dashboard</NavLink> */}
                            <NavLink tag={RRNavLink} exact to="/Dashboard" onClick={this.handleDisplay}>
                                <span class="material-symbols-outlined notranslate">dashboard</span>&nbsp;{T('Dashboard')}
                            </NavLink>
                            <NavLink tag={RRNavLink} exact to="/Favorites" onClick={this.handleDisplay}>
                                <span class="material-symbols-outlined notranslate">favorite</span>&nbsp;{T('Favorites')}
                            </NavLink>
                            <NavLink tag={RRNavLink} exact to="/ManageCrimpSheets" onClick={this.handleDisplay}>
                                <span className="material-symbols-outlined notranslate">library_books</span>&nbsp;{T('Crimp Sheets')}
                            </NavLink>
                            <NavLink tag={RRNavLink} exact to="/HoseFiltersfromDashboard" onClick={this.handleDisplay}>
                                <span class="material-symbols-outlined notranslate">tune</span>&nbsp;{T('Hose Filters')}
                            </NavLink>
                            <NavLink tag={RRNavLink} exact to="/MyAccount" onClick={this.handleDisplay}>
                                <span className="material-symbols-outlined notranslate">settings</span>&nbsp;{T('My Account')}
                            </NavLink>
                            {/* <NavLink tag={RRNavLink} exact to="/change-password/" activeClassName="active">Change Password</NavLink> */}
                            <hr />
                            <NavLink className="text-center logout" onClick={this.logout.bind(this)}>{T('Logout')}</NavLink>
                        </div>
                    </div>
                </NavItem>
            </Nav>
        );
    }

    // Render public/guests links
    renderNavbarGuestLinks() {

        return (

            <Nav className="ml-auto" navbar>

                <NavItem>
                    <NavLink>
                        {this.renderRegionselector()}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={RRNavLink} exact to="/Signin">
                        <PersonIcon className='flag-pos cursor person-icon' />
                    </NavLink>
                </NavItem>
            </Nav>
        );
    }

    renderRegionselector() {

        // const Language = localStorage.getItem("language") || "en";
        const Language = this.props.language

        const country = this.props.country.toUpperCase()
        return (
            <>
                <img className='flag-pos cursor flag-icon' src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`} alt="flag" onClick={this.show} />
                <LanguageList Language={Language} />
            </>
        );
    }

    show = (type) => {
        // Shows the selected popup
        this.setState({ open: true, selectedLanguage: this.props.language, selectedCountry: this.props.country });
    }
    hide = (type) => {
        // Hides the selected popup
        this.setState({ open: false });
    }

    handleRegionChange = async (event) => {
        const region = event.nativeEvent.target[event.target.selectedIndex].getAttribute("data-region")
        const country = event.target.value
        const unit = event.nativeEvent.target[event.target.selectedIndex].getAttribute("data-unit")

        this.setState({
            defaultUnit: unit,
            selectedCountry: country,
            selectedRegion: region,
        });
    }
    
    handleLanguageChange = async (event) => {
        const selectedLanguage = event.target.value
        this.setState({ selectedLanguage })
    }
    
    handleApply = async () => {
        Util.removeCookie("selectedCrimper")
        localStorage.removeItem("HoseFilters")
        await this.props.setSelectedLanguage(this.state.selectedLanguage)
        await this.props.setSelectedRegion(this.state.selectedRegion)
        await this.props.setSelectedCountry(this.state.selectedCountry)
        cleardataHoseFilters()
        this.setState({ open: false });
        this.props.applyChanges()
    }

    togglePopup = () => {
        this.setState(prevState => ({ showTimeoutModal: !prevState.showTimeoutModal }));
    }
    handleOnAction(event) {
        this.setState({ isTimedOut: false })
    }

    handleOnActive(event) {
        this.setState({ isTimedOut: false })
    }

    handleOnIdle(event) {

        const { isLoggedIn } = this.context
        if (isLoggedIn) {
            this.togglePopup();
            this.logoutTimer = setTimeout(() => {
                this.handleLogout();
            }, 1000 * 60 * 25); // 5 minutes
        }
    }

    handleLogout = () => {
        this.logout();
    }

    handleDisplay = () => {
        this.state.display === 'none' ? this.setState({ display: 'table' }) : this.setState({ display: 'none' });
    }

    // NavBar toggle for mobile
    toggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    // Logout user from the app
    logout() {
        this.context.logout();
        cleardataHoseFilters()
        history.replace("/")
    }

    handleStayLoggedIn = () => {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
            this.logoutTimer = null;
        }
        this.idleTimer.reset();
        this.togglePopup();
    }

    renderTimeoutModal = () => {
        return (
            <Modal isOpen={this.state.showTimeoutModal} toggle={this.togglePopup} keyboard={false} backdrop="static">
                <ModalBody>
                    Your session is about to expire in 5 minutes due to inactivity.
                </ModalBody>
                <ModalFooter>
                    <Button className="change-btn" onClick={this.handleStayLoggedIn}>Stay Logged In</Button>
                </ModalFooter>
            </Modal>
        );
    }


    render() {


        const { isLoggedIn } = this.context;
        const { region, country, language, } = this.props
        const { open } = this.state
        var page = window.location.pathname
        var unit = this.state.defaultUnit
        let stored_data = Util.getCookie("digital_data")

        // Setting Global Variable to get data in Console

        if (stored_data) {
            stored_data["page"] = page
            stored_data["region"] = region
            stored_data["language"] = language
            stored_data["country"] = country
            stored_data["unit"] = unit
            stored_data["loggedinStatus"] = 'Loggedin'
            window.Digitaldata = stored_data;

        } else {
            var digitaldata = {
                name: 'anonymous',
                loggedinStatus: 'NotLoggedin'
            }
            digitaldata["page"] = page
            digitaldata["region"] = region
            digitaldata["language"] = language
            digitaldata["country"] = country
            digitaldata["unit"] = unit

            window.Digitaldata = digitaldata;

        }


        return (
            <Navbar expand="md">
                <NavbarBrand tag={RRNavLink} exact to={"/"}>
                    <img src={TempLogo} alt="Gates eCrimp" />
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle.bind(this)} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    {isLoggedIn ? this.renderNavbarAuthLinks() : this.renderNavbarGuestLinks()}
                </Collapse>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 30} // 10 minutes
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    stopOnIdle={true}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                {this.renderTimeoutModal()}

                <Modalbootstrap
                    show={open}
                    onHide={open}
                    dialogClassName="modal-60w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <div className="banner-notify">
                        <p>
                            {
                                T('Welcome to the NEW eCrimp website.  It has all of the functionality of the previous eCrimp website, plus much more!\nPlease set up a user account, which will allow you to use new features and capabilities, such as selecting and keeping favorites, and saving and printing spec sheets.')
                            }
                        </p>
                    </div>
                    <Modalbootstrap.Header className="text-center">
                        <Modalbootstrap.Title id="example-custom-modal-styling-title" className="regionSelectorModalTitle">

                            <div className='header-padding'>

                                <div className="underlinestyle">
                                    <h2>{T("CHOOSE YOUR REGION OR LANGUAGE")}</h2>
                                </div>

                            </div>
                        </Modalbootstrap.Title>
                    </Modalbootstrap.Header>
                    <Modalbootstrap.Body className="regionSelectorModalBody">

                        <div className="row">

                            <div className="offset-md-2 col-md-4">

                                <h3>{T('Region')}</h3>
                                <select className='select-dropmenu text-box-border' value={this.state.selectedCountry}
                                    onChange={this.handleRegionChange.bind(this)}>
                                    {this.state.regions.map(r => (
                                        <option key={r.COUNTRY_CODE} value={r.COUNTRY_CODE} data-region={r.REGION_CODE}
                                            data-unit={r.DEFAULT_UNIT}>
                                            {r.COUNTRY_NAME}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-4 notranslate">
                                <h3>{T('Language')}</h3>
                                <select className='select-dropmenu text-box-border' value={this.state.selectedLanguage}
                                    onChange={this.handleLanguageChange.bind(this)}>
                                    <option selected value="en">English</option>
                                    <option value="fr">Français</option>
                                    <option value="es">Español</option>
                                    <option value="de">Deutsch</option>
                                    <option value="ru">Русский</option>
                                    <option value="ja">言語</option>
                                    <option value="pl">Polski</option>
                                    <option value="zh-CN">语言</option>
                                    <option value="pt">Português</option>
                                </select>
                            </div>
                        </div>
                    </Modalbootstrap.Body>
                    <Modalbootstrap.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.hide()}>{T("Close")}</button>
                        {
                            (region && region !== "null" && language && language !== "null" && language !== "None")
                                ?
                                <button className="apply-btn-modal" type="button" onClick={() => this.handleApply()}>{T("Apply")}</button>
                                :
                                ""
                        }
                    </Modalbootstrap.Footer>
                </Modalbootstrap>
            </Navbar>
        );
    }
}

export default connectDataContext(NavBar);
