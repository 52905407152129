import React, { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
import RedirectRoute from "./components/common/RedirectRoute";
import Spinner from "./components/common/Spinner";

import { AuthProvider } from "./components/auth-hook/auth-context";
// General Layouts
import Footer from "./components/layout/Footer";
import NavBar from "./components/layout/NavBar";
import NotFound from "./components/not-found/NotFound";

// Auth Pages

import ChangePassword from "./components/auth/ChangePassword";
import ManageForgot from "./components/auth/Forgotpassword/ManageForgot";
import MyAccount from "./components/auth/MyAccount/MyAccount";
import Signin from "./components/auth/Signin/Signin";
import Registration from "./components/auth/Signup/Registration";
import Status from "./components/auth/Status";
// Dashboard
import Favorites from "./components/Favorites/Favorites";
import HoseFiltersPage from "./components/HoseFilters/HoseFiltersPage";
import ResetPassword from "./components/auth/Forgotpassword/ResetPassword";
import Dataversion from "./components/common/Dataversion";
import MainDashboard from "./components/dashboard/MainDashboard";
import ManageCrimp from "./components/dashboard/ManageCrimp";
import StartupPage from "./components/dashboard/StartupPage";
import GoogleTranslator from "./components/layout/GoogleTranslator";
//Used for Ondemand// import ecrimpTransalations from './components/layout/ecrimpTransalations'
import { DataContext } from "./components/common/DataProvider";
import EncryptionService from "./services/EncryptionService";
import DeleteAccount from "./user/pages/DeleteAccount/DeleteAccount";

const Navigation = () => {
  const { language } = useContext(DataContext);

  const history = useHistory();

  return (
    <AuthProvider>
      <Router history={history}>
        <div className="App">
          <div className="container-fluid">
            <Spinner />
            <NavBar />
            <Switch>
              {/* Public Routes */}
              <Route path="/user/delete" exact component={DeleteAccount} />
              <Route path="/encryption" exact component={EncryptionService} />
              <Route path="/" exact component={StartupPage} />
              <RedirectRoute path="/status" exact component={Status} />

              {/* Latest PUBLIC Routes */}
              <RedirectRoute path="/Signin" component={Signin} />
              <RedirectRoute
                path="/Forgotpassword"
                exact
                component={ManageForgot}
              />
              <RedirectRoute
                path="/Registration"
                exact
                component={Registration}
              />
              <RedirectRoute path="/reset-password" component={ResetPassword} />

                            {/* End of Public Routes */}

                            {/* Private Routes */}

                            {/* Dashboard routes */}

              {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
              <Route exact path="/Dashboard" component={MainDashboard} />
              <Route exact path="/Dataversion" component={Dataversion} />
              <Route
                exact
                path="/HoseFiltersfromDashboard"
                component={HoseFiltersPage}
              />
              <PrivateRoute path="/Favorites" exact component={Favorites} />
              <PrivateRoute
                path="/ManageCrimpSheets"
                exact
                component={ManageCrimp}
              />
              <PrivateRoute
                path="/MyAccount"
                exact
                render={(props) => <MyAccount language={language} {...props} />}
              />

              <PrivateRoute
                path="/change-password"
                exact
                component={ChangePassword}
              />
              <RedirectRoute path="/reset-password" component={ResetPassword} />
              <Route
                path="/GoogleTranslator"
                exact
                component={GoogleTranslator}
              />
              {/* Used for ondemand <Route
                path='/ecrimpTransalations'
                exact
                component={ecrimpTransalations}
  />*/}

                            {/* End of Dashboard routes */}

                            {/* End of Private Routes */}

              <Route exact path="*" component={NotFound} />
            </Switch>
            <Footer />
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default Navigation;
