module.exports = {

    APP_NAME: "Project Name",

    /* ASYNC STORAGE KEYS */
	ASYNC_STORAGE_KEYS: {
        USER_CORE_PROPERTIES: 'AS_APP_USER_PROPERTIES',
        CORE_DATA: 'CORE_DATA',
    },

    /* ENVIRONMENT */
    ENVIRONMENT: {

        ENV: process.env.NODE_ENV === 'development' ? 'DEV' : (process.env.TARGET_ENV === 'staging' ? 'STAGING' : 'PRODUCTION'),

        DEV: {
            BACKEND_BASE_URL: 'http://localhost:1337/',
        },

        STAGING: {
            BACKEND_BASE_URL: 'http://localhost:1337/',
        },

        PRODUCTION: {
            BACKEND_BASE_URL: 'http://localhost:1337/',
        },
    },
};
