module.exports = {
  "Dashboard":"Dashboard",
  "FAVORITES" : "FAVORITES",
  "SAVED CRIMP SHEETS":"SAVED CRIMP SHEETS",
  "KNOWLEDGE CENTER":"KNOWLEDGE CENTER",
  "LOGIN / SIGN-UP" :"LOGIN / SIGN-UP",
  "FIND CRIMP SPECS":"FIND CRIMP SPECS",
  "What Do You Want To Do?":"WHAT DO YOU WANT TO DO?",
  "Select Your Crimper":"Select Your Crimper",
  "CHOOSE YOUR REGION OR LANGUAGE":"CHOOSE YOUR REGION OR LANGUAGE",
  "MyAccount":"MY ACCOUNT",
  "Email Address": "Email Address",
  "Password": "Password",
  "FORGOT PASSWORD": "FORGOT PASSWORD",
  "LOGIN": "LOGIN",
  "Login": "Login",
  "SIGN UP": "SIGN UP",
  "Don't have an account": "Don't have an account",
  "Close": "Close",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Company": "Company",
  "Country": "Country",
  "Apply": "Apply",
  "Save": "Save",
  "Cancel": "Cancel",
  "Name": "Name",
  "Reset":"Reset",
  "Send":"Send",
  "Yes":"Yes",
  "No":"No",
  "Do you wan't to delete":"Do you wan't to delete?",
  "DISCLAIMER": "DISCLAIMER",
  "Back to Dashboard":"Back to Dashboard",
  "Confirmation":"Confirmation",
  "Delete Confirmation":"Delete Confirmation",
  "Units":"Units",
  "Back": "Back",

  "Specifications":"Specifications",
  "Click to view Specifications":"Click to view Specifications",
  "Save favorites to your eCrimp account to save time retrieving data on your most used specifications.": "Save favorites to your eCrimp account to save time retrieving data on your most used specifications.",
  "Save crimp sheet to your eCrimp account. You can view anytime or print a copy.": "Save crimp sheet to your eCrimp account. You can view anytime or print a copy.",
  "Find tutorials, product catalogs, training resources and more.": "Find tutorials, product catalogs, training resources and more.",
  "Set up a free account to access helpful site functions, such as saving favorites and creating Crimp Sheet.": "Set up a free account to access helpful site functions, such as saving favorites and creating Crimp Sheet.",
  "Manage Crimp Sheets": "Manage Crimp Sheets",
  "Rename Crimp Sheet": "Rename Crimp Sheet",
  "Do you want to delete this Crimp sheet":"Do you want to delete this Crimp sheet?",
  "Do you want to delete this record":"Do you want to delete this record?",

  "Favorites": "FAVORITES",
  "Save the crimp specifications you use most often for quick access and ease of use.": "Save the crimp specifications you use most often for quick access and ease of use.",
  "Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.": "Crimp data may sometimes change. Printed crimp specifications are uncontrolled, and should be re-printed every 6 months or any time a new eCrimp revision is released. All settings are APPROXIMATE. Always check the final crimp OD to ensure the crimp has been properly formed.",
  "Do you want to remove from Favorites": "Do you want to remove from Favorites?",

  "Download PDF": "Download PDF",
  "Download Selected": "Download Selected",
  "View All Crimp Sheets": "View All Crimp Sheets",
  "Add to Crimp Sheet": "Add to Crimp Sheet",
  "Create New Crimp Sheet":"+ Create New Crimp Sheet",
  "Show Metric Units":"Show Metric Units",
  "Show English Units":"Show English Units",
  "Total records found":"Total records found",
  "Add New Crimp Sheet":"Add New Crimp Sheet",
  "Applied Filters":"Applied Filters",

  "Please check your email for password reset instructions.": "Please check your email for password reset instructions.",
  "We are experiencing a technical difficulty. Please try again after sometime.": "We are experiencing a technical difficulty. Please try again after sometime.",
  "No account associated with the email. Please use SIGN UP to register to the site.": "No account associated with the email. Please use SIGN UP to register to the site.",

  "Email and token verification failed.":"Email and token verification failed.",
  "The new password and confirm password should match.": "The new password and confirm password should match.",
  "Password was successfully updated.": "Password was successfully updated.",
  "We are experiencing a technical difficulty. Please try again later.": "We are experiencing a technical difficulty. Please try again later.",
  "Please enter values for new password and confirm password.": "Please enter values for new password and confirm password.",

  "Knowledge Center":"Knowledge Center",
    "Click on a link below to view the desired Gates eCrimp document.": "Click on a link below to view the desired Gates eCrimp document.",

        "ResetPassword.PasswordUpdated": "Password updated successfully!",
    "ForgotPassword.EmailInstructions": "We sent an e-mail with a link to Reset your password!",
    "ForgotPassword.NoAccount": "No Account Exists",
};
