import appDB from './indexedDB'
const db = appDB.db
export async function saveDataInIndexDB(data) {
    if (data) {
        if (db.testData) {
            await db.testData.clear()
            await db.testData.add({ datakey: data })
        }
    }
}
export async function saveAccessoriesDataInIndexDB(data) {
    if (data) {
        if (db.accessories) {
            await db.accessories.clear()
            await db.accessories.add({ Filteredaccessories: data })
        }
    }
}
export async function saveFavoritesDataInIndexDB(data) {
  if (data) {
    if (db.favoritesData) {
      await db.favoritesData.clear()
      await db.favoritesData.add({ favoritesData: data })
    }
  }
}
export async function saveresponseDataInIndexDB(data) {
    if (data) {
        if (db.responseCrimpers) {
            await db.responseCrimpers.clear()
            await db.responseCrimpers.add({ responseCrimpers: data })
        }
    }
}
export async function savefinalNotesDashboardDataInIndexDB(data) {
    if (data) {
        if (db.finalNotesDashboard) {
            await db.finalNotesDashboard.clear()
            await db.finalNotesDashboard.add({ finalNotesDashboard: data })
        }
    }
}
export async function saveAppliedNotesDataDataInIndexDB(data) {
    if (data) {
        if (db.AppliedNotesData) {
            await db.AppliedNotesData.clear()
            await db.AppliedNotesData.add({ AppliedNotesData: data })
        }
    }
}

export async function clearAppliedNotesData() {
    if (db.AppliedNotesData) await db.AppliedNotesData.clear()
}
export async function clearUserdata() {
    if (db.userData) await db.userData.clear()
}
export async function clearresponseCrimpers() {
    if (db.responseCrimpers) await db.responseCrimpers.clear()
}

export async function saveUserdataInIndexDB(data) {
    if (data) {
        if (db.userData) await db.userData.clear()
        db.userData.add({ userData: data })
    }
}

export async function getuserDataFromIndexDB() {
  const testData = await db.userData.toArray()
  if (testData && testData.length > 0) {
    return testData[0].userData
  }
  return null
}

export async function getfinalNotesDashboardDataFromIndexDB() {
  const testData = await db.finalNotesDashboard.toArray()
  if (testData && testData.length > 0) {
    return testData[0].finalNotesDashboard
  }
  return []
}

export async function getAppliedNotesDataDataFromIndexDB() {
  const testData = await db.AppliedNotesData.toArray()
  if (testData && testData.length > 0) {
    return testData[0].AppliedNotesData
  }
  return []
}

export async function getresponseDataFromIndexDB() {
    const testData = await db.responseCrimpers
        .where('responseCrimpers')
        .equals('responseCrimpers')
        .toArray()
    if (testData && testData.length > 0) {
        return testData[0]
    }
    return []
}

export async function getDataFromIndexDB() {
    const testData = await db.testData
        .where('datakey')
        .equals('datakey')
        .toArray()
    if (testData && testData.length > 0) {
        return testData[0]
    }
    return []
}
export async function getAccessoriesDataFromIndexDB() {
    const testData = await db.accessories
        .where('Filteredaccessories')
        .equals('Filteredaccessories')
        .toArray()
    if (testData && testData.length > 0) {
        return testData[0]
    }
    return []
}
export async function getFavoritesDataFromIndexDB() {
    const testData = await db.favoritesData
        .where('favoritesData')
        .equals('favoritesData')
        .toArray()
    if (testData && testData.length > 0) {
        return testData[0].data
    }
    return []
}
export async function clearNotesData() {
    await db.finalNotesDashboard.clear()
}

export const saveSelectedCrimper = async selectedCrimper => {
    const crimper = await db.selectedCrimper.get({ selectedCrimper });
    if (crimper !== selectedCrimper) {
        await db.selectedCrimper.clear()
        await db.selectedCrimper.add({ selectedCrimper })
    }
}

export const clearSelectedCrimper = () => db.selectedCrimper.clear()

export const getSelectedCrimper = async () => {
    const crimper = await db.selectedCrimper.limit(1).toArray()
    return crimper[0]
}

export const saveSelectedRegion = async selectedRegion => {
    const region = await db.selectedRegion.get({ region: selectedRegion });
    if (!region) {
        await db.selectedRegion.clear()
        await db.selectedRegion.add({ region: selectedRegion })
    }
}

export const getSelectedRegion = async () => {
    const region = await db.selectedRegion.limit(1).toArray()
    return region[0]
}

export const saveSelectedLanguage = async selectedLanguage => {
    const language = await db.selectedLanguage.get({ language: selectedLanguage });
    if (!language) {
        await db.selectedLanguage.clear()
        await db.selectedLanguage.add({ language: selectedLanguage })
    }
}

export const getSelectedLanguage = async () => {
    const language = await db.selectedLanguage.limit(1).toArray()
    return language[0]
}

export const saveSelectedCountry = async selectedCountry => {
    const country = await db.selectedCountry.get({ country: selectedCountry });
    if (!country) {
        await db.selectedCountry.clear()
        await db.selectedCountry.add({ country: selectedCountry })
    }
}

export const getSelectedCountry = async () => {
    const country = await db.selectedCountry.limit(1).toArray()
    return country[0]
}
