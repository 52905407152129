import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-table-v6/react-table.css";
import "react-toastify/dist/ReactToastify.css";
import "../dashboard/DashboardStyles.css";

import "jspdf-autotable";
import "react-bootstrap/Accordion";
import "./HoseFiltersPage.scss";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import TextField from "@mui/material/TextField";
import { ToastContainer } from "react-toastify";
import { T } from "react-translator-component";
import Modal from "react-bootstrap/Modal";
import ReactTable from "react-table-v6";
import ApiServices from "../../utils/ApiServices";
import API from "../../utils/config.json";
import { DataContext, PressureTypes } from "../common/DataProvider";

function HoseFiltersPage(props) {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [rawData, setRawData] = useState([]);
    const [crimpers, setCrimpers] = useState([]);
    const [filteredCrimpers, setFilteredCrimpers] = useState([]);
    const [selectedCrimper, setSelectedCrimper] = useState(null);
    const [hoses, setHoses] = useState([]);
    const [filteredHoses, setFilteredHoses] = useState([]);
    const [selectedHose, setSelectedHose] = useState(null);
    const [filteredDashSizes, setFilteredDashSizes] = useState([]);
    const [selectedDashSizes, setSelectedDashSizes] = useState([]);
    const [filteredDieSets, setFilteredDieSets] = useState([]);
    const [selectedDieSets, setSelectedDieSets] = useState([]);
    const [filteredStemTypes, setFilteredStemTypes] = useState([]);
    const [selectedStemTypes, setSelectedStemTypes] = useState([]);
    const [filteredWorkingPressures, setFilteredWorkingPressures] = useState([]);

  const { region, pressureType } = useContext(DataContext);

    const resetFilters = () => {
        setLoading(false);
        setSearch("");
        setFilteredCrimpers(crimpers);
        setSelectedCrimper(null);
        setHoses(hoses);
        setSelectedHose(null);
        setSelectedCrimper(null);
        setSelectedDashSizes([]);
        setSelectedDieSets([]);
        setSelectedStemTypes([]);
    };

    const reset = () => {
        setLoading(false);
        setSearch("");
        setRawData([]);
        setCrimpers([]);
        setFilteredCrimpers([]);
        setSelectedCrimper(null);
        setHoses([]);
        setFilteredHoses([]);
        setSelectedHose(null);
        setFilteredDashSizes([]);
        setSelectedDashSizes([]);
        setFilteredDieSets([]);
        setSelectedDieSets([]);
        setFilteredStemTypes([]);
        setSelectedStemTypes([]);
        setFilteredWorkingPressures([]);
    };

    const applyAllFilters = (hose, crimper, dashes, stems, dies) => (data) => {
        return (
            (!hose || hose === data.DESCRIPTION) &&
            (!crimper || crimper === data.CRIMPER_ID) &&
            (!dashes.length || dashes.includes(data.DASH_SIZE)) &&
            (!stems.length || stems.includes(data.STEM_TYPE)) &&
            (!dies.length || dies.includes(data.DIE_ID))
        );
    };

  const filterAll = (data, hose, crimper, dashes, stems, dies) => {
    const filtered = data.filter(
      applyAllFilters(hose, crimper, dashes, stems, dies)
    );
    setFilteredHoses(getFilteredByKey("DESCRIPTION", filtered));
    setFilteredCrimpers(getFilteredByKey("CRIMPER_ID", filtered));
    setFilteredDashSizes(getFilteredByKey("DASH_SIZE", filtered));
    setFilteredStemTypes(getFilteredByKey("STEM_TYPE", filtered));
    setFilteredDieSets(getFilteredByKey("DIE_ID", filtered));
    if (pressureType === PressureTypes.mpa) {
      setFilteredWorkingPressures(
        getFilteredByKey("WORKING_PRESSURE_SI", filtered)
      );
    } else {
      setFilteredWorkingPressures(
        getFilteredByKey("WORKING_PRESSURE", filtered)
      );
    }
  };

    const getFilteredByKey = (key, data) => [
        ...new Map(data.map((item) => [item[key], item])).values(),
    ];

    const getCrimpersData = useCallback(async () => {
        setLoading(true);
        // let url = API.URL + "crimpers?$format=json&$filter=REGION_ID eq '" + region + "'&$select=CRIMPER_ID,URL"
        const url = `${API.URL}/crimpers/filters?&$orderby=DESCRIPTION&$filter=REGION_ID eq '${region}' and  (HOSE_TYPE_ID eq 'HYD' or HOSE_TYPE_ID eq 'IND')&$format=json`;

        // // debugger
        const response = await ApiServices.httpGET(url);
        const results = response?.data?.d?.results;
        if (results) {
            setRawData(results);
        }

        setLoading(false);
    }, [props, region]);

    const requestSearch = (e) => {
        e.preventDefault();

        const name = e.target.value;
        const filterCrimpers = crimpers.filter((obj) =>
            JSON.stringify(obj).toLowerCase().includes(name.toLowerCase())
        );
        const filterHoses = hoses.filter((obj) =>
            JSON.stringify(obj).toLowerCase().includes(name.toLowerCase())
        );
        setSearch(name);
        setFilteredCrimpers(filterCrimpers);
        setFilteredHoses(filterHoses);
    };

    const cancelSearch = () => {
        setSearch("");
        setFilteredCrimpers(crimpers);
        setFilteredHoses(hoses);
    };

    const setResultHoses = (results) => {
        const res = [
            ...new Map(results.map((item) => [item.DESCRIPTION, item])).values(),
        ];

        setHoses(res);
        setFilteredHoses(res);
    };

    const setResultCrimpers = (results) => {
        const res = [
            ...new Map(results.map((item) => [item.CRIMPER_ID, item])).values(),
        ];

        setCrimpers(res);
        setFilteredCrimpers(res);
    };

    const setResultDashSizes = (results) => {
        const res = [
            ...new Map(results.map((item) => [item.DASH_SIZE, item])).values(),
        ];
        setFilteredDashSizes(res);
    };

    const setResultStemTypes = (results) => {
        const res = [
            ...new Map(results.map((item) => [item.STEM_TYPE, item])).values(),
        ];
        setFilteredStemTypes(res);
    };

    const setResultDieSets = (results) => {
        const res = [
            ...new Map(results.map((item) => [item.DIE_ID, item])).values(),
        ];
        setFilteredDieSets(res);
    };

    const setResultWorkingPressures = (results) => {
        const res = [
            ...new Map(
                results.map((item) => [item.WORKING_PRESSURE_SI, item])
            ).values(),
        ];
        setFilteredWorkingPressures(res);
    };

    useEffect(() => {
        if (rawData) {
            setResultCrimpers(rawData);
            setResultHoses(rawData);
            setResultDashSizes(rawData);
            setResultStemTypes(rawData);
            setResultDieSets(rawData);
            setResultWorkingPressures(rawData);
        }
    }, [rawData]);

    useEffect(() => {
        getCrimpersData();

        return () => {
            reset();
        };
    }, [getCrimpersData]);

    useEffect(() => {
        filterAll(
            rawData,
            selectedHose,
            selectedCrimper,
            selectedDashSizes,
            selectedStemTypes,
            selectedDieSets
        );
    }, [
        rawData,
        selectedHose,
        selectedCrimper,
        selectedDashSizes,
        selectedStemTypes,
        selectedDieSets,
    ]);

  const hasFilters =
    selectedHose !== null ||
    selectedCrimper !== null ||
    selectedDashSizes.length > 0 ||
    selectedStemTypes.length > 0 ||
    selectedDieSets.length > 0;

  const takeToCrimpSpecs = () => {
    const fields = {
      selectedCrimper,
      selectedHose,
      selectedDashSizes,
      selectedStemTypes,
      selectedDieSets,
    };
    const query = {};
    Object.keys(fields).forEach((key) => {
      const value = fields[key];
      if (Array.isArray(value)) {
        if (value.length > 0) query[key] = value;
      } else {
        if (value) query[key] = value;
      }
    });
    const url = `/Dashboard?${new URLSearchParams(query).toString()}`;
    props.history.push(url);
  };

  return (
    <div className="dashboardContainer">
      <div
        className={`${loading ? "show" : "hide"} partial-loader`}
        id="loading"
      >
        <div className="loading-message" />
      </div>
      <ToastContainer />
      <div className="center-hose-search mt-2 display-flex">
        <TextField
          fullWidth
          id="standard-bare"
          variant="filled"
          placeholder="Search by Hose or Crimper"
          value={search || ""}
          onChange={(e) => requestSearch(e)}
          className="search-box-user margin-left-5"
          InputProps={
            search.length === 0
              ? {
                  endAdornment: (
                    <SearchOutlined className="cursor-pointer color-red" />
                  ),
                }
              : {
                  endAdornment: (
                    <HighlightOffIcon
                      className="cursor-pointer color-red"
                      onClick={(e) => cancelSearch()}
                    />
                  ),
                }
          }
        />
        <button className="reset-btn" type="button" onClick={resetFilters}>
          {T("Reset")}
        </button>
      </div>
      <div className="row col-md-12 mt-5">
        <div className="col-md-2 mt-2">
          <h4>SELECT HOSE</h4>

                    <div className="hosefilter-height" id="listHoseFilter">
                        {filteredHoses.map((value) => {
                            const selected = selectedHose === value.DESCRIPTION;
                            return (
                                <div
                                    className={`filter_row ${selected ? "selected" : ""}`}
                                    onClick={() => {
                                        setSelectedHose((previous) =>
                                            previous === value.DESCRIPTION ? null : value.DESCRIPTION
                                        );
                                    }}
                                    key={value.DESCRIPTION}
                                >
                                    <p className={`filter_text ${selected ? "selected" : ""}`}>
                                        {value.DESCRIPTION}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="col-md-2 mt-2">
                    <h4>SELECT CRIMPER</h4>

          <div className="hosefilter-height" id="listAllCrimper">
            {filteredCrimpers.map((value) => {
              return (
                <div
                  className={`filter_row ${
                    selectedCrimper === value.CRIMPER_ID ? "selected" : ""
                  }`}
                  selected={selectedCrimper === value.CRIMPER_ID}
                  key={value.CRIMPER_ID}
                  onClick={() => {
                    setSelectedCrimper((previous) =>
                      previous === value.CRIMPER_ID ? null : value.CRIMPER_ID
                    );
                  }}
                >
                  <p
                    className={`filter_text ${
                      selectedCrimper === value.CRIMPER_ID ? "selected" : ""
                    }`}
                  >
                    {value.CRIMPER_ID}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-2 mt-2">
          <h4>HOSE DASH SIZE</h4>

                    <div className="hosefilter-height" id="listhoseDashes">
                        {filteredDashSizes
                            .sort((a, b) => a.DASH_SIZE - b.DASH_SIZE)
                            .map((value) => {
                                const checked = selectedDashSizes.includes(value.DASH_SIZE);
                                return (
                                    <div className="mb-6 mt-4" key={value.DASH_SIZE}>
                                        <input
                                            className="checkbox-size"
                                            type="checkbox"
                                            id="checkedbox"
                                            checked={checked}
                                            onChange={() => {
                                                if (!checked) {
                                                    setSelectedDashSizes((previous) => [
                                                        ...previous,
                                                        value.DASH_SIZE,
                                                    ]);
                                                } else {
                                                    setSelectedDashSizes((previous) =>
                                                        previous.filter((size) => size !== value.DASH_SIZE)
                                                    );
                                                }
                                            }}
                                            value={value.DASH_SIZE}
                                        />
                                        {value.DASH_SIZE}
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="col-md-2 mt-2">
                    <h4>STEM TYPE</h4>

                    <div className="hosefilter-height" id="liststemTypes">
                        {filteredStemTypes.map((value) => {
                            const checked = selectedStemTypes.includes(value.STEM_TYPE);
                            return (
                                <div className="mb-6 mt-4" id="listboxes" key={value.STEM_TYPE}>
                                    <input
                                        className="checkbox-size"
                                        type="checkbox"
                                        id="checkedbox"
                                        checked={checked}
                                        onChange={() => {
                                            if (!checked) {
                                                setSelectedStemTypes((previous) => [
                                                    ...previous,
                                                    value.STEM_TYPE,
                                                ]);
                                            } else {
                                                setSelectedStemTypes((previous) =>
                                                    previous.filter((size) => size !== value.STEM_TYPE)
                                                );
                                            }
                                        }}
                                        value={value.STEM_TYPE}
                                    />
                                    {value.STEM_TYPE}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="col-md-2 mt-2">
                    <h4>DIE SET</h4>

          <div className="hosefilter-height" id="listdies">
            {filteredDieSets.map((value) => {
              const checked = selectedDieSets.includes(value.DIE_ID);
              return (
                <div className="mb-6 mt-4" id="listboxes" key={value.DIE_ID}>
                  <input
                    className="checkbox-size"
                    type="checkbox"
                    id="checkedbox"
                    checked={checked}
                    onChange={() => {
                      if (!checked) {
                        setSelectedDieSets((previous) => [
                          ...previous,
                          value.DIE_ID,
                        ]);
                      } else {
                        setSelectedDieSets((previous) =>
                          previous.filter((size) => size !== value.DIE_ID)
                        );
                      }
                    }}
                    value={value.DIE_ID}
                  />
                  {value.DIE_ID}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-2 mt-2">
          <h4>{`${T("WORKING PRESSURE")} (${pressureType})`}</h4>

          <div className="hosefilter-height" id="listpressures">
            {filteredWorkingPressures.map((value) => {
              return (
                <div
                  className="mb-6 mt-4 pressure-row"
                  id="listboxes"
                  key={
                    pressureType === PressureTypes.mpa
                      ? value.WORKING_PRESSURE_SI
                      : value.WORKING_PRESSURE
                  }
                >
                  <div>
                    {pressureType === PressureTypes.mpa
                      ? Number(value.WORKING_PRESSURE_SI).toFixed(2)
                      : Number(value.WORKING_PRESSURE).toFixed(2)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {hasFilters ? (
          <button
            className="crimp-specs-button"
            disabled={!selectedCrimper || !selectedHose}
            onClick={takeToCrimpSpecs}
          >
            {T("Take me to Crimp Specs")}
          </button>
        ) : null}
      </div>

      <Modal
        show={false}
        dialogClassName="modal-60W"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            <div className="underlinestyle">
              <b>{T("Units")}</b>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Hose-size-table">
            <ReactTable
              className="fullwidth-table hidepagination"
              data={[]}
              columns={[
                {
                  Header: "DASH SIZE",
                  accessor: "DASH_SIZE",
                },
                {
                  Header: "IN",
                  accessor: "INCH",
                },
                {
                  Header: "MM",
                  accessor: "MILLIMETER",
                },
              ]}
              showPageSizeOptions={false}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel-btn-modal" type="button" onClick={() => {}}>
            {T("Close")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
    unit: state.region.unit,
});

export default connect(mapStateToProps, {})(HoseFiltersPage);
