import React, { useCallback, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../auth-hook/auth-context";
import '../dashboard/DashboardStyles.css'

const PrivateRoute = ({ component: Component, render, location, ...rest }) => {
	const { isLoggedIn, initialLoaded } = useContext(AuthContext)
	const RouteData = useCallback(props => {
		if(!initialLoaded) return <Loader />
		if(!isLoggedIn) {
			return <Redirect to={`/Signin?redirectTo=${location.pathname}`} />
		}
		if(render) {
			return render(props)
		}

		return <Component {...props} />
	}, [isLoggedIn, initialLoaded])

	return (
		<Route
			{...rest}
			render={RouteData}
		/>
	)
};

const Loader = () => {
	return (
		<div
        className="show partial-loader"
        id="loading"
      >
        <div className="loading-message" />
      </div>
	)
}

export default PrivateRoute;
