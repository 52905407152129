import React, { useCallback, useContext, useEffect, useState } from 'react'

import * as uuid from 'uuid'

const defaultFilter = data => data

export const contexted = (context, filter = defaultFilter) => Component => {
    return props => {
        const data = useContext(context)
        const [key, setKey] = useState(null)

        const handleReset = () => {
            setKey(uuid.v4())
        }
        
        useEffect(() => {
            handleReset()
        }, [data])

        const getComponent = useCallback(() => <Component reset={handleReset} key={key} {...filter(data)} {...props} />, [key, data])

        return getComponent()
    }

}

// not open dataversion in another tab