import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

export class Ui {

    static showErrors(...messages) {
        messages.forEach(x => {
            if (!Array.isArray(x)) {
                toast.error(x, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 5000 });
            }
            else {
                (x).forEach((y) => toast.error(y, { position: toast.POSITION.BOTTOM_CENTER }));
            }
        });
    }
    static Clear() {
        toast.dismiss();
    }

    static showInfo(message) {
        toast.info(message, { position: toast.POSITION.BOTTOM_RIGHT });
    }

    static showSuccess(message, autoClose = 1000) {
        toast.success(message, { position: toast.POSITION.BOTTOM_CENTER, autoClose: autoClose });
    }

    static showInfowithLink(message, autoClose = 5000) {
        toast.info(message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: autoClose });
    }
}
