import { DashSizeInMetrics } from './DashSizeInMetrics';

export function UnitConversion(tableData) {
    if (tableData && Array.isArray(tableData)) {
        var mm = 25.4; // 1 inch = 25.4 mm
        var mpa = 145.038 // 1 psi = 145.038 MPa
        for (var i = 0; i < tableData.length; i++) {
            tableData[i].Id = i + 1
            tableData[i].isChecked = false

            tableData[i].HOSE_DISPLAY_NAME_NOTES = ""
            if (tableData[i].APPROXIMATE_DIGITAL_READOUT) {
                if (tableData[i].APPROXIMATE_DIGITAL_READOUT.match(/\d+\.{1}\d+/i)) {
                    //Added by siva, if condition based on IT service desk ticket RE-779347
                    if (tableData[i].APPROXIMATE_DIGITAL_READOUT.toString().split('.')[1].length >= 3) {
                        tableData[i].APPROXIMATE_DIGITAL_READOUT = Number(tableData[i].APPROXIMATE_DIGITAL_READOUT).toFixed(3)
                    }
                    else {
                        tableData[i].APPROXIMATE_DIGITAL_READOUT = Number(tableData[i].APPROXIMATE_DIGITAL_READOUT).toFixed(2)
                    }

                }
            }
            if (tableData[i].OUTSIDE_DIAMETER !== null || tableData[i].OUTSIDE_DIAMETER !== undefined) {
                tableData[i].OUTSIDE_DIAMETER_INENGLISH = Number(tableData[i].OUTSIDE_DIAMETER).toFixed(3)
                tableData[i].OUTSIDE_DIAMETER_INMETRICS = Number(tableData[i].OUTSIDE_DIAMETER * mm).toFixed(2)
            }

            //SIZE
            tableData[i].SIZE_INENGLISH = Number(tableData[i].SIZE).toFixed(2)
            // tableData[i].SIZE_INMETRICS = Number(tableData[i].SIZE * mm).toFixed(2)
            // This is the Hose Size whhc should be based on Dash size as the Hana table hose_size_SI_Conv for Metric units
            tableData[i].SIZE_INMETRICS = DashSizeInMetrics(tableData[i].DASH_SIZE, tableData[i].SIZE)

            // WORKING_PRESSURE
            tableData[i].WORKING_PRESSURE_INENGLISH = tableData[i].WORKING_PRESSURE
            tableData[i].WORKING_PRESSURE_INMETRICS = Math.round(tableData[i].WORKING_PRESSURE / mpa).toFixed(1)

            // SKIVE_LENGTH_DISPLAY
            if (tableData[i].SKIVE_LENGTH_DISPLAY === "0.000") {

                tableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH = "NONE"
                tableData[i].SKIVE_LENGTH_DISPLAY_INMETRICS = "NONE"
            }
            else if (!tableData[i].SKIVE_LENGTH_DISPLAY) {
                tableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH = "null"
                tableData[i].SKIVE_LENGTH_DISPLAY_INMETRICS = "null"
            }
            else if (tableData[i].SKIVE_LENGTH_DISPLAY.match(/[a-z]/i)) {
                tableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH = tableData[i].SKIVE_LENGTH_DISPLAY
                tableData[i].SKIVE_LENGTH_DISPLAY_INMETRICS = tableData[i].SKIVE_LENGTH_DISPLAY
            }
            else {
                // tableData[i].SKIVE_LENGTH_DISPLAY = (tableData[i].SKIVE_LENGTH_DISPLAY).toFixed(2)
                tableData[i].SKIVE_LENGTH_DISPLAY_INENGLISH = Number(tableData[i].SKIVE_LENGTH_DISPLAY).toFixed(2)
                tableData[i].SKIVE_LENGTH_DISPLAY_INMETRICS = Number(tableData[i].SKIVE_LENGTH_DISPLAY * mm).toFixed(2)

            }

            // SKIVE_DIAMETER_DISPLAY

            if (tableData[i].SKIVE_DIAMETER_DISPLAY === "0.000") {

                tableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH = "NONE"
                tableData[i].SKIVE_DIAMETER_DISPLAY_INMETRICS = "NONE"

            }
            else if (!tableData[i].SKIVE_DIAMETER_DISPLAY) {

                tableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH = "null"
                tableData[i].SKIVE_DIAMETER_DISPLAY_INMETRICS = "null"

            }
            else if (tableData[i].SKIVE_DIAMETER_DISPLAY.match(/[a-z]/i)) {
                tableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH = tableData[i].SKIVE_DIAMETER_DISPLAY
                tableData[i].SKIVE_DIAMETER_DISPLAY_INMETRICS = tableData[i].SKIVE_DIAMETER_DISPLAY
            }
            else {
                // tableData[i].SKIVE_DIAMETER_DISPLAY = (tableData[i].SKIVE_DIAMETER_DISPLAY).toFixed(2)
                tableData[i].SKIVE_DIAMETER_DISPLAY_INENGLISH = Number(tableData[i].SKIVE_DIAMETER_DISPLAY).toFixed(2)

                tableData[i].SKIVE_DIAMETER_DISPLAY_INMETRICS = Number(tableData[i].SKIVE_DIAMETER_DISPLAY * mm).toFixed(2)

            }
            // CRIMP INSERTION LENGTH
            if (tableData[i].INSERTION_LENGTH_DISPLAY === "0.000") {

                tableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH = "NONE"
                tableData[i].INSERTION_LENGTH_DISPLAY_INMETRICS = "NONE"

            }
            else if (!tableData[i].INSERTION_LENGTH_DISPLAY) {

                tableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH = "null"
                tableData[i].INSERTION_LENGTH_DISPLAY_INMETRICS = "null"

            }
            else if (tableData[i].INSERTION_LENGTH_DISPLAY.match(/[a-z]/i)) {
                tableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH = tableData[i].INSERTION_LENGTH_DISPLAY
                tableData[i].INSERTION_LENGTH_DISPLAY_INMETRICS = tableData[i].INSERTION_LENGTH_DISPLAY
            }
            else {
                // tableData[i].INSERTION_LENGTH_DISPLAY = (tableData[i].INSERTION_LENGTH_DISPLAY).toFixed(2)
                tableData[i].INSERTION_LENGTH_DISPLAY_INENGLISH = Number(tableData[i].INSERTION_LENGTH_DISPLAY).toFixed(2)
                tableData[i].INSERTION_LENGTH_DISPLAY_INMETRICS = Number(tableData[i].INSERTION_LENGTH_DISPLAY * mm).toFixed(2)

            }

            // CRIMP LENGTH
            if (tableData[i].LENGTH_DISPLAY === "0.000") {

                tableData[i].LENGTH_DISPLAY_INENGLISH = "NONE"
                tableData[i].LENGTH_DISPLAY_INMETRICS = "NONE"

            }
            else if (!tableData[i].LENGTH_DISPLAY) {

                tableData[i].LENGTH_DISPLAY_INENGLISH = "null"
                tableData[i].LENGTH_DISPLAY_INMETRICS = "null"

            }
            else if (tableData[i].LENGTH_DISPLAY.match(/[a-z]/i)) {
                tableData[i].LENGTH_DISPLAY_INENGLISH = tableData[i].LENGTH_DISPLAY
                tableData[i].LENGTH_DISPLAY_INMETRICS = tableData[i].LENGTH_DISPLAY
            }
            else {
                // tableData[i].LENGTH_DISPLAY = (tableData[i].LENGTH_DISPLAY).toFixed(2)
                tableData[i].LENGTH_DISPLAY_INENGLISH = Number(tableData[i].LENGTH_DISPLAY).toFixed(2)
                tableData[i].LENGTH_DISPLAY_INMETRICS = Number(tableData[i].LENGTH_DISPLAY * mm).toFixed(2)

            }

            // OUTSIDE_DIAMETER_DISPLAY

            if (tableData[i].OUTSIDE_DIAMETER_DISPLAY === "0.000") {

                tableData[i].OUTSIDE_DIAMETER_DISPLAY_INENGLISH = "NONE"
                tableData[i].OUTSIDE_DIAMETER_DISPLAY_INMETRICS = "NONE"

            }
            else if (!tableData[i].OUTSIDE_DIAMETER_DISPLAY) {

                tableData[i].OUTSIDE_DIAMETER_DISPLAY_INENGLISH = "null"
                tableData[i].OUTSIDE_DIAMETER_DISPLAY_INMETRICS = "null"

            }
            else if (tableData[i].OUTSIDE_DIAMETER_DISPLAY.match(/[a-z]/i)) {
                tableData[i].OUTSIDE_DIAMETER_DISPLAY_INENGLISH = tableData[i].OUTSIDE_DIAMETER_DISPLAY
                tableData[i].OUTSIDE_DIAMETER_DISPLAY_INMETRICS = tableData[i].OUTSIDE_DIAMETER_DISPLAY
            }
            else {
                //tableData[i].OUTSIDE_DIAMETER_DISPLAY_INMETRICS = Number(tableData[i].OUTSIDE_DIAMETER_DISPLAY * mm).toFixed(2)
                tableData[i].OUTSIDE_DIAMETER_DISPLAY_INENGLISH = Number(tableData[i].OUTSIDE_DIAMETER_DISPLAY).toFixed(3)
                tableData[i].OUTSIDE_DIAMETER_DISPLAY_INMETRICS = Number(Math.round((Number(tableData[i].OUTSIDE_DIAMETER_DISPLAY * mm)) / 0.05) * 0.05).toFixed(2);


            }
            //OUTSIDE_DIAMETER_TOLERANCE_DISPLAY

            if (tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY === "0.000") {

                tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH = "NONE"
                tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS = "NONE"

            }
            else if (!tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY) {

                tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH = "null"
                tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS = "null"

            } else if (tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY.match(/[a-z]/i)) {
                tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH = tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY
                tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS = tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY
            }
            else {
                // tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY = (tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY).toFixed(2)
                tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INENGLISH = Number(tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY).toFixed(3)
                tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY_INMETRICS = Number(tableData[i].OUTSIDE_DIAMETER_TOLERANCE_DISPLAY * mm).toFixed(2)

            }
            //TAIL_LENGTH

            if (tableData[i].TAIL_LENGTH === "0") {

                tableData[i].TAIL_LENGTH_INENGLISH = "NONE"
                tableData[i].TAIL_LENGTH_INMETRICS = "NONE"

            }
            else if (!tableData[i].TAIL_LENGTH) {

                tableData[i].TAIL_LENGTH_INENGLISH = "null"
                tableData[i].TAIL_LENGTH_INMETRICS = "null"

            } else if (tableData[i].TAIL_LENGTH.match(/[a-z]/i)) {
                tableData[i].TAIL_LENGTH_INENGLISH = tableData[i].TAIL_LENGTH
                tableData[i].TAIL_LENGTH_INMETRICS = tableData[i].TAIL_LENGTH
            }
            else {
                // tableData[i].TAIL_LENGTH = (tableData[i].TAIL_LENGTH).toFixed(2)
                tableData[i].TAIL_LENGTH_INENGLISH = Number(tableData[i].TAIL_LENGTH).toFixed(2)
                tableData[i].TAIL_LENGTH_INMETRICS = Number(tableData[i].TAIL_LENGTH * mm).toFixed(2)

            }
        }
    }
    return tableData || []
}
