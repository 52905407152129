import ResultJSON from './DashSizeConstants.json'
const mm = 25.4 // 1 inch = 25.4 mm

export function DashSizeInMetrics(DASH_SIZE, SIZE) {
    let isexist = false
    for (let i = 0; i < ResultJSON.length; i++) {
        if (ResultJSON[i].DASH_SIZE === DASH_SIZE) {
            isexist = true
            return ResultJSON[i].SIZE_INMETRICS
        }
    }
    if (!isexist) {
        return Math.round((SIZE * mm))
    }


}