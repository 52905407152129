import React, { Component } from 'react';
import ApiServices from './../../utils/ApiServices'
import API from './../../utils/config.json'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { T } from "react-translator-component";
import { connectDataContext } from '../common/DataProvider';
class Footer extends Component {
    parseLanguage = () => {
      const language = this.props.language
      if(!language || language === 'undefined' || language === 'null') {
        return 'EN';
      }

      return language.toUpperCase()
    }
    
    terms = async () => {
      const language = this.parseLanguage();
      const url = API.URL + `media-master?$format=json&$filter=CATEGORY eq 'TANDC' and (LANGUAGE eq '${language}' or LANGUAGE eq '') and (APP_USED_BY eq 'ecrimp' or APP_USED_BY eq 'All')`;
      
      const response = await ApiServices.httpGET(url);

      if (response?.data?.d?.results) {
        var data = response.data.d.results;
        if(Array.isArray(data) && data.length > 0) {
          const terms = data[0].URL
          window.open(terms, '_blank')
        }
      }
    }

    policy = async () => {
      const language = this.parseLanguage();
      const url = API.URL + `media-master?$format=json&$filter=CATEGORY eq 'PRIVACY' and (LANGUAGE eq '${language}' or LANGUAGE eq '') and (APP_USED_BY eq 'ecrimp' or APP_USED_BY eq 'All')`;
      
      const response = await ApiServices.httpGET(url);

      if (response?.data?.d?.results) {
        var data = response.data.d.results;
        if(Array.isArray(data) && data.length > 0) {
          const policy = data[0].URL
          window.open(policy, '_blank')
        }
      }
    }

    render() {
        return (  
              <footer>
                    <div className="footer">
                            <p className="left notranslate">
                        © {new Date().getFullYear()}&nbsp;{'Gates Corporation'  }                          
                            <a className="left-center" href="https://www.gates.com" target="_blank">Gates.com</a></p>
                            <p className="right">
                                <span style={{marginRight:"15px", cursor: "pointer"}} onClick={this.terms.bind(this)}>{T('Terms of Use')}</span>
                                <span style={{cursor: "pointer"}} onClick={this.policy.bind(this)}>{T('Privacy Policy')}</span>
                            </p>
                    </div>
            </footer>
        );
    }
}

Footer.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  console.log(state)
  return {
    auth: state.auth,
    flag: state.region.flag
  }
};

export default connectDataContext(
  connect(mapStateToProps, {})(
    Footer
  )
);