import React, { Component } from 'react';
import ApiServices from '../../../utils/ApiServices';
import Modal from 'react-bootstrap/Modal'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import './../authStyles.css'
import { Encryption } from '../../../utils/SecurePassword'
import API from './../../../utils/config.json'
import { T } from 'react-translator-component'
import Util from '../../../utils/Util';
import { connectDataContext } from '../../common/DataProvider';

class Registration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            open: true,
            firstname: "",
            lastname: "",
            company: "",
            country: "",
            email: "",
            password: "",
            termsofservice: false,
            privacy: false,
            agreemnt: false,
            errmessage: "",
            successmessage: "",
            terms: [],
            regions: []
        };
    }

    componentDidMount() {
        this.getRegions();
    }
    
    parseLanguage = () => {
        const language = this.props.language
        if(!language || language === 'undefined' || language === 'null') {
          return 'EN';
        }
  
        return language.toUpperCase()
      }

    terms = async () => {
        const language = this.parseLanguage();
        const url = API.URL + `media-master?$format=json&$filter=CATEGORY eq 'TANDC' and (LANGUAGE eq '${language}' or LANGUAGE eq '') and (APP_USED_BY eq 'ecrimp' or APP_USED_BY eq 'All')`;
      
      const response = await ApiServices.httpGET(url);

      if (response?.data?.d?.results) {
        var data = response.data.d.results;
        if(Array.isArray(data) && data.length > 0) {
          const terms = data[0].URL
          window.open(terms, '_blank')
        }
      }
    }

    policy = async () => {
        const language = this.parseLanguage();
        const url = API.URL + `media-master?$format=json&$filter=CATEGORY eq 'PRIVACY' and (LANGUAGE eq '${language}' or LANGUAGE eq '') and (APP_USED_BY eq 'ecrimp' or APP_USED_BY eq 'All')`;
      
      const response = await ApiServices.httpGET(url);

      if (response?.data?.d?.results) {
        var data = response.data.d.results;
        if(Array.isArray(data) && data.length > 0) {
          const policy = data[0].URL
          window.open(policy, '_blank')
        }
      }
    }
    show = (type) => {
        // Shows the selected popup

        this.setState({ open: true });


    }
    hide = (type) => {
        // Hides the selected popup

        this.setState({ open: false });

    }
    getRegions = () => {
        var url = API.URL + "countries?$orderby=COUNTRY_NAME";
        ApiServices.httpGET(url, (err, response) => {
            if (response?.data?.d?.results) {
                var regions = response.data.d.results;
                this.setState({ regions: response.data.d.results });
            };
        })
    }
    onSubmit = () => {
        const { firstname, lastname, company, country, email, password, agreemnt, privacy, termsofservice } = this.state;
        this.setState({ errmessage: "", successmessage: "" })
        var isvalid = false;
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            isvalid = true;
        }
        else {

            alert("You have entered an invalid email address!")
            isvalid = false;
            return false;
        }
        if (password.length >= 8) {
            isvalid = true;

        }
        else {
            alert("Password must be at least 8 characters !")
            isvalid = false;
            return false;
        }
        if (firstname.trimStart().trimEnd() !== "" && lastname.trimStart().trimEnd() !== "" && country.trimStart().trimEnd() !== "") {
            isvalid = true;

        }
        else {
            alert("Please fill mandatory details")
            isvalid = false;
            return false;
        }
        if (termsofservice && privacy) {
            isvalid = true;

        }
        else {
            alert("Please fill the checkboxes")
            isvalid = false;
            return false;
        }

        // password encryption 
        var encryptedPassword = Encryption(password);
        var Newsletter=0;
        if(agreemnt==true){Newsletter=1}

        const date = "/Date(" + Date.now() + ")/";
        var data = {
            FIRST_NAME: firstname.toUpperCase(),
            LAST_NAME: lastname.toUpperCase(),
            COMPANY: company.toUpperCase(),
            EMAIL: email.toLowerCase(),
            PASSWORD: encryptedPassword,
            NEWSLETTER: Newsletter,
            COUNTRY_NAME: country,
            POLICY: 1,
            POLICY_ACCEPT_DATE: date,
            CREATED_DATE: date,
            LAST_MODIFIED: date,
        }
        var url = API.URL + `users`

        if (isvalid) {

            ApiServices.httpPOST(url, data, (err, response) => {
                if (response) {
                    var data = response;
                    if (response.status === 200) {
                        if (data.data.error) {
                            this.setState({ errmessage: "Email Already exists" })

                            return false
                        }
                        else {
                            this.setState({ successmessage: "Account Creation Successfull" })
                            this.props.history.push(`/Signin${this.props.location.search}`)

                        }


                    }
                };
            });
        }


    }

    changeFirstname = (event) => {
        this.setState({ firstname: event.target.value });
    }
    changeLastname = (event) => {
        this.setState({ lastname: event.target.value });
    }
    changecompany = (event) => {
        this.setState({ company: event.target.value });
    }
    changecountry = (event) => {
        this.setState({ country: event.target.value });
    }
    changeemail = (event) => {
        this.setState({ email: event.target.value });
    }
    changepassword = (event) => {
        this.setState({ password: event.target.value });
    }
    changeTermsofservice = (event) => {
        this.setState({ termsofservice: event.target.checked });
    }
    changePrivacy = (event) => {
        this.setState({ privacy: event.target.checked });
    }
    changeAgreement = (event) => {
        this.setState({ agreemnt: event.target.checked });
    }

    redirect = (Topage) => {
        var hostname = window.location.hostname;
        var page = Topage;
        var goto = page
        window.location = goto
    }
    render() {

        const { firstname, lastname, company, country, email, password, agreemnt, termsofservice, privacy, errmessage, successmessage, terms, regions } = this.state;
        const data = [
            { NAME: "Crimper", CREATED_DATE: "02-05-2022 04:00", LAST_MODIFIED: "02-05-2022 05:00" }
        ]
        const countryList = [
            { value: 'India', label: 'India' },
            { value: 'USA', label: 'USA' },
            { value: 'UK', label: 'UK' },
        ];
        const { open } = this.state;

        return (
            <div className="section-container ">

                <Modal
                    show={open}
                    onHide={open}
                    dialogClassName="modal-40w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header >
                        <Modal.Title id="example-custom-modal-styling-title" className='custom-title'>
                            <div className='header-padding'>

                                <h2 className='underlinestyle'>{T("SIGN UP")}</h2>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className=''>

                            <div className='registraion-container '>
                                <div className="form-group  " style={{ marginLeft: "1%" }}>
                                    <label className=" col-form-label"><b>{T("First Name")}</b> <span className="star"></span></label>
                                    <div className="" >
                                        <input type="text" id="txt_name" className="form-control text-box-border" value={firstname} onChange={this.changeFirstname} />
                                    </div>
                                </div>

                                <div className="form-group  " style={{ marginLeft: "1%" }}>
                                    <label className=" col-form-label"><b>{T("Last Name")}</b> <span className="star"></span></label>
                                    <div className="" >
                                        <input type="text" id="txt_name" className="form-control text-box-border" value={lastname} onChange={this.changeLastname} />
                                    </div>
                                </div>
                                <div className="form-group  " style={{ marginLeft: "1%" }}>
                                    <label className=" col-form-label"><b>{T("Company")} </b></label>
                                    <div className="" >
                                        <input type="text" id="txt_name" className="form-control text-box-border" value={company} onChange={this.changecompany} />
                                    </div>
                                </div>
                                <div className="form-group  " style={{ marginLeft: "1%" }}>
                                    <label className=" col-form-label "><b>{T("Country")}</b> <span className="star"></span></label>
                                    <div className="" >
                                        <select className='select-dropmenu text-box-border' onChange={this.changecountry}>
                                            <option value="" disabled selected>Select...</option>

                                            {regions ? regions.map((value, index) => <option value={value.COUNTRY_NAME} >{value.COUNTRY_NAME}</option>) : ""}

                                        </select>
                                    </div>
                                </div>
                                <div className="form-group  " style={{ marginLeft: "1%" }}>
                                    <label className=" col-form-label"><b>{T("Email Address")}  </b><span className="star"></span></label>
                                    <div className="" >
                                        <input type="text" id="txt_name" className="form-control text-box-border" value={email} onChange={this.changeemail} />
                                    </div>
                                    <p className="text-danger">{errmessage}</p>
                                    <p className="text-info">{successmessage}</p>

                                </div>
                                <div className="form-group  " style={{ marginLeft: "1%" }}>
                                    <label className=" col-form-label"><b>{T("Password")}</b> <span className="star"></span></label>
                                    <div className="" >
                                        <input type="password" id="txt_name" className="form-control text-box-border" placeholder='must be at least 8 characters' value={password} onChange={this.changepassword} />
                                    </div>
                                </div>

                                <div className="form-group row " style={{ marginLeft: "1%" }}>
                                    <input type="checkbox" id="txt_name" className='custom-checkbox' value={termsofservice} onChange={this.changeTermsofservice} />
                                    <div className="" style={{ marginLeft: "1%" }}>I agree to the <span className='text-red cursor' onClick={this.terms.bind(this)}>{T('Terms of Use')} </span><span className="star"></span>
</div>
                                </div>
                                <div className="form-group row " style={{ marginLeft: "1%" }}>
                                    <input type="checkbox" id="txt_name" className='custom-checkbox' value={privacy} onChange={this.changePrivacy} />
                                    <div className="" style={{ marginLeft: "1%" }}>I accept the <span className='text-red cursor' onClick={this.policy.bind(this)}>{T('Privacy Policy')}</span><span className="star"></span>
</div>
                                </div>
                                <div className="form-group row " style={{ marginLeft: "1%" }}>
                                    <input type="checkbox" id="txt_name" className='custom-checkbox' value={agreemnt} onChange={this.changeAgreement} />
                                    <div className="" style={{ marginLeft: "1%" }}>I agree to receive marketing / promotional emails from Gates </div>
                                </div>
                                <br />
                                <button className="change-btn " type="button" onClick={() => this.onSubmit()} >{T("SIGN UP")}</button>

                            </div>

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.redirect("Signin")}>{T("Back")}</button>
                        <button className="cancel-btn-modal" type="button" onClick={() => this.redirect("/")}>{T("Close")}</button>

                    </Modal.Footer>
                </Modal>


            </div>
        );
    }
}
Registration.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connectDataContext(
    connect(mapStateToProps, {})(
        Registration
    )
);

