import React, { createContext, useEffect, useMemo, useState } from 'react'
import { contexted } from './ContextStore';
import { useDB } from '../../IndexedDB/useDB';

export const DataContext = createContext();

export const connectDataContext = contexted(DataContext)

export const PressureTypes = {
    mpa: "MPa",
    psi: "PSi",
}

const DataContextProvider = ({ children }) => {
    const { get, put, remove } = useDB()
    const [language, setLanguage] = useState('en')
    const [country, setCountry] = useState('US')
    const [region, setRegion] = useState('NA')
    const [flag, setFlag] = useState(null)
    const [pressureType, setPressureType] = useState(PressureTypes.psi)
    const [crimper, setCrimper] = useState(null)
    const [revision, setRevision] = useState(null)

    const setSelectedLanguage = async language => {
        await put('language', language)
        setLanguage(language)
    }

    const setSelectedCountry = async (country, force = true) => {
        await put('country', country)
        setFlag(`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country || 'US'}.svg`)
        setPressureType(country === 'US' ? PressureTypes.psi : PressureTypes.mpa)
        setCountry(country)
        if(force) cleanSelectedCrimper()
    }

    const setSelectedRegion = async (region, force = true) => {
        await put('region', region)
        setRegion(region)
        if(force) cleanSelectedCrimper()
    }

    const cleanSelectedCrimper = async () => {
        await remove('selectedCrimper')
        setCrimper(null)
    }

    const setSelectedCrimper = async (selected, force = true) => {
        if(!selected) return
        await put('selectedCrimper', selected)
        setCrimper(selected)
        if(force) applyChanges()
    } 

    const applyChanges = () => {
        setRevision(Date.now())
    }

    const loadPrefs = async () => {
        const selectedLanguage = await get('language')
        const selectedCountry = await get('country')
        const selectedRegion = await get('region')
        const selectedCrimper = await get('selectedCrimper')

        if (selectedLanguage) await setSelectedLanguage(selectedLanguage.data)
        if (selectedCountry) await setSelectedCountry(selectedCountry.data, false)
        if (selectedRegion) await setSelectedRegion(selectedRegion.data, false)
        if(selectedCrimper) await setSelectedCrimper(selectedCrimper.data, false)
        applyChanges()
    }

    useEffect(() => {    
        loadPrefs()
    }, [])

    useEffect(() => {
        console.log(country, flag)
    }, [country, flag])


    const data = useMemo(() => {
        return {
            language,
            setSelectedLanguage,
            country,
            flag,
            setSelectedCountry,
            region,
            setSelectedRegion,
            pressureType,
            crimper,
            setSelectedCrimper,
            revision,
            cleanSelectedCrimper,
            applyChanges,
            loadInitialData: loadPrefs
        }
    }, [revision])

    return (
        <DataContext.Provider value={data}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContextProvider