import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import './MyAccount.css';
import clip from '../../../images/clipboard.png';
import ApiServices from './../../../utils/ApiServices';
import API from './../../../utils/config.json';
import { Decryption, Encryption } from '../../../utils/SecurePassword';
import { Ui } from '../../common/Toaster';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { T } from 'react-translator-component'
import Util from '../../../utils/Util';

class MyAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            company: "",
            country: "",
            email: "",
            password: "",
            newPassword: "",
            newsLetter: "",
            terms: [],
            regions: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.getDetails();
        this.getRegions();
    }
    getRegions = () => {
        var url = API.URL + "countries?$orderby=COUNTRY_NAME";
        ApiServices.httpGET(url, (err, response) => {
            if (response?.data?.d?.results) {
                var regions = response.data.d.results;
                this.setState({ regions: response.data.d.results });
            };
        })
    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;
        this.setState({ ...this.state, [name]: value });
    }
    changecountry = (event) => {
        this.setState({ country: event.target.value });
    }
    handleSubmit(event) {
        const url = API.URL + "users?$format=json&$filter=EMAIL eq '" + this.state.email + "'";
        const patchUrl = API.URL + "users/" + this.state.email;

        if (this.state.password !== '' && this.state.newPassword !== '') {
            ApiServices.httpGET(url, (err, response) => {
                if (response?.data?.d?.results) {
                    let result = response.data.d.results;
                    if (result.length !== 0) {

                        var oldPassword = this.state.password;
                        var encryptedCP = result[0].PASSWORD;
                        // Password Decryption
                        var decryptedUtf8 = Decryption(encryptedCP);

                        if (decryptedUtf8 === oldPassword) {
                            const patchData = {
                                FIRST_NAME: this.state.firstname,
                                LAST_NAME: this.state.lastname,
                                COMPANY: this.state.company,
                                PASSWORD: Encryption(this.state.newPassword),
                                NEWSLETTER: (this.state.newsLetter === true ? 1 : 0),
                                COUNTRY_NAME: this.state.country,
                                LAST_MODIFIED: "/Date(" + Date.now() + ")/"
                            }
                            ApiServices.httpPATCH(patchUrl, patchData, (err, response) => {
                                if (response.status === 200) {
                                    Ui.showSuccess("Account Details Updated!");
                                }
                                else {
                                    Ui.showErrors("Issue updating account details.");
                                }

                            })
                        }
                        else {
                            Ui.showErrors("Password doesn't match with what we have on account.");
                        }

                    }
                    else {
                        Ui.showErrors("User not found.");
                    }
                }
            })
        }

        else {
            const patchData = {
                FIRST_NAME: this.state.firstname,
                LAST_NAME: this.state.lastname,
                COMPANY: this.state.company,
                NEWSLETTER: (this.state.newsLetter === true ? 1 : 0),
                COUNTRY_NAME: this.state.country,
                LAST_MODIFIED: "/Date(" + Date.now() + ")/"
            }
            ApiServices.httpPATCH(patchUrl, patchData, (err, response) => {
                if (response.status === 200) {
                    Ui.showSuccess("Account Details Updated!");
                }
                else {
                    Ui.showErrors("Issue updating Account details.");
                }
            })
        }
    }
    parseLanguage = () => {
        const language = this.props.language
        if(!language || language === 'undefined' || language === 'null') {
          return 'EN';
        }
  
        return language.toUpperCase()
      }

    terms = async () => {
      const language = this.parseLanguage();
      const url = API.URL + `media-master?$format=json&$filter=CATEGORY eq 'TANDC' and (LANGUAGE eq '${language}' or LANGUAGE eq '') and (APP_USED_BY eq 'ecrimp' or APP_USED_BY eq 'All')`;
      
      const response = await ApiServices.httpGET(url);
      
      if (response?.data?.d?.results) {
        var data = response.data.d.results;
        if(Array.isArray(data) && data.length > 0) {
          const terms = data[0].URL
          window.open(terms, '_blank')
        }
      }
    }

    policy = async () => {
      const language = this.parseLanguage();
      const url = API.URL + `media-master?$format=json&$filter=CATEGORY eq 'PRIVACY' and (LANGUAGE eq '${language}' or LANGUAGE eq '') and (APP_USED_BY eq 'ecrimp' or APP_USED_BY eq 'All')`;
      
      const response = await ApiServices.httpGET(url);

      if (response?.data?.d?.results) {
        var data = response.data.d.results;
        if(Array.isArray(data) && data.length > 0) {
          const policy = data[0].URL
          window.open(policy, '_blank')
        }
      }
    }

    getDetails = () => {
        var email = Util.getCookie("email");


        const url = API.URL + "users?$format=json&$filter=EMAIL eq '" + email + "'";
        ApiServices.httpGET(url, (err, response) => {
            if (response) {
                var data = response.data.d.results[0];

                if (response.status === 200) {
                    this.setState({
                        firstname: data.FIRST_NAME, lastname: data.LAST_NAME, company: data.COMPANY, country: data.COUNTRY_NAME,
                        email: data.EMAIL, newsLetter: (data.NEWSLETTER !== 1 ? false : true)
                    })
                }
            };
        });
    }
    render() {
        const { firstname, lastname, company, country, email, newsLetter, terms, regions } = this.state;
        const data = [
            { NAME: "Crimper", CREATED_DATE: "02-05-2022 04:00", LAST_MODIFIED: "02-05-2022 05:00" }
        ]
        return (
            <div className="section-container ">
                <a href="/Dashboard" className="color-black">
                    <ArrowBackIos className="backmenu cursor" />
                    <label className="cursor">{T("Back to Dashboard")}</label>
                </a>
                <br />
                <br />
                <div className='redline'>
                <b><h4>{T("MyAccount")} </h4></b>
                </div>


                <div className='row col-md-12 '>
                    <div className='col-md-6 margin-bottom-10  myaccount-center'>
                        <div className="form-group" style={{ marginLeft: "1%" }}>
                            <label className="col-form-label"><b>First Name</b> <span className="colon"></span></label>
                            <div className="" >
                                <input type="text" id="txt_firstname" name="firstname" className="form-control text-box-border"
                                    value={firstname} onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className="form-group" style={{ marginLeft: "1%" }}>
                            <label className="col-form-label"><b>Last Name</b> <span className="colon"></span></label>
                            <div className="" >
                                <input type="text" id="txt_lastname" name="lastname" className="form-control text-box-border"
                                    value={lastname} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="form-group" style={{ marginLeft: "1%" }}>
                            <label className="col-form-label"><b>Company </b><span className="colon"></span></label>
                            <div className="" >
                                <input type="text" id="txt_company" name="company" className="form-control text-box-border"
                                    value={company} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="form-group" style={{ marginLeft: "1%" }}>
                            <label className="col-form-label"><b>Country</b> <span className="colon"></span></label>
                            <div className="" >
                                <select className='select-dropmenu text-box-border' defaultValue={country} onChange={this.changecountry}>
                                    {regions ? regions.map((value, index) => <option selected={country === value.COUNTRY_NAME} value={value.COUNTRY_NAME} >{value.COUNTRY_NAME}</option>) : ""}
                                </select>
                            </div>
                        </div>
                        <div className="form-group" style={{ marginLeft: "1%" }}>
                            <label className="col-form-label"><b>Email Address </b><span className="colon"></span></label>
                            <div className="" >
                                <input type="email" id="txt_email" className="form-control text-box-border" readOnly
                                    value={email} />
                            </div>
                        </div>
                        <div className="form-group" style={{ marginLeft: "1%" }}>
                            <label className="col-form-label"><b>Old Password</b> <span className="colon"></span></label>
                            <div className="" >
                                <input type="password" id="txt_password" name="password" className="form-control text-box-border"
                                    value={this.state.password} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="form-group" style={{ marginLeft: "1%" }}>
                            <label className="col-form-label"><b>New Password </b><span className="colon"></span></label>
                            <div className="" >
                                <input type="password" id="txt_new_password" name="newPassword" placeholder='must be atleast 8 characters'
                                    className="form-control text-box-border" value={this.state.newPassword} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="form-group row" style={{ marginLeft: "1%" }}>
                            <input type="checkbox" id="txt_marketing" name="newsLetter" className='custom-checkbox'
                                checked={newsLetter} onChange={this.handleChange} />
                            <div className="" style={{ marginLeft: "1%" }}>I agree to receive marketing / promotional emails from Gates </div>
                        </div>
                        <button className="change-btn" type="button" onClick={this.handleSubmit}>CHANGE</button>

                    </div>
                    <div className='col-md-3 margin-bottom-10'>
                        <div className='myaccount-setting'>
                            <div className='padding-top-12 margin-left-12'>
                                <span className='bold-text-large'>SETTINGS</span>
                                <br />
                                <br />
                                {/* <p className='bold-text-small'>UNIT OF MEASUREMENT</p>
                                <input id="setttings" type="radio" name="s" /><label className='font-bold margin-left-3' for="">Metric</label><br />
                                <input id="setttings" type="radio" name="s" /><label className='font-bold margin-left-3' for="">Imperial</label>
                                <br />
                                <br /> */}
                                <div className='row margin-left-none'>
                                    <img className='terms-icon' src={clip} /><span className='bold-text-small cursor' onClick={this.terms.bind(this)}>{T('Terms of Use')}</span>
                                </div>

                                <br />
                                <div className='row margin-left-none'>
                                    <img className='terms-icon' src={clip} /><span className='bold-text-small cursor' onClick={this.policy.bind(this)}>{T('Privacy Policy')}</span>
                                </div>
                                <br />
                                <a className='bold-text-small' href='https://www.gates.com/us/en/solutions/product-innovations.html' target='_blank'>New Products</a>
                                <br />
                                <a className='bold-text-small' href='https://www.gates.com/' target='_blank'>About us</a>
                                <br />
                                <a target='_self' rel="noopener noreferrer" className='bold-text-small' href='/user/delete'>Delete Account</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
MyAccount.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(
    MyAccount
);

