import appDB from './indexedDB'

const db = appDB.db
// dataHoseFilters

export async function savedataHoseFilters(data) {
  if (data) {
    if (db.dataHoseFilters) db.dataHoseFilters.clear()
    db.dataHoseFilters
      .add({ dataHoseFilters: 'dataHoseFilters', data })
      .then(() => {})
  }
}

export async function getdataHoseFilters() {
  const testData = await db.dataHoseFilters
    .where('dataHoseFilters')
    .equals('dataHoseFilters')
    .toArray()
  if (testData && testData.length > 0) {
    return testData[0].data
  }
  return null
}

export async function cleardataHoseFilters() {
  db.dataHoseFilters.clear()
}
