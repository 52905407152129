import React, { Component } from "react";
import './../authStyles.css'
import { Container, Button, Form} from "react-bootstrap";
import queryString from 'query-string';
import ApiServices from '../../../utils/ApiServices';
import API from '../../../utils/config.json';
import { T } from "react-translator-component";
import { Encryption } from "../../../utils/SecurePassword";
import { Ui } from "../../common/Toaster";
import { ToastContainer, toast } from 'react-toastify';


class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.changeNewPassword = this.changeNewPassword.bind(this);
        this.changeConfirmPassword = this.changeConfirmPassword.bind(this);

        this.state = {
            newPassword: "",
            confirmPassword: "",
            isSame: false,
            message: "",
            tokenEmailVerified: false,
            email:""
        }
    }

    componentDidMount() {

        const queryParams = queryString.parse(window.location.search);
        if(queryParams.email !== undefined && queryParams.token !== undefined) {
            let url = API.URL + "users/verify-email-token?$format=json&$filter=EMAIL eq '"+queryParams.email+"' and TOKEN eq '"+queryParams.token+"'";
        
            ApiServices.httpGET(url, (err, response) => {
                if(response?.data?.d?.results[0].EMAIL === queryParams.email && response?.data?.d?.results[0].TOKEN === queryParams.token)
                {
                    this.setState({tokenEmailVerified: true});
                    this.setState({email: queryParams.email});
                    
                }
                else {
                    this.setState({message: T("ResetPassword.TokenVerificationFailed")});
                }
            })
        }
        else {
            this.setState({message: T("ResetPassword.TokenVerificationFailed")});
        }
    }
    

    changeNewPassword = (event) => {
        this.setState({newPassword: event.target.value});
    }

    changeConfirmPassword = (event) => {
        this.setState({confirmPassword: event.target.value});
    }

    resetPassword = (event) => { 

        event.preventDefault();
        if(this.state.newPassword !== '' && this.state.confirmPassword !== '') {

            if(this.state.newPassword !== this.state.confirmPassword) {
                this.setState({message: T("ResetPassword.PasswordShouldMatch")})
                Ui.showErrors(T("ResetPassword.PasswordShouldMatch").toString());
            }

            else {
                
                //Do a PATCH to USERS_WEB
                let url = API.URL + "users/" + this.state.email;
                const patchData = {
                    "PASSWORD": Encryption(this.state.newPassword)
                }
                
                ApiServices.httpPATCH(url, patchData, (err, response) => {
                    if (response.status === 200) {
                        this.setState({message: T("ResetPassword.PasswordUpdated")});
                        Ui.showSuccess(T("ResetPassword.PasswordUpdated").toString());
                    }
                    else {
                        this.setState({message: T("ResetPassword.TechnicalDifficulty")});
                        Ui.showErrors(T("ResetPassword.TechnicalDifficulty").toString());
                    }
                })
            }
        }
        else {
            this.setState({message: T("ResetPassword.EnterValuesPassword")})
            Ui.showErrors(T("ResetPassword.EnterValuesPassword").toString());
        }
    }

    render() {
        const { tokenEmailVerified } = this.state;
        return (
            <div className="section-container">
            {
                    tokenEmailVerified ? 
                    <Container>
                        <ToastContainer />
                        <div className="underlinestyle">
                        </div>
                            <h2>Reset Password</h2>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control className="text-box-border" type="password" placeholder="New Password" value={this.state.newPassword} 
                                    onChange={this.changeNewPassword}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control className="text-box-border" type="password" placeholder="Confirm Password" value={this.state.confirmPassword} 
                                    onChange={this.changeConfirmPassword}/>
                                </Form.Group>
                                <Button variant="danger" type="button" onClick={this.resetPassword}>
                                    Submit
                                </Button>
                            </Form> 
                    </Container>
                    : <h2>{this.state.message}</h2>
                }
            </div>
        )
    }
}

export default ResetPassword;